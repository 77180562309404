import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {createRef, LegacyRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Identifier, XYCoord} from "dnd-core";
import {
  createEditorTestsQuestionChoice,
  deleteEditorTestQuestionChoice,
  deleteEditorTestQuestionChoiceFile,
  linkEditorTestQuestionChoiceFile,
  purgeEditorTestQuestionChoices,
  updateEditorTestsQuestionChoice
} from "store/editorTest";
import {Box, Button, Grid, LinearProgress} from "@mui/material";
import clsx from "clsx";
import {Delete, DragHandle, Edit, Image, TextFields, Tune} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {SwitchPlain} from "components/form/CheckboxFormField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {ConfirmDialog} from "components/ConfirmDialog";
import {ContextMenu, ContextMenuAction} from "components/ContextMenu";
import {
  ChoiceType,
  DragItem,
  QuestionType,
  sortByPosition,
  TestEntryType,
  uploadFile,
  VersionsType
} from "../../helpers/editorTest";
import {EditorTestChoice} from "components/editorTest/EditorTestChoice";
import {EditorTestQuestionForm} from "components/editorTest/EditorTestQuestionForm";
import {
  createCatalogueQuestionChoice,
  deleteCatalogueQuestionChoice,
  deleteCatalogueQuestionChoiceFile,
  linkCatalogueQuestionChoiceFile,
  purgeCatalogueQuestionChoices,
  updateCatalogueQuestionChoice
} from "store/catalogues";

interface Props {
  testId?: number;
  stepId?: number;
  testHasSteps?: boolean;
  catalogueId?: number;
  question: QuestionType;
  entry?: TestEntryType;
  questionNo?: number;
  versions?: VersionsType;
  onOpen?: () => unknown;
  open?: boolean;
  onEntryMove?: (dragId: number, hoverId: number) => void;
  onEntryChange?: (entry: TestEntryType) => void;
  onQuestionChange: (q: QuestionType) => void;
  onQuestionRemove?: (q: QuestionType) => void;
  onQuestionFileRemove: (q: QuestionType) => void;
  onQuestionFileUpload: (questionId: number, f: File) => void;
  disabled: boolean;
  onBroadcastAnyChanged: () => void;
}

interface QuestionAndChoicesState {
  showProgress: boolean;
  explanationNeeded: boolean;
  choicesTypeChange?: string;
  stepsModalOpen?: boolean
}

const evalExplanationNeeded = (choices: ChoiceType[]): boolean => {
  return !!choices.find((ch) => !!ch.explanation);
}

const imageStyles = {
  '.imageQuestion': {
    '.imageActions': {
      marginLeft: '40px',
      width: '410px',
    },
    '.imageContent': {
      marginLeft: '40px',
      width: '410px',
      height: '330px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F2F2F2',
      borderRadius: '6px',
      border: '1px solid #C4C4C4',
      '.imageContainer': {
        position: 'relative',
        'img': {
          display: 'block',
          maxWidth: '408px',
          maxHeight: '330px',
          width: 'auto',
          height: 'auto',
        }
      }
    }
  }
};

export const EditorTestQuestion = (props: Props) => {

  const {
    testId, stepId, testHasSteps, catalogueId,
    question, entry, questionNo,
    versions, open,
    onOpen, onQuestionChange,
    onEntryMove, onEntryChange,
    onQuestionRemove, onQuestionFileRemove, onQuestionFileUpload, disabled, onBroadcastAnyChanged
  } = props;

  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const [choices, setChoices] = useState<ChoiceType[]>((question.choices || []).sort(sortByPosition));
  const [dirtyChoices, setDirtyChoices] = useState<ChoiceType[] | undefined>(undefined);
  const [questionAndChoicesState, setQuestionAndChoicesState] = useState<QuestionAndChoicesState>({
    showProgress: false,
    explanationNeeded: evalExplanationNeeded(question.choices || []),
  });
  const createFileRef = createRef<HTMLInputElement>();
  const updateFileRef = createRef<HTMLInputElement>();
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const [{handlerId}, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'question',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current || !entry) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = entry.position

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if (onEntryMove) {
        onEntryMove(dragIndex, hoverIndex)
      }

      item.index = hoverIndex
    }
  }, [entry]);

  const [{isDragging}, drag, preview] = useDrag({
    type: 'question',
    item: () => {
      return {id: entry?.id, index: entry?.position, targetIndex: entry?.position}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item) {
      if (onEntryChange && item.targetIndex !== item.index && item.index && entry) {
        onEntryChange({...entry, position: item.index});
      }
    },
  }, [entry]);

  const broadcastEditorSuccessEvent = useCallback(() => {
    onBroadcastAnyChanged();
  }, [onBroadcastAnyChanged]);

  const maxPosition = choices.length;
  const addQuestionChoice = useCallback(async (values: (string | undefined)[]) => {
    const newItems: ChoiceType[] = [];
    let newPosition = maxPosition + 1;
    for (const value of values) {
      const body = {
        question_id: question.id,
        position: newPosition++,
        value: (value || ''),
        is_correct: question.question_type === 'matching',
        payload: []
      };
      let item;
      if (testId) {
        item = (await dispatch(createEditorTestsQuestionChoice({
          testId,
          questionId: question.id,
          body
        })))?.payload as ChoiceType;
      }
      if (catalogueId) {
        item = (await dispatch(createCatalogueQuestionChoice({
          questionCatalogueId: catalogueId,
          questionId: question.id,
          body
        })))?.payload as ChoiceType;
      }
      if (item?.id) {
        newItems.push(item);
      }
    }
    if (newItems.length > 0) {
      setChoices((items) => {
        return [...items, ...newItems];
      });
    }
    broadcastEditorSuccessEvent();

  }, [testId, catalogueId, question, broadcastEditorSuccessEvent, maxPosition, setChoices, dispatch]);

  const handleChoiceDelete = useCallback(async (choice: ChoiceType) => {
    if (testId) {
      if (choice.file?.id) {
        await dispatch(deleteEditorTestQuestionChoiceFile({
          id: '' + choice.file.id,
          choiceId: choice.id
        }));
      }
      await dispatch(deleteEditorTestQuestionChoice({
        id: '' + choice.id,
        testId,
        questionId: question.id,
      }));
    }
    if (catalogueId) {
      if (choice.file?.id) {
        await dispatch(deleteCatalogueQuestionChoiceFile({
          id: '' + choice.file.id,
          choiceId: choice.id,
          questionCatalogueId: catalogueId,
          questionId: question.id,
        }));
      }
      await dispatch(deleteCatalogueQuestionChoice({
        id: '' + choice.id,
        questionCatalogueId: catalogueId,
        questionId: question.id,
      }));
    }
    setChoices((items) => {
      return items
        .filter((item) => choice.id !== item.id)
        .map((item, i) => ({...item, position: i + 1}));
    });
    broadcastEditorSuccessEvent();

  }, [testId, catalogueId, question, broadcastEditorSuccessEvent, setChoices, dispatch]);

  const handleChoiceTypeChange = useCallback(async () => {
    if (!questionAndChoicesState.choicesTypeChange) {
      return;
    }
    if (testId) {
      dispatch(purgeEditorTestQuestionChoices({
        testId,
        questionId: question.id,
        body: {}
      }));
    }
    if (catalogueId) {
      dispatch(purgeCatalogueQuestionChoices({
        questionCatalogueId: catalogueId,
        questionId: question.id,
        body: {}
      }));
    }

    onQuestionChange({...question, response_type: questionAndChoicesState.choicesTypeChange});

    setQuestionAndChoicesState((s) => {
      return {...s, choicesTypeChange: undefined}
    })
    setChoices([]);
    broadcastEditorSuccessEvent();

  }, [testId, catalogueId, question, broadcastEditorSuccessEvent, questionAndChoicesState.choicesTypeChange,
    setQuestionAndChoicesState, setChoices, onQuestionChange, dispatch]);

  const handleChoiceMove = useCallback((dragIndex: number, hoverIndex: number) => {
    setChoices((prevChoices: ChoiceType[]) => {
        const dragged = prevChoices.find((ch) => ch.position === dragIndex);
        if (!dragged || dragged.position === hoverIndex || dragIndex < 1 || hoverIndex > prevChoices.length) {
          return prevChoices;
        }
        const newChoices = [...prevChoices];
        newChoices.splice(dragIndex - 1, 1);
        newChoices.splice(hoverIndex - 1, 0, dragged)
        return newChoices.map((ch, i) => {
          return {...ch, position: i + 1}
        });
      }
    )
  }, [setChoices]);

  const handleChoiceChange = useCallback(async (choice: ChoiceType) => {
    setChoices((items) => {
      const current = items.find((ch) => ch.id === choice.id);
      if (current === choice) {
        return items;
      }
      const dirtyChoices = [choice];
      if (choice.is_correct && question.question_type === 'single') {
        items.filter((ch) => ch.is_correct && ch.id !== choice.id)
          .forEach((ch) => {
            ch.is_correct = false;
            dirtyChoices.push({...ch, is_correct: false});
          })
      }
      setDirtyChoices(dirtyChoices);
      return items.map((ch) => ch.id === choice.id ? choice : ch);
    });

  }, [question, setChoices, setDirtyChoices]);

  const saveDirtyChoices = useCallback(async (choices: ChoiceType[]) => {
    for (const choice of choices) {
      const body = {...choice, value: choice.value || ''};
      if (testId) {
        await dispatch(updateEditorTestsQuestionChoice({
          testId,
          questionId: question.id,
          id: '' + choice.id,
          body
        }));
      }
      if (catalogueId) {
        await dispatch(updateCatalogueQuestionChoice({
          questionCatalogueId: catalogueId,
          questionId: question.id,
          id: '' + choice.id,
          body
        }));
      }
    }
    broadcastEditorSuccessEvent();

  }, [testId, catalogueId, question, broadcastEditorSuccessEvent, dispatch]);

  const handleQuestionTypeChange = useCallback(async (newType: string) => {
    const prevType = question.question_type;
    if (prevType === newType) {
      return;
    }
    if (prevType === 'matching' || newType === 'matching') {
      // if (testId) {
      //   await dispatch(purgeEditorTestQuestionChoices({
      //     testId,
      //     questionId: question.id,
      //     body: {}
      //   }));
      // }
      // if (catalogueId) {
      //   await dispatch(purgeCatalogueQuestionChoices({
      //     questionCatalogueId: catalogueId,
      //     questionId: question.id,
      //     body: {}
      //   }));
      // }
      onQuestionChange({...question, question_type: newType, response_type: 'text'});
      // setChoices([]);
    } else {
      if (newType !== 'multiple') {
        const dirtyChoices: ChoiceType[] = [];
        choices.filter((ch) => ch.is_correct).forEach((ch) => {
          ch.is_correct = false;
          dirtyChoices.push({...ch, is_correct: false});
        });
        if (saveDirtyChoices.length > 0) {
          setChoices(choices);
          await saveDirtyChoices(dirtyChoices);
        }
      }
      onQuestionChange({...question, question_type: newType});
    }
    broadcastEditorSuccessEvent();

  }, [question, broadcastEditorSuccessEvent, choices, onQuestionChange, saveDirtyChoices]);

  const handleChoiceFileUpload = useCallback(async (choiceId: number, file: File) => {
    // upload file
    const f = await uploadFile(dispatch, file);
    if (!f) {
      return;
    }

    // link to choice
    if (testId) {
      await dispatch(linkEditorTestQuestionChoiceFile({
        id: f.id,
        choiceId: choiceId,
        questionId: question.id,
        testId
      }));
    }
    if (catalogueId) {
      await dispatch(linkCatalogueQuestionChoiceFile({
        id: f.id,
        choiceId: choiceId,
        questionId: question.id,
        questionCatalogueId: catalogueId
      }));
    }

    setChoices((items) => {
      return items.map((q) => q.id === choiceId ? {...q, file: f} : q)
    });
    broadcastEditorSuccessEvent();

  }, [testId, catalogueId, question, broadcastEditorSuccessEvent, dispatch]);

  const handleQuestionRemove = useCallback(() => {
    if (onQuestionRemove) {
      onQuestionRemove(question);
    }
  }, [question, onQuestionRemove]);

  useEffect(() => {
    if (!dirtyChoices) {
      return;
    }
    saveDirtyChoices(dirtyChoices).then();
    setDirtyChoices(undefined);

  }, [dirtyChoices, handleChoiceChange, setDirtyChoices, saveDirtyChoices]);

  const choiceItems = useMemo(() => {
    const items: JSX.Element[] = [];

    choices.forEach((ch) => {
      items.push(<EditorTestChoice
        key={ch.id} question={question} choice={ch}
        explanation={questionAndChoicesState.explanationNeeded}
        onChoiceMove={handleChoiceMove}
        onChoiceDelete={handleChoiceDelete}
        onChoiceChange={handleChoiceChange}
        onChoiceFileUpload={handleChoiceFileUpload}
        disabled={disabled}
      />);
    });

    return items;

  }, [choices, question, questionAndChoicesState.explanationNeeded, handleChoiceMove, handleChoiceDelete, handleChoiceChange,
    handleChoiceFileUpload, disabled]);

  const headerActions = useMemo(() => {
    return [
      {title: t('editorTest.questionType.options.single'), callback: () => handleQuestionTypeChange('single')},
      {title: t('editorTest.questionType.options.multiple'), callback: () => handleQuestionTypeChange('multiple')},
      {title: t('editorTest.questionType.options.matching'), callback: () => handleQuestionTypeChange('matching')}
    ] as ContextMenuAction[];

  }, [handleQuestionTypeChange, t]);

  const createFileInput = useCallback((ref: LegacyRef<HTMLInputElement>) => {
    return <input
      type={'file'} name={'file'} ref={ref} accept={'image/jpeg, image/png'}
      onChange={async (v) => {
        const f = v.target?.files?.[0];
        if (f) {
          setQuestionAndChoicesState(s => ({...s, showProgress: true}));
          await onQuestionFileUpload(question.id, f);
          setQuestionAndChoicesState(s => ({...s, showProgress: false}));
        }
      }} hidden/>
  }, [question.id, onQuestionFileUpload]);

  const opacity = isDragging ? 0.5 : 1;
  if (!disabled && onEntryMove) {
    drag(dragRef);
    drop(preview(previewRef));
  }

  const isFile = !!question.file || questionAndChoicesState.showProgress;

  return <Grid item xs={12}
               className={clsx('question', open && !disabled ? 'active' : undefined, open ? 'showed' : undefined)}
               ref={previewRef} style={{opacity}} data-handler-id={handlerId}>
    <Grid container>
      {!!onEntryMove && <Grid item xs={12} ref={dragRef}>
          <Box onClick={onOpen}
               className={'question-handle tw-flex tw-justify-between tw-self-center tw-align-middle tw-cursor-pointer'}>
              <div>
                {!disabled && <DragHandle sx={{verticalAlign: 'middle', cursor: 'move'}}/>}
                  <label
                      className={'question-label'}>{t('editorTest.labels.question', {index: questionNo})}</label>
                  <span> - </span>
                  <span className={'question-name'}>{question.name}</span>
              </div>
            {!disabled && <div className={'actions tw-align-middle tw-flex'} onClick={(e) => e.stopPropagation()}>
                <ContextMenu title={<Tune/>} actions={headerActions}/>
              {!!onQuestionRemove &&
                  <IconButton onClick={handleQuestionRemove} color={'inherit'}><Delete/></IconButton>}
            </div>}
          </Box>
      </Grid>}
      {(open || !onOpen) && <Grid item xs={12} className={'question-content'} sx={{
        '& .MuiFormControlLabel-root': {
          marginRight: '-10px',
        }
      }}>
          <Grid container columns={100} sx={imageStyles}>
              <Grid item xl={isFile ? 60 : 100} lg={isFile ? 40 : 100} md={100} className={'question-right'}>
                  <EditorTestQuestionForm
                      title={!!onEntryMove ? undefined : <ContextMenu title={<Tune/>} actions={headerActions}/>}
                      question={question} stepId={stepId} testHasSteps={testHasSteps}
                      questionNo={questionNo}
                      versions={versions}
                      onQuestionChange={onQuestionChange}
                      disabled={disabled}/>

                {!disabled && !isFile && <Grid item md={100} className={'tw-pt-18'}>
                    <Button onClick={(e) => createFileRef.current?.click()} color={'primary'} variant={'contained'}>
                      {t('editorTest.buttons.addImage')}
                    </Button>
                  {createFileInput(createFileRef)}
                </Grid>}

                  <div
                      className={'tw-flex tw-grid-flow-row tw-justify-between tw-items-center tw-pt-18 options-settings'}>
                      <span className={'options tw-mb-0'}>{t('editorTest.labels.options')}</span>
                      <div className={'tw-flex tw-grid-flow-row tw-items-center tw-justify-end'}>
                        {question.question_type === 'matching' && <div className="tw-items-center">
                            <TextFields/>
                        </div>}
                        {question.question_type !== 'matching' && <div className="tw-items-center tw-pr-10">
                            <IconButton onClick={question.response_type === 'text' ? undefined : () => {
                              setQuestionAndChoicesState((s) => {
                                return {...s, choicesTypeChange: 'text'};
                              })
                            }} color={question.response_type === 'text' ? 'primary' : 'inherit'}
                                        sx={question.response_type === 'text' && disabled ? {
                                          '&.Mui-disabled': {
                                            color: (theme) => theme.palette['primary'].main
                                          }
                                        } : undefined}
                                        disabled={disabled}><TextFields/></IconButton>
                            <span> / </span>
                            <IconButton onClick={question.response_type === 'image' ? undefined : () => {
                              setQuestionAndChoicesState((s) => {
                                return {...s, choicesTypeChange: 'image'};
                              })
                            }} color={question.response_type === 'image' ? 'primary' : 'inherit'}
                                        sx={question.response_type === 'image' && disabled ? {
                                          '&.Mui-disabled': {
                                            color: (theme) => theme.palette['primary'].main
                                          }
                                        } : undefined}
                                        disabled={disabled}><Image/></IconButton>
                        </div>}
                        {question.question_type !== 'matching' &&
                            <div className="tw-flex tw-grid-flow-row tw-items-center">
                                <span className="question_label">{t('editorTest.labels.explanations')}</span>
                                <SwitchPlain name={'hintNeeded'} type={'switch'}
                                             currentValue={questionAndChoicesState.explanationNeeded}
                                             onChange={(v) => {
                                               setQuestionAndChoicesState((s) => {
                                                 return {...s, explanationNeeded: v};
                                               })
                                             }}
                                             disabled={disabled}/>
                            </div>}
                      </div>
                  </div>
                  <Grid container className={'optionsRows tw-pb-20 tw-mt-0'} columnSpacing={20} rowSpacing={6}>
                    {choiceItems}
                      <Grid item
                            xl={question.response_type === 'image' ? (question.file?.id ? 6 : 4) : 12}
                            lg={question.response_type === 'image' ? (question.file?.id ? 12 : 6) : 12}
                            md={12}
                            className={clsx('tw-items-center tw-flex tw-mb-20', question.response_type === 'image' && 'tw-mt-100 tw-mb-100 tw-justify-center')}>
                        {!disabled && <span className={'tw-pr-10'}><AddCircleOutlineIcon/></span>}
                        {!disabled && <Button onClick={() => addQuestionChoice([undefined])}
                                              color={'inherit'}>{question.question_type === 'matching'
                          ? t('editorTest.buttons.addCategory')
                          : t('editorTest.buttons.addChoice')}</Button>}
                        {choiceItems.length <= 0
                          && question.question_type === 'single'
                          && question.response_type !== 'image'
                          && <Button onClick={() => addQuestionChoice([t('editorTest.yes'), t('editorTest.no')])}
                                     color={'inherit'}>{t('editorTest.buttons.addYesNo')}</Button>}
                      </Grid>
                  </Grid>
              </Grid>
            {isFile && <Grid item md={30} className={'imageQuestion'}>
              {!disabled && !!question.file && <div className={'tw-flex tw-justify-end imageActions'}>
                  <IconButton onClick={() => updateFileRef.current?.click()} color={'inherit'}><Edit/></IconButton>
                  <IconButton onClick={() => onQuestionFileRemove(question)} color={'inherit'}><Delete/></IconButton>
                {createFileInput(updateFileRef)}
              </div>}
                <div className={'imageContent tw-relative'}>
                  {questionAndChoicesState.showProgress &&
                      <LinearProgress className={'tw-absolute tw-right-0 tw-left-0 tw-top-0'}
                                      variant={'indeterminate'}/>}
                    <div className="imageContainer">
                      {!!question.file && <img src={question.file.download_url} alt={question.file.file_name}/>}
                    </div>
                </div>
            </Grid>}
          </Grid>
      </Grid>}
    </Grid>
    {!!questionAndChoicesState.choicesTypeChange && <ConfirmDialog
        title={t('editorTest.changeResponseType.title')}
        action={t('editorTest.changeResponseType.confirm')}
        onConfirm={handleChoiceTypeChange}
        onCancel={() => setQuestionAndChoicesState((s) => ({...s, choicesTypeChange: undefined}))}
        isSaving={false}
    >
        <p>{t('editorTest.changeResponseType.content.' + questionAndChoicesState.choicesTypeChange)}</p>
    </ConfirmDialog>}

  </Grid>;
}
