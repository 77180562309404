import { authReducer } from './auth';
import { AppDispatch, RootState } from '../index';
import { useDispatch } from 'react-redux';
import { codebooksReducer } from './codebooks';
import { contentViewerReducer } from './contentViewer';
import { localAppReducer } from './localApp';
import { billingReducer } from './billing';
import * as runtime from "generated-api/runtime";

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  contentViewer: contentViewerReducer,
  codebooks: codebooksReducer,
  localApp: localAppReducer,
  auth: authReducer,
  billing: billingReducer,
};

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export type AppThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
  extra?: unknown;
  rejectValue?: unknown;
};

export interface AppRequestInit extends RequestInit {
  ignoreErrorCodes?: {[key in string]: ((json: any) => void) | null}
}

export const withIgnoreErrorCodes: (codes: AppRequestInit['ignoreErrorCodes']) => runtime.InitOverrideFunction = (ignoreErrorCodes) => {
  return async () => {
    return {
      ignoreErrorCodes
    } as AppRequestInit
  }
}
