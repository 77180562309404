import SchoolIcon from '@mui/icons-material/School';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BookIcon from '@mui/icons-material/Book';
import SettingsIcon from '@mui/icons-material/Settings';
import ListIcon from '@mui/icons-material/List';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { ReactComponent as Editor } from 'icons/open-in-editor.svg';
import { useAppTranslation } from 'services/i18n';
import { selectContentViewer } from 'store/selectors';
import { useSelector } from 'react-redux';
import {FileCopy} from "@mui/icons-material";

interface Props {
  isUserOrganization: boolean;
  showDashboardLink: boolean;
  isUserKindOfAdmin: boolean;
  onClick: (
    e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => boolean;
}

export const NavMenuTopLinks = ({ isUserOrganization, showDashboardLink, isUserKindOfAdmin, onClick }: Props) => {
  const t = useAppTranslation();

  const contentViewerState = useSelector(selectContentViewer);
  const isPreview = contentViewerState?.course?.is_preview;
  const isMarketplacePreview = contentViewerState?.course?.is_marketplace_preview;
  const contentVersionId = contentViewerState?.course?.content_version?.id;

  // TODO change inline styles to tailwind classes
  const navigationTopMenuLinks = [
    {
      target: '_self',
      string: t('contentViewer.nav.dashboard'),
      icon: <SchoolIcon style={{ color: '#757575' }} />,
      url: '#/dashboard',
      enabled: true,
    },
    {
      target: '_self',
      string: t('contentViewer.nav.adminDashboard'),
      icon: <DashboardIcon style={{ color: '#757575' }} />,
      url: '#/adminDashboard',
      enabled: isUserKindOfAdmin && isUserOrganization && showDashboardLink,
    },
    {
      target: '_self',
      string: t('contentViewer.nav.userManagement'),
      icon: <PeopleIcon style={{ color: '#757575' }} />,
      url: '#/userManagement',
      enabled: isUserKindOfAdmin && isUserOrganization,
    },
    {
      target: '_self',
      string: t('contentViewer.nav.planManagement'),
      icon: <FileCopy style={{ color: '#757575' }} />,
      url: '#/planManagement',
      enabled: isUserKindOfAdmin && isUserOrganization,
    },
    {
      target: '_self',
      string: t('contentViewer.nav.contentManager'),
      icon: <BookIcon style={{ color: '#757575' }} />,
      url: '#/contentManager',
      enabled: isUserKindOfAdmin,
    },
    {
      target: '_self',
      string: t('contentViewer.nav.catalogues'),
      icon: <ListIcon style={{ color: '#757575' }} />,
      url: '#/catalogues',
      enabled: isUserKindOfAdmin && isUserOrganization,
    },
    {
      target: '_self',
      string: t('contentViewer.nav.administrationCS'),
      icon: <SettingsIcon style={{ color: '#757575' }} />,
      url: '#/csPreferences',
      enabled: isUserKindOfAdmin && isUserOrganization,
    },
    {
      target: '_blank',
      string: t('contentViewer.nav.marketplace'),
      icon: <LocalGroceryStoreIcon style={{ color: '#757575' }} />,
      url: '#/marketplace',
      enabled: isUserKindOfAdmin,
    },
  ];

  return (
    <div className="tw-border-b tw-border-lighterGray">
      {isPreview && !isMarketplacePreview && (
        <a
          href={`/#/editor/${contentVersionId}`}
          className="tw tw-flex tw-h-52 tw-items-center tw-gap-20 tw-border-b tw-border-lighterGray tw-px-16 tw-pt-5 hover:tw-bg-black/5"
          target="_self"
        >
          <Editor className="tw-h-24 tw-w-24 tw-fill-[#757575]" />
          {t('contentViewer.nav.editor')}
        </a>
      )}
      {navigationTopMenuLinks.map(({ url, target, string, icon, enabled }) => {
        return (
          enabled && (
            <a
              href={url}
              className="tw-flex tw-h-52 tw-items-center tw-gap-20 tw-px-16 tw-pt-5 hover:tw-bg-black/5"
              target={target}
              key={url}
              onClick={onClick}
            >
              {icon}
              {string}
            </a>
          )
        );
      })}
    </div>
  );
};
