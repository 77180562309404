import ReactDOM from 'react-dom/client';
import { AppDispatch } from '../index';
import { AppBridgeEvent } from '../model/appBridge';
import { initStaticCodebooks } from '../store/codebooks';
import { getLocale, setLocale } from './i18n';

const renderRoots: { [hash: string]: ReactDOM.Root } = {};

function getPageHash(event: AppBridgeEvent) {
  return `${event.page}-${event.rootId}` + (event.instanceId ? '-' + event.instanceId : '');
}

export function unmountBridgedPage(event: AppBridgeEvent) {
  const hash = getPageHash(event);
  renderRoots[hash]?.unmount();
  delete renderRoots[hash];
}

export function createBridgedPage(event: AppBridgeEvent, rootElm: HTMLElement) {
  const hash = getPageHash(event);
  const root = ReactDOM.createRoot(rootElm);
  renderRoots[hash] = root;
  return root;
}

export function appBridgeCheckContext(event: AppBridgeEvent, dispatch: AppDispatch) {
  if ('context' in event && event?.context?.lang && event?.context?.lang !== getLocale()) {
    setLocale(event?.context?.lang);
    dispatch(initStaticCodebooks());
  }
}
