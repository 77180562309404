import {type SpaceUserIndexResponse, type SpaceUserShowResponse, UserBasicInfo} from "generated-api";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {OptionValue} from "model/form";
import {fetchUser, fetchUsers} from "store/user";
import {SelectFormField} from "components/form/SelectFormField";
import {useFormikContext} from "formik";
import {get} from "react-hook-form";

export const createUserOption = (a: Partial<UserBasicInfo>): OptionValue => ({
    value: a.id!,
    label: a.full_name || String(a.id!),
    tooltip: a.email,
    icon: <img alt={a.full_name} style={{width: '24px', borderRadius: '50%', marginRight: '8px'}}
        src={a.small_picture_url || a.picture_url}/>
});

export const UserSelectFormField = ({name, label, placeholder, filterRole, initialUsers}: {
    name: string,
    label: string,
    placeholder?: string,
    filterRole?: string,
    initialUsers?: UserBasicInfo[],
}) => {
    const dispatch = useAppDispatch();

    const {initialValues, values} = useFormikContext();
    const valueIds = get(values as any, name) as number[] | undefined;
    const initialIds = get(initialValues as any, name) as number[] | undefined;

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [userSearch, setUserSearch] = useState('');
    const [userOptions, setUserOptions] = useState<OptionValue[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);

    const knownUsers = useRef<OptionValue[]>(initialUsers?.map(createUserOption) || []);
    const lastSearch = useRef<string>();

    const loadInitialUserOptions = useCallback(async () => {
        if (!initialIds?.length) {
            return;
        }
        const missingIds = initialIds.filter((id) => !knownUsers.current.find((ku) => ku.value === id));
        if (!missingIds.length) {
            return;
        }

        // manually fetch any users initially selected and not returned by the opening search
        const users: OptionValue[] = [];
        for (const missingId of missingIds) {
            const res = await dispatch(fetchUser({id: String(missingId)}));
            const user = createUserOption(res.payload as SpaceUserShowResponse);
            if (!knownUsers.current.find((ca) => user.value === ca.value)) {
                knownUsers.current.push(user);
            }
            users.push(user);
        }
        setUserOptions((current) => {
            const missing = users.filter(u => !current.find(c => c.value === u.value));
            if (!missing.length) {
                return current;
            }
            return [...current, ...missing];
        })

    }, [initialIds, dispatch]);

    useEffect(() => {
        if (lastSearch.current === userSearch) {
            // no-op
            return;
        }
        setIsUserLoading(true);
        dispatch(fetchUsers({
            filterRole,
            filterFulltext: userSearch || undefined
        })).then((res) => {
            const items = res.payload as SpaceUserIndexResponse[];
            const users: OptionValue[] = items?.map(createUserOption);
            if (valueIds?.length) {
                // always add currently selected users to the options for chips
                knownUsers.current.forEach((ca) => {
                    if (valueIds.indexOf(+ca.value) >= 0 && !users?.find((a) => a.value === ca.value)) {
                        users?.unshift(ca);
                    }
                });
            }
            // add fetched users to the local cache for future use (might be needed for the chips when next search returns something else)
            users?.forEach(a => {
                if (!knownUsers.current.find((ca) => a.value === ca.value)) {
                    knownUsers.current.push(a);
                }
            });
            setUserOptions(users);
        }).finally(() => {
            loadInitialUserOptions().finally(() => {
                setIsUserLoading(false);
                setIsInitialized(true);
                lastSearch.current = userSearch;
            });
        });
    }, [valueIds, userSearch, loadInitialUserOptions, filterRole, dispatch]);

    return <SelectFormField name={!isInitialized ? name + 'Temp' : name}
        label={label}
        placeholder={placeholder}
        isMulti={true}
        showTooltip
        options={userOptions}
        onSearch={setUserSearch}
        isLoading={isUserLoading}
        isInitializing={!!valueIds?.length && !isInitialized}/>;
};
