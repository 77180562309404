import { Formik, FormikErrors } from 'formik';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useAppTranslation } from '../../services/i18n';
import { useAppDispatch } from '../../store';
import { BaseCodebookBulkRequest, createOrgUnits } from '../../store/codebooks';
import { addMessage } from '../../store/localApp';
import { Button, Grid, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { TextFormField } from '../form/TextFormField';
import DialogActions from '@mui/material/DialogActions';
import { CodebookCategory } from '../../pages/CodebooksPage';
import { isApiResultError } from '../../helpers/api';

type CodebookAddFormFields = {
  values: string;
};

const validate = (values: CodebookAddFormFields, t: any) => {
  let errors = {} as FormikErrors<CodebookAddFormFields>;
  if (!values.values) {
    errors.values = t('codebooks.form.error.newValuesRequired');
  }
  return errors;
};

type Props = {
  codebookCategory: CodebookCategory;
  onSuccess: (result: any) => unknown;
  onClose: () => unknown;
};

export const CodebookAddForm = (props: Props) => {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);

  const {
    codebookCategory: { category },
    onSuccess,
    onClose,
  } = props;

  const handleSaveItem = useCallback(
    async (values: CodebookAddFormFields) => {
      const request: BaseCodebookBulkRequest = {
        category,
        names: values.values.split('\n'),
      };

      setIsSaving(true);
      const result = await dispatch(createOrgUnits(request));
      setIsSaving(false);

      if (!isApiResultError(result)) {
        dispatch(
          addMessage({
            severity: 'success',
            title:
              request?.names && request.names.length <= 1
                ? t('codebooks.form.success.newValueAdded')
                : t('codebooks.form.success.newValuesAdded'),
          })
        );
        onSuccess(result.payload);
        onClose();
      }
    },
    [dispatch, category, onSuccess, onClose, t]
  );

  return (
    <Dialog open={true} onClose={props.onClose} PaperComponent={Paper}>
      <Formik
        initialValues={{ values: '' } as CodebookAddFormFields}
        onSubmit={handleSaveItem}
        validate={(v) => validate(v, t)}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <DialogTitle>
              {t('codebooks.' + category + '.title')} - {t('form.add')}
            </DialogTitle>
            <LinearProgress hidden={!isSaving} />
            <DialogContent>
              <p>{t('codebooks.form.newValuesInfo')}</p>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFormField
                    name="values"
                    label={t('codebooks.form.newValuesTitle')}
                    minRows={5}
                    maxRows={15}
                    type={'textarea'}
                    placeholder={t('codebooks.form.newValuesPlaceholder')}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                {t('form.cancel')}
              </Button>
              <Button color={'primary'} type={'submit'} disabled={isSaving}>
                {t('form.add')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
