import clsx from 'clsx';
import React, { useState } from 'react';
import { ReactComponent as Chevron } from 'icons/chevron-down.svg';
import IconButton from "@mui/material/IconButton";

interface CollapsibleProps {
  className?: string;
  contentClassName?: string;
  headingClassName?: string;
  heading: React.ReactNode;
  children: React.ReactNode;
  defaultCollapsed?: boolean;
  chevronColor?: string;
}

export const Collapsible = ({
  className,
  contentClassName,
  headingClassName,
  heading,
  children,
  defaultCollapsed,
  chevronColor,
}: CollapsibleProps) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          'tw-flex tw-h-full tw-cursor-pointer tw-items-center tw-justify-between tw-pt-5',
          !collapsed && 'tw-mb-5',
          headingClassName
        )}
      >
        {heading}
        <IconButton onClick={() => setCollapsed((s) => !s)} disableRipple
            className={'tw-flex tw-grow tw-self-stretch tw-rounded-none tw-justify-end tw-pr-20 tw-pl-20'}>
          <Chevron
            className={clsx('tw-shrink-0', !collapsed && '-tw-rotate-180 tw-transform')}
            style={{stroke: chevronColor || 'white'}}
          />
        </IconButton>
      </div>
      <div
        className={clsx(
          'tw-transition-collapse tw-overflow-hidden tw-duration-300',
          collapsed ? 'tw-invisible tw-max-h-0' : '',
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};
