import {useParams} from "react-router-dom";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, withIgnoreErrorCodes} from "store";
import {
  createEditorTest,
  createEditorTestsEntry,
  createEditorTestsQuestion,
  deleteEditorTestQuestion,
  deleteEditorTestQuestionFile,
  deleteEditorTestsEntry,
  fetchEditorElements,
  fetchEditorTest,
  fetchEditorTestsEntries,
  fetchVersions,
  linkEditorTestQuestionFile,
  updateEditorTest,
  updateEditorTestsEntry,
  updateEditorTestsQuestion
} from "store/editorTest";
import {
  ContentEditorElementIndexResponse,
  ContentEditorShowResponse,
  ContentEditorTestDefinitionQuestionCreateResponse,
  QuestionUpdate,
  TestDefinitionCreate,
  TestDefinitionUpdate
} from "generated-api";
import {Box, Grid, LinearProgress} from "@mui/material";
import {useAppTranslation} from "services/i18n";
import {Formik, FormikProps, useField, useFormikContext} from "formik";
import {RadioGroupField} from "components/form/RadioGroupField";
import {FormFieldProps, OptionValue} from "model/form";
import {TextFormField, TextFormFieldPlain} from "components/form/TextFormField";
import {CheckboxFormField, SwitchPlain} from "components/form/CheckboxFormField";
import {ButtonGroupField} from "components/form/ButtonGroupField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {EditorTestQuestion} from "components/editorTest/EditorTestQuestion";
import {
  broadcastNgEditorSuccessEvent,
  QuestionType,
  sortByPosition,
  TestEntryType,
  TestType,
  uploadFile,
  VersionsType
} from "../../helpers/editorTest";
import {ContextMenu, ContextMenuAction} from "components/ContextMenu";
import {EditorTestCatalogueQuestionsDialog} from "components/editorTest/EditorTestCatalogueQuestionsDialog";
import {EditorTestCatalogueCategoriesDialog} from "components/editorTest/EditorTestCatalogueCategoriesDialog";
import {EditorTestCatalogueQuestion} from "components/editorTest/EditorTestCatalogueQuestion";
import {EditorTestCatalogueCategory} from "components/editorTest/EditorTestCatalogueCategory";

const computeTimeNeeded = (h: number, m: number, s: number): number => {
  return s + m * 60 + h * 3600;
}

const styles = {
  '& span:not(.Mui-checked) > .MuiSvgIcon-root': {
    opacity: .6 // md-icon.md-default-theme, md-icon
  },
  '& button:not(.MuiIconButton-colorPrimary) > .MuiSvgIcon-root': {
    opacity: .6 // md-icon.md-default-theme, md-icon
  },
  '& div > .MuiSvgIcon-root': {
    opacity: .6 // md-icon.md-default-theme, md-icon
  }
}

interface TimeNeededFormFieldProps extends FormFieldProps {
}

const TimeNeededFormField = (props: TimeNeededFormFieldProps) => {

  const {name, onChange, disabled} = props;

  const [field, meta, {setValue}] = useField(name);
  const {submitCount} = useFormikContext();
  const t = useAppTranslation();

  const formValue = field.value;
  const showError = !!meta.error && ((meta.touched && !!formValue) || submitCount > 0);

  const h = !!formValue ? Math.floor(formValue / 3600) : 0;
  const m = !!formValue ? Math.floor((formValue - h * 3600) / 60) : 0;
  const s = !!formValue ? formValue - h * 3600 - m * 60 : 0;
  const [timeNeeded, setTimeNeeded] = useState<boolean>(!!formValue);
  const [hours, setHours] = useState<number>(h);
  const [minutes, setMinutes] = useState<number>(m);
  const [seconds, setSeconds] = useState<number>(s);

  const handleUpdate = useCallback((value: number | null) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }

  }, [setValue, onChange]);

  const handleTimeNeededChange = useCallback((v: string) => {
    if (!v) {
      setHours(0);
      setMinutes(0);
      setSeconds(0);
      setTimeNeeded(false);
      handleUpdate(null);
    } else {
      setTimeNeeded(true);
      handleUpdate(computeTimeNeeded(hours, minutes, seconds));
    }
  }, [setTimeNeeded, hours, minutes, seconds, handleUpdate]);

  const handleTimeValueChange = useCallback((hours: number, minutes: number, seconds: number) => {
    let h = hours;
    let m = minutes;
    let s = seconds;

    if (s >= 60) {
      m = m + Math.floor(s / 60);
      s = s % 60;

    } else if (s < 0) {
      if (h <= 0 && m <= 0) {
        s = 0;
      } else {
        m = m - Math.ceil(-s / 60);
        s = 60 - -s % 60;
      }
    }

    if (m >= 60) {
      h = h + Math.floor(m / 60);
      m = m % 60;

    } else if (m < 0) {
      if (h <= 0) {
        m = 0;
      } else {
        h = Math.max(0, h - Math.ceil(-m / 60));
        m = 60 - -m % 60;
      }
    }

    setHours(h);
    setMinutes(m);
    setSeconds(s);
    if (timeNeeded) {
      handleUpdate(computeTimeNeeded(h, m, s));
    }
  }, [timeNeeded, handleUpdate]);

  const handleTimeHoursChange = useCallback((v: string) => {
    let n = v ? parseInt(v) : 0;
    handleTimeValueChange(n, minutes, seconds);
  }, [minutes, seconds, handleTimeValueChange]);

  const handleTimeMinutesChange = useCallback((v: string) => {
    let n = v ? parseInt(v) : 0;
    handleTimeValueChange(hours, n, seconds);
  }, [hours, seconds, handleTimeValueChange]);

  const handleTimeSecondsChange = useCallback((v: string) => {
    let n = v ? parseInt(v) : 0;
    handleTimeValueChange(hours, minutes, n);
  }, [hours, minutes, handleTimeValueChange]);

  return <>
    <SwitchPlain name={'timeNeeded'} label={t('editorTest.labels.time')} disabled={disabled}
                 onChange={handleTimeNeededChange} currentValue={timeNeeded}/>
    {timeNeeded && <Grid container className={'tw-items-center'} sx={{margin: '-15px 0 -5px 0'}}>
        <Grid item lg={1} md={2} xs={3}>
            <TextFormFieldPlain name={'hoursLimit'} currentValue={hours} type={'number'}
                                onChange={handleTimeHoursChange} minValue={0}
                                disabled={disabled}/>
        </Grid>
        <Grid item xs={1}>
            h
        </Grid>
        <Grid item lg={1} md={2} xs={3}>
            <TextFormFieldPlain name={'minutesLimit'} currentValue={minutes} type={'number'}
                                onChange={handleTimeMinutesChange}
                                disabled={disabled}/>
        </Grid>
        <Grid item xs={1}>
            m
        </Grid>
        <Grid item lg={1} md={2} xs={3}>
            <TextFormFieldPlain name={'secondsLimit'} currentValue={seconds} type={'number'}
                                onChange={handleTimeSecondsChange}
                                disabled={disabled}/>
        </Grid>
        <Grid item xs={1}>
            s
        </Grid>
    </Grid>}
    {showError && <p>{meta.error}</p>}
  </>;
}

const entryPoints = (entry: TestEntryType): number => {
  if (entry.kind === 'question' || entry.kind === 'catalogue_question') {
    return entry.question?.point_value || 0;
  }
  if (entry.kind === 'catalogue_category') {
    return entry.settings.reduce((total, s) => total + (s.point_value || 0) * (s.count || 0), 0);
  }
  return 0;
}

interface EditorTestFormFieldsProps {
  formikProps: FormikProps<TestType>;
  entries: TestEntryType[];
  disabled: boolean;
}

const EditorTestFormFields = (props: EditorTestFormFieldsProps) => {
  const {entries, formikProps, disabled} = props;
  const {values, setFieldValue, handleSubmit} = formikProps;

  const t = useAppTranslation();

  const [totalPoints, totalQuestions, isCatalogueUsed] = useMemo(() => {
    return [
      entries.reduce((total, entry) => total + entryPoints(entry), 0), //
      entries.filter(e => e.kind === 'question').length || 1,
      !!entries.find(e => e.kind === 'catalogue_question' || e.kind === 'catalogue_category')
    ];
  }, [entries]);

  const formatPercent = useCallback((v: any) => {
    return !!v || v === 0 ? Math.round(v * 100) : '';
  }, []);

  const normalizePercent = useCallback((v: any) => {
    return !!v || v === 0 ? Math.max(0, Math.min(1, parseInt(v) / 100.0)) : null;
  }, []);

  const testTypeOptions: OptionValue[] = useMemo(() => [
    {value: 'standard', label: t('editorTest.testType.options.standard')},
    {
      value: 'randomized',
      label: t('editorTest.testType.options.randomized'),
      tooltip: isCatalogueUsed ? t('editorTest.testType.options.randomizedNotAvailableWhenCatalogue') : undefined
    }
  ], [isCatalogueUsed, t]);

  const showAnswersOptions: OptionValue[] = useMemo(() => [
    {value: 'none', label: <span>{t('editorTest.showAnswers.options.none')}</span>},
    {
      value: 'only_incorrect', label: <span>{t('editorTest.showAnswers.options.none')}
        <br/>{t('editorTest.showAnswers.options.only_incorrect')}</span>
    },
    {
      value: 'all', label: <span>{t('editorTest.showAnswers.options.none')}
        <br/>{t('editorTest.showAnswers.options.only_incorrect')}
        <br/>{t('editorTest.showAnswers.options.all')}</span>
    },
  ], [t]);

  const autoSubmit = useCallback(() => setTimeout(() => handleSubmit(), 100), [handleSubmit]); // "next tick" to pickup latest values

  useEffect(() => {
    if (totalPoints < parseInt(values.points_to_pass as any as string)) {
      setFieldValue('points_to_pass', totalPoints);
      autoSubmit();
    }

  }, [totalPoints, values.points_to_pass, setFieldValue, autoSubmit]);

  useEffect(() => {
    if (totalQuestions < parseInt(values.test_size as any as string)) {
      setFieldValue('test_size', totalQuestions);
      autoSubmit();
    }

  }, [totalQuestions, values.test_size, setFieldValue, autoSubmit]);

  return <form onSubmit={handleSubmit}>
    <Grid container className={'test-properties'}>
      <Grid item md={6} className={'test-type'} style={{marginRight: 'unset'}}
            sx={{'& > p': {marginBottom: '5px !important'}}}>
        <div className={'options'}>{t('editorTest.generate')}</div>

        <p>{t('editorTest.labels.testType')}</p>

        <RadioGroupField options={testTypeOptions} name={'test_type'} onChange={autoSubmit}
                         disabled={disabled || isCatalogueUsed}/>

        {values?.test_type === 'standard' && <p>{t('editorTest.labels.neededPoints')}</p>}
        {values?.test_type === 'randomized' && <p>{t('editorTest.labels.testSize')}</p>}

        {values?.test_type === 'standard' && <Grid container className={'tw-items-center'}>
            <Grid item md={1}>
                <TextFormField name={'points_to_pass'} type={'number'} minValue={0} onChange={autoSubmit}
                               maxValue={totalPoints} disabled={disabled}/>
            </Grid>
            <Grid item md={1}>
                <span>{t('editorTest.from')}</span>
            </Grid>
            <Grid item md={1}>
                <TextFormFieldPlain currentValue={totalPoints} name={'totalPoints'} disabled={true}/>
            </Grid>
            <Grid item md={1}>
                <span>{t('editorTest.total')}</span>
            </Grid>
        </Grid>}

        {values?.test_type === 'randomized' && <Grid container className={'tw-items-center'}>
            <Grid item md={1}>
                <TextFormField name={'test_size'} type={'number'} minValue={0} maxValue={totalQuestions}
                               onChange={autoSubmit} disabled={disabled}/>
            </Grid>
            <Grid item md={1}>
                <span>{t('editorTest.from')}</span>
            </Grid>
            <Grid item md={1}>
                <TextFormFieldPlain currentValue={totalQuestions} name={'questionsLength'} disabled={true}/>
            </Grid>
            <Grid item md={1}>
                <span>{t('editorTest.total')}</span>
            </Grid>
        </Grid>}

        {values?.test_type === 'randomized' && <p>{t('editorTest.labels.neededPercent')}</p>}

        {values?.test_type === 'randomized' && <Grid container className={'tw-items-center'}>
            <Grid item md={1}>
                <TextFormField name={'points_to_pass_percent'} type={'number'} minValue={0} maxValue={100}
                               disabled={disabled} onChange={autoSubmit}
                               normalizeValue={normalizePercent}
                               formatValue={formatPercent}/>
            </Grid>
        </Grid>}
      </Grid>
      <Grid item md={6} className={'test-type'} style={{marginRight: 'unset'}}>
        <div className={'options'}>{t('editorTest.otherSettings')}</div>

        <div>
          <CheckboxFormField name={'enforce_study_of_previous_steps'} type={'switch'}
                             label={t('editorTest.labels.isProgressTest.label')}
                             help={t('editorTest.labels.isProgressTest.tooltip')} onChange={autoSubmit}
                             disabled={disabled}/>
        </div>
        <div>
          <CheckboxFormField name={'define_question_dependent_steps'} type={'switch'}
                             label={t('editorTest.labels.defineQuestionDependentSteps.label')}
                             help={t('editorTest.labels.defineQuestionDependentSteps.tooltip')}
                             tooltip={isCatalogueUsed ? t('editorTest.labels.defineQuestionDependentSteps.notAvailableWhenCatalogue') : undefined}
                             onChange={(v) => {
                               if (!v && values.mark_dependent_steps_as_complete) {
                                 setFieldValue('mark_dependent_steps_as_complete', false);
                               }
                               autoSubmit();
                             }} disabled={disabled || isCatalogueUsed}/>
        </div>
        <div>
          <CheckboxFormField name={'mark_dependent_steps_as_complete'} type={'switch'}
                             label={t('editorTest.labels.markDependentStepsAsComplete.label')}
                             help={t('editorTest.labels.markDependentStepsAsComplete.tooltip')} onChange={autoSubmit}
                             disabled={disabled || !values.define_question_dependent_steps || isCatalogueUsed}/>
        </div>
        <TimeNeededFormField name={'time_limit'} onChange={autoSubmit} disabled={disabled}/>

        <p>{t('editorTest.labels.showAnswers')}</p>

        <Box sx={{
          '& > .MuiFormControl-root > .MuiGrid-container > .MuiGrid-item > .MuiButtonBase-root': {
            textTransform: 'inherit',
            fontWeight: 'inherit',
            lineHeight: '120%'
          }
        }}>
          <ButtonGroupField name={'show_answers_in_test_result'} options={showAnswersOptions} onChange={autoSubmit}
                            disabled={disabled} separateButtons fullWidth/>
        </Box>
      </Grid>
    </Grid>
  </form>;
}

const calcCataloguePossible = (test: TestDefinitionUpdate) => {
  return !test.define_question_dependent_steps && test.test_type !== 'randomized';
}

interface EditorTestFormProps {
  stepId: number;
  test: TestType;
  versions: VersionsType;
  isCatalogueEnabled: boolean;
  disabled: boolean;
}

const EditorTestForm = (props: EditorTestFormProps) => {

  const {stepId, test, versions, disabled, isCatalogueEnabled} = props;
  const testId = test.id;

  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const [entries, setEntries] = useState<TestEntryType[]>(test.entries || [])
  const [questions, setQuestions] = useState<QuestionType[]>((test.questions || [])
    .map((q) => {
      (q as QuestionUpdate).dependent_step_ids = q.dependent_steps?.flatMap((inner) => inner.steps?.map((s) => s.id) || []) || []
      return q;
    })
    .sort(sortByPosition));

  const [activeEntryId, setActiveEntryId] = useState<number | undefined>(undefined);
  const [testHasSteps, setTestHasSteps] = useState<boolean>(test.define_question_dependent_steps);

  const [isCataloguePossible, setIsCataloguePossible] = useState(calcCataloguePossible(test));
  const [catalogueQuestionsModal, setCatalogueQuestionsModal] = useState<{ position: number } | undefined>();
  const [catalogueCategoriesModal, setCatalogueCategoriesModal] = useState<{ position: number } | undefined>();

  const fetchEntries = useCallback(async () => {
    const entries = (await dispatch(fetchEditorTestsEntries({testId, testId2: testId})))?.payload as TestEntryType[];
    setEntries(entries);
    return entries;

  }, [testId, dispatch]);

  const handleTestChange = useCallback(async (values: TestDefinitionUpdate) => {
    if (!testId) {
      return;
    }
    setTestHasSteps(values.define_question_dependent_steps);
    setIsCataloguePossible(calcCataloguePossible(values));
    await dispatch(updateEditorTest({
      id: '' + testId,
      body: values
    }));
    broadcastNgEditorSuccessEvent();

  }, [dispatch, testId, setTestHasSteps]);

  const addQuestion = useCallback(async (type: string, position: number) => {
    if (type === 'catalogueQuestion') {
      setCatalogueQuestionsModal({position});
      return;
    }
    if (type === 'catalogueCategory') {
      setCatalogueCategoriesModal({position});
      return;
    }

    const item = (await dispatch(createEditorTestsQuestion({
      testId,
      body: {
        question_type: type,
        position,
        test_id: testId
      }
    })))?.payload as ContentEditorTestDefinitionQuestionCreateResponse;
    if (item?.id) {
      const entries = await fetchEntries();

      const entry = entries.find(e => e.question?.id === item.id);
      if (entry) {
        setActiveEntryId(entry.id);
      }

      setQuestions((items) => {
        return [...items, item]
      });
      broadcastNgEditorSuccessEvent();
    }
  }, [testId, fetchEntries, dispatch]);

  const handleQuestionChange = useCallback(async (question: QuestionType) => {
    await dispatch(updateEditorTestsQuestion({
      testId,
      id: '' + question.id,
      body: question
    }));

    await fetchEntries();

    setQuestions((items) => {
      return items.map((q) => q.id === question.id ? {
        ...question,
        file: q.file,
        choices: q.choices,
        dependent_steps: q.dependent_steps
      } : q)
    });
    broadcastNgEditorSuccessEvent();

  }, [fetchEntries, testId, dispatch]);

  const handleEntryChange = useCallback(async (entry: TestEntryType) => {
    await dispatch(updateEditorTestsEntry({
      testId,
      id: '' + entry.id,
      body: entry
    }));

    const entries = await fetchEntries();

    setQuestions((items) => {
      return items.map((q) => {
        const e = entries.find(e => e.question?.id === q.id);
        return {...q, position: e?.position || q.position};
      });
    });
    broadcastNgEditorSuccessEvent();

  }, [fetchEntries, testId, dispatch]);

  const handleEntryRemove = useCallback(async (entry: TestEntryType) => {
    await dispatch(deleteEditorTestsEntry({
      testId,
      id: '' + entry.id,
    }));

    await fetchEntries();

    broadcastNgEditorSuccessEvent();

  }, [fetchEntries, testId, dispatch]);

  const handleQuestionRemove = useCallback(async (question: QuestionType) => {
    await dispatch(deleteEditorTestQuestion({
      testId,
      id: '' + question.id,
    }));

    await fetchEntries();

    setQuestions((items) => {
      return items
        .filter((item) => item.id !== question.id)
        .map((item, i) => ({...item, position: i + 1}));
    });
    broadcastNgEditorSuccessEvent();

  }, [fetchEntries, testId, dispatch]);

  const handleQuestionFileUpload = useCallback(async (questionId: number, file: File) => {
    // upload file
    const f = await uploadFile(dispatch, file);
    if (!f) {
      return;
    }

    // link to question
    await dispatch(linkEditorTestQuestionFile({
      id: f.id,
      testId,
      questionId: questionId
    }));

    setQuestions((items) => {
      return items.map((q) => q.id === questionId ? {...q, file: f} : q)
    });
    broadcastNgEditorSuccessEvent();

  }, [dispatch, testId]);

  const handleQuestionFileRemove = useCallback(async (question: QuestionType) => {
    if (!question.file?.id) {
      return;
    }
    await dispatch(deleteEditorTestQuestionFile({
      testId,
      questionId: question.id,
      id: '' + question.file.id
    }));
    setQuestions((items) => {
      return items.map((q) => q.id === question.id ? {...q, file: undefined} : q)
    });
    broadcastNgEditorSuccessEvent();

  }, [testId, dispatch]);

  const validate = useCallback((values: TestType) => {
    return {};
  }, []);

  const addQuestionActions = useMemo(() => {
    const nextPosition = entries.length + 1;
    const types = ['single', 'multiple', 'matching'];
    if (isCatalogueEnabled) {
      types.push('catalogueQuestion');
      types.push('catalogueCategory');
    }

    return types.map(type => {
      const action: ContextMenuAction = {
        title: t('editorTest.questionType.options.' + type),
        callback: () => addQuestion(type, nextPosition)
      };
      if (type === 'catalogueQuestion' || type === 'catalogueCategory') {
        if (!isCataloguePossible) {
          action.disabled = true;
          action.tooltip = t('editorTest.questionType.notAvailableForThisTest');
        }
      }
      return action;
    });

  }, [isCatalogueEnabled, isCataloguePossible, t, addQuestion, entries.length]);

  const handleEntryMove = useCallback((dragIndex: number, hoverIndex: number) => {
    setEntries((prevItems: TestEntryType[]) => {
        const dragged = prevItems.find((q) => q.position === dragIndex);
        if (!dragged || dragged.position === hoverIndex || dragIndex < 1 || hoverIndex > prevItems.length) {
          return prevItems;
        }
        const newItems = [...prevItems];
        newItems.splice(dragIndex - 1, 1);
        newItems.splice(hoverIndex - 1, 0, dragged)
        return newItems.map((q, i) => {
          return {...q, position: i + 1}
        });
      }
    )
  }, []);

  const handleCatalogueQuestionsModalClose = useCallback(() => {
    setCatalogueQuestionsModal(undefined);
  }, []);

  const handleCatalogueQuestionsModalSave = useCallback(async (catalogueQuestionIds: number[]) => {
    if (!catalogueQuestionIds.length || !catalogueQuestionsModal) {
      return;
    }

    let i = 0;
    for (const catalogueQuestionId of catalogueQuestionIds) {
      await dispatch(createEditorTestsEntry({
        testId,
        body: {
          entry_id: catalogueQuestionId as any, // TODO fix swagger
          position: catalogueQuestionsModal.position + i,
          kind: 'catalogue_question'
        }
      }));
      i++;
    }

    await fetchEntries();

    handleCatalogueQuestionsModalClose();

  }, [catalogueQuestionsModal, fetchEntries, testId, handleCatalogueQuestionsModalClose, dispatch]);

  const handleCatalogueCategoriesModalClose = useCallback(() => {
    setCatalogueCategoriesModal(undefined);
  }, []);

  const handleCatalogueCategoriesModalSave = useCallback(async (catalogueCategoryIds: number[]) => {
    if (!catalogueCategoryIds.length || !catalogueCategoriesModal) {
      return;
    }

    let i = 0;
    for (const catalogueCategoryId of catalogueCategoryIds) {
      await dispatch(createEditorTestsEntry({
        testId,
        body: {
          entry_id: catalogueCategoryId as any, // TODO fix swagger
          position: catalogueCategoriesModal.position + i,
          kind: 'catalogue_category'
        }
      }));
      i++;
    }

    await fetchEntries();

    handleCatalogueCategoriesModalClose();

  }, [catalogueCategoriesModal, testId, fetchEntries, handleCatalogueCategoriesModalClose, dispatch]);

  const questionItems = useMemo(() => {
    const items: JSX.Element[] = [];

    entries.forEach((e) => {
      const onOpen = () => setActiveEntryId((id) => id === e.id ? undefined : e.id);

      if (e.kind === 'question' && e.question?.id) {
        const q = questions.find(q => q.id === e.question?.id);
        if (q) {
          items.push(<EditorTestQuestion
            key={e.id} testId={testId} stepId={stepId} testHasSteps={testHasSteps} entry={e} question={q}
            questionNo={questions.filter(o => o.id !== e.question?.id && o.position < e.position).length + 1}
            versions={versions}
            open={e.id === activeEntryId}
            onOpen={onOpen}
            onQuestionChange={handleQuestionChange}
            onEntryChange={handleEntryChange}
            onEntryMove={handleEntryMove}
            onQuestionRemove={handleQuestionRemove}
            onQuestionFileRemove={handleQuestionFileRemove}
            onQuestionFileUpload={handleQuestionFileUpload}
            disabled={disabled}
            onBroadcastAnyChanged={broadcastNgEditorSuccessEvent}
          />);
        }

      } else if (e.kind === 'catalogue_question' && e.question) {
        const q = e.question;
        items.push(<EditorTestCatalogueQuestion
          key={e.id} testId={testId} entry={e} question={{...q, position: e.position}}
          open={e.id === activeEntryId}
          onOpen={onOpen}
          onEntryChange={handleEntryChange}
          onEntryMove={handleEntryMove}
          onEntryRemove={handleEntryRemove}
          disabled={disabled}
        />);
      } else if (e.kind === 'catalogue_category' && e.question_category) {
        const c = e.question_category;
        items.push(<EditorTestCatalogueCategory
          key={e.id} testId={testId} entry={e} category={c}
          open={e.id === activeEntryId}
          onOpen={onOpen}
          onEntryChange={handleEntryChange}
          onEntryMove={handleEntryMove}
          onEntryRemove={handleEntryRemove}
          disabled={disabled}
        />);
      }
    });

    return items;

  }, [testId, activeEntryId, stepId, questions, entries, versions, handleQuestionChange,
    handleEntryMove, handleEntryChange, handleEntryRemove,
    handleQuestionRemove, handleQuestionFileRemove, handleQuestionFileUpload,
    testHasSteps, disabled]);

  return <>
    <Formik
      initialValues={test}
      onSubmit={handleTestChange}
      validate={validate}
    >
      {(props) => <EditorTestFormFields entries={entries} formikProps={props} disabled={disabled}/>}
    </Formik>

    <Grid container sx={styles}>
      <Grid item md={12}>
        <Grid container className={'questions'}>
          {questionItems}
        </Grid>
      </Grid>

      {!disabled && <Grid item md={12} sx={{padding: '10px 0'}}>
          <ContextMenu icon={<AddCircleOutlineIcon/>} title={t('editorTest.buttons.addQuestion')}
                       actions={addQuestionActions}/>
      </Grid>}
    </Grid>

    {!!catalogueQuestionsModal && <EditorTestCatalogueQuestionsDialog
        currentQuestionIds={entries.filter(e => e.kind === 'catalogue_question').map(e => e.question?.id || 0) || []}
        onSuccess={handleCatalogueQuestionsModalSave}
        onClose={handleCatalogueQuestionsModalClose}/>}

    {!!catalogueCategoriesModal && <EditorTestCatalogueCategoriesDialog
        currentCategoryIds={entries.filter(e => e.kind === 'catalogue_category').map(e => e.question_category?.id || 0) || []}
        onSuccess={handleCatalogueCategoriesModalSave}
        onClose={handleCatalogueCategoriesModalClose}/>}
  </>;
}

export const EditorTestPage: React.FC = () => {
  const {courseId, stepId} = useParams();

  const dispatch = useAppDispatch();
  const [test, setTest] = useState<TestType | undefined>(undefined);
  const [versions, setVersions] = useState<VersionsType | undefined>(undefined);

  const isCatalogueEnabled = true; // TODO company_space features

  const fetchVersion = useCallback(async () => {
    const versions = (await dispatch(fetchVersions({id: courseId as string})))?.payload as ContentEditorShowResponse;
    setVersions(versions);
  }, [courseId, dispatch]);

  const fetchTest = useCallback(async (stepId: number) => {
    const elements = (await dispatch(fetchEditorElements({stepId})))?.payload as ContentEditorElementIndexResponse[];
    if (elements) {
      const primary = elements.find(item => item.priority === 'primary' && item.element_type === 'test');
      if (primary) {
        let test = (await dispatch(fetchEditorTest({
          elementId: primary.id,
          initOverrides: withIgnoreErrorCodes({'ActiveRecord::RecordNotFound': null})
        })))?.payload as TestType;
        if (!test?.id) {
          // 404
          test = (await dispatch(createEditorTest({
            body: {
              element_id: primary.id
            } as TestDefinitionCreate
          })))?.payload as TestType;
          broadcastNgEditorSuccessEvent();
        }
        if (test.id) {
          test.entries = (await dispatch(fetchEditorTestsEntries({
            testId: test.id,
            testId2: test.id
          })))?.payload as TestEntryType[];
        }
        setTest(test);
      }
    }
    await fetchVersion();

  }, [dispatch, fetchVersion]);

  useEffect(() => {
    if (stepId) {
      fetchTest(parseInt(stepId)).then();
    }

    window.addEventListener("reactVersionDirty", fetchVersion);

    return () => {
      window.removeEventListener("reactVersionDirty", fetchVersion);
    }

    // ignore stepId change as the page will re-mount upon change (angular bridge)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTest, fetchVersion]);


  if (!stepId || !test || !versions) {
    return <LinearProgress/>;
  }

  return <EditorTestForm stepId={parseInt(stepId)} test={test} versions={versions}
                         disabled={versions?.state !== 'draft'} isCatalogueEnabled={isCatalogueEnabled}/>;
}
