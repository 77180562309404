import { useAppTranslation } from 'services/i18n';
import { ContentLayoutWithControls } from './ContentLayoutWithControls';

interface AudioViewerProps {
  file: string;
  onNavigateToNextStep: () => void;
  onNavigateToPreviousStep: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  contentLoading?: boolean;
  showCourseIncompleteModal: boolean;
}

export const AudioViewer = ({
  file,
  onNavigateToNextStep,
  onNavigateToPreviousStep,
  isFirstStep,
  isLastStep,
  contentLoading,
  showCourseIncompleteModal,
}: AudioViewerProps) => {
  const t = useAppTranslation();

  return (
    <ContentLayoutWithControls
      contentLoading={contentLoading}
      leftKeyText={t('contentViewer.keyboardControls.previousStep')}
      onLeftKeyPress={onNavigateToPreviousStep}
      leftKeyDisabled={isFirstStep}
      rightKeyText={!isLastStep ? t('contentViewer.keyboardControls.endStep') : undefined}
      onRightKeyPress={!isLastStep ? onNavigateToNextStep : undefined}
      enterKeyText={isLastStep ? t('contentViewer.keyboardControls.certificate') : undefined}
      onEnterKeyPress={isLastStep && !showCourseIncompleteModal ? onNavigateToNextStep : undefined}
      audioFile={file}
    >
      <div className="tw-flex tw-h-full tw-items-center tw-justify-center tw-border-b tw-border-b-white/20 tw-bg-darkestGray tw-text-white">
        {t('contentViewer.content.audio')}
      </div>
    </ContentLayoutWithControls>
  );
};
