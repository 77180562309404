import {OfferUpdate} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useState} from "react";
import {updateOffer} from "store/offer";
import {isApiResultError} from "../../helpers/api";
import {addMessage} from "store/localApp";
import {ConfirmDialog} from "components/ConfirmDialog";
import {Content} from "pages/PublicOfferPage";

export const OfferToggleDialog = ({content, offer, onSuccess, onClose}: {
    content: Content,
    offer: OfferUpdate & { is_public?: boolean },
    onSuccess: () => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);

    const newIsActive = !offer.is_active;
    const trnPrefix = 'offer.'
        + (offer.is_public ? 'public' : 'private')
        + '.state.'
        + (newIsActive ? 'activate' : 'deactivate');

    const handleToggleItem = useCallback(async () => {
        setIsSaving(true);
        const result = await dispatch(updateOffer({
            id: '' + offer.id,
            contentId: content.id!,
            body: {
                ...offer,
                is_active: newIsActive
            }
        }));
        setIsSaving(false);

        if (!isApiResultError(result)) {
            dispatch(addMessage({
                severity: 'success',
                title: t(trnPrefix + '.toast')
            }));
            onSuccess();
            onClose();
        }
    }, [newIsActive, trnPrefix, offer, content.id, dispatch, onSuccess, onClose, t]);

    return (
        <ConfirmDialog
            title={t(trnPrefix + '.alert.title')}
            action={t(trnPrefix + '.alert.ok')}
            onConfirm={handleToggleItem}
            onCancel={onClose}
            isSaving={isSaving}
        >
            <p>{t(trnPrefix + '.alert.text')}</p>
        </ConfirmDialog>
    );
};
