import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ContentParticipationApi,
  ContentViewerShowResponse,
  ContentViewerStepParticipationsPostRequest,
  ContentViewerTestAttemptsGetRequest,
  ElementApi,
  StepParticipationApi,
  TestAttemptApi,
  ContentViewerElementIndexResponse,
  ContentViewerTestDefinitionTestAttemptPayloadIndexResponse,
  ContentViewerTestAttemptsPostRequest,
  ContentViewerAnswersPostRequest,
  ContentViewerAnswersIdPutRequest,
  AnswerApi,
  ContentViewerElementsGetRequest,
  ContentViewerTestAttemptsIdPutRequest,
  ContentViewerStepParticipationsPutRequest,
  ContentViewerParticipationsIdPutRequest,
  ContentViewerParticipationsIdConfirmPostRequest,
} from '../generated-api';
import { API_CONFIG } from '../app/api-config';
import * as runtime from "generated-api/runtime";

const contentParticipationApi = new ContentParticipationApi(API_CONFIG);
const elementApi = new ElementApi(API_CONFIG);
const stepParticipationApi = new StepParticipationApi(API_CONFIG);
const testAttemptApi = new TestAttemptApi(API_CONFIG);
const answerApi = new AnswerApi(API_CONFIG);

export const fetchLesson = createAsyncThunk('contentParticipation/get', async (id: string) => {
  return await contentParticipationApi.contentViewerParticipationsIdGet({ id });
});

export const updateLesson = createAsyncThunk(
  'contentParticipation/update',
  async (requestParameters: ContentViewerParticipationsIdPutRequest) => {
    return await contentParticipationApi.contentViewerParticipationsIdPut(requestParameters);
  }
);

export const confirmLesson = createAsyncThunk(
  'contentParticipation/confirm',
  async (requestParameters: ContentViewerParticipationsIdConfirmPostRequest) => {
    return await contentParticipationApi.contentViewerParticipationsIdConfirmPost(
      requestParameters
    );
  }
);

export const fetchCourseElements = createAsyncThunk(
  'currentContent/get',
  async (request: ContentViewerElementsGetRequest & {initOverrides?: RequestInit | runtime.InitOverrideFunction}) => {
    return await elementApi.contentViewerElementsGet(request, request.initOverrides);
  }
);

export const postStepParticipation = createAsyncThunk(
  'stepParticipation/create',
  async (request: ContentViewerStepParticipationsPostRequest & {initOverrides?: RequestInit | runtime.InitOverrideFunction}) => {
    return await stepParticipationApi.contentViewerStepParticipationsPost(request, request.initOverrides);
  }
);

export const updateStepParticipation = createAsyncThunk(
  'stepParticipation/update',
  async (request: ContentViewerStepParticipationsPutRequest & {initOverrides?: RequestInit | runtime.InitOverrideFunction}) => {
    return await stepParticipationApi.contentViewerStepParticipationsPut(request, request.initOverrides);
  }
);

export const fetchTestAttempts = createAsyncThunk(
  'testAttempts/get',
  async (request: ContentViewerTestAttemptsGetRequest) => {
    return await testAttemptApi.contentViewerTestAttemptsGet(request);
  }
);

export const createTestAttempt = createAsyncThunk(
  'testAttempts/create',
  async (body: ContentViewerTestAttemptsPostRequest) => {
    return await testAttemptApi.contentViewerTestAttemptsPost(body);
  }
);

export const updateTestAttempt = createAsyncThunk(
  'testAttempts/update',
  async (requestParams: ContentViewerTestAttemptsIdPutRequest) => {
    return await testAttemptApi.contentViewerTestAttemptsIdPut(requestParams);
  }
);

export const createAnswer = createAsyncThunk(
  'answer/create',
  async (requestParams: ContentViewerAnswersPostRequest) => {
    return await answerApi.contentViewerAnswersPost(requestParams);
  }
);

export const updateAnswer = createAsyncThunk(
  'answer/update',
  async (requestParams: ContentViewerAnswersIdPutRequest) => {
    return await answerApi.contentViewerAnswersIdPut(requestParams);
  }
);

interface ContentViewerState {
  course: ContentViewerShowResponse;
  testAttempts: ContentViewerTestDefinitionTestAttemptPayloadIndexResponse[];
  currentContent: ContentViewerElementIndexResponse[] | undefined;
  stepParticipationStatus: 'fulfilled' | 'pending' | 'rejected';
}

const initialState = {} as ContentViewerState;

const contentViewerSlice = createSlice({
  name: 'contentViewer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateStepParticipation.fulfilled, (state) => {
      return {
        ...state,
        stepParticipationStatus: 'fulfilled',
      };
    });
    builder.addCase(updateStepParticipation.pending, (state) => {
      return {
        ...state,
        stepParticipationStatus: 'pending',
      };
    });
    builder.addCase(updateStepParticipation.rejected, (state, action) => {
      return {
        ...state,
        stepParticipationStatus: 'rejected',
      };
    });
    builder.addCase(postStepParticipation.fulfilled, (state) => {
      return {
        ...state,
        stepParticipationStatus: 'fulfilled',
      };
    });
    builder.addCase(postStepParticipation.pending, (state) => {
      return {
        ...state,
        stepParticipationStatus: 'pending',
      };
    });
    builder.addCase(postStepParticipation.rejected, (state, action) => {
      return {
        ...state,
        stepParticipationStatus: 'rejected',
      };
    });
    builder.addCase(fetchLesson.fulfilled, (state, action) => {
      return {
        ...state,
        course: action.payload,
        stepParticipationStatus: 'fulfilled',
      };
    });
    builder.addCase(fetchLesson.pending, (state, action) => {
      return {
        ...state,
        stepParticipationStatus: 'pending',
      };
    });
    builder.addCase(fetchLesson.rejected, (state, action) => {
      return {
        ...state,
        stepParticipationStatus: 'rejected',
      };
    });
    builder.addCase(updateLesson.fulfilled, (state, action) => {
      return {
        ...state,
        course: action.payload,
      };
    });
    builder.addCase(confirmLesson.fulfilled, (state, action) => {
      const confirmed_at = action.payload.confirmed_at;
      const updatedCourse = { ...state.course, confirmed_at };
      return {
        ...state,
        course: updatedCourse,
      };
    });
    builder.addCase(fetchCourseElements.pending, (state, action) => {
      return {
        ...state,
        currentContent: undefined,
      };
    });
    builder.addCase(fetchCourseElements.fulfilled, (state, action) => {
      return {
        ...state,
        currentContent: action.payload,
      };
    });
    builder.addCase(fetchTestAttempts.fulfilled, (state, action) => {
      return {
        ...state,
        testAttempts: action.payload,
      };
    });
    builder.addCase(createTestAttempt.fulfilled, (state, action) => {
      return {
        ...state,
        testAttempts: [...state.testAttempts, action.payload],
      };
    });
    builder.addCase(updateTestAttempt.fulfilled, (state, action) => {
      const updatedTestAttempts = [...state.testAttempts as ContentViewerTestDefinitionTestAttemptPayloadIndexResponse[]];
      const testAttemptIndex = updatedTestAttempts.findIndex(
        (testAttempt) => testAttempt.id === action.payload.id
      );

      updatedTestAttempts[testAttemptIndex] = action.payload;
      return {
        ...state,
        testAttempts: updatedTestAttempts,
      };
    });
  },
});

export const contentViewerReducer = contentViewerSlice.reducer;
