import * as React from "react";
import {useMemo, useState} from "react";
import {Button, Card, ClickAwayListener, ListItemIcon, ListItemText, MenuItem, MenuList, Popper} from "@mui/material";
import {ButtonProps} from "@mui/material/Button/Button";

export interface ContextMenuAction {
  title: string;
  icon?: JSX.Element;
  isApplicable?: () => boolean,
  callback: () => unknown;
  disabled?: boolean;
  tooltip?: string;
}

interface ContextMenuActionProps {
  title: string | JSX.Element,
  icon?: JSX.Element,
  buttonProps?: ButtonProps,
  actions: ContextMenuAction[]
}

export const ContextMenu = (props: ContextMenuActionProps) => {

  const {title, icon, buttonProps, actions} = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const applicableActions: ContextMenuAction[] = useMemo(() => {
    return actions?.filter((action) => !action.isApplicable || action.isApplicable()) || []
  }, [actions]);

  const buttons: JSX.Element[] | undefined = useMemo(() => {
    return applicableActions.map((action, i) => {
      return <div key={i} title={action.tooltip}><MenuItem
        disabled={action.disabled}
        onClick={() => {
          action.callback();
          handleClose();
        }}>
        {!!action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
        <ListItemText>{action.title}</ListItemText>
      </MenuItem></div>;
    })
  }, [applicableActions]);

  if (!actions || !buttons?.length || !(buttons.length > 0)) {
    return null;
  }

  return <div className={'tw-items-center tw-justify-center tw-flex'}>
    {!!icon && <span className={'tw-pr-10'}>{icon}</span>}
    <Button onClick={handleClick} {...{...buttonProps, color: buttonProps?.color || 'inherit'}}>{title}</Button>
    {applicableActions.length > 1 && <Popper open={open} anchorEl={anchorEl} placement="bottom-end" popperOptions={{
      modifiers: [{
        name: "offset",
        options: {
          offset: [20, -40],
        },
      }]
    }} style={{zIndex: 100}}>
        <ClickAwayListener onClickAway={handleClose}>
            <Card>
                <MenuList autoFocusItem={false}>
                  {buttons}
                </MenuList>
            </Card>
        </ClickAwayListener>
    </Popper>}
  </div>;
}