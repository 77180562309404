import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { NavMenu } from 'pages/ContentViewer/ContentViewerNav/NavMenu';
import { ProgressDropdown } from './ProgressDropdown';
import { useAppTranslation } from 'services/i18n';
import { useOutsideClick } from 'utils/utils';
import type {
  ContentViewerElementIndexResponseElementTypeEnum,
  ContentViewerShowResponseContentVersionLessonsInner,
  ContentViewerShowResponseContentVersionMeta,
} from 'generated-api';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as Checkmark } from 'icons/checkmark.svg';
import { ReactComponent as HamburgerMenu } from 'icons/hamburger.svg';
import { ReactComponent as Logo } from 'icons/logo.svg';

interface ContentViewerNavProps {
  contentMeta?: ContentViewerShowResponseContentVersionMeta;
  progress: number;
  lessons: ContentViewerShowResponseContentVersionLessonsInner[];
  mainContentType?: ContentViewerElementIndexResponseElementTypeEnum;
  setShowTestNotCompletedModal: (showTestNotCompletedModal: boolean) => void;
  isActiveTestAttempt: boolean;
  currentStepName?: string;
  currentStepPosition?: number;
  currentStepIsFinished: boolean;
}

export const ContentViewerNav = ({
  contentMeta,
  progress,
  lessons,
  mainContentType,
  setShowTestNotCompletedModal,
  isActiveTestAttempt,
  currentStepName,
  currentStepPosition,
  currentStepIsFinished,
}: ContentViewerNavProps) => {
  const [showTopMenu, setShowTopMenu] = useState(false);
  const { name } = contentMeta || {};
  const t = useAppTranslation();
  const { lessonId } = useParams();

  const allLessons = useMemo(() => {
    return lessons?.map((lesson) => {
      const isCompleted = lesson?.steps?.every((step) => {
        return step.step_participation?.state === 'completed';
      });
      return { lessonId: lesson.id, isCompleted };
    });
  }, [lessons]);

  const completedLessonsCount = allLessons?.filter((lesson) => lesson.isCompleted)?.length;

  const currentLessonCount = lessonId
    ? lessons?.findIndex((lesson) => lesson.id === +lessonId) + 1
    : undefined;

  const menuRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(menuRef, () => setShowTopMenu(false));

  return (
    <nav className="tw-relative tw-flex tw-h-48 tw-w-full tw-bg-darkestGray">
      <div ref={menuRef}>
        <IconButton
          onClick={() => setShowTopMenu(true)}
          className="tw-rounded-none tw-p-12"
          style={{
            backgroundColor: 'var(--color-primary)',
            color: 'var(--color-primary-contrast)'
          }}
          disableTouchRipple
        >
          <HamburgerMenu />
        </IconButton>
        <Logo className="tw-hidden sm:tw-inline" />
        <NavMenu
          showTopMenu={showTopMenu}
          setShowTopMenu={setShowTopMenu}
          mainContentType={mainContentType}
          setShowTestNotCompletedModal={setShowTestNotCompletedModal}
          isActiveTestAttempt={isActiveTestAttempt}
        />
      </div>
      <div className="tw-ml-16 tw-flex tw-flex-col tw-justify-center sm:tw-ml-0 sm:tw-leading-4">
        {currentLessonCount !== undefined && (
          <h1 className="tw-text-14 tw-leading-none tw-text-white sm:tw-text-16 sm:tw-leading-4">
            {t('contentViewer.nav.lessonInfo')} {currentLessonCount}
            {currentStepPosition ? `.${currentStepPosition}` : ''}
            {currentStepName ? `: ${currentStepName}` : ''}
          </h1>
        )}
        <span
          className={clsx(
            'tw-leading-tight tw-text-lighterGray',
            currentLessonCount !== undefined ? 'tw-text-10' : 'tw-text-14'
          )}
        >
          {name}
        </span>
      </div>
      {currentStepIsFinished && (
        <div className="tw-mr-auto tw-ml-32 tw-flex tw-flex-grow tw-items-center tw-justify-start tw-gap-8 tw-text-12 tw-text-white sm:tw-ml-auto sm:tw-mr-32 sm:tw-justify-end">
          <Checkmark />{' '}
          <span className="tw-hidden sm:tw-inline">
            {t('contentViewer.keyboardControls.stepFinished')}
          </span>
        </div>
      )}
      <ProgressDropdown
        progress={progress}
        completedLessonsCount={completedLessonsCount}
        lessonsCount={allLessons.length}
      />
    </nav>
  );
};
