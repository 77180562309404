import clsx from 'clsx';
import { useFormikContext, FormikValues } from 'formik';

interface Props {
  step: number;
  setStep: (step: number) => void;
  showMissingQuestons: boolean;
  className?: string;
}

export const FormNavigation = ({ step, setStep, showMissingQuestons, className }: Props) => {
  const { values } = useFormikContext<FormikValues>();

  const valuesArray = Object.values(values);

  const getClasses = (index: number, isAnswer: boolean) => {
    if (step === index) {
      if (showMissingQuestons) {
        if (!isAnswer) {
          return 'tw-h-30 tw-w-30 tw-bg-darkerOrange tw-text-white';
        }
      }
      return 'tw-h-30 tw-w-30  tw-bg-lightGreen tw-text-white';
    } else {
      if (showMissingQuestons) {
        if (isAnswer) {
          return 'tw-h-24 tw-w-24 tw-bg-darkestGray tw-bg-opacity-40 tw-text-white';
        }
        return 'tw-h-24 tw-w-24 tw-bg-darkerOrange tw-text-white';
      } else {
        if (isAnswer) {
          return 'tw-h-24 tw-w-24 tw-bg-darkestGray tw-bg-opacity-40 tw-text-white';
        }
        return 'tw-h-24 tw-w-24 tw-bg-black/5 tw-text-black';
      }
    }
  };

  return (
    <div className={clsx('tw-flex tw-flex-wrap tw-items-center tw-gap-8 lg:tw-gap-4', className)}>
      {valuesArray?.map((value, index) => {
        const isAnswer = value.answer_id !== '' && value.choice_ids.length > 0;
        return (
          <div className="tw-relative" key={index}>
            <button
              type="button"
              onClick={() => setStep(index)}
              key={index}
              className={clsx(
                'tw-rounded tw-text-12 tw-font-semibold',
                getClasses(index, isAnswer)
              )}
            >
              {index + 1}
            </button>
            {!isAnswer && showMissingQuestons && (
              <div
                className={clsx(
                  'tw-absolute tw-h-4 tw-w-4 tw-rounded-full tw-bg-darkerOrange',
                  step === index ? 'tw-top-35 tw-left-12' : 'tw-top-32 tw-left-10'
                )}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};
