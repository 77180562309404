import * as React from 'react';
import { useCallback, useState } from 'react';
import { useAppTranslation } from '../../services/i18n';
import { useAppDispatch } from '../../store';
import { BaseCodebook, deleteOrgUnit } from '../../store/codebooks';
import { addMessage } from '../../store/localApp';
import { CodebookCategory } from '../../pages/CodebooksPage';
import { isApiResultError } from '../../helpers/api';
import { ConfirmDialog } from '../ConfirmDialog';

type Props = {
  codebookCategory: CodebookCategory;
  item: BaseCodebook;
  onSuccess: () => unknown;
  onClose: () => unknown;
};

export const CodebookDeleteForm = (props: Props) => {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);

  const {
    codebookCategory: { category },
    item,
    onSuccess,
    onClose,
  } = props;

  const id: string = '' + item.id;
  const handleDeleteItem = useCallback(async () => {
    setIsSaving(true);
    const result = await dispatch(
      deleteOrgUnit({
        id,
      })
    );
    setIsSaving(false);

    if (!isApiResultError(result)) {
      dispatch(addMessage({ severity: 'success', title: t('codebooks.form.success.valueDeleted') }));
      onSuccess();
      onClose();
    }
  }, [dispatch, id, onSuccess, onClose, t]);

  return (
    <ConfirmDialog
      title={t('codebooks.' + category + '.title') + ' - ' + t('form.delete')}
      action={t('form.delete')}
      onConfirm={handleDeleteItem}
      onCancel={onClose}
      isSaving={isSaving}
    >
      <p>{t('codebooks.form.deleteConfirm', item)}</p>
    </ConfirmDialog>
  );
};
