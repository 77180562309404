import {useAppTranslation} from "services/i18n";
import {useCallback, useMemo} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper} from "@mui/material";
import {Add, Check, Close, DoNotDisturb} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {sortByPosition, VersionsType} from "../../helpers/editorTest";

interface StepsModalDialogProps {
    stepIds: number[];
    versions: VersionsType;
    currentStepId: number;
    onStepsUpdate: (stepIds: number[]) => unknown;
    onClose: () => unknown;
}

export const StepsModalDialog = (props: StepsModalDialogProps) => {
    const {
        stepIds, versions, currentStepId,
        onStepsUpdate, onClose
    } = props;
    const t = useAppTranslation();

    const updateStep = useCallback((stepId: number, stepIds: number[], isDelete: boolean) => {
        onStepsUpdate(isDelete ? stepIds.filter(id => id !== stepId) : [...stepIds, stepId]);
    }, [onStepsUpdate]);

    const stepItems: JSX.Element[] = useMemo(() => {
        if (!versions.lessons) {
            return [];
        }
        const items: JSX.Element[] = [];
        versions.lessons
            .sort(sortByPosition)
            .forEach((lesson) => {
                const stepItems: JSX.Element[] = [];
                if (lesson.steps) {
                    lesson.steps
                        .sort(sortByPosition)
                        .forEach((step) => {
                            const isSelected = stepIds.indexOf(step.id) >= 0;
                            const isCurrent = step.id === currentStepId;
                            stepItems.push(<Box key={step.id} className={'tw-flex tw-flex-row tw-items-center tw-justify-items-stretch'} sx={{
                                margin: '0 -24px',
                                padding: '0 24px',
                                cursor: isCurrent ? 'unset' : 'pointer',
                                '&:hover': {
                                    backgroundColor: '#eee'
                                }
                            }} onClick={isCurrent ? undefined : () => updateStep(step.id, stepIds, isSelected)}>
                                <div className={'tw-pr-24 tw-flex tw-self-center'}>{isSelected
                                    ? <Check className={'cl-primary'}/>
                                    : (isCurrent
                                            ? <DoNotDisturb className={'cl-black-lighter'}/>
                                            : <Add className={'cl-black-lighter'}/>
                                    )}
                                </div>
                                <p className={'tw-flex tw-w-full'}>{lesson.position}.{step.position} {step.name}</p>
                                {!isCurrent && <Button color={'inherit'}>
                                    {isSelected ? t('editorTest.dependentStepsDialog.remove') : t('editorTest.dependentStepsDialog.add')}
								</Button>}
                            </Box>)
                        });
                }

                items.push(<Box key={lesson.id}>
                    <div className={'tw-pt-16 tw-pb-16 md-subheader'}>{lesson.position}. {lesson.name}</div>
                    {stepItems}
                </Box>);
            });
        return items;

    }, [versions, stepIds, currentStepId, updateStep, t]);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper}>
            <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
                <span>{t('editorTest.dependentStepsDialog.title')}</span>
                <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
            </DialogTitle>
            <DialogContent>
                {stepItems}
            </DialogContent>
            <DialogActions>
                <Button color={'inherit'} onClick={onClose}>
                    {t('form.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
