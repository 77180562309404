import { Field } from 'formik';
import { InputLabel } from '../test/InputLabel';
import clsx from 'clsx';
import { ReactComponent as CheckMark } from 'icons/checkmark-white.svg';
import React, { useRef } from 'react';

interface Props {
  textValue: string;
  name: string;
  value: string;
  isChecked: boolean;
  index: number;
  isImage: boolean;
  setFocusedChoiceId: (focusedChoiceId: number | null) => void;
  imageUrl?: string;
}

export const CheckboxField = ({
  name,
  value,
  textValue,
  index,
  isImage,
  isChecked,
  setFocusedChoiceId,
  imageUrl,
}: Props) => {
  const letterFromNumber = String.fromCharCode(64 + index + 1);
  // Disabling checkbox input default behavior reacting on ArrowUp/ArrowDown/Space keys as it conflicts with required behavior
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === ' ') {
      event.preventDefault();
    }
  };
  return (
    <div
      className="tw-flex tw-cursor-pointer tw-items-center tw-gap-16"
      onClick={(e) => {
        // quickfix, click is registered double when you click directly on checkbox in input
        if (inputRef?.current?.contains(e.target as Node)) return;
        inputRef.current?.click();
      }}
    >
      {isImage && (
        <div
          className={clsx(
            'image-field tw-h-150 tw-w-150 tw-rounded-lg tw-border tw-border-black/5 tw-bg-white tw-p-10 lg:tw-h-200 lg:tw-w-200',
            isChecked && 'tw-outline tw-outline-[4px] tw-outline-green'
          )}
        >
          <img src={imageUrl} alt="" className="tw-h-full tw-w-full tw-object-contain" />
        </div>
      )}
      <div className="tw-relative tw-h-20">
        <Field
          id={`radio-${name}-${value}`}
          type="checkbox"
          name={name}
          value={value}
          innerRef={inputRef}
          onKeyDown={handleKeyDown}
          className={clsx(
            'checkbox-field tw-z-20 tw-h-20 tw-w-20 tw-shrink-0 tw-cursor-pointer tw-appearance-none tw-rounded tw-border tw-border-solid tw-border-lightGray tw-bg-white tw-accent-lightGreen checked:tw-border-none checked:tw-bg-lightGreen'
          )}
          onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFocusedChoiceId(Number(e.target.value))
          }
        />
        <CheckMark className="tw-absolute tw-top-6 tw-left-5" />
      </div>

      <InputLabel
        textValue={textValue}
        label={letterFromNumber}
        isChecked={isChecked}
        className={clsx(isImage && 'tw-hidden')}
      />
    </div>
  );
};
