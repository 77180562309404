import {useAppTranslation} from "services/i18n";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper} from "@mui/material";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {TextFormFieldPlain} from "components/form/TextFormField";
import {CheckboxPlain} from "components/form/CheckboxFormField";
import {isSearchWithinSubject} from "components/DataGrid";
import {shorten, sortByName} from "utils/utils";
import {Link} from "components/clickable/Link";

interface SimpleQuestionType {
  id: number,
  name: string
}

interface Props {
  catalogueId: number;
  questions: SimpleQuestionType[];
  currentQuestionIds?: number[];
  onSuccess: (questionIds: number[]) => unknown;
  onClose: () => unknown;
}

export const CategoryQuestionsDialog = (props: Props) => {
  const {catalogueId, questions, currentQuestionIds, onSuccess, onClose} = props;
  const t = useAppTranslation();

  const [search, setSearch] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<number[]>(currentQuestionIds || []);
  const [clickCount, setClickCount] = useState<number>(0);

  const handleSave = useCallback(() => {
    onSuccess(selectedIds);
  }, [selectedIds, onSuccess]);

  const createItems = useCallback((questions: SimpleQuestionType[], items: JSX.Element[]) => {
    questions
      .sort(sortByName)
      .forEach((question) => {
        if (search && !isSearchWithinSubject(search, question.name)) {
          return;
        }
        const isSelected = selectedIds.indexOf(question.id) >= 0;
        items.push(<Box
          key={question.id} className={'tw-flex tw-flex-row tw-items-center tw-justify-items-stretch'}>
          <div className={'tw-pr-24 tw-flex tw-self-center'}>
            <CheckboxPlain
              name={'cat-' + question.id}
              currentValue={isSelected}
              onChange={() => {
                const pos = selectedIds.indexOf(question.id);
                if (pos >= 0) {
                  selectedIds.splice(pos, 1);
                } else {
                  selectedIds.push(question.id);
                }
                setSelectedIds(selectedIds.sort());
                setClickCount(clickCount + 1);
              }}
            />
          </div>
          <p className={'tw-flex tw-w-full'}>{shorten(100)(question.name)}</p>
        </Box>)
      });

  }, [clickCount, search, selectedIds]);

  const questionItems = useMemo(() => {
    const items: JSX.Element[] = [];
    createItems(questions.filter(q => selectedIds.indexOf(q.id) >= 0), items);
    createItems(questions.filter(q => selectedIds.indexOf(q.id) < 0), items);
    return items;

  }, [questions, selectedIds, createItems]);

  return (
    <Dialog open={true} onClose={onClose} PaperComponent={Paper}>
      <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
        <span>{t('catalogues.categories.form.questionSearch')}</span>
        <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {!!questions.length && <Grid item xs={12}>
              <TextFormFieldPlain
                  name={'search'}
                  currentValue={search}
                  onChange={setSearch}
                  label={t('catalogues.questions.searchTitle')}
                  placeholder={t('catalogues.questions.searchPlaceholder')}
              />
          </Grid>}
          <Grid item xs={12}>
            {!questions.length && <p><i>{t('catalogues.questions.emptyList')}</i></p>}
            {questionItems}
            <Link
              kind="bare"
              className="tw-mt-16 tw-pt-8 tw-pb-10 tw-pl-32 tw-pr-32 tw-inline-block tw-rounded-lg tw-border tw-border-dark tw-bg-dark tw-text-center tw-text-white"
              href={"/#/catalogues/" + catalogueId + "/questions"}
            >
              {t(`catalogues.categories.form.goToQuestions`)}
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={onClose}>
          {t('form.close')}
        </Button>
        {!!questions.length && <Button color={'primary'} onClick={handleSave}>
          {t('catalogues.categories.form.saveQuestions')}
        </Button>}
      </DialogActions>
    </Dialog>
  );
};
