import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store';
import { selectCodebooks } from '../store/selectors';
import { fetchOrgUnits } from '../store/codebooks';
import { Grid } from '@mui/material';
import { CodebookList } from '../components/codebook/CodebookList';

export interface CodebookCategory {
  category: 'department' | 'job_title';
}

const codebookCategories: CodebookCategory[] = [
  {
    category: 'department',
  },
  {
    category: 'job_title',
  },
];

export const CodebooksPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const codebooks = useSelector(selectCodebooks);

  const [isLoading, setIsLoading] = useState(false);

  const handleFetchItems = useCallback(() => {
    setIsLoading(true);
    dispatch(fetchOrgUnits()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    handleFetchItems();
  }, [dispatch, handleFetchItems]);

  const categories = [];
  for (let i = 0; i < codebookCategories.length; i++) {
    const codebookCategory = codebookCategories[i];
    categories.push(
      <Grid item xs={12} md={6} key={codebookCategory.category}>
        <CodebookList
          codebookCategory={codebookCategory}
          itemsState={{ items: codebooks[codebookCategory.category], isLoading }}
          fetchItems={handleFetchItems}
        />
      </Grid>
    );
  }

  return (
    <div className={'layout-fill layout-column'}>
      <div className={'kws-content-margin flex'}>
        <Grid container spacing={2}>
          {categories}
        </Grid>
      </div>
    </div>
  );
};
