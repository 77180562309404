export class SupportChat {
  static isHidden: boolean = true;

  static showChat() {
    // @ts-ignore
    window.smartsupp('chat:show');
  }

  static openChat() {
    // @ts-ignore
    window.smartsupp('chat:open');
    this.isHidden = false;
  }

  static hideChat() {
    // @ts-ignore
    window.smartsupp('chat:hide');
    this.isHidden = true;
  }
}
