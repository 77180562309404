import { AsyncThunk, Middleware, PayloadAction, SerializedError } from '@reduxjs/toolkit';

export const apiUnautorizedMiddlewareFactory: (
  fetchAuthLogout: AsyncThunk<any, void, any>
) => Middleware =
  (fetchAuthLogout) =>
  (store) =>
  (next) =>
  (action: PayloadAction<never, any, any, SerializedError>) => {
    if (action.error?.code === '401' && !fetchAuthLogout.rejected.match(action)) {
      store.dispatch(fetchAuthLogout() as any);
      return (window.location.href = '#/login');
    }

    if (action.error?.code === '403') return (window.location.href = '#/dashboard');
    if (action?.error?.code === '404' || action?.error?.code === 'ActiveRecord::RecordNotFound')
      return (window.location.href = '#/notFound');

    return next(action);
  };
