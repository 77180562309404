import {
  ContentViewerStepParticipation,
  ContentViewerStepParticipationStateEnum,
} from 'generated-api';

export enum TestResult {
  Passed = 'passed',
  Failed = 'failed',
}

export enum ShowAnswersInTestResult {
  None = 'none',
  OnlyIncorrect = 'only_incorrect',
  All = 'all',
}

export enum QuestionType {
  Multiple = 'multiple',
  Single = 'single',
  Matching = 'matching',
}

export enum ResponseType {
  Text = 'text',
  Image = 'image',
}

export interface DraggableItemType {
  item: string;
}

export interface MultipleChoice {
  [key: number | string]: boolean;
}

export interface Answer {
  choice_id: number;
  payload: string[] | null;
}

export interface AnswerKeyType {
  choice_id: number;
  text: string;
}

export interface NotAnsweredQuestion {
  answerId: string;
  choice_ids: string[];
  position: number;
  testInstanceId: string;
}

export type Step =
  | {
      lessonId: number;
      lessonPosition: number;
      stepId?: number;
      stepPosition?: number;
      state?: ContentViewerStepParticipationStateEnum;
      name?: string;
      stepParticipation?: ContentViewerStepParticipation;
    }
  | undefined;
