import {Button, ButtonGroup, FormControl, FormHelperText, FormLabel, Grid} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {FormFieldProps, OptionValue} from '../../model/form';
import {useAppTranslation} from "../../services/i18n";
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";

interface Props extends FormFieldProps {
    options?: OptionValue[],
    isMulti?: boolean,
    fullWidth?: boolean,
    hideLabel?: boolean,
    iconsOnly?: boolean,
    splitBy?: string,
    debounce?: boolean,
    separateButtons?: boolean
}

const INPUT_DELAY = 200;

interface PropsPlain extends Props {
    currentValue: any,
    showError?: boolean,
    error?: string
}

export const ButtonGroupPlain = (props: PropsPlain) => {
    const {
        name, label, options, isMulti,
        onChange, currentValue, showError, error,
        disabled, iconsOnly, separateButtons
    } = props;

    const t = useAppTranslation();

    const buttons = useMemo(() => {
        return options?.map((option, i) => {
            const isSelected = isMulti
                ? (currentValue && currentValue.indexOf(option.value) >= 0)
                : currentValue === option.value;
            return <Button key={i} disableRipple
                color={isSelected ? (option.color || 'primary') : (option.color || 'inherit')}
                variant={isSelected ? 'contained' : (separateButtons ? 'outlined' : undefined)}
                disabled={disabled}
                title={option.tooltip}
                onClick={() => {
                    let newValue;
                    if (isSelected) {
                        if (isMulti) {
                            newValue = currentValue ? currentValue.filter((v: any) => v !== option.value) : [];
                        } else {
                            newValue = undefined;
                        }
                    } else {
                        if (isMulti) {
                            newValue = currentValue ? [...currentValue] : [];
                            newValue.push(option.value);
                            newValue.sort();
                        } else {
                            newValue = option.value;
                        }
                    }
                    if (onChange) {
                        onChange(newValue);
                    }
                }}>{option.icon ? <>{option.icon}{iconsOnly ? null : <span>{option.label}</span>}</> : option.label}</Button>;
        })
    }, [options, isMulti, currentValue, onChange, disabled, iconsOnly, separateButtons]);

    return <FormControl error={showError} fullWidth={props.fullWidth}>
        {label && <FormLabel>{typeof label === 'string' ? t(label) : undefined}</FormLabel>}
        {separateButtons
            ? <Grid columnSpacing={2} columns={buttons?.length} container sx={{
                '& > .MuiGrid-item > .MuiButtonBase-root': {
                    height: '100%',
                    width: '100%',
                },
                '& > .MuiGrid-item > .MuiButton-containedPrimary.Mui-disabled': {
                    backgroundColor: (theme) => theme.palette['primary'].main,
                    color: (theme) => theme.palette['primary'].contrastText,
                    opacity: .7
                }
            }}>{buttons?.map((b, i) => <Grid key={i} item sm={1}>{b}</Grid>)}</Grid>
            : <ButtonGroup size={'small'} title={typeof label === 'string' ? t(label) : undefined} fullWidth={props.fullWidth}>
                {buttons}
            </ButtonGroup>
        }
        {showError && error && <FormHelperText>{error}</FormHelperText>}
        <input name={name} style={{display: 'none'}}/>
    </FormControl>;
}

export const ButtonGroupField = (props: Props) => {
    const {name, onChange, splitBy, debounce} = props;

    const [field, meta, {setValue}] = useField(name);
    const {submitCount} = useFormikContext();
    const [innerValue, setInnerValue] = useState();

    const showError = !!meta.error && (meta.touched || submitCount > 0);

    const parseValue = useCallback((value: any) => {
        return splitBy && value ? value.split(splitBy) : value;
    }, [splitBy]);

    const createValue = useCallback((value: any) => {
        return value && splitBy ? value.slice().sort().join(splitBy) : value;
    }, [splitBy]);

    const formValue = field.value;
    useEffect(() => {
        setInnerValue(formValue);
    }, [formValue]);

    let timeoutHandle = useRef<any>(null);

    const handleOnChange = useCallback((value: any) => {
        const v = createValue(value);
        setInnerValue(v);

        if (!debounce) {
            setValue(v, true);
            if (onChange) {
                onChange(v);
            }
            return;
        }

        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }

        timeoutHandle.current = setTimeout(() => {
            setValue(v, true);
            if (onChange) {
                onChange(v);
            }

        }, timeoutHandle.current ? INPUT_DELAY : 1);
    }, [debounce, createValue, setValue, onChange]);

    return <ButtonGroupPlain {...props}
        currentValue={parseValue(innerValue)}
        onChange={handleOnChange}
        showError={showError}
        error={meta.error}
    />;
}
