import { Notifier } from '@airbrake/browser';

const ID = 131455;
const KEY = 'd227c08fd44064e4c2655c36b3bd4e26';

const getEnvironment = () => {
  if (window.location.hostname.includes('demo')) return 'demo';
  if (window.location.hostname.includes('dev')) return 'development';
  if (window.location.hostname.includes('staging')) return 'staging';
  if (window.location.hostname.includes('localhost')) return 'local';
  return 'production';
};

const airbrake =
  process.env.NODE_ENV === 'production'
    ? new Notifier({
        projectId: ID,
        projectKey: KEY,
        environment: getEnvironment(),
      })
    : undefined;

export default airbrake;
