import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {API_CONFIG} from '../app/api-config';
import {UserApi, UserShowResponse, UserUpdate} from '../generated-api';

const userApi = new UserApi(API_CONFIG);

export const fetchAuthLogout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  await userApi.usersLogoutPost();
  // window.location.reload(); // destroy all js data (store etc.)
});

export const fetchAuthUser = createAsyncThunk('auth/user-info', async (_, thunkAPI) => {
  return await userApi.usersIdGet({
    id: 'me',
  });
});

// Example
// TODO: move following actions to separated file (own store slice?)
export const saveUser = createAsyncThunk(
  'user/save',
  async (param: { userId: number | 'me'; data: UserUpdate }) => {
    return await userApi.usersIdPut({
      id: `${param.userId}`,
      body: param.data,
    });
  }
);

// export const fetchCompanySpaces = createAsyncThunk('subscription/company-spaces', async (_) => {
// 	return await subscriptionApi.companySpacesSubscriptionGet();
// });

export interface AuthState {
  user: UserShowResponse | null;
}

const initialState: AuthState = {
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAuthUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchAuthUser.rejected, (state, action) => {
      state.user = null;
    });
    builder.addCase(fetchAuthLogout.pending, (state, action) => initialState);
  },
});

export const authReducer = authSlice.reducer;

// eslint-disable-next-line no-empty-pattern
export const {} = authSlice.actions;
