import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  ChoiceApi,
  ContentEditorElementsGetRequest,
  ContentEditorTestsChoicesChoiceIdFilesIdDeleteRequest,
  ContentEditorTestsIdPutRequest,
  ContentEditorTestsPostRequest,
  ContentEditorTestsTestIdEntriesGetRequest,
  ContentEditorTestsTestIdEntriesIdDeleteRequest,
  ContentEditorTestsTestIdEntriesIdPutRequest,
  ContentEditorTestsTestIdEntriesPostRequest,
  ContentEditorTestsTestIdQuestionsIdDeleteRequest,
  ContentEditorTestsTestIdQuestionsIdPutRequest,
  ContentEditorTestsTestIdQuestionsPostRequest,
  ContentEditorTestsTestIdQuestionsQuestionIdChoicesChoiceIdFilesIdPostRequest,
  ContentEditorTestsTestIdQuestionsQuestionIdChoicesIdDeleteRequest,
  ContentEditorTestsTestIdQuestionsQuestionIdChoicesIdPutRequest,
  ContentEditorTestsTestIdQuestionsQuestionIdChoicesPostRequest,
  ContentEditorTestsTestIdQuestionsQuestionIdFilesIdDeleteRequest,
  ContentEditorTestsTestIdQuestionsQuestionIdFilesIdPostRequest,
  ContentEditorTestsTestIdQuestionsQuestionIdPurgeChoicesPostRequest,
  ContentEditorVersionsIdGetRequest,
  ElementApi,
  EntryApi,
  QuestionApi,
  TestDefinitionApi,
  UploadedFileApi,
  UploadedFilesIdPutRequest,
  UploadedFilesPostRequest,
  VersionApi,
} from '../generated-api';
import {API_CONFIG} from '../app/api-config';
import * as runtime from "../generated-api/runtime";

const testDefinitionApi = new TestDefinitionApi(API_CONFIG);
const versionApi = new VersionApi(API_CONFIG);
const elementApi = new ElementApi(API_CONFIG);
const questionApi = new QuestionApi(API_CONFIG);
const choiceApi = new ChoiceApi(API_CONFIG);
const uploadedFileApi = new UploadedFileApi(API_CONFIG);
const entryApi = new EntryApi(API_CONFIG);

export const fetchEditorTest = createAsyncThunk('contentEditorTests/get', async (args: {
  elementId: number,
  initOverrides?: RequestInit | runtime.InitOverrideFunction
}) => {
  return await testDefinitionApi.contentEditorTestsGet({elementId: args.elementId}, args.initOverrides);
});

export const fetchEditorElements = createAsyncThunk(
  'contentEditorElements/get',
  async (requestParameters: ContentEditorElementsGetRequest) => {
    return await elementApi.contentEditorElementsGet(requestParameters);
  }
);

export const fetchVersions = createAsyncThunk('contentEditorVersions/get', async (args: ContentEditorVersionsIdGetRequest) => {
  return await versionApi.contentEditorVersionsIdGet(args);
});


export const createEditorTest = createAsyncThunk('contentEditorTests/create', async (args: ContentEditorTestsPostRequest) => {
  return await testDefinitionApi.contentEditorTestsPost(args);
});

export const updateEditorTest = createAsyncThunk('contentEditorTests/create', async (args: ContentEditorTestsIdPutRequest) => {
  return await testDefinitionApi.contentEditorTestsIdPut(args);
});

export const createEditorTestsQuestion = createAsyncThunk('contentEditorTestsQuestions/update', async (args: ContentEditorTestsTestIdQuestionsPostRequest) => {
  return await questionApi.contentEditorTestsTestIdQuestionsPost(args);
});

export const updateEditorTestsQuestion = createAsyncThunk('contentEditorTestsQuestions/update', async (args: ContentEditorTestsTestIdQuestionsIdPutRequest) => {
  return await questionApi.contentEditorTestsTestIdQuestionsIdPut(args);
});

export const deleteEditorTestQuestion = createAsyncThunk('contentEditorTestsQuestions/delete', async (args: ContentEditorTestsTestIdQuestionsIdDeleteRequest) => {
  return await questionApi.contentEditorTestsTestIdQuestionsIdDelete(args);
});

export const purgeEditorTestQuestionChoices = createAsyncThunk('contentEditorTestsQuestionsChoices/purge', async (args: ContentEditorTestsTestIdQuestionsQuestionIdPurgeChoicesPostRequest) => {
  return await questionApi.contentEditorTestsTestIdQuestionsQuestionIdPurgeChoicesPost(args);
});

export const createEditorTestsQuestionChoice = createAsyncThunk('contentEditorTestsQuestionChoices/update', async (args: ContentEditorTestsTestIdQuestionsQuestionIdChoicesPostRequest) => {
  return await choiceApi.contentEditorTestsTestIdQuestionsQuestionIdChoicesPost(args);
});

export const updateEditorTestsQuestionChoice = createAsyncThunk('contentEditorTestsQuestionChoices/update', async (args: ContentEditorTestsTestIdQuestionsQuestionIdChoicesIdPutRequest) => {
  return await choiceApi.contentEditorTestsTestIdQuestionsQuestionIdChoicesIdPut(args);
});

export const deleteEditorTestQuestionChoice = createAsyncThunk('contentEditorTestsQuestionChoices/delete', async (args: ContentEditorTestsTestIdQuestionsQuestionIdChoicesIdDeleteRequest) => {
  return await choiceApi.contentEditorTestsTestIdQuestionsQuestionIdChoicesIdDelete(args);
});

export const linkEditorTestQuestionFile = createAsyncThunk('contentEditorTestsQuestionFiles/link', async (args: ContentEditorTestsTestIdQuestionsQuestionIdFilesIdPostRequest) => {
  return await uploadedFileApi.contentEditorTestsTestIdQuestionsQuestionIdFilesIdPost(args)
});

export const linkEditorTestQuestionChoiceFile = createAsyncThunk('contentEditorTestsQuestionChoicesFiles/link', async (args: ContentEditorTestsTestIdQuestionsQuestionIdChoicesChoiceIdFilesIdPostRequest) => {
  return await uploadedFileApi.contentEditorTestsTestIdQuestionsQuestionIdChoicesChoiceIdFilesIdPost(args)
});

export const deleteEditorTestQuestionFile = createAsyncThunk('contentEditorTestsQuestionFiles/delete', async (args: ContentEditorTestsTestIdQuestionsQuestionIdFilesIdDeleteRequest) => {
  return await uploadedFileApi.contentEditorTestsTestIdQuestionsQuestionIdFilesIdDelete(args);
});

export const deleteEditorTestQuestionChoiceFile = createAsyncThunk('contentEditorTestsQuestionChoiceFiles/delete', async (args: ContentEditorTestsChoicesChoiceIdFilesIdDeleteRequest) => {
  return await uploadedFileApi.contentEditorTestsChoicesChoiceIdFilesIdDelete(args);
});

export const createUploadedFile = createAsyncThunk('contentEditorTestsQuestionFiles/put', async (args: UploadedFilesPostRequest) => {
  return await uploadedFileApi.uploadedFilesPost(args);
});

export const updateUploadedFile = createAsyncThunk('contentEditorTestsQuestionFiles/put', async (args: UploadedFilesIdPutRequest) => {
  return await uploadedFileApi.uploadedFilesIdPut(args);
});

export const fetchEditorTestsEntries = createAsyncThunk('contentEditorTestsEntries/get', async (args: ContentEditorTestsTestIdEntriesGetRequest) => {
  return await entryApi.contentEditorTestsTestIdEntriesGet(args);
});

export const createEditorTestsEntry = createAsyncThunk('contentEditorTestsEntries/create', async (args: ContentEditorTestsTestIdEntriesPostRequest) => {
  return await entryApi.contentEditorTestsTestIdEntriesPost(args);
});

export const updateEditorTestsEntry = createAsyncThunk('contentEditorTestsEntries/update', async (args: ContentEditorTestsTestIdEntriesIdPutRequest) => {
  return await entryApi.contentEditorTestsTestIdEntriesIdPut(args);
});

export const deleteEditorTestsEntry = createAsyncThunk('contentEditorTestsEntries/delete', async (args: ContentEditorTestsTestIdEntriesIdDeleteRequest) => {
  return await entryApi.contentEditorTestsTestIdEntriesIdDelete(args);
});

