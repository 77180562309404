import * as React from "react";
import {Box, Typography} from "@mui/material";

type Props = {
    icon?: JSX.Element,
    title: string
}

export function PageHeader({icon, title}: Props) {
    return <Box sx={{
        backgroundColor: 'var(--color-black80)',
        color: 'var(--color-white)',
        height: '56px',
        padding: '16px 24px',
        display: 'flex',
        alignItems: 'center'
    }}>
        {icon}
        <Typography sx={icon ? {marginLeft: "10px"}: undefined}>{title}</Typography>
    </Box>;
}
