import * as React from "react";
import {useCallback, useState} from "react";
import {styled, Tab, Tabs} from "@mui/material";

// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: number;
//     value: number;
// }
//
// function TabPanel(props: TabPanelProps) {
//     const {children, value, index} = props;
//
//     return value === index ?
//         <Paper sx={{p: 3, boxShadow: "unset", borderRadius: 0, border: "1px solid #F5F5F5", flexGrow: "1"}}>
//             {children}
//         </Paper> : null;
// }

interface StyledTabProps {
    label: string;
    disabled?: boolean;
}

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(
    ({theme}) => ({
        // textTransform: 'none',
        // fontWeight: theme.typography.fontWeightRegular,
        // color: 'rgba(0, 0, 0, 0.87)',
        // minHeight: "56px",
        // fontSize: "16px",
        // padding: "24px",
        // '&.Mui-selected': {
        //     color: 'inherit',
        //     fontWeight: theme.typography.fontWeightMedium,
        //     background: '#fff'
        // },
        // '&:hover': {
        //     color: 'inherit',
        //     background: '#fff'
        // },
        // '&:Mui-focusVisible': {
        //     color: 'inherit',
        //     background: '#fff'
        // },
    }),
);

export type PageTab = {
    tab: string,
    label: string,
    content: () => JSX.Element,
    disabled?: boolean
}

export type PageTabsProps = {
    tabs: PageTab[],
    currentTab?: string
    onTabChange?: (tab: string) => void
}

export const PageTabs = ({tabs, currentTab, onTabChange}: PageTabsProps) => {
    const [value, setValue] = useState(currentTab ? (tabs.indexOf(tabs.find(t => t.tab === currentTab)!) || 0) : 0);

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        if (onTabChange) {
            onTabChange(tabs[newValue].tab);
        }
        setValue(newValue);
    }, [tabs, onTabChange]);

    return <>
        <Tabs key={'tabs'} value={value} onChange={handleChange} sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
                '.MuiTabs-scroller': {
                    '.MuiTabs-flexContainer': {
                        flexWrap: 'wrap',
                    }
                }
            },
        })}>
            {tabs.map((tab, i) => {
                return <StyledTab label={tab.label} key={i} disabled={!!tab.disabled}/>
            })}
        </Tabs>
        {tabs[value]?.content()}
    </>;
}
