import React from 'react';
import './index.scss';
import App from './app/App';
import {AppBridgeEvent} from './model/appBridge';
import {appBridgeCheckContext, createBridgedPage, unmountBridgedPage} from './services/appBridge';
import {initStaticCodebooks} from './store/codebooks';
import configureAppStore, {rootReducer} from './store/configureStore';

// Global redux store persisted between page renders
export const store = configureAppStore();

// Redux types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

// Init bridge
(window as any).kwsAppBridgeCallback = (e: AppBridgeEvent) => {
    // console.log('[appBridge] callback', e.type, e);
    renderBridgedPage(e);
};

// Init global components
store.dispatch(initStaticCodebooks());

function renderBridgedPage(event: AppBridgeEvent, attempt?: number) {
    appBridgeCheckContext(event, store.dispatch);

    if (event.type === 'page') {
        const rootElm = document.getElementById(event.rootId) as HTMLElement;
        attempt = (attempt || 0) + 1;
        if (!rootElm && attempt < 5) {
            setTimeout(() => {
                console.log("Retrying " + event.rootId + " at " + attempt);
                // give ng time to render template (temporary solution)
                renderBridgedPage(event, attempt);
            }, Math.pow(2, attempt) * 100);
            return;
        }

        if (!rootElm) {
            const errorRootElm = document.createElement('div');
            document.body.appendChild(errorRootElm);
            createBridgedPage(event, errorRootElm).render(<App appEvent={event}/>);
            errorRootElm.remove(); // cleanup
        } else {
            createBridgedPage(event, rootElm).render(<App appEvent={event}/>);
        }
    } else if (event.type === 'page-destroy') {
        unmountBridgedPage(event);
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
