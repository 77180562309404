import {
  ContentViewerTestDefinitionTestAttemptPayloadUpdateResponse,
  ContentViewerShowResponse,
} from 'generated-api';
import { TestSolutuon } from './TestSolution';
import { Trans } from 'react-i18next';
import { useAppTranslation } from 'services/i18n';
import clsx from 'clsx';

interface Props {
  results?: ContentViewerTestDefinitionTestAttemptPayloadUpdateResponse;
  courseContent: ContentViewerShowResponse;
  isOnlyErrorsDisplayed?: boolean;
}

export const TestQuestionResults = ({ results, courseContent, isOnlyErrorsDisplayed }: Props) => {
  const t = useAppTranslation();

  const questions = results?.questions;
  const showAnswersInTestResult = results?.show_answers_in_test_result;

  return (
    <div className="tw-mt-40 tw-flex tw-flex-col tw-items-center tw-gap-16">
      {questions?.map((question, index) => {
        const {
          point_value: pointValue,
          name,
          position,
          file,
          dependent_steps: dependentSteps,
          answer,
        } = question;

        const achievedPointsPerQuestion = answer ? answer.achieved_points : 0;
        const isPassed = achievedPointsPerQuestion === pointValue;

        const areDependentSteps = dependentSteps && dependentSteps.length > 0;

        if (isOnlyErrorsDisplayed && achievedPointsPerQuestion > 0) return null;

        return (
          <article
            className="tw-w-full tw-gap-16 tw-rounded-lg tw-bg-white tw-pb-20 lg:tw-w-9/12"
            key={index}
          >
            <div className="tw-p-20">
              <div className="tw-text-textGray tw-text-12">
                <Trans i18nKey="contentViewer.testViewer.question">Otázka č. {{ position }}</Trans>
              </div>
              <p className="tw-flex tw-justify-between tw-gap-10 tw-text-16 tw-font-semibold tw-text-black">
                <span>{name}</span>
                <strong
                  className={clsx(
                    'tw-block tw-min-w-65',
                    isPassed ? 'tw-text-lightGreen' : 'tw-text-red'
                  )}
                >
                  {achievedPointsPerQuestion}/{pointValue} {t(`contentViewer.testViewer.value`)}
                </strong>
              </p>
              {file && (
                <img
                  src={file.download_url}
                  alt="Test question"
                  className="tw-mt-4 tw-max-h-200 tw-max-w-300 tw-object-contain"
                />
              )}
            </div>

            <TestSolutuon question={question} showAnswersInTestResult={showAnswersInTestResult} />
            {areDependentSteps && (
              <section className="tw-border-t tw-border-black/5">
                <p className="tw-mt-12 tw-px-20 tw-text-12 tw-text-gray">Studijní kroky</p>
                <div className="tw-mt-12 tw-flex tw-gap-8 tw-px-20 tw-text-12 tw-text-gray">
                  {areDependentSteps &&
                    dependentSteps.map((dependentStep) => {
                      const { lesson, steps } = dependentStep;
                      return steps?.map((step, index) => (
                        <div
                          className="tw-rounded-xl tw-border tw-border-black/5 tw-px-12 tw-py-4 tw-text-12"
                          key={index}
                        >
                          {lesson?.position}.{step.position} {step.name}
                        </div>
                      ));
                    })}
                </div>
              </section>
            )}
          </article>
        );
      })}
    </div>
  );
};
