import {Checkbox, FormControl, FormControlLabel, FormHelperText, Switch, Tooltip} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {FormFieldProps} from '../../model/form';
import * as React from "react";
import {CheckboxProps} from "@mui/material/Checkbox/Checkbox";
import {CheckBoxOutlineBlankRounded, CheckBoxRounded, HelpOutline} from "@mui/icons-material";
import Radio from "@mui/material/Radio";

interface Props extends FormFieldProps {
    type?: 'checkbox' | 'switch' | 'radio',
    color?: CheckboxProps['color'],
    offColor?: CheckboxProps['color'],
    triState?: boolean,
    emptyValue?: boolean,
    help?: string | JSX.Element,
    tooltip?: string
}

interface PropsPlain extends Props {
    currentValue?: any,
    showError?: boolean,
    error?: string
}

export const CheckboxPlain = (props: PropsPlain) => {
    const {
        name, label, color, offColor, triState,
        emptyValue, onChange, currentValue, tooltip,
        showError, error, disabled
    } = props;

    const indeterminate = triState && currentValue === undefined;

    return <FormControl error={showError}>
        <FormControlLabel sx={{
            alignSelf: 'flex-start',
            '& .Mui-disabled.Mui-checked': {
                color: (theme) => color || theme.palette['primary'].main
            }
        }}
            control={<Checkbox
                name={name}
                checked={currentValue || false}
                indeterminate={indeterminate}
                indeterminateIcon={triState && emptyValue !== undefined
                    ? (emptyValue
                            ? <CheckBoxRounded/>
                            : <CheckBoxOutlineBlankRounded/>
                    ) : undefined}
                color={offColor && currentValue === false ? offColor : (indeterminate ? 'default' : (color || 'primary'))}
                onChange={() => {
                    let value;
                    if (triState) {
                        if (currentValue) {
                            if (emptyValue === false) {
                                value = undefined;
                            } else {
                                value = false;
                            }
                        } else if (currentValue === false) {
                            value = undefined;
                        } else {
                            if (emptyValue) {
                                value = false;
                            } else {
                                value = true;
                            }
                        }
                    } else {
                        value = !currentValue;
                    }
                    if (onChange) {
                        onChange(value);
                    }
                }}/>}
            label={label}
            title={tooltip}
            disabled={disabled}
        />
        {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>;
}

export const CheckRadioPlain = (props: PropsPlain) => {
    const {
        name, label,
        color, offColor,
        onChange, currentValue, tooltip,
        showError, error, disabled
    } = props;

    return <FormControl error={showError}>
        <FormControlLabel sx={{
            alignSelf: 'flex-start',
            '& .Mui-disabled.Mui-checked': {
                color: (theme) => theme.palette['primary'].main
            }
        }}
            control={<Radio
                name={name}
                checked={currentValue || false}
                color={offColor && currentValue === false ? offColor : (color || 'primary')}
                onChange={() => {
                    if (onChange) {
                        onChange(!currentValue);
                    }
                }}/>}
            label={label}
            title={tooltip}
            disabled={disabled}
        />
        {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>;
}

export const SwitchPlain = (props: PropsPlain) => {
    const {
        name, label, color, offColor,
        disabled, onChange, currentValue, showError, error,
        help, tooltip
    } = props;

    return <FormControl error={showError}>
        <FormControlLabel sx={{
            alignSelf: 'flex-start',
            '& > .MuiFormControlLabel-label': {
                order: -1,
                marginLeft: '11px',
                marginRight: '10px'
            },
            '& > .MuiSwitch-root > .Mui-disabled.Mui-checked + .MuiSwitch-track': {
                opacity: .6
            }
        }}
            control={<Switch
                name={name}
                checked={currentValue || false}
                color={offColor && currentValue === false ? offColor : (color || 'primary')}
                onChange={() => {
                    if (onChange) {
                        onChange(!currentValue);
                    }
                }}
                disabled={disabled}
            />}
            label={!!help
                ? <p>{label} <Tooltip title={help}><HelpOutline sx={{fontSize: '100%'}}/></Tooltip></p>
                : <p>{label}</p>}
            title={tooltip}
        />
        {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>;
}

export const CheckboxFormField = (props: Props) => {
    const {type, name, onChange} = props;

    const [field, meta, helpers] = useField(name);
    const {submitCount} = useFormikContext();
    const showError = !!meta.error && (meta.touched || submitCount > 0);

    return type === 'switch'
        ? <SwitchPlain {...props}
            currentValue={field.value}
            onChange={(value) => {
                helpers.setValue(value, true);
                if (onChange) {
                    onChange(value);
                }
            }}
            showError={showError}
            error={meta.error}
        />
        : (type === 'radio'
            ? <CheckRadioPlain {...props}
                currentValue={field.value}
                onChange={(value) => {
                    helpers.setValue(value, true);
                    if (onChange) {
                        onChange(value);
                    }
                }}
                showError={showError}
                error={meta.error}
            />
            : <CheckboxPlain {...props}
                currentValue={field.value}
                onChange={(value) => {
                    helpers.setValue(value, true);
                    if (onChange) {
                        onChange(value);
                    }
                }}
                showError={showError}
                error={meta.error}
            />);

}
