import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

export const featureAuth = (state: RootState) => state.auth;
export const featureCodebooks = (state: RootState) => state.codebooks;
const featureBilling = (state: RootState) => state.billing;
const featureContentViewer = (state: RootState) => state.contentViewer;

export const selectAuthIsLogged = createSelector([featureAuth], (auth) => !!auth.user);
export const selectAuthUser = createSelector([featureAuth], (auth) => auth.user);
export const selectCodebooks = createSelector([featureCodebooks], (state) => state);
export const selectContentViewer = createSelector([featureContentViewer], (state) => state);

export const selectBilling = createSelector([featureBilling], (state) => state);
