import { useAppTranslation } from 'services/i18n';
import { Tag } from 'components/Tag';
import { Markdown } from 'components/Markdown';
import { ContentViewerShowResponseContentVersionLessonsInner } from 'generated-api';
import { ContentLayoutWithControls } from './ContentLayoutWithControls';

interface LessonIntroProps {
  lesson: ContentViewerShowResponseContentVersionLessonsInner;
  contentType?: string;
  contentLoading?: boolean;
  onNavigateToNextStep: () => void;
  onNavigateToPreviousStep: () => void;
  isFirstStep?: boolean;
}

export const LessonIntro = ({
  contentLoading,
  lesson,
  onNavigateToNextStep,
  onNavigateToPreviousStep,
  isFirstStep,
}: LessonIntroProps) => {
  const t = useAppTranslation();
  const { position, name, description } = lesson;

  return (
    <ContentLayoutWithControls
      contentLoading={contentLoading}
      leftKeyText={isFirstStep ? undefined : t('contentViewer.keyboardControls.back')}
      onLeftKeyPress={isFirstStep ? undefined : onNavigateToPreviousStep}
      rightKeyText={t(`contentViewer.intro.actionButton.continue`)}
      onRightKeyPress={true ? onNavigateToNextStep : () => undefined}
    >
      <section className="tw-my-auto tw-ml-16 tw-space-y-16 tw-overflow-auto sm:tw-ml-64">
        <Tag>
          {t(`contentViewer.lessonIntro.tag`)} {position}
        </Tag>
        <h1 className="tw-text-30 tw-font-semibold">{name}</h1>
        <Markdown className="markdown">{description}</Markdown>
      </section>
    </ContentLayoutWithControls>
  );
};
