import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Paper,} from '@mui/material';
import {useAppTranslation} from '../services/i18n';

type Props = {
    title: string;
    action: string;
    onConfirm: (result: any) => unknown;
    onCancel: () => unknown;
    isSaving: boolean;
    children: JSX.Element;
};

export const ConfirmDialog = (props: Props) => {
    const {title, action, onCancel, onConfirm, children, isSaving} = props;
    const t = useAppTranslation();

    return (
        <Dialog open={true} onClose={onCancel} PaperComponent={Paper}>
            <DialogTitle>{t(title)}</DialogTitle>
            <LinearProgress hidden={!isSaving}/>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button color={'inherit'} onClick={onCancel} disabled={isSaving}>
                    {t('form.cancel')}
                </Button>
                <Button color={'primary'} variant={'contained'} onClick={onConfirm} disabled={isSaving}>
                    {action}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
