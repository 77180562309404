import clsx from 'clsx';
import React from 'react';
import { KeyboardControls, KeyboardControlsProps } from './KeyboardControls';
import { LoadingSpinner } from 'components/loading/LoadingSpinner';

interface ContentLayoutWithControlsProps extends KeyboardControlsProps {
  children: React.ReactNode;
  contentLoading?: boolean;
  customKeyboardControls?: React.ReactNode;
  className?: string;
}

export const ContentLayoutWithControls = React.forwardRef(
  (
    {
      children,
      contentLoading,
      customKeyboardControls,
      className,
      ...keyboardControlsProps
    }: ContentLayoutWithControlsProps,
    ref
  ) => {
    return (
      <div className="tw-h-full tw-w-full">
        <div
          className={clsx('h-content tw-flex tw-h-full tw-flex-col tw-overflow-auto', className)}
          ref={ref as React.LegacyRef<HTMLDivElement>}
        >
          {contentLoading ? <LoadingSpinner /> : children}
        </div>
        {customKeyboardControls ? (
          customKeyboardControls
        ) : (
          <KeyboardControls loading={contentLoading} {...keyboardControlsProps} />
        )}
      </div>
    );
  }
);
