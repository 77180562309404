import { useAppTranslation } from 'services/i18n';
import { ReactComponent as AwardIcon } from 'icons/award-small.svg';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

interface Props {
  progress: number;
  completedLessonsCount?: number;
  lessonsCount?: number;
}

export const ProgressDropdown = ({ progress, completedLessonsCount, lessonsCount }: Props) => {
  const done = progress === 100;

  const areLessonNumber = lessonsCount !== undefined && completedLessonsCount !== undefined;
  const isMobile = useMediaQuery('(max-width: 639px)');

  const t = useAppTranslation();
  return (
    <div className="tw-group tw-relative tw-ml-auto tw-mr-40  tw-flex tw-items-center tw-gap-8">
      <div className="tw-relative tw-inline-flex tw-items-center tw-justify-center tw-text-lighterGray">
        <CircularProgress
          variant="determinate"
          size={34}
          value={100}
          sx={{ color: 'rgba(255, 255, 255, 0.2)' }}
        />
        <CircularProgress
          variant="determinate"
          size={34}
          value={progress}
          sx={{
            position: 'absolute',
            color: 'rgb(136 196 37 / var(--tw-text-opacity)) !important'
          }}
        />
        <span className="tw-absolute tw-top-0 tw-left-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-14 tw-font-semibold tw-text-green">
          <AwardIcon
            stroke={clsx(done ? '#88C425' : 'rgba(255, 255, 255, 0.2)')}
            width={12}
            height={16}
          />
        </span>
      </div>
      <span className="tw-hidden tw-text-12 tw-text-white sm:tw-inline">
        {done ? t('contentViewer.nav.completed') : t('contentViewer.nav.progress')}
      </span>

      <div
        className={clsx(
          'tw-absolute  tw-z-50  tw-hidden tw-w-fit tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-px-16 tw-pt-16 tw-pb-6 tw-shadow-lg group-hover:tw-block',
          !isMobile ? 'tw-top-45' : 'tw-top-50',
          !isMobile
            ? done
              ? '-tw-left-60'
              : '-tw-left-100'
            : done
            ? '-tw-left-110'
            : '-tw-left-144'
        )}
      >
        <div
          className={'tw-absolute tw-inset-x-3/4 -tw-top-4 tw-h-8 tw-w-8 tw-rotate-45 tw-bg-white'}
        ></div>
        {areLessonNumber && (
          <p className="tw-text-ellipsis tw-text-16 tw-text-black">
            {done ? (
              t('contentViewer.nav.completedInfo')
            ) : (
              <Trans i18nKey="contentViewer.nav.progressInfo">
                {{ completedLessonsCount }} z {{ lessonsCount }} lekcí
              </Trans>
            )}
          </p>
        )}
        <p className="tw-text-12 tw-text-gray">
          {done ? t('contentViewer.nav.congrats') : t('contentViewer.nav.actionInfo')}
        </p>
      </div>
    </div>
  );
};
