import React, { useEffect, useRef, useState } from 'react';
import { useAppTranslation } from 'services/i18n';
import { ContentLayoutWithControls } from './ContentLayoutWithControls';
import { useMediaQuery } from '@mui/material';

interface VideoViewerProps {
  file: string;
  onNavigateToNextStep: () => void;
  onNavigateToPreviousStep: () => void;
  isLastStep: boolean;
  isFirstStep: boolean;
  contentLoading?: boolean;
  showCourseIncompleteModal: boolean;
}

export const VideoViewer = ({
  file,
  onNavigateToNextStep,
  onNavigateToPreviousStep,
  isLastStep,
  contentLoading,
  showCourseIncompleteModal,
  isFirstStep,
}: VideoViewerProps) => {
  const t = useAppTranslation();
  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const handlePlayPause = () => {
    return videoPlayerRef.current?.paused
      ? videoPlayerRef.current?.play()
      : videoPlayerRef.current?.pause();
  };

  useEffect(() => {
    videoPlayerRef.current?.addEventListener('ended', () => setIsVideoFinished(true));
  }, [videoPlayerRef, setIsVideoFinished]);

  // reset on new video
  useEffect(() => {
    setIsVideoFinished(false);
    setIsPlaying(false);
  }, [file]);

  return (
    <ContentLayoutWithControls
      contentLoading={contentLoading}
      leftKeyText={t('contentViewer.keyboardControls.previousStep')}
      onLeftKeyPress={onNavigateToPreviousStep}
      leftKeyDisabled={isFirstStep}
      rightKeyText={
        isMobile
          ? undefined
          : !isVideoFinished
          ? t('contentViewer.keyboardControls.play')
          : !isLastStep
          ? t('contentViewer.keyboardControls.endStep')
          : undefined
      }
      onRightKeyPress={
        isMobile
          ? undefined
          : !isVideoFinished
          ? handlePlayPause
          : !isLastStep
          ? onNavigateToNextStep
          : undefined
      }
      rightKeyDisabled={!isVideoFinished ? (isPlaying ? true : false) : false}
      enterKeyText={
        isLastStep
          ? t('contentViewer.keyboardControls.certificate')
          : !isVideoFinished
          ? t('contentViewer.keyboardControls.endStep')
          : undefined
      }
      onEnterKeyPress={
        !isVideoFinished
          ? onNavigateToNextStep
          : isLastStep && !showCourseIncompleteModal
          ? onNavigateToNextStep
          : undefined
      }
      isEnterKeyAction={isLastStep && isVideoFinished}
      spaceKeyText={
        isPlaying
          ? t('contentViewer.keyboardControls.pause')
          : t('contentViewer.keyboardControls.play')
      }
      onSpaceKeyPress={handlePlayPause}
      isSpaceKeyAction={!isVideoFinished}
    >
      <div className="tw-flex tw-h-full tw-items-center tw-justify-center tw-bg-darkerGray">
        <video
          controls
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          className="tw-h-full tw-w-full lg:tw-max-w-[calc(100vw-360px)]"
          //   disable native keyboard controls (otherwise space is registered double)
          ref={(el) => {
            el?.addEventListener(
              'focus',
              function () {
                this.blur();
              },
              false
            );
            el?.addEventListener('ended', () => setIsVideoFinished(true));
            el?.addEventListener('play', () => setIsPlaying(true));
            el?.addEventListener('pause', () => setIsPlaying(false));
            videoPlayerRef.current = el;
          }}
          tabIndex={-1}
        >
          <source src={file}></source>
        </video>
      </div>
    </ContentLayoutWithControls>
  );
};
