import {useAppTranslation} from "services/i18n";
import * as React from "react";
import {createRef, LegacyRef, useCallback, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Identifier, XYCoord} from "dnd-core";
import {Formik} from "formik";
import {Box, Button, Grid, LinearProgress} from "@mui/material";
import clsx from "clsx";
import {CheckboxFormField} from "components/form/CheckboxFormField";
import IconButton from "@mui/material/IconButton";
import {Close, Delete, DragHandle, Edit, ModeComment} from "@mui/icons-material";
import {TextFormField} from "components/form/TextFormField";
import {SelectFormField} from "components/form/SelectFormField";
import {ChoiceType, DragItem, QuestionType} from "../../helpers/editorTest";


const imageStyles = {
  '&:not(:hover) .MuiInputBase-root.MuiInput-root:before': {
    borderColor: 'transparent'
  },
  '.imageQuestionChoice': {
    '.only-active': {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '300px',
      '.selectedChoice': {
        color: '#98C148'
      }
    },
    '.imageContentChoice': {
      margin: '0 auto',
      width: '300px',
      height: '300px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F2F2F2',
      borderRadius: '6px',
      border: '1px solid #C4C4C4',
      '&.selected': {
        backgroundColor: '#EDF8D9',
      },
      '&.addOptionImage': {
        backgroundColor: 'transparent',
        border: 'none',
        marginLeft: '5%',
        marginBottom: '5%',
      },
      '.uploadButton': {
        borderRadius: '5px',
        color: 'white',
        position: 'absolute',
      },
      ' .imageContainerChoice': {
        position: 'relative',
        'img': {
          display: 'block',
          maxWidth: '290px',
          maxHeight: '300px',
          width: 'auto',
          height: 'auto',
        },
        '.imageActions': {
          position: 'absolute',
          bottom: 0,
          right: 0,
          backgroundColor: '#98C148',
        }
      }
    }
  }
};

interface EditorTestChoiceProps {
  question: QuestionType;
  choice: ChoiceType;
  explanation: boolean;
  onChoiceMove: (dragId: number, hoverId: number) => void;
  onChoiceChange: (choice: ChoiceType) => void;
  onChoiceDelete: (choice: ChoiceType) => void;
  onChoiceFileUpload: (choiceId: number, file: File) => void;
  disabled: boolean;
}

export const EditorTestChoice = (props: EditorTestChoiceProps) => {

  const {
    question, choice, explanation,
    onChoiceMove, onChoiceChange, onChoiceDelete, onChoiceFileUpload, disabled
  } = props;

  const t = useAppTranslation();
  const createFileRef = createRef<HTMLInputElement>();
  const updateFileRef = createRef<HTMLInputElement>();
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [{handlerId}, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'choice',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = choice.position

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      onChoiceMove(dragIndex, hoverIndex)

      item.index = hoverIndex
    }
  }, [choice]);

  const [{isDragging}, drag, preview] = useDrag({
    type: 'choice',
    item: () => {
      return {id: choice.id, index: choice.position, targetIndex: choice.position, v: choice.value}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item, monitor) {
      if (item.targetIndex !== item.index) {
        onChoiceChange({...choice, position: item.index});
      }
    },
  });

  const createFileInput = useCallback((ref: LegacyRef<HTMLInputElement>) => {
    return <input
      type={'file'} name={'file'} ref={ref} accept={'image/jpeg, image/png'}
      onClick={(e) => e.stopPropagation()}
      onChange={async (v) => {
        const f = v.target?.files?.[0];
        if (f) {
          setShowProgress(true);
          await onChoiceFileUpload(choice.id, f);
          setShowProgress(false);
        }
      }} hidden/>
  }, [choice.id, onChoiceFileUpload]);

  const handleChoiceDelete = useCallback(() => {
    onChoiceDelete(choice);
  }, [choice, onChoiceDelete]);

  const validate = useCallback((values: ChoiceType) => {
    return {};
  }, []);

  const opacity = isDragging ? 0.5 : 1;
  if (!disabled && question.response_type !== 'image') {
    drag(dragRef);
    drop(preview(previewRef));
  }

  const form = <Formik
    initialValues={{...choice, hash: JSON.stringify({...choice, hash: undefined})}} // is_correct change is not being picked up SOMETIMES
    onSubmit={onChoiceChange}
    validate={validate}
    enableReinitialize={true}
  >
    {(props) => {
      const {values} = props;

      const autoSubmit = () => setTimeout(() => props.handleSubmit(), 100); // "next tick" to pickup latest values

      return <form onSubmit={props.handleSubmit} className={'tw-flex tw-basis-full'}>
        <Grid container sx={imageStyles}>
          <Grid item xs={12}
                className={clsx('tw-flex tw-items-start', question.response_type === 'image' ? 'tw-flex-col imageQuestionChoice tw-basis-full' : undefined)}>
            <div
              className={question.response_type === 'image' ? 'only-active tw-w-full' : 'tw-flex tw-pr-8 tw-pl-8 tw-justify-center'}>
              {question.question_type === 'single' &&
                  <CheckboxFormField
                      name={'is_correct'} type={'radio'} onChange={autoSubmit}
                      tooltip={values.is_correct ? t('editorTest.isTheCorrectChoiceTitle') : t('editorTest.makeCorrectChoiceTitle')}
                      disabled={disabled}/>}
              {question.question_type === 'multiple' &&
                  <CheckboxFormField
                      name={'is_correct'} type={'checkbox'} onChange={autoSubmit}
                      tooltip={values.is_correct ? t('editorTest.isTheCorrectChoiceTitle') : t('editorTest.makeCorrectChoiceTitle')}
                      disabled={disabled}/>}

              {question.response_type === 'image' && !disabled && <div className={'actions'}>
                  <IconButton onClick={(e) => updateFileRef.current?.click()} color={'inherit'}><Edit/></IconButton>
                  <IconButton onClick={handleChoiceDelete} color={'inherit'}><Delete/></IconButton>
                {createFileInput(updateFileRef)}
              </div>}
            </div>
            {question.response_type === 'text' && <Box className={'tw-flex tw-basis-full'} sx={{
              // marginTop: '-16px',
              '& .MuiInputBase-input.MuiInput-input': {
                color: (theme) => values.is_correct ? theme.palette['primary'].main : 'currentColor'
              },
              '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                  webkitTextFillColor: 'unset'
              }
            }}>
                <TextFormField
                    name={'value'}
                    label={question.question_type === 'matching' ? t('editorTest.matching.namePlaceholder') : undefined}
                    type={'textarea'} minRows={1} maxRows={100} onChange={autoSubmit} disabled={disabled}/>
            </Box>}
            {question.response_type === 'image' && <Box className={'imageQuestionChoice'}>
                <div className="only-active">
                    <div
                        className={clsx('imageContentChoice tw-relative', values.is_correct && 'selected', !disabled && 'tw-cursor-pointer')}
                        onClick={disabled ? undefined : () => {
                          props.setFieldValue('is_correct', !values.is_correct);
                          props.handleSubmit();
                        }}>
                      {showProgress
                        ? <LinearProgress className={'tw-absolute tw-right-0 tw-left-0 tw-top-0'}
                                          variant={'indeterminate'}/>
                        : !disabled && !choice.file && <div className="uploadButton">
                          <Button onClick={(e) => {
                            e.stopPropagation();
                            createFileRef.current?.click();
                          }} color={'primary'} variant={'contained'}>
                            {t('editorTest.buttons.addImage')}
                          </Button>
                        {createFileInput(createFileRef)}
                      </div>}
                      {choice.file && <div className={'imageContainerChoice'}>
                        {!!choice.file?.download_url && <img src={choice.file.download_url} alt={'Choice'}/>}
                      </div>}
                    </div>
                </div>
            </Box>}
          </Grid>
          {question.question_type === 'matching' &&
              <Grid item xs={12} className={'tw-flex tw-justify-center tw-items-start'}>
                  <div className={'tw-flex tw-pr-8 tw-pl-8 tw-justify-center tw-pb-8'}>
                  </div>
                  <div className={'tw-flex tw-basis-full'}>
                      <SelectFormField
                          name={'payload'} isMulti={true} freeSolo={true}
                          placeholder={disabled ? undefined : values.payload?.length > 0
                            ? t('editorTest.matching.itemsPlaceholderSecondary')
                            : t('editorTest.matching.itemsPlaceholder')} disabled={disabled}
                          onChange={autoSubmit}/>
                  </div>
              </Grid>}
          {explanation && question.question_type !== 'matching' &&
              <Grid item xs={12} className={'tw-flex tw-items-end'}>
                  <div className={'tw-flex tw-justify-center tw-pb-8 tw-pr-8 tw-pl-8'}>
                      <ModeComment/>
                  </div>
                  <div className={'tw-flex tw-basis-full'}>
                      <TextFormField
                          name={'explanation'} type={'textarea'} label={t('editorTest.labels.explanation')}
                          onChange={autoSubmit}
                          minRows={1} maxRows={100} disabled={disabled}/>
                  </div>
              </Grid>}
        </Grid>
      </form>
    }}
  </Formik>;

  return <Grid item
               xl={question.response_type === 'image' ? (question.file?.id ? 6 : 4) : 12}
               lg={question.response_type === 'image' ? (question.file?.id ? 12 : 6) : 12}
               md={12} ref={previewRef} style={{opacity}} data-handler-id={handlerId}>
    <Box className={'choice-row tw-flex'}>
      <div className={'tw-flex tw-grid-flow-row tw-items-start tw-basis-full'}>
        {question.response_type !== 'image' && <div className={'answer-handle tw-pt-8'} ref={dragRef}>
          {!disabled && <DragHandle sx={{verticalAlign: 'middle', cursor: 'move'}}/>}
        </div>}
        {form}
        {question.response_type !== 'image' && !disabled &&
            <IconButton onClick={handleChoiceDelete} color={'inherit'} className='tw-pt-10'><Close/></IconButton>}
      </div>
    </Box>
  </Grid>
}
