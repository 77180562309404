import React from 'react';
import { Clickable } from './Clickable';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';

interface ButtonProps {
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  children: React.ReactNode;
  kind: 'primary' | 'outlined' | 'bare' | 'icon';
  ariaLabel?: string;
  loading?: boolean;
  disableFocus?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  onClick,
  disabled,
  children,
  kind,
  ariaLabel,
  loading,
  disableFocus,
}) => {
  const stylesByKind = {
    primary: `${
      disabled ? 'tw-bg-lightGray' : 'tw-cursor-pointer tw-bg-green hover:tw-bg-darkGreen'
    } tw-text-white tw-font-medium tw-rounded-lg`,
    outlined: `tw-box-border tw-text-darkestGray tw-font-medium tw-rounded-lg tw-border tw-border-lightGray ${
      disabled ? '' : 'tw-cursor-pointer hover:tw-border-darkestGray'
    }`,
    icon: '',
    bare: '',
  };
  return (
    <Clickable
      className={clsx(stylesByKind[kind], className)}
      onClick={!loading ? onClick : undefined}
      disabled={disabled}
      ariaLabel={ariaLabel}
      disableFocus={disableFocus}
    >
      {loading ? <CircularProgress color="primary" size={36} /> : children}
    </Clickable>
  );
};
