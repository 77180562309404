import clsx from 'clsx';
import { useAppDispatch } from 'store';
import { updateAnswer } from 'store/contentViewer';
import { QuestionType, ResponseType } from 'utils/types';
import {
  ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInner,
  AnswerCreateSelectedChoicesInner,
  AnswerUpdateSelectedChoicesInner,
} from 'generated-api';
import { useCallback, useEffect, useState } from 'react';
import { useIsMount } from 'utils/utils';
import { useFormikContext, FormikValues } from 'formik';
import { MatchingType } from './MatchingType';
import { FormNavigation } from './FormNavigation';
import { TestKeyboardControls } from './TestKeyboardControls';
import { RadioField } from 'components/form/RadioField';
import { CheckboxField } from 'components/form/CheckboxField';
import { useAppTranslation } from 'services/i18n';
import { Trans } from 'react-i18next';
import { API_CONFIG } from 'app/api-config';
import { AnswerApi } from '../../generated-api/apis/AnswerApi';
import { ContentLayoutWithControls } from 'pages/ContentViewer/ContentLayoutWithControls';
import { debounce } from '@mui/material/utils';
import { useMediaQuery } from '@mui/material';

interface Props {
  question: ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInner;
  testAttemptId: number;
  numberOfQuestions: number;
  stepNumber: number;
  setStepNumber: (stepNumber: number) => void;
  setShowTestResults: (showTestResults: boolean) => void;
  showMissingQuestons: boolean;
  setShowMissingQuestions: (showMissingQuestons: boolean) => void;
  testAttemptState: string;
  setResultsLoading: (resultsLoading: boolean) => void;
  contentLoading?: boolean;
  finishTestAndCompleteStep: (testAttemptId: string) => Promise<void>;
  disableKeyboardControls?: boolean;
}

export const TestQuestion = ({
  question,
  testAttemptId,
  numberOfQuestions,
  stepNumber,
  setStepNumber,
  setShowTestResults,
  showMissingQuestons,
  setShowMissingQuestions,
  testAttemptState,
  setResultsLoading,
  contentLoading,
  disableKeyboardControls,
  finishTestAndCompleteStep,
}: Props) => {
  const {
    hint,
    name,
    point_value: pointValue,
    file,
    response_type: responseType,
    test_instance_question_id: testInstanceId,
    question_type: questionType,
  } = question;
  const [elementFocused, setElementFocused] = useState<number | null>(null);
  const { values, setValues } = useFormikContext<FormikValues>();
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const [isAnswerProcessing, setIsAnswerProcessing] = useState(false);
  const [focusedChoiceId, setFocusedChoiceId] = useState<number | null>(null);

  const isMount = useIsMount();
  const dispatch = useAppDispatch();
  const answerId = values[testInstanceId]?.answer_id;
  const answers = values[testInstanceId]?.choice_ids;

  const isAnswered = !!answers.length;

  const t = useAppTranslation();

  const updateChoiceAndAnswerId = useCallback(
      async (selectedChoices: AnswerUpdateSelectedChoicesInner[]) => await debounce(async (selectedChoices) => {
      const handleChange = async (
        selectedChoices: AnswerUpdateSelectedChoicesInner[],
        answerId?: number
      ) => {
        setIsAnswerProcessing(true);
        const answerApi = new AnswerApi(API_CONFIG);
        if (!answerId) {
          const requestParams: any = {
            selected_choices: selectedChoices,
            value: '',
            test_attempt_id: testAttemptId,
            test_instance_question_id: testInstanceId,
          };
          const result = await answerApi.contentViewerAnswersPost({ body: requestParams });
          return result.id;
        } else {
          const requestParams = {
            selected_choices: selectedChoices,
            value: '',
          };
          await dispatch(updateAnswer({ id: answerId.toString(), body: requestParams }));
        }
      };

      if (!answerId) {
        const resultedAnswerId = await handleChange(selectedChoices);
        setValues((values: FormikValues) => ({
          ...values,
          [testInstanceId]: {
            choice_ids: values[testInstanceId]?.choice_ids,
            answer_id: resultedAnswerId,
          },
        }));
      } else {
        await handleChange(selectedChoices, answerId);
      }

      setIsAnswerProcessing(false);
    }, 250)(selectedChoices),
    [answerId, setValues, testInstanceId, dispatch, testAttemptId]
  );

  const isSingleOrMultipleChoice =
    questionType === QuestionType.Single || questionType === QuestionType.Multiple;
  const isImageResponse = responseType === ResponseType.Image;

  useEffect(() => {
    if (!isMount || testAttemptState === 'completed') return;

    const selectedChoices: AnswerUpdateSelectedChoicesInner[] = answers.map(
      (value: AnswerCreateSelectedChoicesInner | AnswerUpdateSelectedChoicesInner) => {
        if (value.payload) {
          return value;
        }
        return { choice_id: +value, payload: null };
      }
    );

    selectedChoices.length > 0 && updateChoiceAndAnswerId(selectedChoices);
    // cannot set updateChoiceAndAnswerId as dependency because it's a new fc on rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, isMount, testAttemptState]);

  useEffect(() => {
    const choiceIndex =
      question?.choices &&
      focusedChoiceId &&
      question.choices.findIndex((choice) => choice.id === focusedChoiceId);
    choiceIndex != null && setElementFocused(choiceIndex);
  }, [focusedChoiceId, question?.choices]);

  const questionNumber = stepNumber + 1;
  const areImagesWrapped = isImageResponse && question?.choices && question?.choices?.length > 3;

  return (
    <ContentLayoutWithControls
      contentLoading={contentLoading}
      className={clsx(isLandscape && 'tw-px-32 lg:tw-px-0')}
      customKeyboardControls={
        <TestKeyboardControls
          question={question}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          numberOfQuestions={numberOfQuestions}
          testAttemptId={testAttemptId}
          setShowTestResults={setShowTestResults}
          setShowMissingQuestions={setShowMissingQuestions}
          showMissingQuestons={showMissingQuestons}
          elementFocused={elementFocused}
          setElementFocused={setElementFocused}
          isAnswerProcessing={isAnswerProcessing}
          setIsAnswerProcessing={setIsAnswerProcessing}
          finishTestAndCompleteStep={finishTestAndCompleteStep}
          disableKeyboardControls={disableKeyboardControls}
          isAnswered={isAnswered}
        />
      }
    >
      <div className="tw-mb-4 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-px-16 tw-pt-16 lg:tw-pt-48 lg:tw-pr-136 lg:tw-pl-96">
        <div className="tw-flex tw-flex-col tw-gap-32 lg:tw-flex-row">
          <FormNavigation
            className="tw-flex lg:tw-hidden"
            step={stepNumber}
            setStep={setStepNumber}
            showMissingQuestons={showMissingQuestons}
          />
          <h1 className="tw-font-roboto tw-text-2xl tw-font-semibold tw-text-black">
            <Trans i18nKey="contentViewer.testViewer.questionPosition">
              Otázka {{ questionNumber }} z {{ numberOfQuestions }}
            </Trans>
          </h1>
          <FormNavigation
            className="tw-hidden lg:tw-flex"
            step={stepNumber}
            setStep={setStepNumber}
            showMissingQuestons={showMissingQuestons}
          />
        </div>
      </div>
      <div className="tw-flex tw-grow tw-flex-col tw-px-16 tw-pb-16 lg:tw-px-96 lg:tw-pb-48">
        <p className="tw-mb-10 tw-flex tw-gap-20 tw-rounded-2xl tw-text-sm tw-text-gray">
          <Trans i18nKey={'contentViewer.testViewer.valuePoint'}>{{ pointValue }} body</Trans>
        </p>
        <div className="tw-flex tw-h-full tw-w-full tw-items-start tw-justify-between tw-gap-20">
          <section className="tw-flex tw-w-full tw-flex-col">
            <div className="tw-mb-24 tw-w-fit tw-rounded-xl tw-bg-white tw-p-16 lg:tw-max-w-[50vw]">
              {file?.download_url && (
                <img
                  src={file?.download_url}
                  alt="Question illustration"
                  className="tw-max-h-[50vh] tw-min-h-200 tw-min-w-300 tw-max-w-[40vw] tw-object-contain"
                />
              )}
              <div>
                <span
                  className={clsx(
                    'tw-flex tw-gap-16 tw-text-clip tw-text-16 tw-font-semibold',
                    file?.download_url && 'tw-pt-6'
                  )}
                >{`${name}`}</span>
                {hint && <span className="tw-text-12 tw-text-gray">{hint}</span>}
              </div>
            </div>

            {questionType === QuestionType.Multiple && (
              <div className="tw-mb-24 tw-w-fit tw-rounded-md tw-bg-black/5 tw-py-4 tw-px-10 tw-text-12">
                {t('contentViewer.testViewer.multipleChoice')}
              </div>
            )}

            {isSingleOrMultipleChoice && (
              <div
                className={clsx(
                  areImagesWrapped
                    ? 'sm:tw-columns-2 lg:tw-columns-3'
                    : 'tw-flex tw-flex-col tw-flex-wrap tw-gap-y-12'
                )}
              >
                {question?.choices?.map((choice, index) => {
                  const { file: choiceImage } = choice;
                  const isChecked =
                    questionType === QuestionType.Single
                      ? values[testInstanceId]?.choice_ids[0] === choice.id.toString()
                      : values[testInstanceId]?.choice_ids.includes(choice.id.toString());

                  return (
                    <div
                      id={clsx(elementFocused === index && 'focused')}
                      className={clsx(
                        'tw-scroll-smooth',
                        isImageResponse && 'tw-flex tw-items-center',
                        'answer-input',
                        areImagesWrapped && 'tw-mb-16'
                      )}
                      key={index}
                      ref={(el) => {
                        if (elementFocused === index) {
                          el?.scrollIntoView({
                            block: 'center',
                          });
                        }
                      }}
                    >
                      {questionType === QuestionType.Single && (
                        <RadioField
                          key={index}
                          index={index}
                          textValue={choice.value}
                          name={`${testInstanceId}.choice_ids[0]`}
                          value={choice.id.toString()}
                          isImage={isImageResponse}
                          isChecked={isChecked}
                          setFocusedChoiceId={setFocusedChoiceId}
                          imageUrl={choiceImage?.download_url}
                        />
                      )}
                      {questionType === QuestionType.Multiple && (
                        <CheckboxField
                          key={index}
                          index={index}
                          textValue={choice.value}
                          name={`${testInstanceId}.choice_ids`}
                          value={choice.id.toString()}
                          isImage={isImageResponse}
                          isChecked={isChecked}
                          setFocusedChoiceId={setFocusedChoiceId}
                          imageUrl={choiceImage?.download_url}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {questionType === QuestionType.Matching && <MatchingType question={question} />}
          </section>
        </div>
      </div>
    </ContentLayoutWithControls>
  );
};
