import { ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInner } from 'generated-api';
import { useFormikContext, FormikValues } from 'formik';
import { KeyboardControls } from 'pages/ContentViewer/KeyboardControls';
import { useAppTranslation } from 'services/i18n';
import { QuestionType } from 'utils/types';
import { useCallback, useEffect, useState } from 'react';
import { NotAnsweredQuestion } from 'utils/types';
import { Trans } from 'react-i18next';
import { ContentViewerModal } from 'pages/ContentViewer/ContentViewerModal';
import { debounce } from '@mui/material/utils';
import { useMediaQuery } from '@mui/material';

interface Props {
  question: ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInner;
  stepNumber: number;
  setStepNumber: (stepNumber: number) => void;
  numberOfQuestions: number;
  testAttemptId: number;
  setShowTestResults: (showTestResults: boolean) => void;
  setShowMissingQuestions: (showMissingQuestons: boolean) => void;
  showMissingQuestons: boolean;
  elementFocused: number | null;
  setElementFocused: (elementFocused: number) => void;
  isAnswerProcessing: boolean;
  setIsAnswerProcessing: (isAnswerProcessing: boolean) => void;
  finishTestAndCompleteStep: (testAttemptId: string) => Promise<void>;
  disableKeyboardControls?: boolean;
  isAnswered?: boolean;
}

export const TestKeyboardControls = ({
  question,
  stepNumber,
  setStepNumber,
  numberOfQuestions,
  testAttemptId,
  setShowTestResults,
  setShowMissingQuestions,
  showMissingQuestons,
  elementFocused,
  setElementFocused,
  isAnswerProcessing,
  setIsAnswerProcessing,
  finishTestAndCompleteStep,
  disableKeyboardControls,
  isAnswered,
}: Props) => {
  const { values, setValues } = useFormikContext<FormikValues>();
  const [showMissingQuestionsModal, setShowMissingQuestionsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    test_instance_question_id: testInstanceId,
    question_type: questionType,
    choices,
  } = question;

  const t = useAppTranslation();
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const handleAnswerConfirm = debounce(() => {
    setIsAnswerProcessing(true);
    const choiceId = elementFocused !== null && choices?.[elementFocused].id;
    elementFocused !== null &&
      setValues((values: FormikValues) => {
        const choiceIds = values[testInstanceId]?.choice_ids;
        const elementIndex =
          questionType === QuestionType.Multiple && choiceIds.indexOf(choiceId?.toString());
        typeof elementIndex === 'number' &&
          elementIndex !== -1 &&
          choiceIds.splice(elementIndex, 1);
        const resultedChoiceIds =
          elementIndex === -1 ? [...choiceIds, choiceId?.toString()] : [...choiceIds];

        return {
          ...values,
          [testInstanceId]: {
            ...values[testInstanceId],
            choice_ids:
              questionType === QuestionType.Single
                ? [choiceId?.toString()]
                : [...resultedChoiceIds],
          },
        };
      });
    setIsAnswerProcessing(false);
  }, 250);

  const isSingleOrMultipleChoice =
    questionType === QuestionType.Single || questionType === QuestionType.Multiple;

  const isMatching = questionType === QuestionType.Matching;

  const isLastQuestion = numberOfQuestions === stepNumber + 1;

  const notAnsweredQuestions: NotAnsweredQuestion[] = Object.entries(values)
    .map((value, index) => {
      return { testInstanceId: value[0], position: index + 1, ...value[1] };
    })
    .filter((value) => {
      return !value.answer_id || value.choice_ids.length === 0;
    });

  const testChoices = question?.choices?.map((choice, index) => {
    const letterFromNumber = String.fromCharCode(96 + index + 1);
    return { choice_id: choice.id, text: letterFromNumber };
  });

  const handleAnswerKeyPress = (key: string) => {
    setIsAnswerProcessing(true);
    const choiceIdByKey = testChoices?.find((testChoice) => {
      return testChoice.text === key;
    })?.choice_id;

    setValues((values: FormikValues) => {
      return {
        ...values,
        [testInstanceId]: {
          ...values[testInstanceId],
          choice_ids: [choiceIdByKey?.toString()],
        },
      };
    });
    setIsAnswerProcessing(false);
  };

  const keyBoardProps = {
    disableControls: showMissingQuestionsModal || showConfirmModal || disableKeyboardControls,
    manualControl: isMatching,
    onUpKeyPress: !isMatching
      ? elementFocused !== null
        ? () => setElementFocused(elementFocused - 1)
        : () => setElementFocused(0)
      : undefined,
    upKeyDisabled: elementFocused === 0,
    onDownKeyPress: !isMatching
      ? elementFocused !== null
        ? () => setElementFocused(elementFocused + 1)
        : () => setElementFocused(0)
      : undefined,
    downKeyDisabled: elementFocused !== null && elementFocused + 1 === choices?.length,
    upDownKeyText: !isMatching && t(`contentViewer.keyboardControls.answerSelect`),
    leftKeyText: numberOfQuestions > 1 && t(`contentViewer.keyboardControls.previousQuestion`),
    leftKeyDisabled: stepNumber === 0,
    isLeftKeyAction:
      isAnswered && !!notAnsweredQuestions.filter((q) => q.position < stepNumber + 1).length,
    onLeftKeyPress: !showMissingQuestons
      ? () => setStepNumber(stepNumber - 1)
      : () => {
          const previousQuestionWithMissingAnswer = notAnsweredQuestions
            .reverse()
            .find((notAnsweredQuestion) => {
              return notAnsweredQuestion.position < stepNumber + 1;
            });

          previousQuestionWithMissingAnswer &&
            setStepNumber(previousQuestionWithMissingAnswer.position - 1);
        },
    rightKeyText:
      isLastQuestion && isMobile
        ? undefined
        : numberOfQuestions > 1 && t(`contentViewer.keyboardControls.nextQuestion`),
    rightKeyDisabled: isLastQuestion || isAnswerProcessing,
    isRightKeyAction:
      isAnswered && !!notAnsweredQuestions.filter((q) => q.position > stepNumber + 1).length,
    onRightKeyPress: !showMissingQuestons
      ? () => setStepNumber(stepNumber + 1)
      : () => {
          const nextQuestionWithMissingAnswer = notAnsweredQuestions.find((notAnsweredQuestion) => {
            return notAnsweredQuestion.position - 1 > stepNumber;
          });
          nextQuestionWithMissingAnswer &&
            setStepNumber(nextQuestionWithMissingAnswer.position - 1);
        },
    spaceKeyText: !isMatching && t(`contentViewer.keyboardControls.answerConfirm`),
    onSpaceKeyPress: isSingleOrMultipleChoice ? handleAnswerConfirm : undefined,
    isSpaceKeyAction: elementFocused !== null && notAnsweredQuestions.length !== 0,
    enterKeyText: t(`contentViewer.keyboardControls.endTest`),
    enterKeyDisabled: isAnswerProcessing,
    onEnterKeyPress: () => {
      if (notAnsweredQuestions.length !== 0) {
        setShowMissingQuestionsModal(true);
        setShowMissingQuestions(true);
      } else {
        setShowConfirmModal(true);
      }
    },
    isEnterKeyAction: notAnsweredQuestions.length === 0,
    answerKeys: isSingleOrMultipleChoice ? testChoices : undefined,
    onAnswerKeyPress: isSingleOrMultipleChoice ? handleAnswerKeyPress : undefined,
  };

  const submitTest = useCallback(() => {
    if (showMissingQuestionsModal || showConfirmModal) {
      const getTestResults = async () => {
        finishTestAndCompleteStep(testAttemptId.toString());
      };
      getTestResults();
      setShowTestResults(true);
    }
  }, [
    showMissingQuestionsModal,
    showConfirmModal,
    testAttemptId,
    setShowTestResults,
    finishTestAndCompleteStep,
  ]);

  const MissingQuestions = notAnsweredQuestions.map((notAnsweredQuestion) => {
    const { testInstanceId, position } = notAnsweredQuestion;
    const handleMissingQuestionClick = () => {
      setShowMissingQuestionsModal(false);
      setStepNumber(position - 1);
    };
    return (
      <div
        key={testInstanceId}
        className="tw-cursor-pointer tw-rounded-xl tw-bg-lightRed tw-px-12 tw-py-6 tw-text-12 tw-text-darkerRed"
        onClick={handleMissingQuestionClick}
      >
        <Trans i18nKey="contentViewer.testViewer.question">Otázka č.{{ position }}</Trans>
      </div>
    );
  });

  useEffect(() => {
    notAnsweredQuestions.length === 0 && setShowMissingQuestions(false);
  }, [notAnsweredQuestions.length, setShowMissingQuestions]);

  return (
    <>
      {showConfirmModal && (
        <ContentViewerModal
          isFullScreen={true}
          title={t('contentViewer.testViewer.confirmModal.title')}
          explanation={t('contentViewer.testViewer.confirmModal.explanation')}
          enterKeyText={t('contentViewer.testViewer.confirmModal.action')}
          enterKeyClass={'tw-bg-green tw-text-white tw-border-none'}
          onEnterKeyPress={submitTest}
          escapeKeyText={t('contentViewer.testViewer.confirmModal.cancel')}
          showModal={showConfirmModal}
          setShowModal={setShowConfirmModal}
          modalWidth={400}
        />
      )}
      {showMissingQuestionsModal && (
        <ContentViewerModal
          isFullScreen={true}
          title={t('contentViewer.testViewer.missingAnswersModal.title')}
          explanation={t('contentViewer.testViewer.missingAnswersModal.explanation')}
          missingItemsInfo={t('contentViewer.testViewer.missingAnswersModal.missingAnswers')}
          missingItems={MissingQuestions}
          spaceKeyText={t('contentViewer.testViewer.missingAnswersModal.action')}
          onSpaceKeyPress={submitTest}
          enterKeyText={t('contentViewer.testViewer.missingAnswersModal.backAction')}
          enterKeyClass={'tw-bg-green tw-text-white tw-border-none'}
          onEnterKeyPress={() => setShowMissingQuestionsModal(false)}
          showModal={showMissingQuestionsModal}
          setShowModal={setShowMissingQuestionsModal}
          modalWidth={500}
        />
      )}

      <KeyboardControls {...keyBoardProps} />
    </>
  );
};
