import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface MarkdownProps {
  children: string;
  className?: string;
}

export const Markdown: React.FC<MarkdownProps> = ({ children, className }) => (
  <ReactMarkdown linkTarget="_blank" className={className} rehypePlugins={[rehypeRaw]}>
    {children}
  </ReactMarkdown>
);
