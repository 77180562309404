import {useAppTranslation} from "services/i18n";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper} from "@mui/material";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {CatalogueCategoryType} from "../../helpers/editorTest";
import {TextFormFieldPlain} from "components/form/TextFormField";
import {CheckboxPlain} from "components/form/CheckboxFormField";
import {isSearchWithinSubject} from "components/DataGrid";
import {sortByTitle} from "utils/utils";
import {Link} from "components/clickable/Link";

interface Props {
  catalogueId: number;
  categories: CatalogueCategoryType[];
  currentCategoryIds?: number[];
  onSuccess: (categoryIds: number[]) => unknown;
  onClose: () => unknown;
}

export const QuestionCategoriesDialog = (props: Props) => {
  const {catalogueId, categories, currentCategoryIds, onSuccess, onClose} = props;
  const t = useAppTranslation();

  const [search, setSearch] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<number[]>(currentCategoryIds || []);
  const [clickCount, setClickCount] = useState<number>(0);

  const handleSave = useCallback(() => {
    onSuccess(selectedIds);
  }, [selectedIds, onSuccess]);

  const createItems = useCallback((categories: CatalogueCategoryType[], items: JSX.Element[]) => {
    categories
      .sort(sortByTitle)
      .forEach((category) => {
        if (search && !isSearchWithinSubject(search, category.title)) {
          return;
        }
        const isSelected = selectedIds.indexOf(category.id) >= 0;
        items.push(<Box
          key={category.id} className={'tw-flex tw-flex-row tw-items-center tw-justify-items-stretch'}>
          <div className={'tw-pr-24 tw-flex tw-self-center'}>
            <CheckboxPlain
              name={'cat-' + category.id}
              currentValue={isSelected}
              onChange={() => {
                const pos = selectedIds.indexOf(category.id);
                if (pos >= 0) {
                  selectedIds.splice(pos, 1);
                } else {
                  selectedIds.push(category.id);
                }
                setSelectedIds(selectedIds.sort());
                setClickCount(clickCount + 1);
              }}
            />
          </div>
          <p className={'tw-flex tw-w-full'}>{category.title}</p>
        </Box>)
      });

  }, [clickCount, search, selectedIds]);

  const categoryItems = useMemo(() => {
    if (!categories) {
      return [];
    }
    const items: JSX.Element[] = [];
    createItems(categories.filter(c => selectedIds.indexOf(c.id) >= 0), items);
    createItems(categories.filter(c => selectedIds.indexOf(c.id) < 0), items);
    return items;

  }, [categories, createItems, selectedIds]);

  return (
    <Dialog open={true} onClose={onClose} PaperComponent={Paper}>
      <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
        <span>{t('catalogues.questions.form.categorySearch')}</span>
        <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {!!categories.length && <Grid item xs={12}>
              <TextFormFieldPlain
                  name={'search'}
                  currentValue={search}
                  onChange={setSearch}
                  label={t('catalogues.categories.searchTitle')}
                  placeholder={t('catalogues.categories.searchPlaceholder')}
              />
          </Grid>}
          <Grid item xs={12}>
            {!categories.length && <p><i>{t('catalogues.categories.emptyList')}</i></p>}
            {categoryItems}
            <Link
              kind="bare"
              className="tw-mt-16 tw-pt-8 tw-pb-10 tw-pl-32 tw-pr-32 tw-inline-block tw-rounded-lg tw-border tw-border-dark tw-bg-dark tw-text-center tw-text-white"
              href={"/#/catalogues/" + catalogueId + "/categories"}
            >
              {t(`catalogues.questions.form.goToCategories`)}
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={onClose}>
          {t('form.close')}
        </Button>
        {!!categories.length &&<Button color={'primary'} onClick={handleSave}>
          {t('catalogues.questions.form.saveCategories')}
        </Button>}
      </DialogActions>
    </Dialog>
  );
};
