import { selectAuthUser } from 'store/selectors';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { NavMenuTopLinks } from './NavMenuTopLinks';
import { NavMenuMiddleLinks } from './NavMenuMiddleLinks';
import { NavMenuChat } from './NavMenuChat';
import { NavMenuLogout } from './NavMenuLogout';
import { useParams } from 'react-router-dom';
import { ContentViewerElementIndexResponseElementTypeEnum } from 'generated-api';

interface Props {
  showTopMenu: boolean;
  mainContentType?: ContentViewerElementIndexResponseElementTypeEnum;
  setShowTestNotCompletedModal: (showTestNotCompletedModal: boolean) => void;
  isActiveTestAttempt: boolean;
  setShowTopMenu: (showTopMenu: boolean) => void;
}

export const NavMenu = ({
  showTopMenu,
  mainContentType,
  setShowTestNotCompletedModal,
  isActiveTestAttempt,
  setShowTopMenu,
}: Props) => {
  const { stepId } = useParams();

  const authUserState = useSelector(selectAuthUser);
  const companySpaces = authUserState?.company_spaces;

  // TODO proper user & roles management
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  const userOrg = companySpaces?.find((space) => space.subdomain === subdomain);
  const showDashboardLink = !!userOrg?.new_dashboard_on;
  const isUserKindOfAdmin = !userOrg /* private space */ || userOrg.user_role === 'admin' || userOrg.user_role === 'owner'

  const handleAbandonedTest = (
    e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    const isLeavingTestInProgress = mainContentType === 'test' && stepId && isActiveTestAttempt;
    const blockNavigation = () => {
      e?.preventDefault();
      setShowTopMenu(false);
      setShowTestNotCompletedModal(true);
      return true;
    };
    if (e?.currentTarget instanceof HTMLAnchorElement) {
      const nextURL = e?.currentTarget.href;
      const urlStepId = nextURL?.split('/').pop();
      // disallow leaving test in progress
      if (isLeavingTestInProgress && stepId !== urlStepId) blockNavigation();
    }
    if (e?.currentTarget instanceof HTMLButtonElement && isLeavingTestInProgress) blockNavigation();
    return false;
  };

  return (
    <section
      className={clsx(
        'tw-duration-400 tw-fixed tw-top-8 tw-left-8 tw-z-9999 tw-overflow-x-hidden tw-overflow-y-scroll tw-bg-white tw-shadow-2xl',
        showTopMenu
          ? 'tw-w-256 tw-origin-top-left tw-scale-100 tw-opacity-100 tw-transition-all tw-ease-in'
          : 'tw-origin-top-left tw-scale-0 tw-overflow-hidden tw-opacity-0 tw-transition-all tw-ease-in'
      )}
      style={{ maxHeight: showTopMenu ? `calc(100dvh - 8px)` : 'auto' }}
    >
      <NavMenuTopLinks
        isUserOrganization={userOrg !== undefined}
        showDashboardLink={showDashboardLink}
        isUserKindOfAdmin={isUserKindOfAdmin}
        onClick={handleAbandonedTest}
      />
      <NavMenuMiddleLinks onClick={handleAbandonedTest} />
      <NavMenuChat setShowTopMenu={setShowTopMenu} />
      <NavMenuLogout onClick={handleAbandonedTest} />
    </section>
  );
};
