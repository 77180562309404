import { ApiSerializedError } from '../model/api';
import { TAppFunction } from '../services/i18n';

export function getApiErrorData(action: any, t: TAppFunction) {
  let message = null;
  let code = null;

  // TODO: update based on KWS API error reposnses
  // Read message from possible json payload
  if ((action.payload as any)?.message) {
    message = (action.payload as any)?.message;
  } else if ('error' in action) {
    message = (action.error as ApiSerializedError)?.message;
  }

  if ((action.payload as any)?.code) {
    code = (action.payload as any)?.code;
  } else if ('error' in action) {
    code = (action.error as ApiSerializedError)?.code;
  }

  switch (message) {
    case 'Network request failed':
      message = t('Network request failed');
      break;
    case 'Timeout':
      message = t('API Timeout');
      break;
    case 'Rejected':
      message = t('Network error:') + `\n(${action.error}, ${JSON.stringify(action.payload)})`;
      break;
    default:
      switch (code?.[0]) {
        case '5':
          message = t('Server error');
          break;
        case '4':
          message = t('Network request failed');
          break;
      }
  }

  return { message, code };
}

export function getApiErrorMessage(action: any, t: TAppFunction) {
  return getApiErrorData(action, t).message;
}

export function isApiResultError(result: any) {
  return 'error' in result;
}
