import clsx from 'clsx';
import { Clickable, ClickableProps } from './clickable/Clickable';

interface TabContainerProps {
  className?: string;
  children: React.ReactNode;
}

export const TabContainer = ({ children, className }: TabContainerProps) => {
  return (
    <div className={clsx('tw-flex tw-items-center tw-justify-start', className)}>{children}</div>
  );
};

interface TabProps extends ClickableProps {
  active?: boolean;
  className?: string;
}

export const Tab = ({ active, children, className, ...props }: TabProps) => {
  return (
    <Clickable className={clsx('tw-relative tw-h-full', className)} {...props}>
      {children}
      {active && (
        <div className="tw-absolute tw-bottom-0 tw-left-0 tw-h-2 tw-w-full tw-bg-lightGreen" />
      )}
    </Clickable>
  );
};
