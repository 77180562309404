import Countdown, { zeroPad } from 'react-countdown';
import { ReactComponent as TimerIcon } from 'icons/timer.svg';
import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  timeLimit: number | null;
  testAttemptId?: number;
  setShowTestResults: (showTestResults: boolean) => void;
  setRemainingTime: Dispatch<SetStateAction<number | null>>;
  finishTestAndCompleteStep: (testAttemptId: string) => Promise<void>;
}

export const TestTimer = React.memo(
  ({
    timeLimit,
    testAttemptId,
    setShowTestResults,
    finishTestAndCompleteStep,
    setRemainingTime,
  }: Props) => {
    const completeTest = () => {
      if (testAttemptId) {
        finishTestAndCompleteStep(testAttemptId.toString());
        setShowTestResults(true);
        setRemainingTime(0);
      }
    };
    return (
      <div
        className={
          'tw-absolute tw-top-[106px] tw-right-16 tw-z-20 tw-flex tw-items-center tw-gap-10 tw-self-start tw-rounded tw-bg-white tw-p-8 lg:tw-top-80 lg:tw-bg-transparent xl:tw-right-136'
        }
      >
        <TimerIcon />
        {timeLimit && (
          <Countdown
            date={Date.now() + timeLimit * 1000}
            renderer={CountdownTime}
            onComplete={completeTest}
          />
        )}
      </div>
    );
  }
);

interface RenderProps {
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTime = ({ hours, minutes, seconds }: RenderProps) => (
  <span className="tw-text-16">
    {hours > 0 && `${zeroPad(hours)}:`}
    {minutes > 0 && `${zeroPad(minutes)}:`}
    {zeroPad(seconds)}
  </span>
);
