import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch} from '../store';
import {useAppTranslation} from "../services/i18n";
import {useParams} from "react-router-dom";
import {deleteOffer, fetchContentOffers} from "store/offer";
import {isApiResultError} from "../helpers/api";
import {ContentInCompanySpaceIndexResponse, OfferCreate, OfferUpdate} from "generated-api";
import {Grid, LinearProgress} from "@mui/material";
import {fetchContent} from "store/content";
import Button from "@mui/material/Button";
import {OfferToggleDialog} from "components/offer/OfferToggleDialog";
import {OfferEditDialog} from "components/offer/OfferEditDialog";
import {Content, Offer} from "pages/PublicOfferPage";
import {
    colCenter,
    createCol,
    DataGrid,
    DataGridCol,
    DataGridMode,
    DataGridState,
    ItemsState,
    OrderDirType
} from "components/DataGrid";
import {decimalNumber} from "../helpers/number";
import {Check, Clear, Delete, Edit} from "@mui/icons-material";
import {dateToGui} from "../helpers/date";
import {addMessage} from "store/localApp";

const createDefaultPrivateOffer = (contentId: number): OfferCreate => ({
    content_id: contentId,
    price: '' as any as number, // ts hell
    discounts: [],
    note: '',
    is_public: false
});

interface FlattenedOffer extends Offer {
    companyName?: string,
    subdomain?: string
}

interface GridFilter {
}

const defaultState: DataGridState<FlattenedOffer, GridFilter> = {
    orderCol: 'companyName',
    orderDir: OrderDirType.ASC,
    filter: {
        search: '',
    },
};


export const PrivateOfferPage: React.FC = () => {
    const {contentId} = useParams();

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [editOffer, setEditOffer] = useState<OfferCreate | OfferUpdate>();
    const [toggleOffer, setToggleOffer] = useState<OfferUpdate>();
    const [content, setContent] = useState<Content>();

    const [dataGridState, setDataGridState] = useState(defaultState);
    const [itemsState, setItemsState] = useState<ItemsState<FlattenedOffer>>({items: [], isLoading: true});

    const handleFetchOffers = useCallback(async () => {
        if (!contentId) {
            return;
        }
        setItemsState(s => ({...s, isLoading: true}));
        const res = await dispatch(fetchContentOffers({contentId: +contentId}));
        if (!isApiResultError(res)) {
            const offers: Offer[] = res.payload as any;
            const contentRes = await dispatch(fetchContent({id: contentId}));
            if (!isApiResultError(contentRes)) {
                setItemsState(s => ({
                    ...s, items: offers
                        .filter(o => !o.is_public)
                        .map(o => ({
                            ...o,
                            companyName: o.for_company_space?.name,
                            subdomain: o.for_company_space?.subdomain
                        })),
                    isLoading: false
                }));
                setContent(contentRes.payload as any as ContentInCompanySpaceIndexResponse);
            }
        }

    }, [contentId, dispatch]);

    const handleDeleteOffer = useCallback(async (offer: Offer) => {
        if (!content?.id || !offer?.id) {
            return;
        }
        await dispatch(deleteOffer({contentId: content.id, id: '' + offer.id}));
        dispatch(addMessage({
            severity: 'success',
            title: t('offer.private.table.offerRemoved')
        }));
        await handleFetchOffers();
    }, [content?.id, handleFetchOffers, dispatch, t])

    const actions = useMemo(() => [
        {
            title: 'offer.private.table.updateOffer',
            icon: <Edit/>,
            callback: setEditOffer
        },
        {
            title: 'offer.private.table.deactivateOffer',
            icon: <Clear/>,
            callback: setToggleOffer,
            isApplicable: (offer: Offer) => offer.is_active
        },
        {
            title: 'offer.private.table.activateOffer',
            icon: <Check/>,
            callback: setToggleOffer,
            isApplicable: (offer: Offer) => !offer.is_active
        },
        {
            title: 'offer.private.table.deleteOffer',
            icon: <Delete/>,
            callback: handleDeleteOffer,
            isApplicable: (offer: Offer) => !offer.is_accepted
        },
    ], [handleDeleteOffer]);

    const cols: DataGridCol<FlattenedOffer>[] = useMemo(() => [
        createCol(t('offer.private.table.nameOrganization'), 'companyName'),
        createCol(t('offer.private.table.subDomain'), 'subdomain'),
        colCenter(createCol(t('offer.private.table.offerAccepted'), 'accepted_at', (v, row) => row.is_accepted ? dateToGui(v) : null)),
        colCenter(createCol(t('offer.private.table.offerActive'), 'is_active', (v) => v ? <Check/> : <Clear/>)),
        colCenter(createCol(t('offer.private.table.price'), 'price', (v) => v
            ? <span>{decimalNumber(v)} {t('offer.dialog.currency')}</span>
            : <small className={'tw-uppercase'}>{t('offer.price.free')}</small>)),
    ], [t]);

    useEffect(() => {
        handleFetchOffers().then();
    }, [dispatch, handleFetchOffers]);

    const render = () => {
        if (itemsState.isLoading) {
            return <LinearProgress/>;
        }
        if (!content?.active_version) {
            return <Grid container><Grid item xs={12}>
                <div className={'tw-text-center'}>
                    <span>{t('offer.noActiveContent')}</span>
                </div>
            </Grid>
            </Grid>
        }
        return <Grid container spacing={2}>
            <Grid item xs={12} className={'tw-items-end'}>
                <Button color={'primary'} variant={'contained'} disabled={false}
                    onClick={() => setEditOffer(createDefaultPrivateOffer(content!.id))}
                    className={'tw-w-auto'}>
                    {t('offer.private.create')}
                </Button>
            </Grid>
            <Grid item xs={12} className={'tw-relative'}>
                <DataGrid
                    createFilter={() => <div/>}
                    cols={cols}
                    state={dataGridState}
                    setState={setDataGridState}
                    itemsState={itemsState}
                    mode={DataGridMode.CLIENT}
                    emptyListMessage={t('offer.private.empty')}
                    emptySearchMessage={t('offer.private.empty')}
                    actions={actions}
                    isActionMenu
                    rowClass={(o) => o.is_active ? undefined : 'inactive'}
                />
            </Grid>
        </Grid>
    }

    return <div className={'layout-fill layout-column'}>
        <div className={'kws-content-margin flex tw-flex tw-flex-col'}>
            {render()}
            {!!editOffer && !!content &&
                <OfferEditDialog
                    content={content}
                    offer={editOffer}
                    onSuccess={handleFetchOffers}
                    onClose={() => setEditOffer(undefined)}/>}
            {!!toggleOffer && !!content &&
                <OfferToggleDialog
                    content={content}
                    offer={toggleOffer}
                    onSuccess={handleFetchOffers}
                    onClose={() => setToggleOffer(undefined)}/>}
        </div>
    </div>;
};
