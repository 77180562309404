import {OfferCreate, type OfferCreateDiscountsInner, OfferUpdate} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {createOffer, updateOffer} from "store/offer";
import {isApiResultError} from "../../helpers/api";
import {Formik, FormikErrors} from "formik";
import {OptionValue} from "model/form";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper} from "@mui/material";
import {decimalNumber} from "../../helpers/number";
import {RadioGroupField} from "components/form/RadioGroupField";
import {TextFormField, TextFormFieldPlain} from "components/form/TextFormField";
import Button from "@mui/material/Button";
import {Add, Close} from "@mui/icons-material";
import {Content} from "pages/PublicOfferPage";
import {debounce} from "@mui/material/utils";
import {AppDispatch} from "index";
import {checkDomainAvailability} from "store/companySpace";

const MIN_PRICE = 20;
const KWS_CUT_FIX = 15;
const KWS_CUT_PCT = 20;
const DISCOUNTS_MAX_COUNT = 5;

interface OfferValuesType extends Partial<OfferCreate>, Partial<OfferUpdate> {
    priceType: 'paid' | 'free',
    dirtyDiscounts: { [key in string]: OfferCreateDiscountsInner }
}

const dialogStyle = {
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px",
        },
        '& input.Mui-disabled': {
            color: 'var(--color-black-text)',
            'WebkitTextFillColor': 'var(--color-black-text)'
        },
        '& h3': {
            margin: 0,
            padding: 0,
            fontSize: '18px',
        }
    }
}

const discountSort = (a: OfferCreateDiscountsInner, b: OfferCreateDiscountsInner) => {
    if (a.license_count === b.license_count) {
        return 0;
    }
    if (a.license_count) {
        if (b.license_count) {
            return +a.license_count > +b.license_count ? 1 : -1;
        }
        return -1;
    }
    return 1;
}

const debouncedCheckDomain = debounce((subdomain: string, dispatch: AppDispatch, setSubdomainError: (error: string) => void, t: any) => {
    dispatch(checkDomainAvailability({body: {subdomain}})).then((res: any) => {
        if (res.payload.reserved || res.payload.available) {
            setSubdomainError(t('offer.dialog.subdomain.messages.domainExist'));
        }
    })
}, 500);

export const OfferEditDialog = ({content, offer, onSuccess, onClose}: {
    content: Content,
    offer: Partial<OfferCreate> & Partial<OfferUpdate>,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);

    const [subdomainValue, setSubdomainValue] = useState<string | undefined>(offer.for_company_space?.subdomain);
    const [subdomainError, setSubdomainError] = useState<string>();

    const initialValues: OfferValuesType = useMemo(() => {
        const dirtyDiscounts: OfferValuesType['dirtyDiscounts'] = {};
        offer.discounts?.forEach((d, i) => {
            dirtyDiscounts['d' + i] = d;
        })
        return {
            ...offer,
            priceType: !!offer.price && offer.price > 0 ? 'paid' : 'free',
            dirtyDiscounts
        }
    }, [offer]);

    const handleSubdomainCheck = useCallback((subdomain?: string) => {
        if (!subdomain) {
            setSubdomainError(t('offer.dialog.subdomain.messages.required'));
            return;
        }
        if (subdomain.length < 3) {
            setSubdomainError(t('offer.dialog.subdomain.messages.minlength', {count: 3}));
            return;
        }
        setSubdomainError(undefined);
        debouncedCheckDomain(subdomain, dispatch, setSubdomainError, t);
    }, [dispatch, t]);

    useEffect(() => {
        if (offer.id) {
            return;
        }
        handleSubdomainCheck(subdomainValue);
    }, [offer.id, handleSubdomainCheck, subdomainValue]);

    const handleSave = useCallback(async (values: OfferValuesType) => {
        if (!values.is_public && subdomainError) {
            return;
        }
        setIsSaving(true);

        let discounts: OfferCreateDiscountsInner[] = [];
        if (values.priceType === 'paid') {
            discounts = Object.keys(values.dirtyDiscounts)?.map(key => values.dirtyDiscounts[key])?.sort(discountSort) || [];
        }

        let res;
        if (values.id) {
            res = await dispatch(updateOffer({
                id: '' + values.id,
                contentId: content.id,
                body: {
                    ...values,
                    id: values.id!,
                    price: values.priceType === 'free' || !values.price ? 0 : values.price,
                    discounts
                }
            }));
        } else {
            res = await dispatch(createOffer({
                contentId: values.content_id!,
                body: {
                    ...values,
                    content_id: values.content_id!, // ts hell
                    note: values.note!, // ts hell
                    price: values.priceType === 'free' || !values.price ? 0 : values.price,
                    discounts,
                    for_company_space: values.is_public ? undefined : {
                        subdomain: subdomainValue
                    },
                    is_active: !values.is_public
                }
            }));
        }
        if (!isApiResultError(res)) {
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [subdomainError, subdomainValue, content.id, dispatch, onSuccess, onClose]);

    const handleValidate = useCallback((values: OfferValuesType) => {
        let errors = {} as FormikErrors<OfferValuesType>;
        if (values.priceType === 'paid') {
            if (!values.price) {
                errors.price = t('offer.dialog.sellingPrice.messages.required');
            } else if (values.price < MIN_PRICE) {
                errors.price = t('offer.dialog.sellingPrice.messages.min', {price: MIN_PRICE});
            }

            if (values.dirtyDiscounts) {
                const discountsErrors = {} as FormikErrors<OfferValuesType['dirtyDiscounts']>;
                const counts: number[] = [];
                Object.keys(values.dirtyDiscounts).forEach(key => {
                    const discountErrors = {} as FormikErrors<OfferValuesType['dirtyDiscounts'][0]>;
                    const discount = values.dirtyDiscounts[key];
                    if (!discount.license_count) {
                        discountErrors.license_count = t('offer.dialog.licenseCount.messages.required')
                    } else {
                        const count = +discount.license_count;
                        if (count < 1) {
                            discountErrors.license_count = t('offer.dialog.licenseCount.messages.min', {count})
                        } else if (counts.indexOf(count) >= 0) {
                            discountErrors.license_count = t('offer.dialog.licenseCount.messages.unique')
                        } else if (Math.round(count) !== count) {
                            discountErrors.license_count = t('offer.dialog.licenseCount.messages.number')
                        }
                        counts.push(count);
                    }

                    if (!discount.discount_percentage) {
                        discountErrors.discount_percentage = t('offer.dialog.discountPercentable.messages.required')
                    } else {
                        const pct = +discount.discount_percentage;
                        if (pct < 1) {
                            discountErrors.discount_percentage = t('offer.dialog.discountPercentable.messages.min', {min: 1})
                        } else if (pct > 99) {
                            discountErrors.discount_percentage = t('offer.dialog.discountPercentable.messages.max', {max: 99})
                        } else if (Math.round(pct) !== pct) {
                            discountErrors.discount_percentage = t('offer.dialog.discountPercentable.messages.number')
                        }
                    }

                    if (!!Object.keys(discountErrors).length) {
                        discountsErrors[key] = discountErrors;
                    }
                });
                if (!!Object.keys(discountsErrors).length) {
                    errors.dirtyDiscounts = discountsErrors;
                }
            }
        }
        return errors;
    }, [t]);

    const priceOptions: OptionValue[] = useMemo(() => [
        {value: 'free', label: t('offer.dialog.free')},
        {value: 'paid', label: t('offer.dialog.paid')},
    ], [t]);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} sx={dialogStyle}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSave}
                validate={handleValidate}
            >
                {({values, handleSubmit, setFieldValue, submitCount}) => {
                    const profitSale = decimalNumber(Math.max(0, Math.round(((Math.min((values.price || 0), 100000) || 0) - KWS_CUT_FIX) * (100 - KWS_CUT_FIX)) / 100));
                    const discounts = Object.keys(values.dirtyDiscounts)
                        ?.map(key => ({
                            key,
                            ...values.dirtyDiscounts[key]
                        } as OfferCreateDiscountsInner & { key: string }))
                        ?.sort(discountSort) || [];

                    return <form onSubmit={handleSubmit}>
                        <DialogTitle>
                            {offer.id
                                ? offer.is_public ? t('offer.dialog.updatePublic') : t('offer.dialog.updatePrivate')
                                : offer.is_public ? t('offer.dialog.createPublic') : t('offer.dialog.createPrivate')}
                        </DialogTitle>
                        <LinearProgress hidden={!isSaving}/>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{"& .MuiFormGroup-row": {flexFlow: 'column'}}}>
                                    <RadioGroupField options={priceOptions} name={'priceType'}/>
                                </Grid>
                                {values.priceType === 'paid' && <>
                                    <Grid item xs={6}>
                                        <TextFormField name={'price'} type={'number'} label={t('offer.dialog.sellingPrice.label')}
                                            maxValue={100000}
                                            suffixText={t('offer.dialog.currency')}
                                            helperText={t('offer.dialog.profitSale.hint', {
                                                profit: KWS_CUT_FIX,
                                                percent: KWS_CUT_PCT
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFormFieldPlain name={'profitSale'} label={t('offer.dialog.profitSale.label')}
                                            currentValue={profitSale}
                                            disabled={true}
                                            suffixText={t('offer.dialog.currency')}/>
                                    </Grid>
                                    {!!discounts.length && <Grid item xs={12}>
                                        <h3>{t('offer.dialog.discounts')} ({discounts.length || 0}/{DISCOUNTS_MAX_COUNT})</h3>
                                    </Grid>}
                                    {discounts.map((discount, i) => {
                                        const licencePrice = decimalNumber(Math.max(0, Math.round((Math.min((values.price || 0), 100000) || 0)
                                            * (100 - (discount.discount_percentage || 0))) / 100));
                                        return <Grid item xs={12} key={discount.key}>
                                            <Grid container columns={14} columnSpacing={2}>
                                                <Grid item xs={4}>
                                                    <TextFormField name={'dirtyDiscounts[' + discount.key + '][license_count]'} label={t('offer.dialog.licenseCount.label')} type={'number'}/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextFormField name={'dirtyDiscounts[' + discount.key + '][discount_percentage]'} label={t('offer.dialog.discountPercentable.label')} type={'number'}/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextFormFieldPlain name={'dirtyDiscounts[' + discount.key + '][licencePrice]'} label={t('offer.dialog.sellingPrice.label')}
                                                        currentValue={licencePrice}
                                                        disabled={true}
                                                        suffixText={t('offer.dialog.currency')}/>
                                                </Grid>
                                                <Grid item xs={2} sx={{paddingTop: '24px', textAlign: 'right'}}>
                                                    <Button variant={'text'} color={'inherit'} size={'small'} tabIndex={-1} onClick={() => {
                                                        const v = values.dirtyDiscounts;
                                                        delete v[discount.key];
                                                        setFieldValue('dirtyDiscounts', v || {});
                                                    }}>
                                                        <Close/>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    })}
                                    {(!discounts.length || discounts.length < DISCOUNTS_MAX_COUNT) &&
                                        <Grid item xs={12}>
                                            <Button color={'dark' as any} variant={'contained'} onClick={() => {
                                                const v = values.dirtyDiscounts || {};
                                                let i = 0;
                                                while (!!v['x' + i]) {
                                                    i++;
                                                }
                                                v['x' + i] = {};
                                                setFieldValue('dirtyDiscounts', v);
                                            }}>
                                                <Add/>
                                                {t('offer.dialog.addDiscount')}
                                            </Button>
                                        </Grid>}
                                </>}
                                {!offer.is_public &&
                                    <Grid item xs={12} sx={{paddingTop: values.priceType === 'paid' ? '32px !important' : '16px'}}>
                                        <TextFormFieldPlain name={'subdomain'} label={t('offer.dialog.subdomain.label')}
                                            currentValue={subdomainValue}
                                            onChange={setSubdomainValue}
                                            showError={!!subdomainError && !!(submitCount || subdomainValue)}
                                            error={subdomainError}
                                            disabled={!!offer.id}
                                        />
                                    </Grid>}
                                {!offer.is_public && <Grid item xs={12}>
                                    <TextFormField name={'note'} label={t('offer.dialog.note.label')}
                                        type={'textarea'}
                                        minRows={5}
                                        maxRows={15}/>
                                </Grid>}
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{padding: '16px'}}>
                            <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                                {t('form.cancel')}
                            </Button>
                            <Button color={'primary'} variant={'contained'} type={'submit'} disabled={isSaving}>
                                {offer.id ? t('offer.dialog.update') : t('offer.dialog.create')}
                            </Button>
                        </DialogActions>
                    </form>
                }}
            </Formik>
        </Dialog>
    );
};
