import { useState, useEffect, useMemo } from 'react';
import { DropArea } from './DropArea';
import {
  ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInner,
  AnswerCreateSelectedChoicesInner,
} from 'generated-api';
import { DraggableArea } from './DraggableArea';
import { useFormikContext, FormikValues } from 'formik';
import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { useAppTranslation } from 'services/i18n';

interface Props {
  question: ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInner;
}

export const MatchingType = ({ question }: Props) => {
  const [isItemDragging, setIsItemDragging] = useState(false);
  const t = useAppTranslation();
  const { values } = useFormikContext<FormikValues>();

  const {
    items_to_match: allItemsToMatch,
    choices,
    test_instance_question_id: testInstanceId,
  } = question;
  const testInstanceValues = values[testInstanceId];

  const shuffledItems = useMemo(() => {
    return [...allItemsToMatch].sort(() => Math.random() - 0.5);
  }, [allItemsToMatch]);

  const isMobile = useMediaQuery('(max-width: 1023px)');
  const isLandscape = useMediaQuery('(orientation: landscape)');

  const [itemsToMatch, setItemsToMatch] = useState<string[]>([]);
  const [itemsToMove, setItemsToMove] = useState<string[]>([]);

  useEffect(() => {
    const alreadyMatchedItems = values[testInstanceId]?.choice_ids
      .map((selectedChoice: AnswerCreateSelectedChoicesInner) => selectedChoice.payload)
      .flat();

    const remainingItemsToMatch = shuffledItems.filter(
      (itemToMatch) => !alreadyMatchedItems.includes(itemToMatch)
    );
    setItemsToMatch(remainingItemsToMatch);
  }, [testInstanceValues, allItemsToMatch, testInstanceId, values, shuffledItems]);

  return (
    <section
      className={clsx(
        isMobile && isLandscape && 'tw-flex tw-flex-wrap tw-justify-between tw-gap-32'
      )}
    >
      <div className="tw-mb-0 tw-w-full tw-py-4 tw-px-10 tw-text-12 lg:tw-w-fit">
        {!isMobile
          ? t('contentViewer.testViewer.matchingType')
          : t('contentViewer.testViewer.matchingTypeMobile')}
      </div>
      <DraggableArea
        itemsToMatch={itemsToMatch}
        setItemsToMatch={setItemsToMatch}
        testInstanceId={testInstanceId.toString()}
        itemsToMove={itemsToMove}
        setItemsToMove={setItemsToMove}
        isMobile={isMobile}
        isItemDragging={isItemDragging}
        setIsItemDragging={setIsItemDragging}
      />

      <div className={clsx(!isMobile ? 'tw-flex tw-gap-8' : 'landscape:tw-w-500')}>
        {choices?.map((choice, index) => (
          <DropArea
            choice={choice}
            key={index}
            testInstanceId={testInstanceId.toString()}
            itemsToMove={itemsToMove}
            setItemsToMove={setItemsToMove}
            isMobile={isMobile}
            setIsItemDragging={setIsItemDragging}
          />
        ))}
      </div>
    </section>
  );
};
