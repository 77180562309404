import clsx from 'clsx';

interface VideoProps {
  file: string;
  className?: string;
  isBackgroundFaded?: boolean;
}

export const Video = ({ file, className, isBackgroundFaded }: VideoProps) => {
  return (
    <div
      className={clsx(
        'tw-absolute tw-right-0 tw-z-9999 tw-max-h-300 tw-w-250 tw-overflow-auto lg:tw-w-[359px]',
        isBackgroundFaded
          ? 'tw-w-[359px] tw-from-transparent tw-to-black/75 sm:tw-bg-gradient-to-b'
          : '',
        className
      )}
    >
      <video
        controls
        controlsList="nodownload noplaybackrate"
        autoPlay
        onLoadStart={(e) => ((e.target as HTMLVideoElement).volume = 0.2)}
        disablePictureInPicture
        className={clsx('tw-z-30 tw-float-right tw-w-250 tw-object-bottom lg:tw-w-[359px]')}
      >
        <source src={file}></source>
      </video>
    </div>
  );
};
