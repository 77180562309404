import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { RunningApiInfo } from '../services/appFetch';
import { AlertColor } from '@mui/material';

export interface FlashMessage {
  severity: AlertColor;
  title: string;
}

export interface FlashMessageWithId extends FlashMessage {
  id: number;
}

const initialState = {
  messages: [] as FlashMessageWithId[],
  lastMessageId: 0,
  showLoader: false,
  runningApi: [] as RunningApiInfo[],
};

export type LocalAppState = typeof initialState;

export const localAppSlice = createSlice({
  name: 'localApp',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<FlashMessage>) => {
      state.lastMessageId++;
      state.messages.push({ id: state.lastMessageId, ...action.payload });
    },
    removeMessage: (state, action: PayloadAction<FlashMessageWithId>) => {
      state.messages = state.messages.filter((m) => m.id !== action.payload.id);
    },
    removeAllMessages: (state, action: PayloadAction<undefined>) => {
      state.messages = [];
    },
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setAppApiRunning: (state, action: PayloadAction<{ info: RunningApiInfo; active: boolean }>) => {
      state.runningApi = action.payload.active
        ? state.runningApi.concat(action.payload.info)
        : state.runningApi.filter((info) => info.code !== action.payload.info.code);
    },
  },
  extraReducers: (builder) => {},
});

export const localAppReducer = localAppSlice.reducer;
export const { addMessage, removeMessage, setShowLoader, removeAllMessages, setAppApiRunning } = localAppSlice.actions;

export const selectAppMessages = createSelector(
  [(state: RootState) => state.localApp],
  (localApp: LocalAppState) => localApp.messages
);
export const selectAppLoading = createSelector(
  [(state: RootState) => state.localApp],
  (localApp: LocalAppState) => localApp.showLoader
);

const noLoaderApis: string[] = ['/users/me'];

// Use as a source for fullpage loader if needed
export const selectAppApiIsRunning = createSelector(
  [(state: RootState) => state.localApp],
  (app) =>
    app.runningApi?.filter((api: RunningApiInfo) => !noLoaderApis.some((a) => api.code.includes(a)))
      ?.length > 0
);
