import { useAppTranslation } from 'services/i18n';
import { useEffect, useRef, useState } from 'react';
import { ContentLayoutWithControls } from './ContentLayoutWithControls';

interface Props {
  iframeHTML?: string;
  onNavigateToNextStep: () => void;
  onNavigateToPreviousStep: () => void;
  isLastStep: boolean;
  contentLoading?: boolean;
  showCourseIncompleteModal: boolean;
}

export const IframeViewer = ({
  iframeHTML,
  onNavigateToNextStep,
  onNavigateToPreviousStep,
  isLastStep,
  contentLoading,
  showCourseIncompleteModal,
}: Props) => {
  const [zoomScale, setZoomScale] = useState(1);

  const t = useAppTranslation();

  const handleZoomIn = () => {
    zoomScale < 1.3 && setZoomScale(zoomScale + 0.1);
  };

  const handleZoomOut = () => {
    zoomScale > 1 && setZoomScale(zoomScale - 0.1);
  };

  const iframeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    iframeRef.current && iframeRef.current.focus();
  }, []);

  const src = iframeHTML?.match(/src="(.*?)"/)?.[1];

  return (
    <ContentLayoutWithControls
      contentLoading={contentLoading}
      ref={iframeRef}
      leftKeyText={t('contentViewer.keyboardControls.back')}
      onLeftKeyPress={onNavigateToPreviousStep}
      leftKeyDisabled={false}
      rightKeyText={!isLastStep ? t('contentViewer.keyboardControls.endStep') : undefined}
      onRightKeyPress={!isLastStep ? onNavigateToNextStep : undefined}
      enterKeyText={isLastStep ? t('contentViewer.keyboardControls.certificate') : undefined}
      onEnterKeyPress={isLastStep && !showCourseIncompleteModal ? onNavigateToNextStep : undefined}
      handleZoomIn={handleZoomIn}
      handleZoomOut={handleZoomOut}
      manualControl={true}
    >
      <div className="tw-h-full tw-w-full tw-overflow-auto">
        <iframe
          src={src}
          title="Content viewer iFrame"
          className="tw-h-full tw-w-full tw-origin-top"
          style={{ transform: `scale(${zoomScale})` }}
        ></iframe>
      </div>
    </ContentLayoutWithControls>
  );
};
