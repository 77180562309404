const numberLocales: { [lng: string]: Intl.LocalesArgument } = {
    cs: 'cs-cz',
    en: 'en-us',
};

let locale: Intl.LocalesArgument = numberLocales.en;

export function setNumberLocale(lng: string) {
    locale = numberLocales[lng] || numberLocales.en;
}

export function decimalNumber(v: number, digits: number = 2): string {
    return v.toLocaleString(locale, {minimumFractionDigits: digits});
}
