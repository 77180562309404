import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabContainer, Tab } from 'components/Tab';
import { useAppTranslation } from 'services/i18n';
import { SidebarLessons } from './SidebarLessons';
import { SidebarStudyMaterials } from './SidebarStudyMaterials';

import type { ContentViewerShowResponse } from 'generated-api';

import { ReactComponent as List } from 'icons/list.svg';
import { ReactComponent as Paper } from 'icons/paper.svg';
import { ReactComponent as ChevronRight } from 'icons/chevron-right.svg';
import { LoadingSpinner } from 'components/loading/LoadingSpinner';

enum ActiveTab {
  Lessons = 'lessons',
  Materials = 'materials',
}

interface ContentViewerSidebarProps {
  course: ContentViewerShowResponse;
  onRegisterParticipation: (stepId: string, courseId: string) => void;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  isSideVideo?: boolean;
  loading?: boolean;
}

export const ContentViewerSidebar = ({
  onRegisterParticipation,
  course,
  onClick,
  onClose,
  isSideVideo,
  loading,
}: ContentViewerSidebarProps) => {
  const { stepId, lessonId } = useParams();
  const currentStepId = stepId ? +stepId : undefined;
  const currentLessonId = lessonId ? +lessonId : undefined;

  const lessons = course.content_version?.lessons;

  const studyMaterials = course?.content_version?.study_materials;
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Lessons);

  const t = useAppTranslation();

  return (
    <aside className="h-screen tw-z-30 tw-ml-auto tw-w-full tw-shrink-0 tw-overflow-auto tw-border-white/20 tw-bg-darkestGray sm:tw-w-360 sm:tw-border-l">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <TabContainer className="tw-sticky tw-top-0 tw-z-20 tw-h-48 tw-bg-darkestGray tw-text-12 tw-text-white">
            <Tab
              className="tw-px-16"
              active={activeTab === ActiveTab.Lessons}
              onClick={() => setActiveTab(ActiveTab.Lessons)}
            >
              <List className="tw-mr-8 tw-inline" />
              {t('contentViewer.sidebar.lessonOverview')}
            </Tab>

            <Tab
              className="tw-px-16"
              active={activeTab === ActiveTab.Materials}
              onClick={() => setActiveTab(ActiveTab.Materials)}
            >
              <Paper className="tw-mr-8 tw-inline" />
              {t('contentViewer.sidebar.studyMaterials.tab')}
            </Tab>
            <IconButton
              className="tw-ml-auto tw-h-48 tw-w-48 tw-rounded-none tw-bg-darkestGray"
              onClick={onClose}
            >
              <ChevronRight />
            </IconButton>
          </TabContainer>

          {activeTab === ActiveTab.Lessons && lessons && (
            <SidebarLessons
              lessons={lessons}
              onRegisterParticipation={onRegisterParticipation}
              currentStepId={currentStepId}
              currentLessonId={currentLessonId}
              onClick={onClick}
            />
          )}

          {activeTab === ActiveTab.Materials && lessons && studyMaterials && (
            <SidebarStudyMaterials
              lessons={lessons}
              studyMaterials={studyMaterials}
              currentLessonId={currentLessonId}
            />
          )}
          {isSideVideo && <div className="tw-h-360"></div>}
        </>
      )}
    </aside>
  );
};
