import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch} from '../store';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select
} from '@mui/material';
import {fetchCatalogues} from "store/catalogues";
import {useAppTranslation} from "services/i18n";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {CatalogueAddForm} from "components/catalogue/CatalogueAddForm";
import {CatalogueEditForm} from "components/catalogue/CatalogueEditForm";
import {CategoryList} from "components/catalogue/CategoryList";
import {QuestionList} from "components/catalogue/QuestionList";
import {useNavigate, useParams} from "react-router-dom";
import {ModalProvider} from "services/modal";
import {CatalogueType} from "helpers/editorTest";

type PAGE_TYPE = 'questions' | 'categories' | 'publishing' | 'edit';

const headerStyle = {
  '.MuiGrid-item': {
    display: 'flex'
  },
  '.MuiButtonBase-root.MuiButton-colorInherit': {
    // color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 'unset',
    boxShadow: 'unset'
  },
  '.MuiButtonBase-root.MuiButton-colorInherit > svg': {
    margin: 0,
  },
  '.MuiInputBase-colorPrimary': {
    backgroundColor: '#e0e0e0',
    paddingRight: '8px'
  },
  '.MuiButtonBase-root.MuiButton-root + .MuiButton-root': {
    marginLeft: '16px'
  }
};

export const CataloguesPage: React.FC = () => {
  const {catalogueId, pageType, pageId} = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const t = useAppTranslation();

  const [activePage, setActivePage] = useState<PAGE_TYPE>(pageType ? pageType as PAGE_TYPE : 'questions');
  const [catalogues, setCatalogues] = useState<CatalogueType[]>([]);
  const [activeCatalogueId, setActiveCatalogueId] = useState<number | undefined>(!!catalogueId ? parseInt(catalogueId, 10) : undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [actionButtons, setActionButtons] = useState<JSX.Element | undefined>(undefined);

  const [isOpenCatalogueAddForm, setIsOpenCatalogueAddForm] = useState(false);

  const handleFetchCatalogueItems = useCallback(async () => {
    setIsLoading(true);

    const catalogueItems = (await dispatch(fetchCatalogues({})))?.payload as CatalogueType[];
    setCatalogues(catalogueItems);
    setIsLoading(false);
    return catalogueItems;

  }, [dispatch]);

  const handleOpenCatalogueAddForm = useCallback(() => {
    setIsOpenCatalogueAddForm(true);
  }, []);

  const handleCloseCatalogueAddForm = useCallback(() => {
    setIsOpenCatalogueAddForm(false);
  }, []);

  const handleSuccessCatalogueAdd = useCallback((v: CatalogueType) => {
    handleFetchCatalogueItems().then(() => {
      navigate('/catalogues/' + v.id + '/questions');
    });
  }, [navigate, handleFetchCatalogueItems]);

  const handleCloseCatalogueEdit = useCallback(() => {
    navigate('/catalogues/' + activeCatalogueId + '/questions')
  }, [activeCatalogueId, navigate]);

  const handleSuccessCatalogueEdit = useCallback(() => {
    handleFetchCatalogueItems().then(() => {
      handleCloseCatalogueEdit();
    });
  }, [handleFetchCatalogueItems, handleCloseCatalogueEdit]);

  useEffect(() => {
    handleFetchCatalogueItems().then((res) => {
      setActiveCatalogueId((id) => id || res[0]?.id);
    });
  }, [dispatch, handleFetchCatalogueItems]);

  useEffect(() => {
    if (catalogueId) {
      setActiveCatalogueId(parseInt(catalogueId, 10));
    }
  }, [catalogueId]);

  useEffect(() => {
    if (pageType) {
      setActivePage(pageType as PAGE_TYPE);
    }
  }, [pageType]);

  const content = useMemo(() => {
    if (isLoading) {
      return <LinearProgress/>;
    }
    if (!activeCatalogueId) {
      return <em>{t('catalogues.emptyCatalogues')}</em>;
    }

    if (activePage === 'categories') {
      return <CategoryList
        catalogueId={activeCatalogueId}
        categoryId={pageId ? parseInt(pageId, 10) : undefined}
        onActionButtons={setActionButtons}
      />;
    }

    if (activePage === 'publishing') {
      return null;
    }

    if (activePage === 'edit') {
      return <Grid container>
        <Grid item md={6} xs={12} className={'tw-pb-16'}>
          <CatalogueEditForm
            key={activeCatalogueId}
            id={activeCatalogueId}
            onActionButtons={setActionButtons}
            onSuccess={handleSuccessCatalogueEdit}
            onCancel={handleCloseCatalogueEdit}
          />
        </Grid>
      </Grid>;
    }

    return <QuestionList
      catalogueId={activeCatalogueId}
      questionId={pageId ? parseInt(pageId, 10) : undefined}
      onActionButtons={setActionButtons}
    />;

  }, [isLoading, activePage, pageId, activeCatalogueId,
    handleSuccessCatalogueEdit, handleCloseCatalogueEdit, t]);

  return <ModalProvider>
    <div className={'layout-fill layout-column tw-absolute'}>
      <div className={'kws-content-margin flex'}>
        <Card className={'tw-h-full tw-flex tw-flex-col'}>
          <CardHeader
            title={<Grid container spacing={2} sx={headerStyle}>
              <Grid item>
                <Button onClick={handleOpenCatalogueAddForm} title={t('catalogues.add')} color={'inherit'}
                        variant={'contained'}>
                  <AddCircleOutlineIcon/>
                </Button>
              </Grid>
              {catalogues.length > 0 && <Grid item>
                  <FormControl size="small">
                      <Select
                          displayEmpty={true}
                          value={activeCatalogueId}
                          onChange={(e, v) => {
                            navigate('/catalogues/' + e.target.value + '/' + activePage)
                          }}>
                        {catalogues?.map((c, i) => {
                          return <MenuItem key={i} value={c.id}>{c.title}</MenuItem>;
                        })}
                      </Select>
                  </FormControl>
              </Grid>}
              {!!activeCatalogueId && <Grid item>
                  <FormControl size="small">
                      <Select
                          displayEmpty={true}
                          value={activePage + (pageId ? '-' + pageId : '')}
                          onChange={(e, v) => {
                            navigate('/catalogues/' + activeCatalogueId + '/' + e.target.value)
                          }}>
                          <MenuItem value={'questions'}>{t('catalogues.pages.questions')}</MenuItem>
                          <MenuItem value={'categories'}>{t('catalogues.pages.categories')}</MenuItem>
                        {/*<MenuItem value={'publishing'}>{t('catalogues.pages.publishing')}</MenuItem>*/}
                          <MenuItem value={'edit'}>{t('catalogues.pages.edit')}</MenuItem>
                        {!!pageId && <MenuItem value={activePage + '-' + pageId}>{t('catalogues.pages.' + activePage + 'Edit')}</MenuItem>}
                      </Select>
                  </FormControl>
              </Grid>}
              {!!actionButtons && <Grid item className={'tw-ml-auto tw-items-center'}>
                {actionButtons}
              </Grid>}
            </Grid>}
          />
          <CardContent className={'tw-p-32 tw-h-full tw-flex tw-flex-col' + (!!pageId ? ' tw-overflow-y-scroll' : '')}>
            {content}
          </CardContent>
        </Card>
        {isOpenCatalogueAddForm && (
          <CatalogueAddForm
            onSuccess={handleSuccessCatalogueAdd}
            onClose={handleCloseCatalogueAddForm}
          />
        )}
      </div>
    </div>
  </ModalProvider>;
};
