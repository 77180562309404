import {ContentCategory, MarketplaceOfferIndexResponse} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {isApiResultError} from "../../helpers/api";
import {
    Box,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Paper
} from "@mui/material";
import Button from "@mui/material/Button";
import {Content} from "pages/PublicOfferPage";
import 'react-quill/dist/quill.snow.css';
import {fetchCategories} from "store/category";
import {updateOffer} from "store/offer";
import {TextFormFieldPlain} from "components/form/TextFormField";
import {sortByName} from "utils/utils";
import {isSearchWithinSubject} from "components/DataGrid";
import {ArrowDropDownOutlined, Close} from "@mui/icons-material";

interface CategoryType extends ContentCategory {

}

export const OfferCategoriesDialog = ({content, offer, onSuccess, onClose}: {
    content: Content,
    offer: MarketplaceOfferIndexResponse,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [categories, setCategories] = useState<CategoryType[]>();
    const [search, setSearch] = useState<string>("");
    const [selectedIds, setSelectedIds] = useState<number[]>(offer.content_categories?.map(c => c.id) || []);

    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const handleFetchCategories = useCallback(async () => {
        const res = await dispatch(fetchCategories());
        if (!isApiResultError(res)) {
            setCategories(res.payload as CategoryType[]);
        }
    }, [dispatch]);

    const handleSave = useCallback(async () => {
        setIsSaving(true);

        const res = await dispatch(updateOffer({
            id: '' + offer.id,
            contentId: content.id,
            body: {
                ...offer,
                category_ids: selectedIds,
            }
        }));
        if (!isApiResultError(res)) {
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [selectedIds, offer, content.id, dispatch, onSuccess, onClose]);

    const toggleSelected = useCallback((id: number) => {
        setSelectedIds((selectedIds) => {
            const pos = selectedIds.indexOf(id);
            if (pos >= 0) {
                selectedIds.splice(pos, 1);
            } else {
                selectedIds.push(id);
            }
            return [...selectedIds].sort();
        });
    }, []);

    const selectedItems = useMemo(() => {
        if (categories === undefined) {
            return null;
        }

        return categories
            .filter(c => selectedIds.indexOf(c.id) >= 0)
            .sort(sortByName)
            .map((c, i) => <Grid item xs={12} key={i}>
                <TextFormFieldPlain currentValue={c.name} name={'c' + c.id} readonly
                    suffixText={
                        <Close className={'hover:tw-opacity-75 hover:tw-cursor-pointer'} onClick={() => toggleSelected(c.id)}/>}/>
            </Grid>);

    }, [selectedIds, toggleSelected, categories]);

    const handleBlur = useCallback(() => {
        setTimeout(() => setIsSearchFocused(false), 100);
    }, []);

    const handleClickItem = useCallback((id: number) => {
        toggleSelected(id);
        setSearch("");
        setIsSearchFocused(false)
    }, [toggleSelected]);

    const listItems = useMemo(() => {
        if (categories === undefined) {
            return null;
        }

        return categories
            .filter(c => selectedIds.indexOf(c.id) < 0)
            .filter(c => !search || isSearchWithinSubject(search, c.name))
            .sort(sortByName)
            .map((c) => {
                return <Box
                    key={c.id} className={'tw-flex tw-flex-row tw-items-center tw-justify-items-stretch ' +
                    'hover:tw-cursor-pointer hover:tw-bg-lightestGray'}
                    onClick={() => handleClickItem(c.id)}
                >
                    <p className={'tw-flex tw-w-full tw-pl-10 tw-pr-10'}>{c.name}</p>
                </Box>;
            });

    }, [search, selectedIds, handleClickItem, categories]);

    useEffect(() => {
        handleFetchCategories().then();
    }, [handleFetchCategories]);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} maxWidth={'md'}>
            <DialogTitle>
                {t('offer.categories.dialog.title')}
            </DialogTitle>
            <LinearProgress hidden={!isSaving}/>
            <DialogContent>
                <ClickAwayListener onClickAway={handleBlur}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {selectedItems}
                        </Grid>
                        <Grid item xs={12}>
                            {categories === undefined
                                ? <Box className={'tw-h-100'}><LinearProgress/></Box>
                                : <TextFormFieldPlain
                                    name={'search'}
                                    currentValue={search}
                                    onChange={setSearch}
                                    label={t('offer.categories.dialog.add')}
                                    suffixText={<ArrowDropDownOutlined/>}
                                    onFocus={() => setIsSearchFocused(true)}
                                    autoComplete={'off'}
                                    autoFocus={true}
                                />}
                        </Grid>
                        {isSearchFocused && <Grid item xs={12}>
                            <div style={{minHeight: '250px', maxHeight: '250px', overflowY: 'auto'}}>
                                {!!listItems?.length ? listItems :
                                    <em className={'tw-opacity-75'}>{t('common.emptyList')}</em>}
                            </div>
                        </Grid>}
                    </Grid>
                </ClickAwayListener>
            </DialogContent>
            <DialogActions sx={{padding: '16px'}}>
                <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                    {t('form.cancel')}
                </Button>
                <Button color={'primary'} variant={'contained'} type={'submit'} onClick={handleSave} disabled={isSaving}>
                    {t('offer.categories.dialog.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
