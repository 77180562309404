import {CompanySpacesUserGroupsGetKindEnum, type ContentInCompanySpaceIndexResponse} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {isApiResultError} from "../../helpers/api";
import {Formik, useFormikContext} from "formik";
import {OptionValue} from "model/form";
import {ButtonGroup, Dialog, DialogActions, DialogContent, FormControl, FormLabel, Grid, MenuItem, Paper, Select} from "@mui/material";
import {TextFormFieldPlain} from "components/form/TextFormField";
import Button from "@mui/material/Button";
import {SelectFormField} from "components/form/SelectFormField";
import {fetchContents} from "store/content";
import {UserGroupGridFilter} from "pages/UserGroupsPage";
import {DataGridColFilter} from "components/DataGrid";
import {UserSelectFormField} from "components/form/UserSelectFormField";
import {FormikHelpers} from "formik/dist/types";
import {addDuration, dateToGui, firstDayOfMonthYmd, lastDayOfMonthYmd} from "../../helpers/date";

export type UserGroupFilterType =
    'name'
    | 'kind'
    | 'form'
    | 'daysCount'
    | 'userCount'
    | 'content'
    | 'contentCount'
    | 'status'
    | 'selfAssignable'
    | 'coordinatorIds'
    | 'startDate'
    | 'offerType'
// | 'lectorIds'
    ;

const dialogStyle = {
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
        },
        '& input.Mui-disabled': {
            color: 'var(--color-black-text)',
            'WebkitTextFillColor': 'var(--color-black-text)'
        },
        '& h3': {
            margin: 0,
            padding: 0,
            fontSize: '18px',
        }
    }
}

const NumberCompareFilter = ({filter, name, label}: { filter: UserGroupGridFilter, name: UserGroupFilterType, label?: string }) => {
    const t = useAppTranslation();

    const [comparison, setComparison] = useState('from');
    const [value, setValue] = useState('');

    const {setFieldValue, values} = useFormikContext<UserGroupGridFilter>();
    const currentValue = values[name];

    const updateFieldValue = useCallback((comparison: string, value?: string) => {
        setFieldValue(name, value ? comparison + ':' + value : undefined);
    }, [setFieldValue, name]);

    useEffect(() => {
        setComparison((currentValue
            ? String(currentValue).split(':')[0]
            : undefined) || 'from');

        setValue((currentValue
            ? String(currentValue).split(':')[1]
            : undefined) || '');

    }, [currentValue]);

    const compareOptions: OptionValue[] = useMemo(() => [
        {value: 'from', label: t('common.value.from')},
        {value: 'to', label: t('common.value.to')},
        {value: 'eq', label: t('common.value.eq')},
    ], [t]);

    return <Grid item xs={12}>
        <Grid container spacing={2}>
            <Grid item xs={5} className={'tw-flex tw-items-end'}>
                <FormControl fullWidth className={'tw-mb-4'}>
                    {!!label && <FormLabel>{label}</FormLabel>}
                    <Select
                        fullWidth
                        variant={'standard'}
                        name={name + 'Compare'}
                        // displayEmpty={true}
                        value={comparison}
                        onChange={(e) => {
                            setComparison(e.target.value);
                            updateFieldValue(String(e.target.value), value);
                        }}>
                        {compareOptions?.map((c, i) => {
                            return <MenuItem key={i} value={String(c.value)}>{c.label}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={7} className={'tw-flex tw-items-end'}>
                <TextFormFieldPlain currentValue={value} name={name}
                    label={t('common.value.enter')}
                    minValue={1} maxValue={9999} maxlength={4}
                    pattern={{re: '^[0-9]*$', message: t('common.value.number')}}
                    onChange={(v) => {
                        setValue(v);
                        updateFieldValue(comparison, v);
                    }}/>
            </Grid>
        </Grid>
    </Grid>;
}

const DateCompareFilter = ({name, label}: { name: UserGroupFilterType, label?: string }) => {
    const t = useAppTranslation();

    const [comparison, setComparison] = useState('from');
    const [valueFrom, setValueFrom] = useState('');
    const [valueTo, setValueTo] = useState('');

    const {setFieldValue, values} = useFormikContext<UserGroupGridFilter>();
    const currentValue = values[name];

    const updateFieldValue = useCallback((comparison: string, valueFrom?: string, valueTo?: string) => {
        if (comparison === 'from') {
            setFieldValue(name, valueFrom ? 'from:' + valueFrom : undefined);
        } else if (comparison === 'to') {
            setFieldValue(name, valueTo ? 'to:' + valueTo : undefined);
        } else {
            setFieldValue(name, valueFrom && valueTo ? 'from:' + valueFrom + ',to:' + valueTo : undefined);
        }

    }, [setFieldValue, name]);

    useEffect(() => {
        setComparison((currentValue
            ? String(currentValue).split(',').map(t => t.split(':')[0]).join('_') // from:2024-01-01,to:2024-01-31 => from_to
            : undefined) || 'from');

        setValueFrom((currentValue
            ? String(currentValue).split(',').find(t => t.startsWith('from'))?.split(':')[1]
            : undefined) || '');

        setValueTo((currentValue
            ? String(currentValue).split(',').find(t => t.startsWith('to'))?.split(':')[1]
            : undefined) || '');

    }, [currentValue]);

    const compareOptions: OptionValue[] = useMemo(() => [
        {value: 'from', label: t('common.date.from')},
        {value: 'to', label: t('common.date.to')},
        {value: 'from_to', label: t('common.date.from_to')},
    ], [t]);

    return <Grid item xs={12}>
        <Grid container spacing={2} className={'max-sm:tw-flex-col'}>
            <Grid item className={'tw-flex tw-items-end tw-grow'}>
                <FormControl fullWidth className={'tw-mb-4'}>
                    {!!label && <FormLabel>{label}</FormLabel>}
                    <Select
                        fullWidth
                        variant={'standard'}
                        name={name + 'Compare'}
                        // displayEmpty={true}
                        value={comparison}
                        onChange={(e) => {
                            setComparison(e.target.value);
                            updateFieldValue(e.target.value, valueFrom || valueTo, valueTo || valueFrom); /* preserve values when toggling */
                        }}>
                        {compareOptions?.map((c, i) => {
                            return <MenuItem key={i} value={String(c.value)}>{c.label}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            {(comparison === 'from' || comparison === 'from_to') && <Grid item className={'tw-flex tw-items-end tw-grow'}>
                <TextFormFieldPlain currentValue={valueFrom} name={name}
                    type={'date'}
                    maxValue={valueTo}
                    onChange={(v) => {
                        setValueFrom(v);
                        updateFieldValue(comparison, v, valueTo);
                    }}/>
            </Grid>}
            {(comparison === 'to' || comparison === 'from_to') && <Grid item className={'tw-flex tw-items-end tw-grow'}>
                <TextFormFieldPlain currentValue={valueTo} name={name}
                    type={'date'}
                    minValue={valueFrom}
                    onChange={(v) => {
                        setValueTo(v);
                        updateFieldValue(comparison, valueFrom, v);
                    }}/>
            </Grid>}
        </Grid>
    </Grid>;
}

export const UserGroupFilterDialog = ({colFilter, kind, onSuccess, onClose}: {
    colFilter: DataGridColFilter<UserGroupGridFilter, UserGroupFilterType>,
    kind?: CompanySpacesUserGroupsGetKindEnum,
    onSuccess?: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    // content autocomplete
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [contentSearch, setContentSearch] = useState('');
    const [contentOptions, setContentOptions] = useState<OptionValue[]>([]);

    const [isSaving, setIsSaving] = useState(false);

    const filterName = colFilter.filterName as keyof typeof colFilter.currentFilter;

    const initialValues: UserGroupGridFilter = useMemo(() => {
        return {
            [filterName]: colFilter.currentFilter[filterName]
        }
    }, [colFilter.currentFilter, filterName]);

    const handleValidate = useCallback((values: UserGroupGridFilter) => {
        // let errors = {} as FormikErrors<UserGroupGridFilter>;
        //
        // return errors;
        return {};
    }, []);

    const handleSave = useCallback(async (values: UserGroupGridFilter) => {
        setIsSaving(true);
        const result = {[colFilter.filterName]: undefined, ...values}; // undefined needed to override any previous value
        colFilter.onApply(result);
        if (onSuccess) {
            onSuccess(result);
        }
        onClose();
        setIsSaving(false);
    }, [colFilter, onSuccess, onClose]);

    const handleReset = useCallback(async () => {
        const defaultValue = colFilter.defaultFilter[filterName];
        await handleSave({
            [filterName]: defaultValue === undefined
                ? defaultValue
                : JSON.parse(JSON.stringify(defaultValue))
        });
    }, [filterName, colFilter.defaultFilter, handleSave]);

    const isFilterUsed = useMemo(() => {
        return colFilter.currentFilter[filterName] !== colFilter.defaultFilter[filterName]
            && JSON.stringify(colFilter.currentFilter) !== JSON.stringify(colFilter.defaultFilter[filterName]);
    }, [filterName, colFilter.currentFilter, colFilter.defaultFilter]);

    useEffect(() => {
        if (filterName !== 'content') {
            return;
        }
        setIsContentLoading(true);
        dispatch(fetchContents({
            contentTypes: 'blended_learning',
            ownershipType: 'original',
            perPage: 50,
            sort: "name:asc",
            name: contentSearch || undefined
        })).then((res) => {
            if (!isApiResultError(res)) {
                setContentOptions((res.payload as ContentInCompanySpaceIndexResponse[])
                    .filter(c => !!c.active_version?.id)
                    .map(c => ({value: c.active_version!.id!, label: c.name})));
            } else {
                setContentOptions([]);
            }
        }).finally(() => {
            setIsContentLoading(false);
        })
    }, [filterName, dispatch, contentSearch]);

    const quickFilters = useCallback((setFieldValue: FormikHelpers<any>['setFieldValue']) => {
        let filters: { label: string, action: () => void }[] | undefined;
        if (filterName === 'startDate') {
            const f = (v: Date) => dateToGui(v, "yyyy-MM-dd");
            const today = new Date();
            const tomorrow = addDuration(today, {days: 1});
            const nextMonth = addDuration(today, {months: 1});
            filters = [
                {
                    label: t('userGroup.filter.startDate.today'),
                    action: () => setFieldValue(filterName, 'from:' + f(today) + ',to:' + f(today))
                },
                {
                    label: t('userGroup.filter.startDate.tomorrow'),
                    action: () => setFieldValue(filterName, 'from:' + f(tomorrow) + ',to:' + f(tomorrow))
                },
                {
                    label: t('userGroup.filter.startDate.thisMonth'),
                    action: () => setFieldValue(filterName, 'from:' + firstDayOfMonthYmd(today) + ',to:' + lastDayOfMonthYmd(today))
                },
                {
                    label: t('userGroup.filter.startDate.nextMonth'),
                    action: () => setFieldValue(filterName, 'from:' + firstDayOfMonthYmd(nextMonth) + ',to:' + lastDayOfMonthYmd(nextMonth))
                }
            ];

        } else if (filterName === 'daysCount') {
            filters = [
                {
                    label: t('userGroup.filter.daysCount.single'),
                    action: () => setFieldValue(filterName, 'eq:1')
                },
                {
                    label: t('userGroup.filter.daysCount.multiple'),
                    action: () => setFieldValue(filterName, 'from:2')
                }
            ]
        }
        if (!filters) {
            return undefined;
        }
        return <ButtonGroup sx={{display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: 0, right: 0}}>{filters
            .map((f, i) => <Button variant={'text'} color={'inherit'} key={i} onClick={f.action}>{f.label}</Button>)
        }</ButtonGroup>

    }, [filterName, t]);

    const yesNoOptions: OptionValue[] = useMemo(() => [
        {value: true, label: t('common.yes')},
        {value: false, label: t('common.no')},
    ], [t]);

    const kindOptions: OptionValue[] = useMemo(() => [
        {value: 'blended_learning', label: t('userGroup.kind.blended_learning')},
        {value: 'elearning', label: t('userGroup.kind.elearning')},
    ], [t]);

    const formOptions: OptionValue[] = useMemo(() => [
        {value: 'online', label: t('userGroup.form.online')},
        {value: 'in_person', label: t('userGroup.form.in_person')},
        {value: 'in_person_and_online', label: t('userGroup.form.in_person_and_online')},
    ], [t]);

    const statusOptions: OptionValue[] = useMemo(() => [
        {value: 'active', label: t('userGroup.status.active')},
        {value: 'cancelled', label: t('userGroup.status.cancelled')},
        {value: 'completed', label: t('userGroup.status.completed')},
    ], [t]);

    const offerTypeOptions: OptionValue[] = useMemo(() => [
        {value: 'private', label: t('userGroup.offerType.private')},
        {value: 'public', label: t('userGroup.offerType.public')},
        {value: 'none', label: t('userGroup.offerType.noneLong')}
    ], [t]);

    return <Dialog open={true} onClose={onClose} PaperComponent={Paper} sx={dialogStyle}>
        <Formik
            initialValues={initialValues}
            onSubmit={handleSave}
            validate={handleValidate}
        >
            {({handleSubmit, setFieldValue, values}) => {
                return <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={1} sx={{
                            minHeight: '100px',
                            'small': {color: 'var(--color-black-text)'}
                        }} className={'tw-mt-8'}>
                            <Grid item xs={12} sx={{position: 'relative'}}>
                                {quickFilters(setFieldValue)}
                                {filterName === 'kind'
                                    && <SelectFormField name={filterName} options={kindOptions} label={t('userGroup.table.kind')} onChange={handleSubmit}/>}
                                {filterName === 'status'
                                    && <SelectFormField name={filterName} isMulti options={statusOptions} label={t('userGroup.table.status')}/>}
                                {filterName === 'selfAssignable'
                                    && <SelectFormField name={filterName} options={yesNoOptions} label={t('userGroup.dialog.selfAssign.label')} onChange={handleSubmit}/>}
                                {filterName === 'content' && <SelectFormField
                                    name={'content_version_id'}
                                    options={contentOptions}
                                    onSearch={setContentSearch}
                                    isLoading={isContentLoading}
                                    label={t('userGroup.dialog.blendedContent.label')}
                                />}
                                {filterName === 'form'
                                    && <SelectFormField name={filterName} options={formOptions} label={t('userGroup.dialog.form.label')} onChange={handleSubmit}/>}
                                {filterName === 'coordinatorIds'
                                    && <UserSelectFormField name={filterName}
                                        label={t('userGroup.dialog.coordinators.label')}
                                        placeholder={t('userGroup.dialog.coordinators.add')}
                                        filterRole={'admin,owner'}
                                    />}
                                {filterName === 'lecturerIds'
                                    && <UserSelectFormField name={filterName}
                                        label={t('userGroup.dialog.lecturers.label')}
                                        placeholder={t('userGroup.dialog.lecturers.add')}
                                    />}
                                {filterName === 'userCount'
                                    && <NumberCompareFilter filter={colFilter.currentFilter} name={filterName}
                                        label={kind === 'blended_learning' ? t('userGroup.table.participants') : t('userGroup.table.users')}/>}
                                {filterName === 'contentCount'
                                    && <NumberCompareFilter filter={colFilter.currentFilter} name={filterName}
                                        label={t('userGroup.table.content')}/>}
                                {filterName === 'daysCount'
                                    && <NumberCompareFilter filter={colFilter.currentFilter} name={filterName}
                                        label={t('userGroup.filter.daysCount.label')}/>}
                                {filterName === 'startDate'
                                    && <DateCompareFilter name={filterName} label={t('userGroup.table.startDate')}/>}
                                {filterName === 'offerType'
                                    && <SelectFormField name={filterName} options={offerTypeOptions} label={t('userGroup.table.offer')} onChange={handleSubmit}/>}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{padding: '16px'}}>
                        <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                            {t('form.cancel')}
                        </Button>
                        {isFilterUsed && <Button color={'secondary'} onClick={handleReset} disabled={isSaving}>
                            {t('userGroup.filter.cancel')}
                        </Button>}
                        <Button color={'primary'} variant={'contained'} type={'submit'} disabled={isSaving}>
                            {t('userGroup.filter.apply')}
                        </Button>
                    </DialogActions>
                </form>
            }}
        </Formik>
    </Dialog>;
};
