import { useEffect, useState } from 'react';
import { ContentViewerTestDefinitionTestAttemptPayloadIndexResponse } from 'generated-api';
import { useAppDispatch } from 'store';
import { createTestAttempt } from 'store/contentViewer';
import { MultiStepForm } from './MultiStepForm';
import { TestQuestion } from './TestQuestion';

interface Props {
  contentParticipationId?: number;
  testDefinitionId: number;
  setShowTestResults: (showTestResults: boolean) => void;
  activeTestAttempt?: ContentViewerTestDefinitionTestAttemptPayloadIndexResponse;
  setResultsLoading: (resultsLoading: boolean) => void;
  contentLoading?: boolean;
  finishTestAndCompleteStep: (testAttemptId: string) => Promise<void>;
  disableKeyboardControls?: boolean;
}

export const Test = ({
  contentParticipationId,
  testDefinitionId,
  setShowTestResults,
  activeTestAttempt,
  setResultsLoading,
  contentLoading,
  finishTestAndCompleteStep,
  disableKeyboardControls,
}: Props) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [showMissingQuestons, setShowMissingQuestions] = useState(false);

  const dispatch = useAppDispatch();

  const questions = activeTestAttempt?.questions;

  useEffect(() => {
    if (contentParticipationId && !activeTestAttempt && !contentLoading) {
      const handlePostTestAttempt = async () => {
        const requestBody = {
          test_definition_id: testDefinitionId,
          content_participation_id: contentParticipationId,
        };
        await dispatch(createTestAttempt({ body: requestBody }));
      };
      handlePostTestAttempt();
    }
  }, [contentParticipationId, testDefinitionId, dispatch, activeTestAttempt, contentLoading]);

  return (
    <>
      {activeTestAttempt && questions && (
        <MultiStepForm
          name="multistep-form"
          activeTestAttempt={activeTestAttempt}
          stepNumber={stepNumber}
        >
          {questions.map((question, index) => (
            <TestQuestion
              disableKeyboardControls={disableKeyboardControls}
              key={index}
              question={question}
              testAttemptId={activeTestAttempt.id}
              numberOfQuestions={questions?.length}
              stepNumber={stepNumber}
              setStepNumber={setStepNumber}
              setShowTestResults={setShowTestResults}
              showMissingQuestons={showMissingQuestons}
              setShowMissingQuestions={setShowMissingQuestions}
              testAttemptState={activeTestAttempt.state}
              setResultsLoading={setResultsLoading}
              contentLoading={contentLoading}
              finishTestAndCompleteStep={finishTestAndCompleteStep}
            />
          ))}
        </MultiStepForm>
      )}
    </>
  );
};
