import { CSSProperties } from 'react';
import type { XYCoord } from 'react-dnd';
import { useDragLayer } from 'react-dnd';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getDisplayStyleByCoordinates(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

interface Props {
  item: string;
}

const DraggableItemPreview = ({ item }: Props) => (
  <div className="dragged-item tw-inline-block tw-rotate-6 tw-cursor-grabbing tw-rounded-xl tw-bg-black/5 tw-py-12 tw-px-16 tw-font-roboto tw-text-14 tw-text-black">
    {item}
  </div>
);

export const CustomDragLayer = ({ item }: Props) => {
  const { itemType, isDragging, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getDisplayStyleByCoordinates(initialOffset, currentOffset)}>
        {itemType === 'item' && <DraggableItemPreview item={item} />}
      </div>
    </div>
  );
};
