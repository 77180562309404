import {OfferUpdate} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {isApiResultError} from "../../helpers/api";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import {Content} from "pages/PublicOfferPage";
import 'react-quill/dist/quill.snow.css';
import {updateOffer} from "store/offer";
import {TextFormFieldPlain} from "components/form/TextFormField";

const PREFIX = 'https://www.youtube.com/embed/';

const createUrlFromEmbedLink = (embedUrl?: string) => {
    if (embedUrl) {
        const tokens = embedUrl.split(PREFIX);
        if (tokens.length > 1) {
            return PREFIX + tokens[1];
        }
    }
    return embedUrl || '';
}

const parseIdFromRawLink = (rawLink?: string) => {
    if (rawLink && rawLink.indexOf('youtu') >= 0) {
        try {
            const url = new URL(rawLink);
            if (url.searchParams?.get('v')?.length === 11) {
                // https://www.youtube.com/watch?v=VlhIcp_O2Fo
                return url.searchParams.get('v');
            }
            const tokens = url.pathname.split('/');
            if (tokens?.[2]?.length === 11) {
                // https://www.youtube.com/live/VlhIcp_O2Fo?si=9FjHd9EtnMy-biY7
                // https://www.youtube.com/embed/VlhIcp_O2Fo
                return tokens[2];
            }
            if (tokens?.[1]?.length === 11) {
                // https://youtu.be/VlhIcp_O2Fo?si=qF6FBT5ML3VX1NY_
                return tokens[1];
            }
        } catch (ignored) {
        }
    }
    return undefined;
}

export const OfferPromoVideoDialog = ({content, offer, onSuccess, onClose}: {
    content: Content,
    offer: OfferUpdate,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [rawLink, setRawLink] = useState(createUrlFromEmbedLink(offer.promo_video_url));
    const [isSaving, setIsSaving] = useState(false);

    const validUrl = useMemo(() => {
        const id = parseIdFromRawLink(rawLink);
        if (id) {
            return PREFIX + id;
        }
        return undefined;

    }, [rawLink]);

    const handleSave = useCallback(async () => {
        setIsSaving(true);

        const res = await dispatch(updateOffer({
            id: '' + offer.id,
            contentId: content.id,
            body: {
                ...offer,
                promo_video_url: validUrl || '',
            }
        }));
        if (!isApiResultError(res)) {
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [validUrl, offer, content.id, dispatch, onSuccess, onClose]);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} maxWidth={'md'}>
            <DialogTitle>
                {t('offer.promo.dialog.title')}
            </DialogTitle>
            <LinearProgress hidden={!isSaving}/>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFormFieldPlain currentValue={rawLink} name={'youtubeId'} label={t('offer.promo.dialog.label')}
                            onChange={setRawLink}
                            disabled={!!validUrl}
                            showError={!!rawLink && !validUrl}
                            error={t('offer.promo.dialog.invalid')}
                            placeholder={'https://www.youtube.com/watch?v=ABCDEFG1234'}
                            maxlength={255}
                            helperText={!validUrl ? t('offer.promo.dialog.hint') : undefined}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {!!validUrl &&
                            <iframe src={validUrl} width="300" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                title={t('offer.promo.iframeTitle')}
                                allowFullScreen>
                            </iframe>}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{padding: '16px'}}>
                <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                    {t('form.cancel')}
                </Button>
                {!!validUrl &&
                    <Button color={'inherit'} variant={'contained'} onClick={() => setRawLink('')} disabled={isSaving}>
                        {t('offer.promo.dialog.remove')}
                    </Button>}
                <Button color={'primary'} variant={'contained'} type={'submit'} onClick={handleSave} disabled={isSaving || !(validUrl || !rawLink)}>
                    {t('offer.promo.dialog.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
