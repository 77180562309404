import clsx from 'clsx';
import { LoadingSpinner } from 'components/loading/LoadingSpinner';
import React, { useState, useRef, useEffect, useLayoutEffect, createRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useAppTranslation } from 'services/i18n';
import { ContentLayoutWithControls } from './ContentLayoutWithControls';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useSwipeable } from 'react-swipeable';
// resolve pdfjs worker error
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface PdfDocument {
  numPages: number;
}

interface PdfPage {
  width: number;
  height: number;
}

interface PdfViewerProps {
  file: string;
  audioFile?: string;
  onNavigateToNextStep: () => void;
  onNavigateToPreviousStep: () => void;
  currentStepIsFinished?: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
  type: 'presentation' | 'document';
  showCourseIncompleteModal: boolean;
  contentLoading?: boolean;
}

export const PdfViewer = ({
  file,
  onNavigateToNextStep,
  onNavigateToPreviousStep,
  currentStepIsFinished,
  isFirstStep,
  isLastStep,
  audioFile,
  type,
  showCourseIncompleteModal,
  contentLoading,
}: PdfViewerProps) => {
  const t = useAppTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = useRef<number>();
  const [pdfLoading, setPdfLoading] = useState(true);
  const [zoomScale, setZoomScale] = useState(1);

  const contentScreenRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = createRef<HTMLCanvasElement>();
  const documentRef = useRef<HTMLDivElement | null>(null);
  const [canvasWidth, setCanvasWidth] = useState(contentScreenRef?.current?.offsetWidth);
  const [canvasHeight, setCanvasHeight] = useState(contentScreenRef?.current?.offsetHeight);
  const [pixelRatio, setPixelRatio] = useState(Math.max(2, window.devicePixelRatio || 1)); // double the DPI for non-retinas

  // Resize pdf on window resize
  useLayoutEffect(() => {
    if (!contentScreenRef.current || type === 'document') return;
    // in case canvasRef isn't registered for some reason 🤷
    const canvas = document.getElementsByClassName('react-pdf__Page__canvas')?.[0];
    const pdfRatio = canvasRef.current
      ? canvasRef.current?.clientWidth / canvasRef.current?.clientHeight
      : canvas?.clientWidth / canvas?.clientHeight;
    const resizeObserver = new ResizeObserver((x) => {
      const screenWidth = x[0].contentRect.width;
      const screenHeight = x[0].contentRect.height;
      const screenRatio = screenWidth / screenHeight;

      if (screenRatio >= pdfRatio && canvasHeight !== Math.round(screenHeight)) {
        setCanvasWidth(undefined);
        return setCanvasHeight(screenHeight);
      }
      if (screenRatio < pdfRatio && canvasWidth !== Math.round(screenWidth)) {
        setCanvasWidth(screenWidth);
        return setCanvasHeight(undefined);
      }
    });
    resizeObserver.observe(contentScreenRef.current);
    setPixelRatio(Math.max(2, window.devicePixelRatio || 1));
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef, contentScreenRef, type, contentScreenRef.current]);

  // Reset controls on new pdf
  useEffect(() => {
    setPdfLoading(true);
    setPageNumber(1);
    setZoomScale(1);
  }, [file]);

  function onDocumentLoadSuccess({ numPages }: PdfDocument) {
    totalPages.current = numPages;
    setPdfLoading(false);
  }

  function onRenderSuccess(page: PdfPage) {
    // Resize pdf on load
    if (!contentScreenRef.current) return;
    const pdfRatio = page.width / page.height;
    const screenWidth = contentScreenRef.current.clientWidth;
    const screenHeight = contentScreenRef.current.clientHeight;
    const screenRatio = screenWidth / screenHeight;

    if (type === 'document') {
      return setCanvasWidth(screenWidth * 0.8);
    }

    if (screenRatio >= pdfRatio) {
      setCanvasWidth(undefined);
      return setCanvasHeight(screenHeight);
    } else {
      setCanvasWidth(screenWidth);
      return setCanvasHeight(undefined);
    }
  }

  const setNextPage = () => {
    if (pageNumber === totalPages.current) return;
    return setPageNumber((s) => s + 1);
  };

  const setPreviousPage = () => {
    if (pageNumber === 1) return;
    return setPageNumber((s) => s - 1);
  };

  const handleZoomIn = () => {
    if (zoomScale >= 2.4) return;
    return setZoomScale((s) => s + 0.2);
  };

  const handleZoomOut = () => {
    if (zoomScale <= 0.8 && type === 'presentation') return;
    if (zoomScale <= 0.6) return;
    return setZoomScale((s) => s - 0.2);
  };

  const isOnLastPage = type === 'document' || pageNumber === totalPages.current;
  const isOnFirstPage = type === 'document' || pageNumber === 1;
  const allowFinishStep =
    type !== 'document' &&
    !currentStepIsFinished &&
    totalPages.current !== undefined &&
    pageNumber < totalPages.current;

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => setPreviousPage(),
    onSwipedLeft: (eventData) => setNextPage(),
  });

  const swipableRefPassthrough = (el: HTMLDivElement) => {
    swipeHandlers.ref(el);
    if (contentScreenRef) contentScreenRef.current = el;
  };

  const scroll = (el: HTMLElement | null, direction: 'up' | 'down') => {
    if (el === null || el === undefined) return;
    const currentScroll = el.scrollTop;
    el.scroll(0, direction === 'down' ? currentScroll + 100 : currentScroll - 100);
  };

  return (
    <ContentLayoutWithControls
      contentLoading={contentLoading}
      leftKeyText={t('contentViewer.keyboardControls.back')}
      onLeftKeyPress={isOnFirstPage ? onNavigateToPreviousStep : setPreviousPage}
      leftKeyDisabled={isFirstStep && isOnFirstPage}
      rightKeyText={
        isOnLastPage
          ? !isLastStep
            ? t('contentViewer.keyboardControls.endStep')
            : undefined
          : t('contentViewer.keyboardControls.continue')
      }
      // TODO make a loading spinner inside a button when awaiting async action
      onRightKeyPress={
        isOnLastPage ? (!isLastStep ? onNavigateToNextStep : undefined) : setNextPage
      }
      enterKeyText={
        isLastStep
          ? t('contentViewer.keyboardControls.certificate')
          : allowFinishStep
          ? t('contentViewer.keyboardControls.endStep')
          : undefined
      }
      isEnterKeyAction={isLastStep}
      onEnterKeyPress={
        (isLastStep || allowFinishStep) && !showCourseIncompleteModal
          ? onNavigateToNextStep
          : undefined
      }
      upDownKeyText={type === 'document' ? t('contentViewer.keyboardControls.scroll') : undefined}
      onDownKeyPress={type === 'document' ? () => scroll(documentRef.current, 'down') : undefined}
      onUpKeyPress={type === 'document' ? () => scroll(documentRef.current, 'up') : undefined}
      totalPages={totalPages.current}
      pageNumber={type === 'presentation' ? pageNumber : undefined}
      loading={pdfLoading}
      handleZoomIn={handleZoomIn}
      handleZoomOut={handleZoomOut}
      audioFile={audioFile}
    >
      <div className="tw-h-full tw-w-full" {...swipeHandlers} ref={swipableRefPassthrough}>
        <Document
          file={file}
          className={clsx(
            'tw-flex tw-h-full tw-w-full tw-overflow-auto tw-bg-darkerGray',
            type === 'presentation' ? 'tw-justify-center' : 'tw-flex-col tw-items-center',
            type === 'presentation' &&
              (zoomScale <= 1 ? 'tw-items-center' : 'tw-items-center lg:tw-items-start')
          )}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<LoadingSpinner />}
          externalLinkTarget="_blank"
          inputRef={documentRef}
        >
          {type === 'presentation' ? (
            <Page
              onRenderSuccess={onRenderSuccess}
              width={canvasWidth}
              height={canvasHeight}
              canvasRef={canvasRef}
              scale={zoomScale}
              pageNumber={pageNumber}
              renderAnnotationLayer={true}
              renderTextLayer={false}
              devicePixelRatio={pixelRatio}
            />
          ) : (
            Array.from({ length: totalPages.current! }, (_, i) => i + 1).map((page) => (
              <Page
                key={page}
                onRenderSuccess={onRenderSuccess}
                width={canvasWidth}
                height={canvasHeight}
                canvasRef={canvasRef}
                scale={zoomScale}
                pageNumber={page}
                renderAnnotationLayer={true}
                renderTextLayer={false}
                devicePixelRatio={pixelRatio}
              />
            ))
          )}
        </Document>
      </div>
    </ContentLayoutWithControls>
  );
};
