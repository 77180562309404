import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import { Button } from 'components/clickable/Button';
import { Link } from 'components/clickable/Link';
import { Collapsible } from 'components/Collapsible';
import { ContentViewerShowResponseContentVersionLessonsInner } from 'generated-api';

import { ReactComponent as Pen } from 'icons/pen.svg';
import { ReactComponent as Book } from 'icons/book.svg';
import { ReactComponent as Camera } from 'icons/camera.svg';
import { ReactComponent as Audio } from 'icons/sound.svg';
import { ReactComponent as Paper } from 'icons/paper.svg';
import { ReactComponent as Checkmark } from 'icons/checkmark.svg';
import { ReactComponent as Cross } from 'icons/cross.svg';
import { ReactComponent as Iframe } from 'icons/iframe.svg';

interface StepIconByTypeProps {
  type: string;
}

const StepIconByType = ({ type }: StepIconByTypeProps) => {
  if (type === 'presentation') return <Book />;
  if (type === 'document') return <Paper />;
  if (type === 'video') return <Camera />;
  if (type === 'audio') return <Audio />;
  if (type === 'test') return <Pen />;
  if (type === 'iframe') return <Iframe width={16} height={16} />;
  return <div className="">?</div>;
};

interface SidebarLessonsProps {
  lessons: ContentViewerShowResponseContentVersionLessonsInner[];
  onRegisterParticipation: (stepId: string, courseId: string) => void;
  currentStepId?: number;
  currentLessonId?: number;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SidebarLessons = ({
  lessons,
  onRegisterParticipation,
  currentStepId,
  currentLessonId,
  onClick,
}: SidebarLessonsProps) => {
  const { courseId } = useParams();

  return (
    <>
      {lessons?.map((lesson, ix) => {
        const isInCurrentLesson = lesson.id === currentLessonId;
        const numberOfFinishedSteps = lesson.steps?.filter(
          (step) => step.step_participation?.state === 'completed'
        ).length;
        const isLessonDone = numberOfFinishedSteps === lesson.steps?.length;
        const isLastLesson = ix + 1 === lessons.length;
        return (
          <Collapsible
            key={lesson.id}
            defaultCollapsed={false}
            className={clsx(
              'tw-border-t tw-border-white/20 tw-pt-10',
              isLastLesson ? 'tw-pb-0' : 'tw-pb-10',
              isInCurrentLesson && 'tw-bg-white/5'
            )}
            headingClassName={clsx(!currentStepId && isInCurrentLesson && 'tw-bg-white/15')}
            chevronColor={isLessonDone ? '#88C425' : undefined}
            heading={
              <Link
                  kind="bare"
                  to={`/learn/${courseId}/lesson/${lesson.id}`}
                  scrollIntoView={!currentStepId && currentLessonId === lesson.id}
                  onClick={onClick}
                  disableFocus
              >
              <div className="tw-flex">
                <div className="tw-flex tw-w-48 tw-shrink-0 tw-items-center tw-justify-center">
                  {isLessonDone && <Checkmark width={14} strokeWidth={2} />}
                </div>
                <div className="">
                  <h4
                    className={clsx(
                      'tw-text-14',
                      isLessonDone ? 'tw-text-lightGreen' : 'tw-text-white'
                    )}
                  >
                    {lesson.position}. {lesson.name}
                  </h4>
                  {numberOfFinishedSteps !== undefined && (
                    <div className="tw-text-12 tw-text-lightGray">
                      {numberOfFinishedSteps}/{lesson.steps?.length}
                    </div>
                  )}
                </div>
              </div>
              </Link>
            }
          >
            <div className="tw-text-white">
              {lesson.steps?.map((step) => {
                const isCompleted = step.step_participation?.state === 'completed';
                const isFailed = step.step_participation?.repeated_study;
                return (
                  <Link
                    kind="bare"
                    key={step.id}
                    to={`/learn/${courseId}/lesson/${lesson.id}/step/${step.id}`}
                    className={clsx(
                      'tw-flex tw-h-[45px] tw-cursor-pointer tw-items-center tw-gap-12 tw-pl-16 hover:tw-bg-white/15',
                      step.id === currentStepId && 'tw-bg-white/15'
                    )}
                    scrollIntoView={step.id === currentStepId}
                    onClick={onClick}
                    disableFocus
                  >
                    {courseId && step.element_types[0] !== 'test' && (
                      <Button
                        kind="bare"
                        onClick={(e) => {
                          e?.preventDefault();
                          onRegisterParticipation(step.id.toString(), courseId);
                        }}
                        disabled={isFailed}
                        className="tw-relative tw-flex tw-h-16 tw-w-16 tw-shrink-0 tw-items-center tw-justify-center tw-rounded tw-bg-black/40"
                      >
                        {isCompleted && <Checkmark className="tw-absolute" width={10} />}
                        {isFailed && !isCompleted && <Cross width={10} />}
                      </Button>
                    )}

                    {step.element_types[0] === 'test' && (
                      <div className="tw-flex tw-w-16 tw-justify-center">
                        {step.step_participation?.state === 'completed' && <Checkmark />}
                      </div>
                    )}
                    <StepIconByType type={step.element_types[0]} />
                    <span className={clsx(
                        'tw-text-14',
                        isCompleted && 'tw-text-lightGreen',
                        isFailed && !isCompleted && 'tw-text-red'
                    )}>
                      {lesson.position}.{step.position} {step.name}
                    </span>
                    {!!step.study_materials?.length && (
                      <Paper className="tw-ml-auto tw-mr-20 tw-flex-shrink-0" />
                    )}
                  </Link>
                );
              })}
            </div>
          </Collapsible>
        );
      })}
    </>
  );
};
