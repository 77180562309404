import { Formik, FormikErrors } from 'formik';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useAppTranslation } from '../../services/i18n';
import { useAppDispatch } from '../../store';
import { BaseCodebook, updateOrgUnit } from '../../store/codebooks';
import { addMessage } from '../../store/localApp';
import { Button, Grid, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { TextFormField } from '../form/TextFormField';
import DialogActions from '@mui/material/DialogActions';
import { CodebookCategory } from '../../pages/CodebooksPage';
import { isApiResultError } from '../../helpers/api';

type CodebookEditFormFields = {
  value: string;
};

const validate = (values: CodebookEditFormFields, item: BaseCodebook, t: any) => {
  let errors = {} as FormikErrors<CodebookEditFormFields>;
  if (!values.value || values.value === item.name) {
    errors.value = t('codebooks.form.error.valueRequired');
  }
  return errors;
};

type Props = {
  codebookCategory: CodebookCategory;
  item: BaseCodebook;
  onSuccess: (result: any) => unknown;
  onClose: () => unknown;
};

export const CodebookEditForm = (props: Props) => {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);

  const {
    codebookCategory: { category },
    item,
    onSuccess,
    onClose,
  } = props;

  const id: string = '' + item.id;
  const handleSaveItem = useCallback(
    async (values: CodebookEditFormFields) => {
      setIsSaving(true);
      const result = await dispatch(
        updateOrgUnit({
          id,
          body: {
            category,
            name: values.value,
          } as BaseCodebook,
        })
      );
      setIsSaving(false);

      if (!isApiResultError(result)) {
        dispatch(addMessage({ severity: 'success', title: t('codebooks.form.success.valueUpdated') }));
        onSuccess(result.payload);
        onClose();
      }
    },
    [dispatch, category, id, onSuccess, onClose, t]
  );

  return (
    <Dialog open={true} onClose={props.onClose} PaperComponent={Paper}>
      <Formik
        initialValues={{ value: item.name } as CodebookEditFormFields}
        onSubmit={handleSaveItem}
        validate={(v) => validate(v, item, t)}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <DialogTitle>
              {t('codebooks.' + category + '.title')} - {t('form.update')}
            </DialogTitle>
            <LinearProgress hidden={!isSaving} />
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFormField
                    name="value"
                    label={t('codebooks.form.valueTitle')}
                    maxlength={100}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                {t('form.cancel')}
              </Button>
              <Button color={'primary'} type={'submit'} disabled={isSaving}>
                {t('form.update')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
