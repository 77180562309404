import {useAppTranslation} from "services/i18n";
import * as React from "react";
import {useCallback} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper} from "@mui/material";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {QuestionErrorType} from "../../helpers/editorTest";

interface QuestionCategoriesDialogProps {
  errors: QuestionErrorType[];
  onConfirm: () => unknown;
  onClose: () => unknown;
}

export const QuestionPublishDialog = (props: QuestionCategoriesDialogProps) => {
  const {errors, onConfirm, onClose} = props;
  const t = useAppTranslation();

  const handleSave = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <Dialog open={true} onClose={onClose} PaperComponent={Paper}>
      <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
        <span>{t('catalogues.questions.publish.action')}</span>
        <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
      </DialogTitle>
      <DialogContent>
        <p>{errors.length
          ? t('catalogues.questions.publish.errors')
          : t('catalogues.questions.publish.message')}</p>
        {!!errors.length && <Grid container spacing={1}>
          {errors.map((e, i) => <Grid key={i} item xs={12}>
            <span className={'tw-text-darkerRed'}>{e.full_message}</span>
          </Grid>)}
        </Grid>}
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={onClose}>
          {t('form.close')}
        </Button>
        {!errors.length && <Button color={'primary'} onClick={handleSave}>
          {t('catalogues.questions.publish.button')}
        </Button>}
      </DialogActions>
    </Dialog>
  );
};
