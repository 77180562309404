import { FieldConfig, FormikValues, Formik, Form } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { ContentViewerTestDefinitionTestAttemptPayloadIndexResponse } from 'generated-api';
import { QuestionType } from 'utils/types';

interface Props extends FieldConfig<FormikValues> {
  children: React.ReactNode;
  activeTestAttempt: ContentViewerTestDefinitionTestAttemptPayloadIndexResponse;
  stepNumber: number;
}

export const MultiStepForm = ({ children, activeTestAttempt, stepNumber }: Props) => {
  const [snapshot, setSnapshot] = useState<{ [key: string]: string | string[] } | null>(null);
  const steps = React.Children.toArray(children) as React.ReactElement[];
  const step = steps[stepNumber];
  const questions = activeTestAttempt?.questions;

  const initialAnswers = useMemo(() => {
    return (
      questions &&
      Object.assign(
        {},
        ...questions?.map((question) => {
          const answerId = question.answer ? question.answer.id : '';
          const choiceIds =
            question.answer &&
            question.answer?.selected_choices.map((selectedChoice) =>
              selectedChoice.choice_id.toString()
            );
          return {
            [question.test_instance_question_id]: {
              choice_ids:
                question.question_type === QuestionType.Matching && question.answer
                  ? question.answer?.selected_choices
                  : choiceIds
                  ? choiceIds
                  : [],
              answer_id: answerId,
            },
          };
        })
      )
    );
  }, [questions]);

  useEffect(() => {
    setSnapshot(initialAnswers);
  }, [initialAnswers]);

  return (
    <>
      {snapshot && (
        <Formik onSubmit={() => {}} initialValues={snapshot}>
          {() => {
            return (
              <Form className="tw-flex tw-h-full tw-w-full tw-grow tw-flex-col tw-justify-between">
                {step}
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
