import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ContentViewerTestDefinition } from 'generated-api';
import { Trans } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useAppTranslation } from 'services/i18n';
import { useNavigate } from 'react-router-dom';
import { ContentViewerModal } from 'pages/ContentViewer/ContentViewerModal';
import { ContentLayoutWithControls } from 'pages/ContentViewer/ContentLayoutWithControls';
import { Link } from 'components/clickable/Link';
import { Step } from 'utils/types';

interface Props {
  testDefinition: ContentViewerTestDefinition;
  setShowTestQuestions: (showTestQuestions: boolean) => void;
  contentParticipationId: number;
  enforceStudyPreviousSteps: boolean;
  contentLoading?: boolean;
  notCompletedPreviousSteps?: Step[];
  isStartTestAllowed: boolean;
  handleNavigateToNextStep: () => Promise<void>;
  handleNavigateToPreviousStep: () => void;
  isLastStep?: boolean;
  isFirstStep?: boolean;
}

export const TestIntro = ({
  testDefinition,
  setShowTestQuestions,
  contentParticipationId,
  enforceStudyPreviousSteps,
  contentLoading,
  notCompletedPreviousSteps,
  isStartTestAllowed,
  isLastStep,
  isFirstStep,
  handleNavigateToPreviousStep,
  handleNavigateToNextStep,
}: Props) => {
  const navigate = useNavigate();
  const [allStepsCompleted, setAllStepsCompleted] = useState(true);
  const {
    name,
    points_to_pass: points,
    total_points: total,
    points_to_pass_percent: pointsToPassPercentDecimal,
    test_type: testType,
    time_limit: timeLimit,
    description,
  } = testDefinition;
  const pointsToPassPercent = pointsToPassPercentDecimal * 100;

  const t = useAppTranslation();

  const handleStartTest = () => {
    if (enforceStudyPreviousSteps) {
      notCompletedPreviousSteps &&
        notCompletedPreviousSteps.length > 0 &&
        setAllStepsCompleted(true);
      notCompletedPreviousSteps &&
        notCompletedPreviousSteps.length === 0 &&
        setShowTestQuestions(true);
    } else {
      setShowTestQuestions(true);
    }
  };

  useEffect(() => {
    if (enforceStudyPreviousSteps) {
      notCompletedPreviousSteps && notCompletedPreviousSteps.length > 0
        ? setAllStepsCompleted(false)
        : setAllStepsCompleted(true);
    }
  }, [enforceStudyPreviousSteps, notCompletedPreviousSteps]);

  const navigateToUnfinishedStep = useCallback(() => {
    const firstUnfinishedStep = notCompletedPreviousSteps?.[0];
    firstUnfinishedStep &&
      navigate(
        `/learn/${contentParticipationId}/lesson/${firstUnfinishedStep?.lessonId}/step/${firstUnfinishedStep.stepId}`
      );
  }, [contentParticipationId, navigate, notCompletedPreviousSteps]);

  const NotCompletedSteps = notCompletedPreviousSteps?.map((step) => {
    return (
      <Link
        to={`/learn/${contentParticipationId}/lesson/${step?.lessonId}/step/${step?.stepId}`}
        kind="bare"
        className="tw-no-underline"
        key={step?.stepId}
      >
        <div
          key={step?.stepId}
          className="tw-rounded-xl tw-bg-lightRed tw-px-12 tw-py-6 tw-text-12 tw-text-darkerRed"
        >
          {step?.lessonPosition}.{step?.stepPosition} {step?.name}
        </div>
      </Link>
    );
  });

  return (
    <ContentLayoutWithControls
      contentLoading={contentLoading}
      leftKeyText={t('contentViewer.keyboardControls.back')}
      onLeftKeyPress={handleNavigateToPreviousStep}
      leftKeyDisabled={isFirstStep}
      rightKeyText={t('contentViewer.testViewer.startTest')}
      onRightKeyPress={handleStartTest}
      rightKeyDisabled={isStartTestAllowed}
      enterKeyText={!isLastStep && t('contentViewer.keyboardControls.endStep')}
      onEnterKeyPress={handleNavigateToNextStep}
      enterKeyDisabled={isLastStep}
    >
      <div className="tw-h-full tw-w-full tw-px-16 sm:tw-py-48 sm:tw-px-96">
        <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center">
          <span
            className="tw-cursor-pointer tw-rounded-lg tw-bg-orange tw-p-12"
            onClick={handleStartTest}
          >
            <EditIcon />
          </span>
          <h1 className="tw-mb-8 tw-mt-16 tw-text-xl tw-font-semibold tw-text-black sm:tw-mb-30">
            Test: {name}
          </h1>
          {enforceStudyPreviousSteps && !allStepsCompleted ? (
            <ContentViewerModal
              isFullScreen={false}
              title={t(`contentViewer.testViewer.missingStepsModal.title`)}
              explanation={t(`contentViewer.testViewer.missingStepsModal.explanation`)}
              enterKeyText={t(`contentViewer.testViewer.missingStepsModal.action`)}
              onEnterKeyPress={navigateToUnfinishedStep}
              missingItemsInfo={t(`contentViewer.testViewer.missingStepsModal.missingSteps`)}
              missingItems={NotCompletedSteps}
            />
          ) : (
            <>
              {description && (
                <div className="tw-mx-16 tw-mb-24 tw-text-center tw-leading-normal lg:tw-w-1/2">
                  {description}
                </div>
              )}
              <div className="tw-mx-16 tw-mt-6 tw-min-w-250 tw-rounded-2xl tw-bg-black/5 tw-p-24 tw-text-center lg:tw-mx-0 lg:tw-w-1/2">
                { testType === 'standard' ? (
                    <Trans i18nKey="contentViewer.testViewer.points">
                      Musíte získat {{ points }} z {{ total }} bodů pro splnění testu.
                    </Trans>
                  ) : (
                    <Trans i18nKey="contentViewer.testViewer.percent">
                      Musíte získat {{ pointsToPassPercent }}&nbsp;% bodů pro splnění testu.
                    </Trans>
                  )
                }
                <p className="tw-mt-8">
                  {!timeLimit ? (
                    <Trans i18nKey="contentViewer.testViewer.noLimit">
                      Tento test nemá časový limit
                    </Trans>
                  ) : timeLimit % 60 > 0 ? (
                    <Trans i18nKey="contentViewer.testViewer.timeWithSeconds">
                      Máte {{ minutes: Math.floor(timeLimit / 60).toFixed(0) }} minut a{' '}
                      {{ seconds: (timeLimit % 60).toFixed(0) }} sekund na dokončení testu.
                    </Trans>
                  ) : (
                    <Trans i18nKey="contentViewer.testViewer.time">
                      Máte {{ minutes: (timeLimit / 60).toFixed(0) }} minut na dokončení testu.
                    </Trans>
                  )}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </ContentLayoutWithControls>
  );
};
