import { DraggableItem } from './DraggableItem';
import { useDrop } from 'react-dnd';
import clsx from 'clsx';
import { DraggableItemType, Answer } from 'utils/types';
import { FormikValues, useFormikContext } from 'formik';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  itemsToMatch: string[];
  setItemsToMatch: Dispatch<SetStateAction<string[]>>;
  testInstanceId: string;
  itemsToMove: string[] | null;
  setItemsToMove: Dispatch<SetStateAction<string[]>>;
  isMobile: boolean;
  isItemDragging: boolean;
  setIsItemDragging: (isItemDragging: boolean) => void;
}

export const DraggableArea = ({
  itemsToMatch,
  setItemsToMatch,
  testInstanceId,
  itemsToMove,
  setItemsToMove,
  isMobile,
  isItemDragging,
  setIsItemDragging,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const [, drop] = useDrop({
    accept: 'item',
    drop: (item: DraggableItemType) => removeItemFromCategory(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const removeItemFromCategory = (item: { item: string }) => {
    if (!itemsToMatch.includes(item.item)) {
      setItemsToMatch((itemsToMatch) => [...itemsToMatch, item.item]);
      setIsItemDragging(false);
      const newMatchedItems = values[testInstanceId]?.choice_ids.map((matchedItem: Answer) => {
        const filteredPayload = matchedItem.payload?.filter(
          (matchedItem) => matchedItem !== item.item
        );
        return { ...matchedItem, payload: filteredPayload };
      });

      setFieldValue(`${testInstanceId}`, {
        ...values[testInstanceId],
        choice_ids: [...newMatchedItems],
      });
    }
  };

  return (
    <div
      className={clsx(
        'tw-mb-10 tw-flex tw-h-fit tw-min-h-60 tw-flex-wrap tw-items-start tw-gap-8 tw-rounded-lg tw-py-12 tw-px-8',
        isItemDragging && 'glowing-border-area',
        isMobile && 'landscape:tw-max-w-200 landscape:tw-pt-0'
      )}
      id="items"
      ref={drop}
    >
      {itemsToMatch.length > 0 &&
        itemsToMatch.map((item, index) => (
          <DraggableItem
            key={index}
            item={item}
            itemsToMove={itemsToMove}
            setItemsToMove={setItemsToMove}
          />
        ))}
    </div>
  );
};
