import {DayCreate, DayUpdate, type UserBasicInfo} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {createLearningEventDay, updateLearningEventDay} from "store/userGroup";
import {isApiResultError} from "../../helpers/api";
import {Formik, FormikErrors} from "formik";
import {OptionValue} from "model/form";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper} from "@mui/material";
import {TextFormField} from "components/form/TextFormField";
import Button from "@mui/material/Button";
import {SelectFormField} from "components/form/SelectFormField";
import {dateToGui, guiToDatetime, timeToGui} from "../../helpers/date";
import {UserSelectFormField} from "components/form/UserSelectFormField";

interface LearningDayValueType extends Partial<DayCreate>, Partial<DayUpdate> {
    date_from?: string;
    time_from?: string;
    time_to?: string;
    lecturers?: UserBasicInfo[] // serialized on day
}

const dialogStyle = {
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px",
        },
        '& input.Mui-disabled': {
            color: 'var(--color-black-text)',
            'WebkitTextFillColor': 'var(--color-black-text)'
        },
        '& h3': {
            margin: 0,
            padding: 0,
            fontSize: '18px',
        }
    }
}

export const UserGroupLearningEventDayDialog = ({learningEventId, dayId, day, onSuccess, onClose}: {
    learningEventId: number,
    dayId?: number,
    day: LearningDayValueType,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [isSaving, setIsSaving] = useState(false);

    const initialValues: LearningDayValueType = useMemo(() => {
        return {
            ...day,
            date_from: dateToGui(day.start_date, 'yyyy-MM-dd'),
            time_from: timeToGui(day.start_date, 'HH:mm'),
            time_to: timeToGui(day.end_date, 'HH:mm'),
            lecturer_ids: day.lecturers?.map(l => l.id)
        }
    }, [day]);

    const handleSave = useCallback(async (values: LearningDayValueType) => {
        setIsSaving(true);

        const body = {...values};

        body.start_date = undefined;
        if (body.date_from && body.time_from) {
            body.start_date = guiToDatetime(body.date_from + ' ' + body.time_from);
        }

        body.end_date = undefined;
        if (body.date_from && body.time_to) {
            body.end_date = guiToDatetime(body.date_from + ' ' + body.time_to);
        }

        let res;
        if (dayId) {
            res = await dispatch(updateLearningEventDay({
                learningEventId,
                id: '' + dayId,
                body: {
                    ...body
                }
            }));
        } else {
            res = await dispatch(createLearningEventDay({
                learningEventId,
                body: {
                    start_date: body.start_date!,
                    end_date: body.end_date!,
                    form: body.form!,
                    ...body,
                }
            }));
        }
        if (!isApiResultError(res)) {
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [dayId, learningEventId, dispatch, onSuccess, onClose]);

    const handleValidate = useCallback((values: LearningDayValueType) => {
        let errors = {} as FormikErrors<LearningDayValueType>;
        if (!values.date_from) {
            errors.date_from = t('userGroup.days.dialog.dateFrom.messages.required');
        }
        if (!values.time_from) {
            errors.time_from = t('userGroup.days.dialog.timeFrom.messages.required');
        }
        if (!values.time_to) {
            errors.time_to = t('userGroup.days.dialog.timeTo.messages.required');
        } else if (values.time_from && !(values.time_to > values.time_from)) {
            errors.time_to = t('userGroup.days.dialog.timeTo.messages.interval');
        }
        if (!values.form) {
            errors.form = t('userGroup.days.dialog.form.messages.required');
        }
        if (values.form === 'online' || values.form === 'in_person_and_online') {
            if (!values.online_location) {
                errors.online_location = t('userGroup.days.dialog.onlineLocation.messages.required');
            }
        }
        if (values.form === 'in_person' || values.form === 'in_person_and_online') {
            if (!values.physical_location) {
                errors.physical_location = t('userGroup.days.dialog.physicalLocation.messages.required');
            }
        }
        return errors;
    }, [t]);

    const formOptions: OptionValue[] = useMemo(() => [
        {value: 'online', label: t('userGroup.form.online')},
        {value: 'in_person', label: t('userGroup.form.in_person')},
        {value: 'in_person_and_online', label: t('userGroup.form.in_person_and_online')},
    ], [t]);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} sx={dialogStyle}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSave}
                validate={handleValidate}
            >
                {({values, handleSubmit}) => {
                    return <form onSubmit={handleSubmit}>
                        <DialogTitle>
                            {dayId ? t('userGroup.days.dialog.edit') : t('userGroup.days.dialog.new')}
                        </DialogTitle>
                        <LinearProgress hidden={!isSaving}/>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <TextFormField name={'date_from'} label={t('userGroup.days.dialog.dateFrom.label')} type={'date'}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextFormField name={'time_from'} label={t('userGroup.days.dialog.timeFrom.label')} type={'time'}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextFormField name={'time_to'} label={t('userGroup.days.dialog.timeTo.label')} type={'time'} minValue={values.time_from}/>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/*<Grid item xs={6}>*/}
                                {/*    <TextFormField name={'end_date'} label={t('userGroup.days.dialog.endDate.label')} type={'datetime-local'}*/}
                                {/*        minValue={values.start_date}*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                <Grid item xs={12}>
                                    <SelectFormField name={'form'} options={formOptions} label={t('userGroup.days.dialog.form.label')}/>
                                </Grid>

                                {(values.form === 'in_person' || values.form === 'in_person_and_online') && <Grid item xs={6}>
                                    <TextFormField name={'physical_location'} label={t('userGroup.days.dialog.physicalLocation.label')}/>
                                </Grid>}

                                {(values.form === 'in_person' || values.form === 'in_person_and_online') && <Grid item xs={6}>
                                    <TextFormField name={'location_url'} label={t('userGroup.days.dialog.locationUrl.label')}
                                        placeholder={'https://maps.app.goo.gl/tksW6WsYa7Pdvtkm7'}/>
                                </Grid>}

                                {(values.form === 'online' || values.form === 'in_person_and_online') && <Grid item xs={12}>
                                    <TextFormField name={'online_location'} label={t('userGroup.days.dialog.onlineLocation.label')}/>
                                </Grid>}

                                <Grid item xs={12}>
                                    <UserSelectFormField name={'lecturer_ids'}
                                        label={t('userGroup.days.dialog.lecturers.label')}
                                        placeholder={t('userGroup.days.dialog.lecturers.add')}
                                        initialUsers={values.lecturers}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{padding: '16px'}}>
                            <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                                {t('form.cancel')}
                            </Button>
                            <Button color={'primary'} variant={'contained'} type={'submit'} disabled={isSaving}>
                                {dayId ? t('userGroup.days.dialog.update') : t('userGroup.days.dialog.create')}
                            </Button>
                        </DialogActions>
                    </form>
                }}
            </Formik>
        </Dialog>
    );
};
