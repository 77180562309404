import React, { useEffect, useMemo } from 'react';
import { ReactComponent as AwardIcon } from 'icons/award.svg';
import { useAppTranslation } from 'services/i18n';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser, selectContentViewer } from 'store/selectors';
import { confirmLesson } from 'store/contentViewer';
import { useAppDispatch } from 'store';
import { ContentLayoutWithControls } from './ContentLayoutWithControls';

export enum ButtonsLoading {
  SignedCertificate = 'signedCertificate',
  UnsignedCertificate = 'unsignedCertificate',
}

export const Certificate = () => {
  const t = useAppTranslation();
  const { courseId } = useParams();
  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);
  const userName = authUser?.full_name;

  const contentViewerState = useSelector(selectContentViewer);

  const confirmedAt = useMemo(() => {
    return contentViewerState?.course?.confirmed_at
      ? new Date(contentViewerState?.course.confirmed_at)
      : undefined;
  }, [contentViewerState?.course?.confirmed_at]);

  const isCourseCompleted =
    contentViewerState?.course?.state && contentViewerState.course.state === 'completed';

  const dispatch = useAppDispatch();

  const [nameToConfirm, setNameToConfirm] = useState('');

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameToConfirm(e.target.value);
  };

  const confirmCourseCompletion = async () => {
    if (courseId && !confirmedAt) {
      await dispatch(
        confirmLesson({
          id: courseId,
          body: {
            confirmation_string: nameToConfirm,
          },
        })
      );
    }
  };

  const handleShowCertificate = async (buttonLoading?: ButtonsLoading) => {
    if (buttonLoading === ButtonsLoading.SignedCertificate && !confirmedAt) {
      await confirmCourseCompletion();
    }
    courseId &&
      window.open(`api/v1/content_viewer/participations/${courseId}/certificate.html`, '_blank');
  };

  const keyboardProps = {
    leftKeyText: t('contentViewer.keyboardControls.backToCourse'),
    onLeftKeyPress: () => navigate(`/learn/${courseId}`),
    enterKeyText: t('contentViewer.keyboardControls.certificate'),
    isEnterKeyAction: !!nameToConfirm,
    enterKeyDisabled: userName !== nameToConfirm,
    onEnterKeyPress: () => {
      userName === nameToConfirm && handleShowCertificate(ButtonsLoading.SignedCertificate);
    },
  };

  useEffect(() => {
    isCourseCompleted && confirmedAt && navigate(`/learn/${courseId}`);
  }, [isCourseCompleted, confirmedAt, courseId, navigate]);

  return (
    <ContentLayoutWithControls
      className="tw-mx-auto tw-max-w-367 tw-flex-col tw-items-center tw-gap-16 tw-py-16"
      contentLoading={!authUser}
      {...keyboardProps}
    >
      <div
        className={
          'tw-flex tw-h-48 tw-w-48 tw-items-center tw-justify-center tw-rounded-xl tw-bg-lightGreen'
        }
      >
        <AwardIcon />
      </div>

      <h1 className="tw-flex tw-flex-col tw-items-center tw-text-center tw-text-24 tw-font-semibold">
        <span>{t('contentViewer.certificate.title')}</span>
        <span>{t('contentViewer.certificate.subtitle')}</span>
        <span>{t('contentViewer.certificate.missingStep')}</span>
      </h1>
      <div className="tw-mt-8 tw-text-center tw-text-14">
        <p>{t('contentViewer.certificate.confirmCompletion')}</p>
        <p className="tw-mt-32 tw-mb-6">{t('contentViewer.certificate.userName')}</p>
        <TextField
          id="outlined-basic"
          placeholder={userName}
          sx={{
            backgroundColor: 'white',
            height: 48,
            width: 280,
            padding: '11px 16px',
            border: '1px solid #EEEEEE !important',
            borderRadius: '8px',
          }}
          onChange={handleNameChange}
        />
      </div>
      <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-16">
        <button
          className="tw-relative tw-m-0 tw-flex tw-h-48 tw-items-center tw-gap-8 tw-rounded-lg tw-bg-green tw-py-12 tw-px-14 tw-font-medium tw-text-white disabled:tw-bg-lighterGreen"
          disabled={userName !== nameToConfirm}
          onClick={() => handleShowCertificate(ButtonsLoading.SignedCertificate)}
        >
          {t('contentViewer.certificate.confirmAndShow')}
          <div className="tw-hidden tw-cursor-pointer tw-rounded tw-bg-black/5 tw-px-5 tw-py-2 tw-text-12 tw-text-white lg:tw-block">
            Enter
          </div>
        </button>
        <button
          className="tw-relative tw-m-0 tw-flex tw-h-48 tw-items-center tw-gap-8 tw-rounded-lg tw-border tw-border-solid tw-border-lightGray tw-bg-lightestGray tw-py-12 tw-px-14 tw-font-medium tw-text-darkerGray disabled:tw-bg-lighterGray"
          onClick={() => handleShowCertificate(ButtonsLoading.UnsignedCertificate)}
        >
          {t('contentViewer.certificate.showNoConfirm')}
        </button>
      </div>
    </ContentLayoutWithControls>
  );
};
