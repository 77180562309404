import {useCallback, useEffect, useState} from 'react';
import {Test} from './Test';
import {
  fetchLesson,
  fetchTestAttempts,
  postStepParticipation,
  updateStepParticipation,
  updateTestAttempt,
} from 'store/contentViewer';
import {useAppDispatch} from 'store';
import {
  ContentViewerShowResponseContentVersionLessonsInner,
  ContentViewerTestDefinition,
  ContentViewerTestDefinitionTestAttemptPayloadIndexResponse,
  ContentViewerTestDefinitionTestAttemptPayloadUpdateResponse,
} from 'generated-api';
import {TestEvaluation} from 'components/test/TestEvaluation';
import {TestIntro} from './TestIntro';
import {TestTimer} from './TestTimer';
import {useAppTranslation} from 'services/i18n';
import {ContentViewerModal} from 'pages/ContentViewer/ContentViewerModal';
import {Step} from 'utils/types';
import {LoadingSpinner} from 'components/loading/LoadingSpinner';

interface Props {
  contentParticipationId: number;
  testDefinition: ContentViewerTestDefinition;
  stepId: string;
  currentLesson?: ContentViewerShowResponseContentVersionLessonsInner;
  handleNavigateToNextStep: () => Promise<void>;
  handleNavigateToPreviousStep: () => void;
  activeTestAttempt?: ContentViewerTestDefinitionTestAttemptPayloadIndexResponse;
  showTestNotCompletedModal: boolean;
  setShowTestNotCompletedModal: (showTestNotCompletedModal: boolean) => void;
  isLastStep?: boolean;
  isFirstStep?: boolean;
  contentLoading?: boolean;
  showCourseIncompleteModal: boolean;
  currentStepIsFinished: boolean;
  allCourseSteps?: Step[];
}

export const TestViewer = ({
  contentParticipationId,
  testDefinition,
  stepId,
  handleNavigateToPreviousStep,
  handleNavigateToNextStep,
  activeTestAttempt,
  showTestNotCompletedModal,
  setShowTestNotCompletedModal,
  isFirstStep,
  isLastStep,
  contentLoading,
  showCourseIncompleteModal,
  currentStepIsFinished,
  allCourseSteps,
}: Props) => {
  const [showTestQuestions, setShowTestQuestions] = useState<boolean>(false);
  const [showTestResults, setShowTestResults] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);

  const dispatch = useAppDispatch();

  const t = useAppTranslation();

  const {
    id: testDefinitionId,
    time_limit: timeLimit,
    enforce_study_of_previous_steps: enforceStudyPreviousSteps,
  } = testDefinition;
  const testAttemptId = activeTestAttempt?.id;

  const finishTestAndCompleteStep = useCallback(
    async (testAttemptId: string) => {
      setResultsLoading(true);
      const response = (await (
        await dispatch(updateTestAttempt({ id: testAttemptId }))
      ).payload) as ContentViewerTestDefinitionTestAttemptPayloadUpdateResponse;
      const testResult = response.result;

      if (testResult === 'passed' && !currentStepIsFinished) {
        const requestBody = {
          step_id: +stepId,
          state: 'completed',
          content_participation_id: contentParticipationId,
        };
        await dispatch(updateStepParticipation({body: requestBody}));
        await dispatch(fetchLesson(contentParticipationId.toString()));
      }
      setResultsLoading(false);
    },
    [dispatch, contentParticipationId, stepId, currentStepIsFinished]
  );

  const submitTest = useCallback(() => {
    if (testAttemptId) finishTestAndCompleteStep(testAttemptId.toString());
    setShowTestNotCompletedModal(false);
    setShowTestResults(true);
  }, [testAttemptId, setShowTestNotCompletedModal, setShowTestResults, finishTestAndCompleteStep]);

  const notCompletedPreviousSteps = allCourseSteps?.filter((step, index) => {
    const testStepIndex = allCourseSteps?.findIndex((step) => step?.stepId === +stepId);
    return (
      step?.stepId !== undefined &&
      step?.stepParticipation?.state !== 'completed' &&
      testStepIndex &&
      index < testStepIndex
    );
  });

  const isStartTestAllowed =
    enforceStudyPreviousSteps &&
    notCompletedPreviousSteps &&
    notCompletedPreviousSteps.length > 0 &&
    contentLoading
      ? true
      : false;

  useEffect(() => {
    const getTestAttempts = async (testDefinitionId: number, contentParticipationId: number) => {
      setTestLoading(true);
      const testAttempts = (
        await dispatch(
          fetchTestAttempts({
            testDefinitionId,
            contentParticipationId,
          })
        )
      ).payload as ContentViewerTestDefinitionTestAttemptPayloadIndexResponse[];

      testAttempts?.forEach(async (testAttempt) => {
        if (testAttempt.state === 'in_progress' && testAttempt.remaining_time === 0) {
          await dispatch(updateTestAttempt({ id: testAttempt.id.toString() }));
        }
      });

      const activeTestAttempt =
        testAttempts &&
        testAttempts.find(
          (testAttempt: ContentViewerTestDefinitionTestAttemptPayloadIndexResponse) =>
            testAttempt.state === 'in_progress' && testAttempt.remaining_time !== 0
        );

      activeTestAttempt
        ? setRemainingTime(activeTestAttempt.remaining_time)
        : setRemainingTime(timeLimit);

      !activeTestAttempt ? setShowTestQuestions(false) : setShowTestQuestions(true);

      const lastSubmittedTestResults =
        testAttempts && !activeTestAttempt && testAttempts.length > 0
          ? testAttempts[testAttempts.length - 1]
          : undefined;

      lastSubmittedTestResults && setShowTestResults(true);
      setTestLoading(false);
    };

    getTestAttempts(testDefinitionId, contentParticipationId);

    return () => {
      setShowTestQuestions(false);
      setShowTestResults(false);
    };
  }, [contentParticipationId, testDefinitionId, dispatch, setTestLoading, timeLimit, stepId]);
  const isLoading = contentLoading || testLoading || resultsLoading;

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      {showTestNotCompletedModal && (
        <ContentViewerModal
          isFullScreen={true}
          modalWidth={450}
          title={t('contentViewer.testViewer.testLeaveModal.title')}
          explanation={t('contentViewer.testViewer.testLeaveModal.explanation')}
          enterKeyText={t('contentViewer.testViewer.testLeaveModal.action')}
          enterKeyClass={'tw-bg-darkerRed tw-text-white tw-border-none'}
          onEnterKeyPress={submitTest}
          escapeKeyText={t('contentViewer.testViewer.testLeaveModal.cancel')}
          showModal={showTestNotCompletedModal}
          setShowModal={setShowTestNotCompletedModal}
        />
      )}
      {!showTestQuestions && !showTestResults && (
        <TestIntro
          testDefinition={testDefinition}
          setShowTestQuestions={setShowTestQuestions}
          contentParticipationId={contentParticipationId}
          enforceStudyPreviousSteps={enforceStudyPreviousSteps}
          contentLoading={isLoading}
          notCompletedPreviousSteps={notCompletedPreviousSteps}
          isStartTestAllowed={isStartTestAllowed}
          handleNavigateToPreviousStep={handleNavigateToPreviousStep}
          handleNavigateToNextStep={handleNavigateToNextStep}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
        />
      )}
      {showTestQuestions && !showTestResults && (
        <>
          {timeLimit && (
            <TestTimer
              timeLimit={remainingTime}
              setRemainingTime={setRemainingTime}
              testAttemptId={testAttemptId}
              setShowTestResults={setShowTestResults}
              finishTestAndCompleteStep={finishTestAndCompleteStep}
            />
          )}
          <Test
            disableKeyboardControls={showTestNotCompletedModal}
            contentParticipationId={contentParticipationId}
            testDefinitionId={testDefinitionId}
            setShowTestResults={setShowTestResults}
            activeTestAttempt={activeTestAttempt}
            setResultsLoading={setResultsLoading}
            contentLoading={isLoading}
            finishTestAndCompleteStep={finishTestAndCompleteStep}
          />
        </>
      )}
      {showTestResults && (
        <TestEvaluation
          handleNavigateToNextStep={handleNavigateToNextStep}
          setShowTestResults={setShowTestResults}
          isLastStep={isLastStep}
          setShowTestQuestions={setShowTestQuestions}
          contentLoading={isLoading}
          showCourseIncompleteModal={showCourseIncompleteModal}
          isStartTestAllowed={isStartTestAllowed}
          remainingTime={remainingTime}
        />
      )}
    </>
  );
};
