import {CompanySpacesContentGetRequest, ContentApi} from "../generated-api";
import {API_CONFIG} from "app/api-config";
import {createAsyncThunk} from "@reduxjs/toolkit";

const contentApi = new ContentApi(API_CONFIG);

export const fetchContent = createAsyncThunk('content/get', async (args: { id: string }) => {
    return await contentApi.companySpacesContentIdGet(args);
});

export const fetchContents = createAsyncThunk('content/list', async (args: CompanySpacesContentGetRequest) => {
    return await contentApi.companySpacesContentGet(args);
});
