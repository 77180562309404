import { Middleware, ResponseContext } from '../generated-api';
import { ApiSerializedError } from '../model/api';
import {AppRequestInit} from "store";

const priorityHttpStatusCodes = [401, 403];

export function fetchErrorMiddlewareFactory(): Middleware {
  return {
    post: async (context: ResponseContext): Promise<ResponseContext['response'] | void> => {
      // check API ERROR in payload
      if (context.response.status === 200 && context.response.bodyUsed) {
        // console.log('200');
        const json = await context.response.clone().json();
        // console.log({ json });
        if (json.result === 'ERROR') {
          const err = {
            code: json.message,
            message: json.messageValues.error,
          };
          throw err;
        }
      }

      if (context.response.status >= 200 && context.response.status < 300) {
        return context.response;
      }

      let err: ApiSerializedError;
      let json: any;
      try {
        json = await context.response.json();
        const status = context.response.status;
        err = {
          code: priorityHttpStatusCodes.includes(status)
            ? `${status}`
            : json?.code || context.response.status,
          message: json?.message || undefined,
        };
      } catch (e: any) {
        err = {
          code: `${context.response.status}`,
          message: undefined,
        };
      }
      if (err.code && context.init) {
        const overrideCallback = (context.init as AppRequestInit).ignoreErrorCodes?.[err.code];
        if (overrideCallback !== undefined) {
          // suppressed
          if (overrideCallback) {
            overrideCallback(json);
          }
          return {
            ...context.response, status: 200, json: async () => {
              return {map: () => {}} // will always transform to empty object
            }
          };
        }
      }

      console.log(
        '[API] Error:',
        context.url,
        {
          body: context.init.body,
          status: context.response.status,
          ok: context.response.ok,
        },
        err
      );

      if (context.response?.status === 404) {
        window.location.href = '#/notFound';
      }
      throw err;
    },
  };
}
