import { useAppTranslation } from 'services/i18n';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { SupportChat } from 'components/chat/SupportChat';
import { useEffect } from 'react';

interface Props {
  setShowTopMenu: (showTopMenu: boolean) => void;
}

export const NavMenuChat = ({ setShowTopMenu }: Props) => {
  const t = useAppTranslation();

  const string = SupportChat.isHidden
    ? t('contentViewer.nav.openChat')
    : t('contentViewer.nav.closeChat');

  const icon = SupportChat.isHidden ? (
    <ChatBubbleIcon style={{ color: '#757575' }} />
  ) : (
    <ChatBubbleOutlineIcon style={{ color: '#757575' }} />
  );

  const onChatClick = () => {
    SupportChat.isHidden ? SupportChat.openChat() : SupportChat.hideChat();
    setShowTopMenu(false);
  };

  useEffect(() => {
    SupportChat.hideChat();
  }, []);

  return (
    <button
      className="tw-flex tw-h-52 tw-w-full tw-items-center tw-gap-20 tw-px-16 tw-pt-5 hover:tw-bg-black/5"
      onClick={onChatClick}
      type="button"
    >
      {icon}
      {string}
    </button>
  );
};
