import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Alert, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {AddOutlined, RemoveOutlined, WarningOutlined} from "@mui/icons-material";
import {LearningEventDay, UserGroup} from "pages/UserGroupsPage";
import {DataGridItemActions} from "components/DataGrid";
import Button from "@mui/material/Button";
import {ContentManagerUserGroupDashboardContentResponseLearningEvent, DayCreate, DayUpdate} from "generated-api";
import {UserGroupLearningEventDayDialog} from "components/userGroup/UserGroupLearningEventDayDialog";
import {deleteLearningEventDay, fetchUserGroup} from "store/userGroup";
import {isApiResultError} from "../../helpers/api";
import {addMessage} from "store/localApp";
import {useModal} from "services/modal";
import {datetimeToGui, dateToGui, timeToGui} from "../../helpers/date";
import {CheckboxPlain} from "components/form/CheckboxFormField";
import {SxProps} from "@mui/system";

interface LearningEvent extends ContentManagerUserGroupDashboardContentResponseLearningEvent {
    // with days
}

const tableStyle: SxProps = {
    '& > .MuiTableRow-root .MuiTableCell-root': {
        color: 'var(--color-black-text)',
        '&:first-of-type': {
            width: '45px',
            maxWidth: '45px',
            'button': {
                width: 'auto',
                minWidth: 'unset'
            }
        }
    },
    '& > .MuiTableRow-root:first-of-type .MuiTableCell-root': {
        background: 'var(--color-black80)',
        color: 'var(--color-white)',
        'svg': {
            color: 'inherit'
        }
    },
    '& > .MuiTableRow-root:nth-of-type(2) .MuiTableCell-root': {
        borderBottom: '1px solid #8D8D8D',
    },
    '& + .MuiTableBody-root': {
        borderTop: '12px solid white'
    }
};

const UserGroupLearningEventDay = ({day, dayNo, onRemoveDay, onEditDay, hasMassActions}: {
    day: LearningEventDay,
    dayNo: number,
    onRemoveDay: (day: LearningEventDay) => void,
    onEditDay: (day: LearningEventDay) => void,
    hasMassActions: boolean,
}) => {
    const t = useAppTranslation();
    // const dispatch = useAppDispatch();

    const [isCollapsed, setIsCollapsed] = useState(true);

    return <TableBody sx={tableStyle}>
        <TableRow>
            <TableCell>
                <Button color={'inherit' as any} variant={'text'}
                    onClick={() => setIsCollapsed(!isCollapsed)}>
                    {isCollapsed ? <AddOutlined/> : <RemoveOutlined/>}
                </Button>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.day')}</strong>
                <div>{t('userGroup.days.day')} {dayNo}</div>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.date')}</strong>
                <div>{dateToGui(day.start_date)}</div>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.time')}</strong>
                <div>{timeToGui(day.start_date, 'HH:mm')} - {timeToGui(day.end_date, 'HH:mm')}</div>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.form')}</strong>
                <div>{t('userGroup.form.' + day.form)}</div>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.location')}</strong>
                {!!day.physical_location && <div>{day.physical_location}</div>}
                {!!day.online_location && <div>{day.online_location}</div>}
            </TableCell>
            <TableCell sx={{width: '30%'}}>
                <strong>{t('userGroup.days.lectors')}</strong>
                <div>{day.lecturers?.map(u => u.full_name).join(", ")}</div>
            </TableCell>
            <DataGridItemActions item={day} isActionMenu actions={[
                {title: 'userGroup.days.editDay', callback: onEditDay},
                {title: 'userGroup.days.removeDay.action', callback: onRemoveDay, color: 'error'},
            ]}/>
        </TableRow>
        {!isCollapsed && <TableRow>
            <TableCell>
                {hasMassActions && <CheckboxPlain name={'toggleAll'}/>}
            </TableCell>
            <TableCell>{t('userGroup.days.name')}</TableCell>
            <TableCell>{t('userGroup.days.contentFulfilled')}</TableCell>
            <TableCell>{t('userGroup.days.courseProgress')}</TableCell>
            <TableCell>{t('userGroup.days.testProgress')}</TableCell>
            <TableCell>{t('userGroup.days.attendance')}</TableCell>
            <TableCell>{t('userGroup.days.note')}</TableCell>
            <TableCell></TableCell>
        </TableRow>}

        {!isCollapsed && <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={7}>
                <div>{t('common.emptyList')}</div>
            </TableCell>
        </TableRow>}
        {/*{day.*/}
        {/*    ?.filter(p => isSearchWithinSubject(search, p.full_name))*/}
        {/*    ?.map((day, i) => <TableRow key={i}>*/}
        {/*        <TableCell>*/}
        {/*            {hasMassActions && <CheckboxPlain name={'toggleRow' + i}/>}*/}
        {/*        </TableCell>*/}
        {/*        <TableCell>{day.full_name}</TableCell>*/}
        {/*        <TableCell>{day.email}</TableCell>*/}
        {/*        <TableCell colSpan={7}></TableCell>*/}
        {/*        <DataGridItemActions item={day} isActionMenu actions={[*/}
        {/*            {title: 'userGroup.days.removeUser.action', callback: onRemoveDay},*/}
        {/*            {title: 'userGroup.days.showUser', callback: onEditDay}*/}
        {/*        ]}/>*/}
        {/*    </TableRow>)}*/}
    </TableBody>
}

export const UserGroupDaysTab = ({userGroup}: { userGroup: UserGroup }) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const [learningEvent, setLearningEvent] = useState<LearningEvent>();
    const [editDay, setEditDay] = useState<DayCreate | DayUpdate>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchEvent = useCallback(async () => {
        if (!userGroup.learning_event?.id) {
            return;
        }
        setIsLoading(true);
        try {
            // const res = await dispatch(fetchLearningEvent({id: String(userGroup.learning_event.id)}));
            // if (!isApiResultError(res)) {
            //     setLearningEvent(res.payload as LearningEvent);
            // }
            const res = await dispatch(fetchUserGroup({id: String(userGroup.id)}));
            if (!isApiResultError(res)) {
                setLearningEvent((res.payload as UserGroup).learning_event);
            }
        } finally {
            setIsLoading(false);
        }
    }, [userGroup, dispatch]);

    const handleAddDay = useCallback(async () => {
        if (!learningEvent?.id) {
            return;
        }
        setEditDay({});
    }, [learningEvent?.id]);

    const handleEditDay = useCallback(async (day: LearningEventDay) => {
        if (!learningEvent?.id) {
            return;
        }
        setEditDay(day);
    }, [learningEvent?.id, dispatch]);

    const handleRemoveDay = useCallback(async (day: LearningEventDay) => {
        if (!learningEvent?.id) {
            return;
        }
        const result = await modal.confirm({
            title: t('userGroup.days.removeDay.title'),
            message: t('userGroup.days.removeDay.body', {start_date: datetimeToGui(day.start_date), end_date: timeToGui(day.end_date)}),
            confirmText: t('userGroup.days.removeDay.button')
        });
        if (result !== 'CONFIRM') {
            return;
        }
        await dispatch(deleteLearningEventDay({learningEventId: learningEvent.id, id: '' + day.id}));
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.days.removeDay.success')
        }));
        await fetchEvent();
    }, [learningEvent?.id, dispatch]);

    useEffect(() => {
        fetchEvent().then();
    }, [fetchEvent]);

    if (learningEvent === undefined) {
        return <LinearProgress/>;
    }

    const hasMassActions = false;

    return <Grid container sx={{background: '#fff'}}>
        <Grid item xs={12} className={'tw-relative'}>
            {isLoading && <LinearProgress className={'tw-absolute tw-left-0 tw-right-0'}/>}
            {!learningEvent?.days?.length && <Alert severity={'warning'}
                icon={<WarningOutlined/>}>
                {t('userGroup.days.empty')}
            </Alert>}
            <TableContainer sx={{padding: '16px 24px'}}>
                <Table sx={{'th.MuiTableCell-head, th, td': {padding: '4px 4px', border: 'none', '.MuiFormControlLabel-root': {margin: 0}}}}>
                    {learningEvent?.days?.map((day, i) => <UserGroupLearningEventDay key={i}
                        dayNo={i + 1}
                        day={day}
                        // onAddDay={handleAddDay}
                        onRemoveDay={handleRemoveDay}
                        onEditDay={handleEditDay}
                        hasMassActions={hasMassActions}
                    />)}
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Button color={'dark' as any} variant={'text'}
                                    onClick={handleAddDay}
                                    className={'tw-w-auto tw-normal-case'}>
                                    <AddOutlined/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{t('userGroup.days.addDay')}
                                </Button>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        {!!editDay && learningEvent?.id &&
            <UserGroupLearningEventDayDialog
                learningEventId={learningEvent.id}
                dayId={(editDay as LearningEventDay).id}
                day={editDay}
                onSuccess={async () => {
                    await fetchEvent()
                }}
                onClose={() => {
                    setEditDay(undefined);
                }}/>}
    </Grid>;
}
