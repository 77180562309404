import { Configuration } from '../generated-api';
import { store } from '../index';
import { appFetchApiFactory, RunningApiInfo } from '../services/appFetch';
import { fetchErrorMiddlewareFactory } from '../services/fetchErrorMiddleware';
import { getLocale } from '../services/i18n';
import { xsrfTokenMiddleware } from '../services/xsrfTokenMiddleware';
import { setAppApiRunning } from '../store/localApp';
import { apiBasePath } from './config';

export const API_CONFIG = configurationFactory();

export function configurationFactory(overrides?: { apiBasePath?: string }) {
  return new Configuration({
    basePath: overrides?.apiBasePath || apiBasePath(getLocale()),
    fetchApi: appFetchApiFactory((info: RunningApiInfo, active: boolean) =>
      store.dispatch(setAppApiRunning({ info, active }))
    ),
    middleware: [fetchErrorMiddlewareFactory(), xsrfTokenMiddleware()],
    credentials: 'include',
  });
}
