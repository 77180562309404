import clsx from 'clsx';
import { useAppTranslation } from 'services/i18n';
import { ContentLayoutWithControls } from './ContentLayoutWithControls';
import { useMediaQuery } from '@mui/material';
import { Link } from 'components/clickable/Link';

import { ReactComponent as FullLogo } from 'icons/logo-full-white.svg';

export const NotFoundPage = () => {
  const t = useAppTranslation();

  const isLandscape = useMediaQuery('(orientation: landscape)');

  return (
    <ContentLayoutWithControls>
      <div
        className={clsx(
          'tw-mx-auto tw-flex tw-h-full tw-w-full tw-justify-around tw-px-16 tw-py-16 lg:tw-w-4/5 lg:tw-px-0',
          isLandscape ? 'tw-flex-row tw-gap-16 lg:tw-gap-64' : 'tw-flex-col tw-gap-0'
        )}
      >
        <section className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-32 tw-overflow-auto">
          <FullLogo className="tw-w-300 tw-fill-black" />
          <h1 className="tw-text-24 tw-font-semibold">{t(`contentViewer.404.notFound`)}</h1>
          <Link
            kind="primary"
            href="#/dashboard"
            className="tw-rounded-xl tw-py-10 tw-px-16 tw-text-16"
          >
            {t(`contentViewer.404.returnToDashboard`)}
          </Link>
        </section>
      </div>
    </ContentLayoutWithControls>
  );
};
