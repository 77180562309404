import { Middleware, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { t } from '../services/i18n';
import { getApiErrorMessage } from '../helpers/api';
import { addMessage } from '../store/localApp';

import airbrake from 'utils/airbrake';
export const apiErrorMiddlewareFactory: () => Middleware =
  () => (store) => (next) => (action: PayloadAction<never, any, any, SerializedError>) => {
    if (action.error && !action.meta.rejectedWithValue && action.error.code !== 'abort') {
      const message = getApiErrorMessage(action, t);

      if (!airbrake) console.warn('Missing airbrake id or key');
      const state = store.getState();
      const userId = state.auth?.user?.id;

      airbrake?.notify({
        error: action.error,
        params: { ...action, userId },
      });

      // console.log('apiErrorMiddleware', action, message);
      if (message) {
        store.dispatch(
          addMessage({
            severity: 'error',
            title: message,
          })
        ); // dispatch an addMessage action
      }
    }

    return next(action);
  };
