import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../store';
import {selectBilling} from '../store/selectors';
import {Box, Grid, LinearProgress, Paper, Typography} from "@mui/material";
import {BillingState, fetchSubscription} from "../store/billing";
import {PageHeader} from "../components/PageHeader";
import {useAppTranslation} from "../services/i18n";
import {CheckCircleRounded, EventNoteRounded, PaidRounded, PeopleAltRounded, SchoolRounded} from "@mui/icons-material";
import {dateDiff, dateToGui} from "../helpers/date";
import {PageTabs} from "../components/PageTabs";

const BillingTitle = ({label}: { label: string }) => {
    return <Typography component={'h2'} paragraph={false} sx={{
        fontWeight: "bold",
        margin: "0 0 24px 0",
        fontSize: "20px",
    }}>{label}</Typography>
}

type BillingCardProps = {
    icon: JSX.Element,
    label: string,
    content: any,
    sub?: any
}

const BillingCard = ({icon, label, content, sub}: BillingCardProps) => {
    return <Grid item xs={30} sm={15} md={10} lg={10} xl={6}>
        <Box sx={{
            border: "1px solid #CCCCCC",
            borderRadius: "16px",
            padding: "16px 20px",
            "& > svg": {
                color: (theme) => theme.palette.primary.main,
                marginBottom: "12px"
            },
            "& > h3": {
                fontSize: "14px",
                color: "#595959"
            },
            "& > strong": {
                fontSize: "18px",
                lineHeight: "130%",
            },
            "& > strong > small": {
                fontSize: "12px",
                lineHeight: "140%",
                color: "#595959",
                fontWeight: 400,
                paddingLeft: "8px"
            }
        }}>
            {icon}
            <Typography component={'h3'} paragraph={false}>{label}</Typography>
            <strong>{content}{sub !== undefined ? <small>({sub})</small> : null}</strong>
        </Box>
    </Grid>
}


export const BillingPage: React.FC = () => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const {subscription} = useSelector(selectBilling) as BillingState;
    const {plan, subscription_cycle} = subscription || {};

    const [isLoading, setIsLoading] = useState(true);

    const handleFetchBilling = useCallback(() => {
        setIsLoading(true);
        dispatch(fetchSubscription()).then(() => {
            setIsLoading(false);
        });

    }, [dispatch]);

    const renderTab = useCallback((children: JSX.Element) => {
        return <Paper sx={{p: 3, boxShadow: "unset", borderRadius: 0, border: "1px solid #F5F5F5", flexGrow: "1"}}>
            {children}
        </Paper>
    }, []);

    useEffect(() => {
        handleFetchBilling();
    }, [dispatch, handleFetchBilling]);

    const overview = () => {
        let content: JSX.Element;

        if (isLoading) {
            content = <LinearProgress/>;
        } else if (subscription == null) {
            content = <i>{t('billing.overview.noSubscription')}</i>
        } else {
            let remainingLicenseCount = plan!.prepaid_user_count - (subscription?.active_user_count || 0)

            content = <Grid container spacing={2} columns={30}>
                <BillingCard
                    icon={<PaidRounded/>}
                    label={t('billing.overview.currentPlan')}
                    content={subscription?.plan?.name || ''}
                />

                {subscription_cycle?.end_date && <BillingCard
                    icon={<EventNoteRounded/>}
                    label={t('billing.overview.billingPeriod')}
                    content={<>{dateToGui(subscription_cycle.start_date)} - {dateToGui(subscription_cycle.end_date)}</>}
                />}

                {subscription?.expires_on && !(subscription_cycle?.end_date) && <BillingCard
                    icon={<EventNoteRounded/>}
                    label={t('billing.overview.validUntil')}
                    content={t('billing.overview.validDays', {count: dateDiff(new Date(), subscription.expires_on)})}
                    sub={dateToGui(subscription.expires_on)}
                />}

                {(subscription?.active_user_count || subscription?.active_user_count === 0) && <BillingCard
                    icon={<CheckCircleRounded/>}
                    label={t('billing.overview.actUserCount')}
                    content={subscription?.active_user_count}
                />}

                {(plan?.prepaid_user_count || plan?.prepaid_user_count === 0) && <BillingCard
                    icon={<PeopleAltRounded/>}
                    label={t('billing.overview.subUserCount')}
                    content={plan?.prepaid_user_count}
                />}

                {(plan?.prepaid_user_count || plan?.prepaid_user_count === 0) && <BillingCard
                    icon={<SchoolRounded/>}
                    label={t('billing.overview.remLicenceCount')}
                    content={remainingLicenseCount}
                    sub={subscription?.subscription_cycle?.next_reconciliation_date && remainingLicenseCount < 0
                        ? t('billing.overview.nextBillingOn', {nextBillingDate: dateToGui(subscription.subscription_cycle.next_reconciliation_date)})
                        : undefined}
                />}
            </Grid>
        }

        return renderTab(<>
            <BillingTitle label={t('billing.overview.title')}/>
            {content}
        </>);
    }
    const invoices = () => {
        return renderTab(<i dangerouslySetInnerHTML={{__html: t('billing.invoices.text')}}/>);
    }
    const plans = () => {
        return renderTab(<i dangerouslySetInnerHTML={{__html: t('billing.plans.text')}}/>);
    }

    return <div className={'layout-fill layout-column'}>
        <div className={'kws-content-margin flex tw-flex tw-flex-col'}>
            <PageHeader icon={<EventNoteRounded/>} title={t('billing.title')}/>
            <PageTabs tabs={[
                {
                    tab: '',
                    label: t('billing.overview.title'),
                    content: overview
                },
                {
                    tab: 'invoices',
                    label: t('billing.invoices.title'),
                    content: invoices
                },
                {
                    tab: 'plans',
                    label: t('billing.plans.title'),
                    content: plans
                }
            ]}/>
        </div>
    </div>;
};
