import React from 'react';
import { Clickable } from './Clickable';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';

interface LinkProps {
  className?: string;
  to?: string;
  href?: string;
  disabled?: boolean;
  children: React.ReactNode;
  kind: 'primary' | 'outlined' | 'bare';
  ariaLabel?: string;
  openInNewWindow?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disableFocus?: boolean;
  scrollIntoView?: boolean;
}

export const Link: React.FC<LinkProps> = ({
  className,
  to,
  href,
  disabled,
  children,
  kind,
  ariaLabel,
  openInNewWindow,
  onClick,
  loading,
  disableFocus,
  scrollIntoView,
}) => {
  const stylesByKind = {
    primary: `${
      disabled ? 'tw-bg-lightGray' : 'tw-cursor-pointer tw-bg-green hover:tw-bg-darkGreen'
    } tw-text-white tw-font-medium tw-rounded-lg tw-text-center tw-block`,
    outlined: `tw-box-border tw-text-darkestGray tw-font-medium tw-rounded-lg tw-border tw-border-lightGray ${
      disabled ? '' : 'tw-cursor-pointer hover:tw-border-darkestGray tw-text-center tw-block'
    }`,
    bare: '',
  };
  return (
    <Clickable
      className={clsx(stylesByKind[kind], className)}
      to={to}
      href={href}
      disabled={disabled}
      ariaLabel={ariaLabel}
      openInNewWindow={openInNewWindow}
      onClick={!loading ? onClick : undefined}
      disableFocus={disableFocus}
      scrollIntoView={scrollIntoView}
    >
      {loading ? <CircularProgress color="primary" size={36} /> : children}
    </Clickable>
  );
};
