import {useAppTranslation} from "services/i18n";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select
} from "@mui/material";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {TextFormFieldPlain} from "components/form/TextFormField";
import {CheckboxPlain} from "components/form/CheckboxFormField";
import {isSearchWithinSubject} from "components/DataGrid";
import {useAppDispatch} from "store";
import {fetchCatalogueCategories, fetchCatalogues} from "store/catalogues";
import {CatalogueCategoryType, CatalogueType} from "helpers/editorTest";
import {Link} from "components/clickable/Link";
import {sortByTitle} from "utils/utils";

interface Props {
  currentCategoryIds: number[];
  onSuccess: (categoryIds: number[]) => unknown;
  onClose: () => unknown;
}

export const EditorTestCatalogueCategoriesDialog = (props: Props) => {
  const {currentCategoryIds, onSuccess, onClose} = props;

  const dispatch = useAppDispatch();
  const t = useAppTranslation();

  const [catalogues, setCatalogues] = useState<CatalogueType[] | undefined>();
  const [activeCatalogueId, setActiveCatalogueId] = useState<number | undefined>();
  const [categories, setCategories] = useState<CatalogueCategoryType[] | undefined>();

  const [search, setSearch] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [clickCount, setClickCount] = useState<number>(0);

  const handleFetchCatalogues = useCallback(async () => {
    const catalogueItems = (await dispatch(fetchCatalogues({})))?.payload as CatalogueType[];
    setCatalogues(catalogueItems);
    if (catalogueItems[0]?.id) {
      setActiveCatalogueId(catalogueItems[0].id)
    }
  }, [dispatch]);

  const handleFetchCategories = useCallback(async (catalogueId: number) => {
    setCategories(undefined);
    const items = (await dispatch(fetchCatalogueCategories({questionCatalogueId: catalogueId})))?.payload as CatalogueCategoryType[];
    setCategories(items);
  }, [dispatch]);

  const handleSave = useCallback(() => {
    onSuccess(selectedIds);
  }, [selectedIds, onSuccess]);

  const createItems = useCallback((categories: CatalogueCategoryType[], items: JSX.Element[]) => {
    categories
      .sort(sortByTitle)
      .forEach((category) => {
        if (search && !isSearchWithinSubject(search, category.title)) {
          return;
        }
        const isAlready = currentCategoryIds.indexOf(category.id) >= 0;
        const isSelected = isAlready || selectedIds.indexOf(category.id) >= 0;
        items.push(<Box
          key={category.id} className={'tw-flex tw-flex-row tw-items-center tw-justify-items-stretch'}>
          <div className={'tw-pr-24 tw-flex tw-self-center'}>
            <CheckboxPlain
              name={'cat-' + category.id}
              currentValue={isSelected}
              onChange={() => {
                const pos = selectedIds.indexOf(category.id);
                if (pos >= 0) {
                  selectedIds.splice(pos, 1);
                } else {
                  selectedIds.push(category.id);
                }
                setSelectedIds(selectedIds.sort());
                setClickCount(clickCount + 1);
              }}
              disabled={isAlready}
              color={isAlready ? 'info' : undefined}
            />
          </div>
          <p className={'tw-flex tw-w-full'}>{category.title}</p>
        </Box>)
      });

  }, [currentCategoryIds, clickCount, search, selectedIds]);

  const categoryItems = useMemo(() => {
    if (categories === undefined) {
      return <Box className={'tw-h-100'}><LinearProgress/></Box>;
    }

    const top = categories.filter(q => selectedIds.indexOf(q.id) >= 0 || currentCategoryIds.indexOf(q.id) >= 0);
    const bottom = categories.filter(q => !top.find(t => t.id === q.id));

    const items: JSX.Element[] = [];
    createItems(top, items);
    createItems(bottom, items);
    return items;

  }, [categories, selectedIds, currentCategoryIds, createItems]);

  useEffect(() => {
    if (activeCatalogueId) {
      handleFetchCategories(activeCatalogueId).then();
    }
  }, [activeCatalogueId, handleFetchCategories]);

  useEffect(() => {
    handleFetchCatalogues().then();
  }, [handleFetchCatalogues]);

  return (
    <Dialog open={true} onClose={onClose} PaperComponent={Paper}>
      <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
        <span>{t('catalogues.questions.form.categorySearch')}</span>
        <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
      </DialogTitle>
      <DialogContent>
        {catalogues === undefined && <LinearProgress/>}
        {catalogues?.length === 0 && <>
            <p>{t('editorTest.catalogueCategories.emptyCatalogues')}</p>
            <Link
                kind="bare"
                className="tw-mb-8 tw-pt-8 tw-pb-10 tw-pl-32 tw-pr-32 tw-inline-block tw-rounded-lg tw-border tw-border-dark tw-bg-dark tw-text-center tw-text-white"
                href={"/#/catalogues"}
            >
              {t(`editorTest.catalogueCategories.goToCatalogue`)}
            </Link>
        </>}
        {!!catalogues?.length && <Grid container className={'tw-mt-0'} spacing={2}>
            <Grid item xs={12}>
              {!!catalogues?.length && <FormControl size="small">
                  <Select
                      displayEmpty={true}
                      value={activeCatalogueId}
                      onChange={(e, v) => {
                        setActiveCatalogueId(+e.target.value);
                      }}>
                    {catalogues?.map((c, i) => {
                      return <MenuItem key={i} value={c.id}>{c.title}</MenuItem>;
                    })}
                  </Select>
              </FormControl>}
            </Grid>
          {!!categories?.length && <Grid item xs={12}>
              <TextFormFieldPlain
                  name={'search'}
                  currentValue={search}
                  onChange={setSearch}
                  label={t('catalogues.categories.searchTitle')}
                  placeholder={t('catalogues.categories.searchPlaceholder')}
              />
          </Grid>}
            <Grid item xs={12}>
              {categories !== undefined && !categories.length && <>
                  <p>{t('editorTest.catalogueCategories.emptyCategories')}</p>
                  <Link
                      kind="bare"
                      className="tw-mb-8 tw-pt-8 tw-pb-10 tw-pl-32 tw-pr-32 tw-inline-block tw-rounded-lg tw-border tw-border-dark tw-bg-dark tw-text-center tw-text-white"
                      href={"/#/catalogues/" + activeCatalogueId + "/categories"}
                  >
                    {t(`editorTest.catalogueCategories.goToCategories`)}
                  </Link>
              </>}
              {categoryItems}
            </Grid>
        </Grid>}
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={onClose}>
          {t('form.close')}
        </Button>
        {!!activeCatalogueId && !!selectedIds.length && <Button color={'primary'} onClick={handleSave}>
          {t('editorTest.catalogueCategories.saveCategories')}
        </Button>}
      </DialogActions>
    </Dialog>
  );
};
