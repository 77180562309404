import { Middleware } from '../generated-api';

const XSRF_TOKEN_HEADER = 'X-XSRF-TOKEN';

export function xsrfTokenMiddleware(): Middleware {
  return {
    pre: (context) => {
      const cookieMatch = document.cookie?.match(/XSRF-TOKEN=([^;]+)/);
      const xsrfToken = (cookieMatch && cookieMatch[1]) || undefined;

      const headersAddon = xsrfToken
        ? {
            [XSRF_TOKEN_HEADER]: xsrfToken,
          }
        : null;

      return Promise.resolve({
        init: {
          ...context.init,
          headers: {
            ...headersAddon,
            ...context.init.headers,
          },
        },
        url: context.url,
      });
    },
  };
}
