import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from 'i18next';
import { useAppTranslation } from 'services/i18n';
import { Link } from 'components/clickable/Link';
import { Tag } from 'components/Tag';
import { Markdown } from 'components/Markdown';
import {
  ContentViewerShowResponseContentVersionLessonsInner,
  ContentViewerShowResponseContentVersionMeta,
} from 'generated-api';
import { getLocale } from 'services/i18n';
import { ContentLayoutWithControls } from './ContentLayoutWithControls';
import { useMediaQuery } from '@mui/material';

interface ProgressCardProps {
  progress: number;
  completedAt?: Date;
  confirmedAt?: Date;
  actionUrl: string;
  firstLessonUrl: string;
  courseState: string;
  handleShowCertificate: () => void;
}

export const ProgressCard = ({
  progress,
  completedAt,
  confirmedAt,
  actionUrl,
  firstLessonUrl,
  courseState,
  handleShowCertificate,
}: ProgressCardProps) => {
  const dashboardUrl = '#/dashboard';

  const t = useAppTranslation();

  const navigate = useNavigate();

  const inProgress = courseState === 'in_progress' && progress > 0 && progress < 100;
  const noProgress = progress === 0;
  const done = progress === 100;

  return (
    <div className="tw-mt-16 tw-w-280 tw-shrink-0 tw-self-center tw-rounded-lg tw-bg-white tw-p-20 lg:tw-mt-0">
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-8">
        <div>
          <h3 className="tw-text-20 tw-font-semibold">
            {noProgress && t(`contentViewer.intro.welcome`)}
            {inProgress && t(`contentViewer.intro.yourProgress`, { progress })}
            {done && t(`contentViewer.intro.courseDone`)}
          </h3>
          <span className="tw-text-14">
            {noProgress && t(`contentViewer.intro.getStarted`)}
            {inProgress && t(`contentViewer.intro.goodStart`)}
            {done &&
              completedAt?.toLocaleDateString(getLocale(), {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
          </span>
        </div>

        {/* progress ring */}
        <div className="tw-relative tw-inline-flex tw-items-center tw-justify-center tw-text-lighterGray">
          <CircularProgress variant="determinate" color="inherit" size={56} value={100} />
          <CircularProgress
            variant="determinate"
            size={56}
            value={progress}
            sx={{
              position: 'absolute',
            }}
          />
          <span className="tw-absolute tw-top-0 tw-left-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-14 tw-font-semibold tw-text-green">
            {progress}%
          </span>
        </div>
      </div>

      <div className="tw-mt-20"></div>

      {done && (
        <Link kind="outlined" className="tw-mb-8 tw-h-48 tw-w-full tw-py-12" to={firstLessonUrl}>
          {t(`contentViewer.intro.actionButton.goToCourse`)}
        </Link>
      )}
      <Link
        kind="primary"
        className="tw-flex tw-h-48 tw-w-full tw-items-center tw-justify-center"
        onClick={confirmedAt ? handleShowCertificate : () => navigate(actionUrl)}
      >
        {noProgress && t(`contentViewer.intro.actionButton.start`)}
        {inProgress && t(`contentViewer.intro.actionButton.continue`)}
        {done && t(`contentViewer.intro.actionButton.showCertificate`)}
      </Link>
      <Link
        kind="outlined"
        className="tw-mb-8 tw-mt-12 tw-block tw-h-48 tw-w-full tw-rounded-lg tw-border tw-border-lightGray tw-bg-lightestGray tw-py-12 tw-text-center tw-text-16 tw-font-bold tw-text-darkerGray"
        href={dashboardUrl}
      >
        {t(`contentViewer.intro.actionButton.goToDashboard`)}
      </Link>
    </div>
  );
};

const { t } = i18n;
t('contentViewer.intro.contentType.course');
t('contentViewer.intro.contentType.document');
t('contentViewer.intro.contentType.test');

interface CourseIntroProps {
  meta: ContentViewerShowResponseContentVersionMeta;
  lessons: ContentViewerShowResponseContentVersionLessonsInner[];
  progress: number;
  contentType?: string;
  completedAt?: Date;
  confirmedAt?: Date;
  currentLessonId?: number;
  currentStepId?: number;
  contentLoading?: boolean;
  courseState: string;
}

export const CourseIntro = ({
  meta,
  progress,
  contentType,
  completedAt,
  confirmedAt,
  lessons,
  currentLessonId,
  currentStepId,
  contentLoading,
  courseState,
}: CourseIntroProps) => {
  const t = useAppTranslation();
  const { name, description } = meta;
  const isLandscape = useMediaQuery('(orientation: landscape)');

  const inProgress = courseState === 'in_progress' && progress > 0 && progress < 100;
  const noProgress = progress === 0;
  const done = progress === 100;

  const { courseId } = useParams();
  const navigate = useNavigate();

  const firstLessonUrl = `/learn/${courseId}/lesson/${lessons[0].id}`;

  const actionUrl = done
    ? `/learn/${courseId}/certificate`
    : noProgress
    ? firstLessonUrl
    : `/learn/${courseId}/lesson/${currentLessonId}/step/${currentStepId}`;

  const handleStartCourse = () => {
    return navigate(done ? firstLessonUrl : actionUrl);
  };

  const handleShowCertificate = () => {
    courseId &&
      window.open(`api/v1/content_viewer/participations/${courseId}/certificate.html`, '_blank');
  };

  return (
    <ContentLayoutWithControls
      contentLoading={contentLoading}
      rightKeyText={
        noProgress
          ? t(`contentViewer.intro.actionButton.start`)
          : inProgress
          ? t(`contentViewer.intro.actionButton.continue`)
          : t(`contentViewer.intro.actionButton.showCertificate`)
      }
      onRightKeyPress={
        done
          ? confirmedAt
            ? handleShowCertificate
            : () => navigate(`/learn/${courseId}/certificate`)
          : handleStartCourse
      }
    >
      <div
        className={clsx(
          'tw-mx-auto tw-flex tw-h-full tw-w-full tw-justify-around tw-px-16 tw-py-16 lg:tw-w-4/5 lg:tw-px-0',
          isLandscape ? 'tw-flex-row tw-gap-16 lg:tw-gap-64' : 'tw-flex-col tw-gap-0'
        )}
      >
        <section className="tw-my-auto tw-space-y-16 tw-overflow-auto">
          <Tag>{t(`contentViewer.intro.contentType.${contentType}`)}</Tag>
          <h1 className="tw-text-30 tw-font-semibold">{name}</h1>
          <Markdown className="markdown">{description}</Markdown>
        </section>

        <ProgressCard
          progress={progress}
          completedAt={completedAt}
          confirmedAt={confirmedAt}
          actionUrl={actionUrl}
          firstLessonUrl={firstLessonUrl}
          courseState={courseState}
          handleShowCertificate={handleShowCertificate}
        />
      </div>
    </ContentLayoutWithControls>
  );
};
