import { ShowAnswersInTestResult } from 'utils/types';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import clsx from 'clsx';
import { ResponseType, QuestionType } from '../../utils/types';
import {
  ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInner,
  ContentViewerTestDefinitionTestAttemptPayloadUpdateResponseShowAnswersInTestResultEnum,
} from 'generated-api';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
import { ReactComponent as FailedIcon } from 'icons/failed.svg';
import { InputLabel } from '../test/InputLabel';
import { useAppTranslation } from 'services/i18n';

interface Props {
  showAnswersInTestResult?: ContentViewerTestDefinitionTestAttemptPayloadUpdateResponseShowAnswersInTestResultEnum;
  question: ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInner;
}

export const TestSolutuon = ({ showAnswersInTestResult, question }: Props) => {
  const t = useAppTranslation();
  const { question_type: questionType, answer, response_type: responseType, choices } = question;
  const isImageResponse = responseType === ResponseType.Image;
  const showAllAnswers = showAnswersInTestResult === ShowAnswersInTestResult.OnlyIncorrect;
  const showAllAnswersWithSolution = showAnswersInTestResult === ShowAnswersInTestResult.All;
  const isSingle = questionType === QuestionType.Single;
  const isSingleOrMultiple =
    questionType === QuestionType.Single || questionType === QuestionType.Multiple;
  const correctChoices = choices?.filter((choice) => Boolean(choice.is_correct));

  return (
    <div className="tw-flex tw-flex-col tw-gap-10 tw-border-t tw-border-black/5 tw-py-12 tw-px-10">
      {isSingleOrMultiple && showAllAnswersWithSolution && (
        <div className="tw-mb-10 tw-flex">
          <Icon isChecked={true} isCorrect={true} showAllAnswers={showAllAnswers} />
          <span className="tw-mr-10 tw-flex tw-w-40 tw-items-center tw-justify-center tw-font-semibold">
            {t(`contentViewer.testViewer.testEvaluation.you`)}
          </span>
          <span className="tw-flex tw-items-center tw-text-12">
            {t(`contentViewer.testViewer.testEvaluation.info`)}
          </span>
        </div>
      )}
      {isSingleOrMultiple &&
        choices?.map((choice, ix) => {
          const { value, id, is_correct: isCorrect, explanation, file } = choice;
          const selectedChoiceId = isSingle && answer?.selected_choices[0]?.choice_id;
          const selectedChoiceIds = answer?.selected_choices.map((choice) => choice.choice_id);
          const isChecked = isSingle
            ? selectedChoiceId === choice.id
            : selectedChoiceIds?.includes(choice.id);

          const isAnsweredCorrectly = isChecked && isCorrect;
          const isAnsweredIncorrectly = isChecked && !isCorrect;

          const isMissed = showAllAnswersWithSolution
            ? !isChecked && correctChoices?.includes(choice)
            : undefined;
          const letterFromNumber = String.fromCharCode(64 + ix + 1);

          return (
            <div
              className={clsx(
                'tw-flex tw-min-h-42 tw-items-start',
                isImageResponse ? 'tw-flex-row tw-gap-10' : 'tw-flex-col tw-justify-center'
              )}
              key={id}
            >
              <FormControlLabel
                value={id}
                control={
                  <Icon
                    isChecked={isChecked}
                    isCorrect={isCorrect}
                    showAllAnswers={showAllAnswers}
                  />
                }
                label={
                  <InputLabel
                    textValue={value}
                    label={letterFromNumber}
                    isCorrect={
                      showAllAnswersWithSolution
                        ? isAnsweredCorrectly
                          ? true
                          : isAnsweredIncorrectly
                          ? false
                          : undefined
                        : undefined
                    }
                    isMissed={isMissed}
                  />
                }
                disabled
              />
              <div>
                {isImageResponse && (
                  <div className="-tw-ml-10 tw-mb-8 tw-h-100 tw-w-100 tw-shrink-0 tw-rounded-lg tw-border tw-border-black/5 tw-bg-white tw-p-10">
                    <img
                      src={file?.download_url}
                      alt=""
                      className="tw-h-full tw-w-full tw-object-contain"
                    />
                  </div>
                )}
                {explanation && (
                  <p
                    className={clsx(
                      'tw-my-6 -tw-ml-10 tw-rounded-md tw-bg-black/5 tw-py-4 tw-px-10 tw-text-12 tw-text-gray',
                      !isImageResponse && 'tw-ml-40'
                    )}
                  >
                    {explanation}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      {questionType === QuestionType.Matching && (
        <section className="tw-flex tw-flex-wrap tw-gap-12">
          {choices?.map((choice) => {
            const selectedChoice = answer?.selected_choices.find(
              (selectedChoice) => selectedChoice.choice_id === choice.id
            );

            const remainingItems = choice.payload?.filter(
              (item) => !selectedChoice?.payload?.includes(item)
            );

            return (
              <div className="tw-grow tw-rounded-lg tw-border tw-border-black/5 " key={choice.id}>
                <p className="tw-p-16 tw-text-16 tw-font-semibold">{choice.value}</p>
                <div className="tw-flex tw-flex-col tw-gap-8 tw-border-t tw-border-black/5 tw-p-16">
                  {selectedChoice?.payload?.map((matchedItem, index) => {
                    const isCorrect = choice?.payload?.includes(matchedItem);

                    return (
                      <div
                        key={index}
                        className={clsx(
                          showAllAnswersWithSolution
                            ? isCorrect
                              ? 'tw-bg-lightGreen'
                              : 'tw-bg-red'
                            : 'tw-bg-black/5',
                          'tw-flex tw-w-fit tw-items-center tw-gap-6 tw-rounded-xl tw-px-12 tw-py-8 tw-text-14',
                          showAllAnswers ? 'tw-text-black' : 'tw-text-white'
                        )}
                      >
                        {showAllAnswersWithSolution && (
                          <Icon
                            isCorrect={isCorrect}
                            showAllAnswers={showAllAnswers}
                            type="matching"
                          />
                        )}
                        {matchedItem}
                      </div>
                    );
                  })}
                  {showAllAnswersWithSolution &&
                    remainingItems?.length > 0 &&
                    remainingItems.map((item, index) => (
                      <div
                        key={index}
                        className={clsx(
                          'tw-flex tw-w-fit tw-items-center tw-gap-6 tw-rounded-xl tw-bg-black/5 tw-px-12 tw-py-8 tw-text-14 tw-text-black'
                        )}
                      >
                        {item}
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </section>
      )}
    </div>
  );
};

interface IconProps {
  isChecked?: boolean | undefined;
  isCorrect: boolean;
  type?: 'matching';
  showAllAnswers: boolean;
}

const Icon = ({ isChecked, isCorrect, type, showAllAnswers }: IconProps) => {
  if (showAllAnswers) {
    return <Radio checked={isChecked} disabled className="tw-shrink-0" />;
  } else {
    if (isCorrect) {
      return (
        <div
          className={clsx(
            'tw-flex tw-h-20 tw-w-20 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full',
            type === 'matching' ? 'tw-bg-white' : 'tw-bg-lightGreen',
            type !== 'matching' && 'tw-mx-12 '
          )}
        >
          <CheckIcon
            className={clsx(type === 'matching' ? 'tw-stroke-lightGreen' : 'tw-stroke-white')}
          />
        </div>
      );
    }
    if ((isChecked && !isCorrect) || (isCorrect === false && type === 'matching')) {
      return (
        <div
          className={clsx(
            'tw-flex tw-h-20 tw-w-20 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full',
            type === 'matching' ? 'tw-bg-white' : 'tw-bg-red',
            type !== 'matching' && 'tw-mx-12 '
          )}
        >
          <FailedIcon className={clsx(type === 'matching' ? 'tw-stroke-red' : 'tw-stroke-white')} />
        </div>
      );
    }
  }
  return <Radio disabled />;
};
