import {useAppTranslation} from "services/i18n";
import * as React from "react";
import {ComponentType, Offer} from "pages/PublicOfferPage";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper} from "@mui/material";
import {CheckCircle, Close, Warning} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const style = {
    fontSize: '14px',
    margin: 0,
    padding: 0,
    '& p': {
        margin: '4px 0 0 0',
    },
    '& h2': {
        fontWeight: '500',
        fontSize: 'inherit',
        margin: 0,
        padding: 0,
        '& > svg': {
            fontSize: '140%',
            verticalAlign: 'text-bottom',
        }
    }
}

const successStyle = {
    ...style,
    '& > h2': {
        color: 'var(--color-success)'
    }
}

const warningStyle = {
    ...style,
    '& > h2': {
        color: 'var(--color-warning)'
    },
}

const tableStyle = {
    '& > .MuiGrid-item': {
        paddingTop: '16px',
        '&:first-of-type': {
            paddingTop: '32px'
        }
    }
}

const OfferCompletionComponent = ({name, components}: { name: keyof ComponentType, components: ComponentType }) => {
    const t = useAppTranslation();

    const status = components[name];

    return <Grid item xs={12} sx={status ? successStyle : warningStyle}>
        <h2>{status ? <CheckCircle/> : <Warning/>} {t('offer.completion.component.' + name + '.title')}</h2>
        <p dangerouslySetInnerHTML={{__html: t('offer.completion.component.' + name + '.' + (status ? 'success' : 'warning'), {link: 'https://www.knowspread.com/trziste?category_ids[]=15'})}}/>
    </Grid>
}

export const OfferCompletionDialog = ({offer, onClose}: {
    offer: Offer,
    onClose: () => void
}) => {
    const t = useAppTranslation();

    const components = offer.evaluation?.components;

    return <Dialog open={true} onClose={onClose} PaperComponent={Paper} maxWidth={'md'}>
        <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
            <span>{t('offer.completion.title')}</span>
            <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
        </DialogTitle>
        <DialogContent sx={{paddingBottom: 0}}>
            <Grid container spacing={2} sx={tableStyle}>
                {!!components && Object.keys(components)
                    .map((name, i) =>
                        <OfferCompletionComponent key={i} name={name as keyof ComponentType} components={components}/>)}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={'inherit'} onClick={onClose}>
                {t('form.close')}
            </Button>
        </DialogActions>
    </Dialog>
};
