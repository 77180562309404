import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Grid} from "@mui/material";
import {useAppDispatch} from "store";
import {useAppTranslation} from "services/i18n";
import {fetchCatalogueCategories} from "store/catalogues";
import {ContentEditorQuestionCataloguesQuestionCatalogueCategoryIndexResponse} from "generated-api";
import {CategoryAddForm} from "components/catalogue/CategoryAddForm";
import {
  createCol,
  DataGrid,
  DataGridCol,
  DataGridMode,
  DataGridState,
  isSearchWithinSubject,
  ItemsState,
  OrderDirType
} from "components/DataGrid";
import {TextFormField} from "components/form/TextFormField";
import {Edit} from "@mui/icons-material";
import {CategoryEditForm} from "components/catalogue/CategoryEditForm";
import {useNavigate} from "react-router-dom";

interface CategoryType extends ContentEditorQuestionCataloguesQuestionCatalogueCategoryIndexResponse {

}

interface GridFilter {
  search?: string;
}

const filterCallback = (filter: GridFilter, item: CategoryType): boolean => {
  return isSearchWithinSubject(filter.search, item.title);
};

const defaultState: DataGridState<CategoryType, GridFilter> = {
  orderCol: 'title',
  orderDir: OrderDirType.ASC,
  filter: {
    search: '',
  },
  filterCallback,
};

interface Props {
  catalogueId: number;
  categoryId?: number;
  onActionButtons: (actions?: JSX.Element) => void;
}

export const CategoryList = (props: Props) => {
  const {catalogueId, categoryId, onActionButtons} = props;

  const dispatch = useAppDispatch();
  const t = useAppTranslation();
  const navigate = useNavigate();

  const [dataGridState, setDataGridState] = useState(defaultState);
  const [itemsState, setItemsState] = useState<ItemsState<CategoryType>>({items: [], isLoading: true});

  const [isOpenAddForm, setIsOpenAddForm] = useState(false);

  const handleFetchItems = useCallback(async () => {
    setItemsState(s => ({...s, isLoading: true}));

    const items = (await dispatch(fetchCatalogueCategories({questionCatalogueId: catalogueId})))?.payload as CategoryType[];
    setItemsState({items, isLoading: false});
    return items;

  }, [catalogueId, dispatch]);

  const handleOpenAddForm = useCallback(() => {
    setIsOpenAddForm(true);
  }, []);

  const handleCloseAddForm = useCallback((category?: CategoryType) => {
    if (category?.id) {
      navigate('/catalogues/' + catalogueId + '/categories/' + category?.id);
    }
    setIsOpenAddForm(false);
  }, [catalogueId, navigate]);

  const handleCloseEditForm = useCallback(() => {
    navigate('/catalogues/' + catalogueId + '/categories');
  }, [catalogueId, navigate]);

  const cols: DataGridCol<CategoryType>[] = useMemo(() => [
    createCol(t('catalogues.categories.table.name'), 'title'),
    // createCol(t('catalogues.categories.table.testCount'), 'user_count'),
    // createCol(t('catalogues.categories.table.questionCount'), 'user_count'),
  ], [t]);

  const createFilter = useCallback((formProps?: any) => {
    return <Grid container spacing={4} className={'tw-pb-16'}>
      {!!formProps && <Grid item md={6} xs={12}>
          <TextFormField
              name={'search'}
              label={t('catalogues.categories.searchTitle')}
              placeholder={t('catalogues.categories.searchPlaceholder')}
          />
      </Grid>}
      <Grid item xs={12}>
        <h3>{t('catalogues.categories.title')}</h3>
        <Button onClick={handleOpenAddForm} color={'primary'} variant={'contained'}>
          {t('catalogues.categories.add')}
        </Button>
      </Grid>
    </Grid>;
  }, [handleOpenAddForm, t]);

  const handleActionEdit = useCallback((c: CategoryType) => {
    navigate('/catalogues/' + catalogueId + '/categories/' + c.id);
  }, [catalogueId, navigate])

  const actions = useMemo(() => [
    // {
    //     title: 'catalogues.questions.delete.action',
    //     icon: <DeleteForever/>,
    //     callback: handleQuestionRemove,
    // },
    {
      title: 'form.update',
      icon: <Edit/>,
      callback: handleActionEdit,
    },
  ], [handleActionEdit]);

  useEffect(() => {
    if (!categoryId) {
      handleFetchItems().then();
    }
  }, [categoryId, handleFetchItems]);

  if (categoryId) {
    return <CategoryEditForm
      id={categoryId}
      catalogueId={catalogueId}
      onSuccess={handleCloseEditForm}
      onCancel={handleCloseEditForm}
      onActionButtons={onActionButtons}
    />;
  }

  return <>
    <DataGrid
      createFilter={createFilter}
      cols={cols}
      state={dataGridState}
      setState={setDataGridState}
      itemsState={itemsState}
      mode={DataGridMode.CLIENT}
      emptyListMessage={t('catalogues.categories.emptyList')}
      emptySearchMessage={t('catalogues.categories.emptySearch')}
      actions={actions}
      noTopBorder
      absoluteScroll
    />
    {isOpenAddForm && (
      <CategoryAddForm
        catalogueId={catalogueId}
        onSuccess={handleCloseAddForm}
        onClose={handleCloseAddForm}
      />
    )}
  </>;
}
