import React, { ErrorInfo } from 'react';
import { Notifier } from '@airbrake/browser';
import airbrake from 'utils/airbrake';

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props> {
  airbrake?: Notifier;
  constructor(props: Props) {
    super(props);
    this.airbrake = airbrake;
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (!this.airbrake) console.warn('Missing airbrake id or key');
    this.airbrake?.notify({
      error: error,
      params: { info: info },
    });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
