import React, { useRef } from 'react';
import { Field } from 'formik';
import { InputLabel } from '../test/InputLabel';
import clsx from 'clsx';

interface Props {
  textValue: string;
  value: string;
  name: string;
  isChecked: boolean;
  index: number;
  isImage: boolean;
  setFocusedChoiceId: (focusedChoiceId: number | null) => void;
  imageUrl?: string;
}

export const RadioField = ({
  textValue,
  value,
  name,
  index,
  isImage,
  isChecked,
  setFocusedChoiceId,
  imageUrl,
}: Props) => {
  const letterFromNumber = String.fromCharCode(64 + index + 1);
  // Disabling radio input default behavior reacting on ArrowUp/ArrowDown/Space keys as it conflicts with required behavior
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === ' ') {
      event.preventDefault();
    }
  };

  return (
    <div
      className={'tw-flex tw-cursor-pointer tw-items-center tw-gap-16'}
      onClick={() => inputRef.current?.click()}
    >
      {isImage && (
        <div
          className={clsx(
            'image-field tw-h-150 tw-w-150 tw-rounded-lg tw-border tw-border-black/5 tw-bg-white tw-p-10 lg:tw-h-200 lg:tw-w-200',
            isChecked && 'tw-outline tw-outline-[4px] tw-outline-green'
          )}
        >
          <img src={imageUrl} alt="" className="tw-h-full tw-w-full tw-object-contain" />
        </div>
      )}
      <Field
        id={`radio-${name}-${value}`}
        type="radio"
        name={name}
        value={value}
        innerRef={inputRef}
        onKeyDown={handleKeyDown}
        className={clsx(
          'radio-field tw-h-20 tw-w-20 tw-shrink-0 tw-appearance-none tw-rounded-full tw-border tw-border-solid tw-border-lightGray tw-bg-white tw-accent-lightGreen checked:tw-border-0 checked:tw-bg-lightGreen '
        )}
        onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFocusedChoiceId(Number(e.target.value))
        }
      />

      <InputLabel
        textValue={textValue}
        label={letterFromNumber}
        isChecked={isChecked}
        className={clsx(isImage && 'tw-hidden')}
      />
    </div>
  );
};
