import { DialogContent } from '@mui/material';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import IconButton from '@mui/material/IconButton';
import { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Modal } from 'components/modal/Modal';

interface Props {
  isFullScreen: boolean;
  modalWidth?: number;
  title: string;
  explanation: string;
  missingItemsInfo?: string;
  missingItems?: JSX.Element[];
  enterKeyText: string;
  enterKeyClass?: string;
  onEnterKeyPress: () => void;
  escapeKeyText?: string;
  spaceKeyText?: string;
  onSpaceKeyPress?: () => void;
  showModal?: boolean;
  setShowModal?: (showModal: boolean) => void;
}

export const ContentViewerModal = ({
  isFullScreen,
  modalWidth,
  title,
  explanation,
  showModal,
  setShowModal,
  enterKeyText,
  enterKeyClass,
  onEnterKeyPress,
  escapeKeyText,
  spaceKeyText,
  onSpaceKeyPress,
  missingItems,
  missingItemsInfo,
}: Props) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      if (key === 'Enter' && onEnterKeyPress) onEnterKeyPress();
      if (key === ' ' && onSpaceKeyPress) onSpaceKeyPress();
    },
    [onEnterKeyPress, onSpaceKeyPress]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Modal
      isFullScreen={isFullScreen}
      showModal={showModal}
      setShowModal={setShowModal}
      modalWidth={modalWidth}
    >
      <ModalContent
        isFullScreen={isFullScreen}
        title={title}
        explanation={explanation}
        enterKeyText={enterKeyText}
        enterKeyClass={enterKeyClass}
        onEnterKeyPress={onEnterKeyPress}
        escapeKeyText={escapeKeyText}
        spaceKeyText={spaceKeyText}
        onSpaceKeyPress={onSpaceKeyPress}
        missingItems={missingItems}
        missingItemsInfo={missingItemsInfo}
        setShowModal={setShowModal}
      />
    </Modal>
  );
};

interface ModalContentProps {
  isFullScreen: boolean;
  title: string;
  explanation: string;
  missingItemsInfo?: string;
  missingItems?: JSX.Element[];
  enterKeyText: string;
  enterKeyClass?: string;
  onEnterKeyPress: () => void;
  escapeKeyText?: string;
  spaceKeyText?: string;
  onSpaceKeyPress?: () => void;
  setShowModal?: (showModal: boolean) => void;
}

const ModalContent = ({
  isFullScreen,
  title,
  explanation,
  setShowModal,
  enterKeyText,
  enterKeyClass,
  onEnterKeyPress,
  escapeKeyText,
  spaceKeyText,
  onSpaceKeyPress,
  missingItems,
  missingItemsInfo,
}: ModalContentProps) => {
  return (
    <DialogContent>
      <div className="tw-mb-8 tw-flex tw-w-full tw-items-start tw-justify-between">
        <h2 className="tw-mb-8 tw-text-20 tw-font-semibold">{title}</h2>
        {isFullScreen && setShowModal && (
          <IconButton onClick={() => setShowModal(false)}>
            <CloseIcon width={16} height={16} />
          </IconButton>
        )}
      </div>
      <h3 className="tw-mb-20 tw-text-16 tw-font-normal tw-text-gray">{explanation}</h3>
      {missingItems && (
        <>
          <p className="tw-mt-0 tw-mb-10 tw-text-12 tw-font-semibold">{missingItemsInfo}</p>
          <div className="tw-mb-20 tw-flex tw-flex-wrap tw-gap-8">{missingItems}</div>
        </>
      )}
      <div className="tw-flex tw-justify-between tw-gap-16">
        {(escapeKeyText || spaceKeyText) && setShowModal && (
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-gap-8 tw-rounded-lg tw-border tw-border-solid tw-border-lightGray tw-py-12 tw-px-14"
            onClick={
              escapeKeyText ? () => setShowModal(false) : spaceKeyText ? onSpaceKeyPress : undefined
            }
          >
            <p className="tw-m-0 tw-font-medium">{escapeKeyText || spaceKeyText}</p>
            <div className="tw-hidden tw-rounded tw-bg-black/5 tw-px-5 tw-py-2 tw-text-12 tw-text-gray lg:tw-block">
              {escapeKeyText ? 'Esc' : 'Space'}
            </div>
          </div>
        )}
        {enterKeyText && (
          <div
            className={clsx(
              'tw-flex tw-grow tw-cursor-pointer tw-items-center tw-justify-center tw-gap-8 tw-rounded-lg tw-border tw-border-solid tw-border-lightGray tw-py-12 tw-px-14 lg:tw-justify-between',
              enterKeyClass
            )}
            onClick={onEnterKeyPress}
          >
            <p className="tw-m-0 tw-font-medium">{enterKeyText}</p>
            <div className="tw-hidden tw-rounded tw-bg-black/5 tw-px-6 tw-py-2 tw-text-12 lg:tw-block">
              Enter
            </div>
          </div>
        )}
      </div>
    </DialogContent>
  );
};
