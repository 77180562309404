import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Formik, FormikProps} from "formik";
import {QuestionType, VersionsType} from "../../helpers/editorTest";
import {useAppTranslation} from "services/i18n";
import {ContentEditorShowResponseLessonsInnerStepsInner} from "generated-api";
import {Button, Chip, Grid} from "@mui/material";
import {TextFormField} from "components/form/TextFormField";
import {SwitchPlain} from "components/form/CheckboxFormField";
import {StepsModalDialog} from "components/editorTest/StepsModalDialog";


interface EditorTestQuestionFormFieldsProps {
  title?: JSX.Element;
  formikProps: FormikProps<QuestionType>;
  stepId?: number;
  testHasSteps?: boolean;
  question: QuestionType;
  questionNo?: number;
  versions?: VersionsType;
  disabled: boolean;
}

const EditorTestQuestionFormFields = (props: EditorTestQuestionFormFieldsProps) => {
  const {
    formikProps, stepId, testHasSteps,
    question, questionNo,
    versions,
    disabled, title
  } = props;
  const {values, setFieldValue, handleSubmit} = formikProps;

  const t = useAppTranslation();
  const [questionState, setQuestionState] = useState<QuestionState>({
    hintNeeded: !!question.hint,
  });

  const availableSteps = useMemo(() => {
    const steps: ContentEditorShowResponseLessonsInnerStepsInner[] = [];
    versions?.lessons?.forEach((lesson) => {
      return lesson.steps?.forEach((step) => {
        steps.push(step);
      });
    });
    return steps;
  }, [versions]);

  const autoSubmit = useCallback(() => setTimeout(() => handleSubmit(), 100), [handleSubmit]); // "next tick" to pickup latest values

  const handleHintNeededToggle = useCallback((v: any) => {
    setQuestionState((s) => ({...s, hintNeeded: v}));
    if (!v) {
      setFieldValue('hint', '');
      autoSubmit();
    }
  }, [setFieldValue, autoSubmit]);

  useEffect(() => {
    if (!testHasSteps && (values.dependent_step_ids?.length || 0) > 0) {
      setFieldValue('dependent_step_ids', []);
      autoSubmit();
    }

  }, [testHasSteps, values.dependent_step_ids?.length, setFieldValue, autoSubmit]);

  return <>
    <form onSubmit={handleSubmit}>
      <Grid container columns={100}>
        <Grid item lg={10} md={20} className={'question-settings tw-items-center tw-flex'}>
          <span className={'options tw-mb-0 tw-mr-10'}>{t('editorTest.placeholder')}</span>
          {title}
        </Grid>
        <Grid item lg={90} md={80}
              className={'question-settings tw-grid-flow-row tw-justify-end tw-items-center tw-flex'}>
          <div className={'tw-grid-flow-row tw-items-center tw-flex'}>
            <span className={'question_label'} style={{whiteSpace: 'nowrap'}}>{t('editorTest.labels.pointValue')}</span>
            <div className={'tw-pl-3 tw-pr-3'}>
              <TextFormField name={'point_value'} type={'number'} minValue={1} maxValue={100} maxlength={3}
                             disabled={disabled} onChange={autoSubmit}/>
            </div>
          </div>
          <div className={'tw-grid-flow-row tw-items-center tw-flex'}>
            <span className={'question_label'}>{t('editorTest.labels.hint')}</span>
            <SwitchPlain name={'hintNeeded'} type={'switch'}
                         currentValue={questionState.hintNeeded}
                         onChange={handleHintNeededToggle}
                         disabled={disabled}/>
          </div>
        </Grid>
        <Grid item md={100} className={'tw-mt-8'}>
          <TextFormField name={'name'} label={!!questionNo
            ? t('editorTest.labels.question', {index: questionNo})
            : t('editorTest.placeholder')}
                         type={'textarea'} minRows={1} maxRows={100} onChange={autoSubmit}
                         disabled={disabled} required/>
        </Grid>
        {!!testHasSteps && <Grid item md={100} className={'tw-pt-18 tw-flex tw-justify-center tw-items-start'}>
            <dl className="kws-definition-list tw-flex tw-basis-full tw-flex-col tw-mr-8">
                <dt>{t('editorTest.dependentSteps.label')}</dt>
                <dd className="chips-readonly-height-fix tw-items-end tw-pb-8"
                    style={{borderBottom: '1px solid rgba(0,0,0,0.12)'}}>
                  {availableSteps
                    .filter(s => values.dependent_step_ids && values.dependent_step_ids.indexOf(s.id) >= 0)
                    .map((s, i) => {
                      const lesson = versions?.lessons?.find((l) => l.id === s.lesson_id);
                      return <Chip key={i} label={lesson?.position + '.' + s.position + ' ' + s.name}
                                   sx={{margin: '8px 8px 0 0'}}/>
                    })}
                </dd>
            </dl>
          {!disabled && <Button onClick={() => setQuestionState((s) => ({...s, stepsModalOpen: true}))}
                                color={'primary'} variant={'contained'} className={'tw-min-w-fit tw-mt-28'}>
            {(values.dependent_step_ids?.length || 0) > 0 ? t('editorTest.dependentSteps.edit') : t('editorTest.dependentSteps.add')}
          </Button>}
        </Grid>}
        {questionState.hintNeeded && <Grid item md={100} className={testHasSteps ? undefined : 'tw-pt-18'}>
            <TextFormField name={'hint'} label={t('editorTest.labels.hint')}
                           type={'textarea'} minRows={1} maxRows={100} onChange={autoSubmit}
                           disabled={disabled}/>
        </Grid>}

      </Grid>
    </form>
    {!!questionState.stepsModalOpen && versions && stepId && <StepsModalDialog
        stepIds={values.dependent_step_ids || []}
        versions={versions}
        currentStepId={stepId}
        onStepsUpdate={(stepIds) => {
          setFieldValue('dependent_step_ids', stepIds);
          autoSubmit();
        }}
        onClose={() => setQuestionState((s) => ({...s, stepsModalOpen: false}))}
    />}
  </>;
}

interface EditorTestQuestionFormProps {
  title?: JSX.Element;
  stepId?: number;
  testHasSteps?: boolean;
  question: QuestionType;
  questionNo?: number;
  versions?: VersionsType;
  onQuestionChange: (q: QuestionType) => void;
  disabled: boolean;
}

interface QuestionState {
  hintNeeded: boolean;
  stepsModalOpen?: boolean;
}

export const EditorTestQuestionForm = (props: EditorTestQuestionFormProps) => {

  const {question, onQuestionChange} = props;

  const validate = useCallback((values: QuestionType) => {
    return {};
  }, []);

  return <Formik
    initialValues={question}
    onSubmit={onQuestionChange}
    validate={validate}
  >
    {(formikProps) => <EditorTestQuestionFormFields formikProps={formikProps} {...props} />}
  </Formik>;
}