import clsx from 'clsx';
import { Collapsible } from 'components/Collapsible';
import { useAppTranslation } from 'services/i18n';

import { Link } from 'components/clickable/Link';

import type {
  ContentViewerShowResponseContentVersionLessonsInner,
  ContentViewerShowResponseContentVersionLessonsInnerStudyMaterialsInner,
} from 'generated-api';

import { ReactComponent as Paper } from 'icons/paper.svg';
import { ReactComponent as Hyperlink } from 'icons/hyperlink.svg';

interface StudyMaterialLinkProps {
  name: string;
  href?: string;
  type: 'regular' | 'secondary';
  contentType: 'hyperlink' | 'file' | string;
}

export const StudyMaterialLink = ({ name, href, type, contentType }: StudyMaterialLinkProps) => {
  return (
    <Link
      kind="bare"
      href={href}
      openInNewWindow
      className="tw-flex tw-items-center tw-justify-between"
    >
      <span
        className={clsx(
          'tw-text-left',
          type === 'regular'
            ? 'tw-text-14 tw-text-white'
            : 'tw-text-12 tw-leading-5 tw-text-lightGray'
        )}
      >
        {name}
      </span>
      {contentType === 'hyperlink' ? (
        <Hyperlink className="tw-ml-8 tw-flex-shrink-0" />
      ) : (
        <Paper className="tw-ml-8 tw-flex-shrink-0" />
      )}
    </Link>
  );
};

interface SidebarStudyMaterialsProps {
  studyMaterials: ContentViewerShowResponseContentVersionLessonsInnerStudyMaterialsInner[];
  lessons: ContentViewerShowResponseContentVersionLessonsInner[];
  currentLessonId?: number;
}

export const SidebarStudyMaterials = ({
  studyMaterials,
  lessons,
  currentLessonId,
}: SidebarStudyMaterialsProps) => {
  const t = useAppTranslation();

  return (
    <>
      <div className="tw-border-t tw-border-t-white/20 tw-pt-16">
        <div className="tw-mt-16 tw-mb-24 tw-px-24">
          <h2 className="tw-mb-16 tw-text-20 tw-font-semibold tw-text-white">
            {t('contentViewer.sidebar.studyMaterials.title')}
          </h2>
          {!!studyMaterials?.length ? (
            <div className="tw-flex tw-flex-col tw-gap-16 tw-rounded-lg tw-bg-white/5 tw-p-16">
              <span className="tw-text-10 tw-text-white/60">
                {t('contentViewer.sidebar.studyMaterials.commonCourseMaterials')}
              </span>
              {studyMaterials?.map(({ name, id, study_material_type, hyperlink, file }) => (
                <StudyMaterialLink
                  href={study_material_type === 'file' ? file?.download_url : hyperlink}
                  contentType={study_material_type}
                  name={name}
                  type="regular"
                  key={id}
                />
              ))}
            </div>
          ) : (
            <span className="tw-mb-16 tw-text-14 tw-text-lighterGray">
              {t('contentViewer.sidebar.studyMaterials.noStudyMaterials')}
            </span>
          )}
        </div>

        {/* Collapsible lessons */}

        {lessons?.map((lesson) => {
          const isInCurrentLesson = lesson.id === currentLessonId;

          const numberOfMaterialsForLesson = lesson.study_materials?.length;
          const numberOfMaterialsInSteps = lesson?.steps
            ?.map((step) => step?.study_materials?.map((material) => material))
            .flat().length;

          const totalNumberOfMaterialsInLesson =
            numberOfMaterialsInSteps !== undefined &&
            numberOfMaterialsForLesson !== undefined &&
            numberOfMaterialsInSteps + numberOfMaterialsForLesson;

          if (totalNumberOfMaterialsInLesson) {
            return (
              <Collapsible
                key={lesson.id}
                defaultCollapsed={!isInCurrentLesson}
                className={clsx(
                  'tw-border-b tw-border-white/20 tw-py-16',
                  isInCurrentLesson && 'tw-bg-white/5'
                )}
                heading={
                  <div className="tw-pl-24">
                    <h4 className="tw-inline tw-align-middle tw-text-white">
                      {lesson.position}. {lesson.name}
                    </h4>{' '}
                    <span className="tw-text-12 tw-text-lightGray">
                      ({totalNumberOfMaterialsInLesson})
                    </span>
                  </div>
                }
              >
                <div className="tw-space-y-16 tw-px-24 tw-py-16">
                  {!!lesson.study_materials?.length && (
                    <div className="tw-flex tw-flex-col tw-gap-16">
                      <span className="tw-text-10 tw-text-white/60">
                        {t('contentViewer.sidebar.studyMaterials.lessonMaterials')}
                      </span>

                      {/* Lesson study materials */}
                      {lesson.study_materials.map(
                        ({ name, hyperlink, study_material_type, id, file }) => (
                          <StudyMaterialLink
                            href={study_material_type === 'file' ? file?.download_url : hyperlink}
                            contentType={study_material_type}
                            name={name}
                            type="regular"
                            key={id}
                          />
                        )
                      )}
                    </div>
                  )}

                  {/* Step study materials */}
                  {!!numberOfMaterialsInSteps && (
                    <div className="tw-flex tw-flex-col tw-gap-16">
                      <span className="tw-text-10 tw-text-white/60">
                        {t('contentViewer.sidebar.studyMaterials.stepMaterials')}
                      </span>

                      {lesson?.steps?.map((step) => {
                        if (!!step?.study_materials?.length) {
                          return (
                            <div className="tw-space-y-10" key={step.id}>
                              <span className="tw-text-14 tw-text-white">
                                {lesson.position}.{step.position}. {step.name}
                              </span>

                              {step.study_materials?.map(
                                ({ name, hyperlink, study_material_type, file, id }) => (
                                  <StudyMaterialLink
                                    key={id}
                                    contentType={study_material_type}
                                    href={
                                      study_material_type === 'file'
                                        ? file?.download_url
                                        : hyperlink
                                    }
                                    name={name}
                                    type="secondary"
                                  />
                                )
                              )}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  )}
                </div>
              </Collapsible>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};
