import {Formik, FormikErrors, useFormikContext} from 'formik';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import {
  fetchCatalogueCategory,
  fetchCatalogueQuestions,
  updateCatalogueCategory,
  updateCatalogueQuestion
} from '../../store/catalogues';
import {Box, Button, Grid, LinearProgress, Tooltip} from '@mui/material';
import {TextFormField} from '../form/TextFormField';
import {
  ContentEditorQuestionCataloguesQuestionCatalogueCategoryShowResponse,
  ContentEditorQuestionCataloguesQuestionIndexResponse,
  QuestionCatalogueCategoryUpdate
} from "generated-api";
import {isApiResultError} from '../../helpers/api';
import {
  createCol,
  DataGrid,
  DataGridCol,
  DataGridMode,
  DataGridState,
  isSearchWithinSubject,
  ItemsState,
  OrderDirType
} from "components/DataGrid";
import {datetimeToGui} from "../../helpers/date";
import {CategoryQuestionsDialog} from "components/catalogue/CategoryQuestionsDialog";
import {shorten} from "utils/utils";

interface QuestionType extends ContentEditorQuestionCataloguesQuestionIndexResponse {

}

interface GridFilter {
  search?: string
}

const filterCallback = (filter: GridFilter, item: QuestionType): boolean => {
  return isSearchWithinSubject(filter.search, item.name);
};

const questionsDefaultState: DataGridState<QuestionType, GridFilter> = {
  orderCol: 'name',
  orderDir: OrderDirType.ASC,
  filter: {
    search: ''
  },
  filterCallback
};

interface Props {
  catalogueId: number;
  id: number;
  onActionButtons: (actions?: JSX.Element) => void;
  onCancel: () => void;
  onSuccess: () => void;
}

interface FormFieldsProps extends Props {
  isSaving: boolean;
}

export const CategoryFormFields = () => {

  const t = useAppTranslation();

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextFormField name="title" label={t('catalogues.categories.form.title')} maxlength={100}/>
    </Grid>
  </Grid>;
}

const CategoryForm = (props: FormFieldsProps) => {
  const {onActionButtons, onCancel, isSaving} = props;

  const t = useAppTranslation();
  const {handleSubmit} = useFormikContext();

  const formSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    onActionButtons(<>
      <Button color={'inherit'} variant={'outlined'} disabled={isSaving} onClick={onCancel}>
        {t('catalogues.categories.form.back')}
      </Button>
      <Button color={'inherit'} variant={'contained'} disabled={isSaving} onClick={formSubmit}>
        {t('catalogues.categories.form.saveChanges')}
      </Button>
    </>);
    return () => {
      onActionButtons(undefined);
    }
  }, [onActionButtons, formSubmit, onCancel, isSaving, t]);

  return <form onSubmit={handleSubmit}>
    <CategoryFormFields/>
  </form>;
}

export const CategoryEditForm = (props: Props) => {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [category, setCategory] = useState<QuestionCatalogueCategoryUpdate | undefined>();

  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [isQuestionsModal, setIsQuestionsModal] = useState(false);

  const [savedQuestionIds, setSavedQuestionIds] = useState<number[]>([]);
  const [questionsDataGridState, setQuestionsDataGridState] = useState(questionsDefaultState);
  const [questionsItemState, setQuestionsItemState] = useState<ItemsState<QuestionType>>({
    items: [],
    isLoading: true
  });

  const {catalogueId, id, onSuccess} = props;
  const handleFetchItem = useCallback(async () => {
    const item = (await dispatch(fetchCatalogueCategory({
      questionCatalogueId: catalogueId,
      id: '' + id
    })))?.payload as ContentEditorQuestionCataloguesQuestionCatalogueCategoryShowResponse;
    if (item) {
      setCategory(item);
    }

    const questions = (await dispatch(fetchCatalogueQuestions({questionCatalogueId: catalogueId})))?.payload as QuestionType[];
    setQuestions(questions);
    if (item) {
      const currentQuestions = questions?.filter((q) => !!q.question_catalogue_categories?.find(c => c.id === item.id));
      setQuestionsItemState((s) => ({...s, items: currentQuestions, isLoading: false}));
      setSavedQuestionIds(currentQuestions?.map(q => q.id))
    }

  }, [catalogueId, id, dispatch]);

  const handleQuestionsModalOpen = useCallback(() => {
    setIsQuestionsModal(true);
  }, []);

  const handleQuestionsModalClose = useCallback(() => {
    setIsQuestionsModal(false);
  }, []);

  const handleQuestionsModalSuccess = useCallback((questionIds: number[]) => {
    setQuestionsItemState((s) => ({...s, items: questions.filter(c => questionIds.indexOf(c.id) >= 0)}))
    handleQuestionsModalClose();
  }, [questions, handleQuestionsModalClose]);

  const handleSaveItem = useCallback(async (values: QuestionCatalogueCategoryUpdate) => {
    setIsSaving(true);
    // save category
    const result = await dispatch(updateCatalogueCategory({
      questionCatalogueId: catalogueId,
      id: '' + id,
      body: values
    }));

    // remove obsolete questions
    const obsoleteIds = savedQuestionIds.filter(id => !questionsItemState.items.find(q => q.id === id));
    for (const questionId of obsoleteIds) {
      const q = questions.find(q => q.id === questionId);
      if (!q) {
        return;
      }
      await dispatch(updateCatalogueQuestion({
        id: '' + q.id,
        questionCatalogueId: catalogueId,
        body: {
          ...q,
          question_catalogue_id: catalogueId,
          question_catalogue_category_ids: q.question_catalogue_categories?.filter(c => c.id !== id).map(c => c.id)
        }
      }))
    }

    // add new questions
    const addedQuestions = questionsItemState.items.filter(q => savedQuestionIds.indexOf(q.id) < 0);
    for (const q of addedQuestions) {
      await dispatch(updateCatalogueQuestion({
        id: '' + q.id,
        questionCatalogueId: catalogueId,
        body: {
          ...q,
          question_catalogue_id: catalogueId,
          question_catalogue_category_ids: [...(q.question_catalogue_categories?.map(c => c.id) || []), id]
        }
      }))
    }

    setIsSaving(false);

    if (!isApiResultError(result)) {
      onSuccess();
    }

  }, [savedQuestionIds, questionsItemState.items, questions, catalogueId, id, onSuccess, dispatch]);

  const handleValidate = useCallback((values: QuestionCatalogueCategoryUpdate) => {
    let errors = {} as FormikErrors<QuestionCatalogueCategoryUpdate>;
    if (!values.title) {
      errors.title = t('catalogues.categories.form.error.titleRequired');
    }
    return errors;
  }, [t]);

  const questionCols: DataGridCol<QuestionType>[] = useMemo(() => [
    createCol(t('catalogues.questions.table.name'), 'name', shorten(100)),
    // createCol(t('catalogues.questions.table.testCount'), ''),
    createCol(t('catalogues.questions.table.categoryCount'), 'categories_count', (v, item) => {
      return <Tooltip
        title={item.question_catalogue_categories?.map(c => c.title).join(', ')}><span>{v}</span></Tooltip>;
    }),
    createCol(t('catalogues.questions.table.createdAt'), 'created_at', datetimeToGui),
    createCol(t('catalogues.questions.table.updatedAt'), 'updated_at', datetimeToGui),
    // createCol(t('catalogues.questions.table.categoryCount'), ''),
  ], [t]);

  useEffect(() => {
    handleFetchItem().then();
  }, [handleFetchItem, t]);

  if (!category) {
    return <LinearProgress/>;
  }

  return <Box>
    <Formik
      initialValues={category}
      onSubmit={handleSaveItem}
      validate={handleValidate}
    >
      <CategoryForm isSaving={isSaving} {...props}/>
    </Formik>
    <Grid container spacing={2}>
      <Grid item xs={12} className={'tw-mt-20'}>
        <strong className={'options'}>{t('catalogues.categories.form.questions')}</strong>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleQuestionsModalOpen} color={'primary'} variant={'contained'}>
          {!!questionsItemState.items?.length
            ? t('catalogues.categories.form.editCategoryQuestions')
            : t('catalogues.categories.form.addCategoryToQuestion')
          }
        </Button>
      </Grid>
      <Grid item xs={12} className={'tw-mb-16'}>
        <DataGrid
          cols={questionCols}
          state={questionsDataGridState}
          setState={setQuestionsDataGridState}
          itemsState={questionsItemState}
          mode={DataGridMode.CLIENT}
          emptyListMessage={t('catalogues.categories.form.noQuestions')}
          emptySearchMessage={t('catalogues.categories.emptySearch')}
          // actions={actions}
          noTopBorder
        />
      </Grid>
    </Grid>
    {isQuestionsModal && <CategoryQuestionsDialog
        catalogueId={catalogueId}
        questions={questions}
        currentQuestionIds={questionsItemState.items?.map(c => c.id)}
        onSuccess={handleQuestionsModalSuccess}
        onClose={handleQuestionsModalClose}/>}
  </Box>;
};
