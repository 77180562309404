import {FetchParams} from '../generated-api';
import {ApiSerializedError} from '../model/api';

const API_TIMEOUT = 60_000;

export interface RunningApiInfo {
    code: string;
    begin: number;
    // eslint-disable-next-line no-undef
    controller: AbortController;
}

function abortControllerFactory() {
    // eslint-disable-next-line no-undef
    return new AbortController();
}

export const appFetchApiFactory =
    (setApiRunning: (info: RunningApiInfo, active: boolean) => void) =>
        async (url: FetchParams['url'], init: FetchParams['init']) => {
            const begin = new Date().valueOf();
            const info = {
                code: `${begin}|${url}`,
                controller: abortControllerFactory(),
                begin,
            };
            init.signal = info.controller.signal;
            let timeoutHandle = null;
            let timeout = false;
            try {
                setApiRunning(info, true);
                timeoutHandle = setTimeout(() => {
                    timeout = true;
                    info.controller.abort();
                }, API_TIMEOUT);
                return await fetch(url, init);
            } catch (e: any) {
                const code = e.name === 'AbortError' ? (timeout ? 'timeout' : 'abort') : 'general';
                const err: ApiSerializedError = {
                    code,
                    message: code === 'timeout' ? 'Timeout' : e.message,
                };

                // console.log(`[API] Fetch ${code}`, {
                // 	e,
                // 	code: info.code,
                // 	err,
                // 	timeout,
                // });
                throw err;
            } finally {
                if (timeoutHandle) {
                    clearTimeout(timeoutHandle);
                    timeoutHandle = null;
                }
                setApiRunning(info, false);
            }
        };
