import { ReactComponent as UKFlag } from 'icons/english-flag.svg';
import { ReactComponent as CzechFlag } from 'icons/czech-flag.svg';
import { useAppDispatch } from 'store';
import { saveUser } from 'store/auth';
import { useCallback } from 'react';
import { useAppTranslation } from 'services/i18n';
import i18n from 'i18next';

export const NavMenuLanguage = () => {
  const language = i18n.language;
  const dispatch = useAppDispatch();

  const t = useAppTranslation();

  const changeLanguage = useCallback(
    async (language: 'en' | 'pl' | 'cs') => {
      i18n.changeLanguage(language);
      await dispatch(
        saveUser({
          userId: 'me',
          data: {
            language,
          },
        })
      );

      window.location.reload();
    },
    [dispatch]
  );

  const languages = [
    {
      lang: 'en',
      string: t('contentViewer.nav.switchToEn'),
      icon: <UKFlag height={24} width={24} />,
      onClick: () => changeLanguage('en'),
    },
    {
      lang: 'cs',
      string: t('contentViewer.nav.switchToCs'),
      icon: <CzechFlag height={24} width={24} />,
      onClick: () => changeLanguage('cs'),
    },
  ];

  const getLanguageOptions = () => {
    if (language === 'pl') {
      return languages;
    }
    return languages.filter((lang) => lang.lang !== language);
  };

  const displayLanguages = getLanguageOptions();

  return (
    <>
      {displayLanguages.map(({ string, icon, onClick, lang }) => (
        <button
          className="tw-flex tw-h-52 tw-w-full tw-items-center tw-gap-20 tw-px-16 tw-pt-5 hover:tw-bg-black/5"
          onClick={onClick}
          type="button"
          key={lang}
        >
          {icon}
          {string}
        </button>
      ))}
    </>
  );
};
