import i18n, { Resource, StringMap, TOptions } from 'i18next';
import {
  DefaultNamespace,
  initReactI18next,
  Namespace,
  TFuncKey,
  TFuncReturn,
  useTranslation,
} from 'react-i18next';
import { setDateLocale } from '../helpers/date';
import { setNumberLocale } from '../helpers/number';

export type AllowedLangsType = 'en' | 'cs';

const translations = {
  en: () => require('../i18n/en.json'),
  cs: () => require('../i18n/cs.json'),
  pl: () => require('../i18n/pl.json'),
} as any;

const allowedLangs = Object.keys(translations) as AllowedLangsType[];
export const DEFAULT_LANG =
  !navigator || !navigator.languages || navigator.languages.indexOf('cs') >= 0 ? 'cs' : 'en';

const langsAliases: { [key: string]: string } = {
  'en-gb': 'en',
};

const fallback = { languageTag: 'cs' };
const i18nResources = Object.keys(translations).reduce((acc, curr) => {
  acc[curr] = { translation: { ...translations[curr]() } };
  return acc;
}, {} as Resource);

i18n.use(initReactI18next).init(
  {
    compatibilityJSON: 'v3',
    nsSeparator: false,
    keySeparator: '.',
    fallbackLng: 'cs',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    missingKeyHandler: (key, ns) => console.log('missing', ns, key),
    resources: i18nResources,
    lng: DEFAULT_LANG,
    debug: true,
  },
  () => {
    setDateLocale(i18n.language);
    setNumberLocale(i18n.language);
  }
);

i18n.on('languageChanged', (lang) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('languageChanged', { lang });
  }

  setDateLocale(lang);
  setNumberLocale(lang);
});

export function setLocale(_lang: string) {
  const lang = langsAliases[_lang] || _lang;
  setDateLocale(lang);
  setNumberLocale(lang);

  if (i18n.language === lang) {
    return;
  }
  return i18n.changeLanguage(allowedLangs.includes(lang as any) ? lang : fallback.languageTag);
}

export function getLocale() {
  return i18n.language as AllowedLangsType;
}

export const t = i18n.t as TAppFunction;

export type TAppFunctionResult = string | undefined;

export interface TAppFunction<N extends Namespace = DefaultNamespace, TKPrefix = undefined> {
  <
    TKeys extends TFuncKey<N, TKPrefix> | TemplateStringsArray extends infer A ? A : never,
    TDefaultResult extends TAppFunctionResult = string,
    TInterpolationMap extends object = StringMap
  >(
    key: TKeys | TKeys[],
    options?: TOptions<TInterpolationMap> | string
  ): TFuncReturn<N, TKeys, TDefaultResult, TKPrefix>;
  <
    TKeys extends TFuncKey<N, TKPrefix> | TemplateStringsArray extends infer A ? A : never,
    TDefaultResult extends TAppFunctionResult = string,
    TInterpolationMap extends object = StringMap
  >(
    key: TKeys | TKeys[],
    defaultValue?: string,
    options?: TOptions<TInterpolationMap> | string
  ): TFuncReturn<N, TKeys, TDefaultResult, TKPrefix>;
}

export function useAppTranslation() {
  const obj = useTranslation();
  return obj.t as any; // TAppFunction breaks use in title={t('foo')} etc
}
