import { Dialog, useMediaQuery } from '@mui/material';

interface ModalProps {
  showModal?: boolean;
  setShowModal?: (showModal: boolean) => void;
  isFullScreen: boolean;
  modalWidth?: number;
  children: JSX.Element;
}

export const Modal = ({
  isFullScreen,
  modalWidth,
  children,
  showModal,
  setShowModal,
}: ModalProps) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  if (isFullScreen && showModal && setShowModal)
    return (
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: isMobile ? '100%' : modalWidth ? `${modalWidth}px!important` : undefined,
            margin: isMobile ? '16px' : '32px',
          },
        }}
      >
        {children}
      </Dialog>
    );
  if (isFullScreen === false)
    return (
      <div className="tw-mb-20 tw-max-w-400 tw-rounded-lg tw-bg-white tw-text-left">{children}</div>
    );
  return <></>;
};
