import { useDrop } from 'react-dnd';
import { ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInnerChoicesInner } from 'generated-api';
import clsx from 'clsx';
import { DraggableItemType, Answer } from 'utils/types';
import { FormikValues, useFormikContext } from 'formik';
import { DraggableItem } from './DraggableItem';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  choice: ContentViewerTestDefinitionTestAttemptPayloadIndexResponseQuestionsInnerChoicesInner;
  testInstanceId: string;
  isMobile: boolean;
  itemsToMove: string[];
  setItemsToMove: Dispatch<SetStateAction<string[]>>;
  setIsItemDragging: (isItemDragging: boolean) => void;
}

export const DropArea = ({
  choice,
  testInstanceId,
  isMobile,
  itemsToMove,
  setItemsToMove,
  setIsItemDragging,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [{ isOver }, dropRef] = useDrop({
    accept: 'item',
    drop: (item: DraggableItemType) => addItemToCategory(item.item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const matchedItemByCategory: Answer =
    values[testInstanceId] &&
    values[testInstanceId]?.choice_ids &&
    values[testInstanceId]?.choice_ids.find((value: FormikValues) => value.choice_id === choice.id);
  const areMatchedItemsInCategory =
    matchedItemByCategory &&
    matchedItemByCategory.payload &&
    matchedItemByCategory.payload.length > 0;

  // On Desktop, I can only drag one item at a time. On Mobile I am able to choose ('tap') several items and assign them all to some category at once
  const addItemToCategory = (itemsToMove: string | string[]) => {
    // This is needed in order to sort items between categories - item disappears in one category and is added in other
    const newMatchedItems = values[testInstanceId]?.choice_ids.map((matchedItem: Answer) => {
      const filteredPayload = matchedItem.payload?.filter((matchedItem) => {
        if (Array.isArray(itemsToMove)) return itemsToMove.every((item) => item !== matchedItem);
        return matchedItem !== itemsToMove;
      });
      return { ...matchedItem, payload: filteredPayload };
    });
    // If there is already some item to category added...
    if (matchedItemByCategory) {
      const indexOfMatchedItem = values[testInstanceId]?.choice_ids.findIndex(
        (matchedItem: FormikValues) => matchedItem.choice_id === choice.id
      );
      newMatchedItems[indexOfMatchedItem] = {
        ...newMatchedItems[indexOfMatchedItem],
        payload: [
          ...newMatchedItems[indexOfMatchedItem].payload,
          ...(Array.isArray(itemsToMove) ? itemsToMove : [itemsToMove]),
        ],
      };
      setFieldValue(testInstanceId, {
        ...values[testInstanceId],
        choice_ids: newMatchedItems,
      });
      // Is't the first item in a category
    } else {
      setFieldValue(testInstanceId, {
        ...values[testInstanceId],
        choice_ids: [
          ...newMatchedItems,
          {
            choice_id: choice.id,
            payload: Array.isArray(itemsToMove) ? [...itemsToMove] : [itemsToMove],
          },
        ],
      });
    }
    isMobile && setItemsToMove([]);
  };

  const areTappedItemsFromSameCategory = itemsToMove.every((item) =>
    matchedItemByCategory?.payload?.includes(item)
  );

  const isCategoryHighlighted =
    isMobile && itemsToMove.length > 0 && !areTappedItemsFromSameCategory;

  return (
    <section
      className={clsx(
        'tw-rounded-lg tw-border tw-bg-white tw-p-16 tw-text-16 tw-font-semibold',
        isMobile ? 'tw-mb-8 tw-w-full landscape:tw-w-full' : 'tw-flex-grow-1 tw-min-h-284 tw-w-320',
        !isMobile && isOver && 'glowing-border',
        isCategoryHighlighted ? 'tw-border-lightGreen' : 'tw-border-white'
      )}
      ref={dropRef}
      id="category"
      onClick={isCategoryHighlighted ? () => addItemToCategory(itemsToMove) : undefined}
    >
      <p
        className={clsx(
          'tw-flex tw-w-full tw-justify-between tw-gap-10',
          ((isMobile && areMatchedItemsInCategory) || !isMobile) &&
            'tw-border-b tw-border-black/5 tw-pb-8'
        )}
      >
        {choice.value}
        {isMobile && itemsToMove.length > 0 && isCategoryHighlighted && (
          <AddCircleOutlineIcon className="tw-text-lightGreen" />
        )}
      </p>
      <div className="tw-flex tw-flex-wrap tw-gap-8  tw-font-roboto tw-text-14 tw-font-normal">
        {areMatchedItemsInCategory && (
          <div
            className={
              'tw-flex tw-flex-wrap tw-gap-8 tw-pt-16 tw-font-roboto tw-text-14 tw-font-normal'
            }
          >
            {matchedItemByCategory?.payload?.map((item, index) => (
              <DraggableItem
                key={index}
                item={item}
                itemsToMove={itemsToMove}
                setItemsToMove={setItemsToMove}
                setIsItemDragging={setIsItemDragging}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
