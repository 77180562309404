import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Identifier, XYCoord} from "dnd-core";
import {Box, Button, Grid} from "@mui/material";
import clsx from "clsx";
import {Delete, DragHandle} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {CatalogueType, DragItem, QuestionType, TestEntryType} from "../../helpers/editorTest";
import {fetchCatalogue} from "store/catalogues";
import {Link} from "components/clickable/Link";
import {TextFormFieldPlain} from "components/form/TextFormField";

interface Props {
  testId?: number;
  entry: TestEntryType;
  question: QuestionType;
  onOpen?: () => unknown;
  open?: boolean;
  onEntryMove: (dragId: number, hoverId: number) => void;
  onEntryChange: (entry: TestEntryType) => void;
  onEntryRemove: (entry: TestEntryType) => void;
  disabled: boolean;
}

export const EditorTestCatalogueQuestion = (props: Props) => {

  const {
    entry, question,
    open,
    onOpen, onEntryMove, onEntryChange, onEntryRemove, disabled
  } = props;
  const catalogueId = entry.question?.question_catalogue_id;

  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const [catalogue, setCatalogue] = useState<CatalogueType | undefined>();

  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const [{handlerId}, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'question',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = entry.position

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if (onEntryMove) {
        onEntryMove(dragIndex, hoverIndex)
      }

      item.index = hoverIndex
    }
  }, [entry]);

  const [{isDragging}, drag, preview] = useDrag({
    type: 'question',
    item: () => {
      return {id: entry.id, index: entry.position, targetIndex: entry.position}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item) {
      if (item.targetIndex !== item.index) {
        onEntryChange({...entry, position: item.index});
      }
    },
  }, [entry]);

  const handleEntryRemove = useCallback(() => {
    if (onEntryRemove) {
      onEntryRemove(entry);
    }
  }, [entry, onEntryRemove]);

  useEffect(() => {
    dispatch(fetchCatalogue({id: '' + catalogueId})).then((res) => {
      const item = res?.payload as CatalogueType;
      setCatalogue(item);
    })

  }, [catalogueId, dispatch]);

  const opacity = isDragging ? 0.5 : 1;
  if (!disabled) {
    drag(dragRef);
    drop(preview(previewRef));
  }

  return <Grid item xs={12}
               className={clsx('question', open && !disabled ? 'active' : undefined, open ? 'showed' : undefined)}
               ref={previewRef} style={{opacity}} data-handler-id={handlerId}>
    <Grid container>
      <Grid item xs={12} ref={dragRef}>
        <Box onClick={onOpen}
             className={'question-handle tw-flex tw-justify-between tw-self-center tw-align-middle tw-cursor-pointer'}>
          <div>
            {!disabled && <DragHandle sx={{verticalAlign: 'middle', cursor: 'move'}}/>}
            <label
              className={'question-label'}>{t('editorTest.catalogueQuestions.question', {catalogueName: catalogue?.title || ''})}</label>
            <span> - </span>
            <span className={'question-name'}>{question.name}</span>
          </div>
          {!disabled && <div className={'actions tw-align-middle tw-flex'} onClick={(e) => e.stopPropagation()}>
              <IconButton onClick={handleEntryRemove} color={'inherit'}><Delete/></IconButton>
          </div>}
        </Box>
      </Grid>
      {(open || !onOpen) && <Grid item xs={12} className={'question-content'}>
          <Grid container spacing={2} className={'tw-pb-16'}>
              <Grid item xs={12} className={'question-right'}>
                  <Grid container columns={100}>
                      <Grid item lg={80} md={60} className={'question-settings tw-items-center tw-flex'}>
                          <em>{question.name}</em>
                      </Grid>
                      <Grid item lg={20} md={40}
                            className={'question-settings tw-grid-flow-row tw-justify-end tw-items-center tw-flex'}>
                          <div className={'tw-grid-flow-row tw-items-center tw-flex'}>
                              <span className={'question_label'}
                                    style={{whiteSpace: 'nowrap'}}>{t('editorTest.labels.pointValue')}</span>
                              <div className={'tw-pl-3 tw-pr-3'}>
                                  <TextFormFieldPlain name={'point_value'} type={'number'} disabled={true}
                                                      currentValue={question.point_value} onChange={undefined}/>
                              </div>
                          </div>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                  <Link
                      kind="bare"
                      className="tw-mr-16 tw-pt-8 tw-pb-10 tw-pl-32 tw-pr-32 tw-inline-block tw-rounded-lg tw-border tw-border-dark tw-bg-dark tw-text-center tw-text-white"
                      href={"/#/catalogues/" + catalogueId + "/questions/" + question.id}
                  >
                    {t(`editorTest.catalogueQuestions.goToQuestion`)}
                  </Link>
                {!disabled && <Button
                    onClick={handleEntryRemove} variant={'outlined'}
                    color={'inherit'}>{t('editorTest.catalogueQuestions.removeQuestion')}</Button>}
              </Grid>
          </Grid>
      </Grid>}
    </Grid>
  </Grid>;
}
