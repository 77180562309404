import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingSpinnerProps {
  size?: number;
}

export const LoadingSpinner = ({ size }: LoadingSpinnerProps) => {
  return (
    <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-white">
      <CircularProgress color="primary" size={size ?? 48} />
    </div>
  );
};
