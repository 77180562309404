import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { CustomDragLayer } from './CustomDragLayer';

interface Props {
  item: string;
  itemsToMove: string[] | null;
  setItemsToMove: Dispatch<SetStateAction<string[]>>;
  setIsItemDragging?: (isItemDragging: boolean) => void;
}

export const DraggableItem = ({ item, itemsToMove, setItemsToMove, setIsItemDragging }: Props) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [isTapped, setIsTapped] = useState(false);

  // Logic for handling selecting (tapping) several items on mobile devices
  const handleTap = () => {
    if (isTapped) {
      setItemsToMove((itemsToMove) => {
        const itemIndexToBeRemoved = itemsToMove.findIndex((itemToMove) => itemToMove === item);
        const newItemsToMove = [...itemsToMove];
        newItemsToMove.splice(itemIndexToBeRemoved, 1);
        return newItemsToMove;
      });
    } else {
      setItemsToMove((itemsToMove) => [...itemsToMove, item]);
    }
    setIsTapped(!isTapped);
  };

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: 'item',
    item: { item },
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    itemsToMove && itemsToMove.length === 0 && setIsTapped(false);
  }, [itemsToMove]);

  useEffect(() => {
    setIsItemDragging?.(isDragging ?? false);
  }, [isDragging, setIsItemDragging]);

  return (
    <div
      ref={dragRef}
      className={clsx(
        'tw-cursor-pointer tw-rounded-xl tw-font-roboto tw-text-14',
        isMobile && isTapped ? 'tw-bg-lightGreen tw-text-white' : 'tw-bg-black/5 tw-text-black',
        isDragging &&
          'w-bg-lightYellow tw-border tw-border-dashed tw-border-lighterGray tw-bg-lightYellow tw-text-lightYellow'
      )}
      onClick={isMobile ? handleTap : undefined}
    >
      <div className="tw-py-12 tw-px-16" ref={itemRef}>
        {item}
      </div>
      {isDragging && !isMobile && <CustomDragLayer item={item} />}
    </div>
  );
};
