import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Identifier, XYCoord} from "dnd-core";
import {Box, Button, Grid} from "@mui/material";
import clsx from "clsx";
import {Delete, DragHandle} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {CatalogueCategoryType, CatalogueType, DragItem, TestEntryType} from "../../helpers/editorTest";
import {fetchCatalogue} from "store/catalogues";
import {Link} from "components/clickable/Link";
import {TextFormFieldPlain} from "components/form/TextFormField";
import {EntryCreateSettingsInner} from "generated-api";

interface Props {
  testId?: number;
  entry: TestEntryType;
  category: CatalogueCategoryType;
  onOpen?: () => unknown;
  open?: boolean;
  onEntryMove: (dragId: number, hoverId: number) => void;
  onEntryChange: (entry: TestEntryType) => void;
  onEntryRemove: (entry: TestEntryType) => void;
  disabled: boolean;
}

type CountsType = { [key in number]: number }

const createCounts = (settings?: EntryCreateSettingsInner[]): CountsType => {
  const counts: CountsType = {};
  settings?.forEach(s => {
    counts[s.point_value] = s.count;
  })

  return counts;
}

export const EditorTestCatalogueCategory = (props: Props) => {

  const {
    entry, category,
    open,
    onOpen, onEntryMove, onEntryChange, onEntryRemove, disabled
  } = props;
  const catalogueId = entry.question_category?.question_catalogue_id;

  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const [catalogue, setCatalogue] = useState<CatalogueType | undefined>();
  const [counts, setCounts] = useState<CountsType>(createCounts(entry.settings));

  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const [{handlerId}, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'question',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = entry.position

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if (onEntryMove) {
        onEntryMove(dragIndex, hoverIndex)
      }

      item.index = hoverIndex
    }
  }, [entry]);

  const [{isDragging}, drag, preview] = useDrag({
    type: 'question',
    item: () => {
      return {id: entry.id, index: entry.position, targetIndex: entry.position}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item) {
      if (item.targetIndex !== item.index) {
        onEntryChange({...entry, position: item.index});
      }
    },
  }, [entry]);

  const handleCategoryRemove = useCallback(() => {
    if (onEntryRemove) {
      onEntryRemove(entry);
    }
  }, [entry, onEntryRemove]);

  const settingsTimeout = useRef<any>();
  const handleSettingsChange = useCallback((entry: TestEntryType, pointValue: number, count: number) => {
    const newEntry = {...entry};
    if (!newEntry.settings) {
      newEntry.settings = [];
    }
    let setting = newEntry.settings.find(s => s.point_value === pointValue)
    if (!setting) {
      newEntry.settings.push({point_value: pointValue, count})
    } else {
      setting.count = count;
    }
    if (settingsTimeout.current) {
      clearTimeout(settingsTimeout.current);
    }
    settingsTimeout.current = setTimeout(() => {
      onEntryChange(newEntry);
    }, 500);
    setCounts(c => ({...c, [pointValue]: count}))

  }, [onEntryChange]);

  useEffect(() => {
    dispatch(fetchCatalogue({id: '' + catalogueId})).then((res) => {
      const item = res?.payload as CatalogueType;
      setCatalogue(item);
    })

  }, [catalogueId, dispatch]);

  const opacity = isDragging ? 0.5 : 1;
  if (!disabled) {
    drag(dragRef);
    drop(preview(previewRef));
  }

  return <Grid item xs={12}
               className={clsx('question', open && !disabled ? 'active' : undefined, open ? 'showed' : undefined)}
               ref={previewRef} style={{opacity}} data-handler-id={handlerId}>
    <Grid container>
      <Grid item xs={12} ref={dragRef}>
        <Box onClick={onOpen}
             className={'question-handle tw-flex tw-justify-between tw-self-center tw-align-middle tw-cursor-pointer'}>
          <div>
            {!disabled && <DragHandle sx={{verticalAlign: 'middle', cursor: 'move'}}/>}
            <label
              className={'question-label'}>{t('editorTest.catalogueCategories.category', {catalogueName: catalogue?.title || ''})}</label>
            <span> - </span>
            <span className={'question-name'}>{category.title}</span>
          </div>
          {!disabled && <div className={'actions tw-align-middle tw-flex'} onClick={(e) => e.stopPropagation()}>
              <IconButton onClick={handleCategoryRemove} color={'inherit'}><Delete/></IconButton>
          </div>}
        </Box>
      </Grid>
      {(open || !onOpen) && <Grid item xs={12} className={'question-content'}>
          <Grid container className={'tw-pb-16'}>
            {category.point_stats
              ?.sort((a, b) => a.point_value > b.point_value ? -1 : 1)
              ?.map((ps, i) => {
                const onChange = (v: any) => handleSettingsChange(entry, ps.point_value, parseInt(v, 10));
                return <Grid key={i} item xs={12}>
                  <Grid container columns={24} spacing={2} className={'tw-items-center tw-mb-16'}>
                    <Grid item>
                      {t('editorTest.catalogueQuestions.selectRulePrefix')}
                    </Grid>
                    <Grid item lg={1} md={3} sm={3} xs={6}>
                      <TextFormFieldPlain
                        name={'question_count_' + i} type={'number'}
                        minValue={0} maxValue={ps.count}
                        onChange={onChange}
                        currentValue={counts[ps.point_value] || ''} disabled={disabled}/>
                    </Grid>
                    <Grid item>
                      {t('editorTest.catalogueQuestions.selectRuleSuffix', {
                        questionCount: ps.count,
                        count: ps.point_value
                      })}
                    </Grid>
                  </Grid>
                </Grid>;
              })}
            {category.point_stats.length <= 0 && <Grid item xs={12}>
                <p><em>{t('editorTest.catalogueCategories.categoryEmpty')}</em></p>
            </Grid>}
              <Grid item xs={12}>
                  <Link
                      kind="bare"
                      className="tw-mr-16 tw-pt-8 tw-pb-10 tw-pl-32 tw-pr-32 tw-inline-block tw-rounded-lg tw-border tw-border-dark tw-bg-dark tw-text-center tw-text-white"
                      href={"/#/catalogues/" + catalogueId + "/categories/" + category.id}
                  >
                    {t(`editorTest.catalogueCategories.goToCategory`)}
                  </Link>
                {!disabled && <Button
                    onClick={handleCategoryRemove} variant={'outlined'}
                    color={'inherit'}>{t('editorTest.catalogueCategories.removeCategory')}</Button>}
              </Grid>
          </Grid>
      </Grid>}
    </Grid>

  </Grid>;
}
