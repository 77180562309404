import {
  ContentEditorQuestionCatalogueIndexResponse,
  ContentEditorQuestionCataloguesQuestionCatalogueCategory,
  ContentEditorQuestionCataloguesQuestionCatalogueCategoryIndexResponse,
  ContentEditorQuestionCataloguesQuestionIndexResponseChoicesInner,
  ContentEditorShowResponse,
  ContentEditorTestDefinitionEntryIndexResponse,
  ContentEditorTestDefinitionIndexResponse,
  ContentEditorTestDefinitionIndexResponseQuestionsInnerDependentStepsInner,
  ContentEditorUploadedFileMap,
  ContentEditorValidationErrorIndexResponse,
  QuestionCatalogueQuestionUpdate,
  QuestionUpdate,
  UploadedFile,
  UploadedFileUpdate
} from "generated-api";
import {AppDispatch} from "index";
import {createUploadedFile, updateUploadedFile} from "store/editorTest";

export interface CatalogueType extends ContentEditorQuestionCatalogueIndexResponse {

}

export interface TestType extends ContentEditorTestDefinitionIndexResponse {
  entries?: TestEntryType[]
}

export interface TestEntryType extends ContentEditorTestDefinitionEntryIndexResponse {
}

export interface VersionsType extends ContentEditorShowResponse {
}

export interface QuestionType extends QuestionUpdate {
  id: number;
  choices?: ContentEditorQuestionCataloguesQuestionIndexResponseChoicesInner[];
  file?: ContentEditorUploadedFileMap;
  dependent_steps?: ContentEditorTestDefinitionIndexResponseQuestionsInnerDependentStepsInner[];
  question_catalogue_categories?: ContentEditorQuestionCataloguesQuestionCatalogueCategory[];
}

export interface CatalogueQuestionType extends QuestionType, QuestionCatalogueQuestionUpdate {
  status?: string;
}

export interface ChoiceType extends ContentEditorQuestionCataloguesQuestionIndexResponseChoicesInner {
}

export interface CatalogueCategoryType extends ContentEditorQuestionCataloguesQuestionCatalogueCategoryIndexResponse {
}

export interface QuestionErrorType extends ContentEditorValidationErrorIndexResponse {

}

export interface DragItem {
  index: number;
  id: string;
  type: string;
  targetIndex: number;
}

export const broadcastNgEditorSuccessEvent = () => {
  try {
    // needed to trigger global "_validateVersions"
    const element = (window as any)['angular'].element(document.body);
    const scope = element.scope();
    scope.$root.$broadcast("$contentEditorSuccess");
  } catch (ignored) {

  }
}

export const sortByPosition = (a: { position: number }, b: { position: number }) => {
  return (a.position > b.position) ? 1 : (a.position === b.position ? 0 : -1);
}

export const uploadFile = async (dispatch: AppDispatch, file: File): Promise<UploadedFile | undefined> => {
  const f = (await dispatch(createUploadedFile({
    body: {
      file_name: file.name,
      mime_type: file.type,
      size: file.size,
      is_public: true,
      is_uploaded: false
    }
  })))?.payload as UploadedFile;
  if (!f.upload_url) {
    return;
  }

  // push content to AWS
  const req = new Request(f.upload_url, {
    method: 'PUT',
    headers: {
      "Content-type": file.type !== '' ? file.type : 'application/octet-stream'
    },
    body: file
  });
  const res = await fetch(req);
  if (!res.ok) {
    return;
  }

  // flag as uploaded
  await dispatch(updateUploadedFile({
    id: f.id,
    body: {...f, is_uploaded: true} as any as UploadedFileUpdate
  }));

  return f;
}
