import {useMemo, useState} from 'react';
import {Button, Card, CardContent, CardHeader, Tooltip} from '@mui/material';
import {DeleteForever, Edit, PlaylistAdd} from '@mui/icons-material';
import {useAppTranslation} from '../../services/i18n';
import {
  createCol,
  DataGrid,
  DataGridCol,
  DataGridMode,
  DataGridState, isSearchWithinSubject,
  ItemsState,
  OrderDirType,
} from '../DataGrid';
import {CodebookAddForm} from './CodebookAddForm';
import {CodebookCategory} from '../../pages/CodebooksPage';
import {BaseCodebook} from '../../store/codebooks';
import {CodebookEditForm} from './CodebookEditForm';
import {CodebookDeleteForm} from './CodebookDeleteForm';

interface CodebookFilter {
  search?: string;
}

const filterCallback = (filter: CodebookFilter, item: BaseCodebook): boolean => {
  return isSearchWithinSubject(filter.search, item.name);
};

const isActionDeleteApplicable = (item: BaseCodebook): boolean => {
  return !(item.user_count && item.user_count > 0);
};

const defaultState: DataGridState<BaseCodebook, CodebookFilter> = {
  orderCol: 'name',
  orderDir: OrderDirType.ASC,
  filter: {
    search: '',
  },
  filterCallback,
};

type Props<E extends BaseCodebook> = {
  codebookCategory: CodebookCategory;
  itemsState: ItemsState<E>;
  fetchItems: () => unknown;
};

export const CodebookList = <E extends BaseCodebook>(props: Props<E>) => {
  const {codebookCategory, itemsState, fetchItems} = props;
  const {category} = codebookCategory;

  const t = useAppTranslation();
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [itemForEditForm, setItemForEditForm] = useState<E | null>(null);
  const [itemForDeleteDialog, setItemForDeleteDialog] = useState<E | null>(null);

  const [dataGridState, setDataGridState] = useState<DataGridState<E, CodebookFilter>>(defaultState);

  const handleOpenAddForm = () => {
    setIsOpenAddForm(true);
  };

  const handleCloseAddNew = () => {
    setIsOpenAddForm(false);
  };

  const handleOpenEditForm = (item: E) => {
    setItemForEditForm(item);
  };

  const handleCloseEditNew = () => {
    setItemForEditForm(null);
  };

  const handleOpenDeleteDialog = (item: E) => {
    setItemForDeleteDialog(item);
  };

  const handleCloseDeleteDialog = () => {
    setItemForDeleteDialog(null);
  };

  const addNewButton = (
    <Button
      style={{margin: '24px'}}
      color={'primary'}
      variant={'contained'}
      onClick={handleOpenAddForm}
    >
      {t('form.add')} {t('codebooks.' + category + '.title')}
    </Button>
  );

  const actions = [
    {
      title: 'form.delete',
      icon: <DeleteForever/>,
      isApplicable: isActionDeleteApplicable,
      callback: handleOpenDeleteDialog,
    },
    {
      title: 'form.update',
      icon: <Edit/>,
      callback: handleOpenEditForm,
    },
  ];

  const cols: DataGridCol<E>[] = useMemo(() => ([
    createCol(t('codebooks.table.title'), 'name'),
    createCol(t('codebooks.table.userCount'), 'user_count'),
  ]), [t]);

  return (
    <>
      <Card>
        <CardHeader
          className={'gray-header'}
          title={t('codebooks.' + category + '.title')}
          action={
            <div>
              <Tooltip title={t('codebooks.title') + ' ' + t('codebooks.' + category + '.title')}>
                <Button onClick={handleOpenAddForm} color={'primary'} variant={'contained'}>
                  <PlaylistAdd/> {t('form.add')}
                </Button>
              </Tooltip>
            </div>
          }
        />
        <CardContent>
          <DataGrid
            cols={cols}
            state={dataGridState}
            setState={setDataGridState}
            itemsState={itemsState}
            mode={DataGridMode.CLIENT}
            emptyListMessage={
              <>
                <p>{t('codebooks.' + category + '.emptyList')}</p>
                {addNewButton}
              </>
            }
            emptySearchMessage={
              <>
                <p>{t('codebooks.' + category + '.emptySearch')}</p>
                {addNewButton}
              </>
            }
            actions={actions}
          />
        </CardContent>
      </Card>
      {isOpenAddForm && (
        <CodebookAddForm
          codebookCategory={codebookCategory}
          onSuccess={fetchItems}
          onClose={handleCloseAddNew}
        />
      )}
      {itemForEditForm && (
        <CodebookEditForm
          codebookCategory={codebookCategory}
          item={itemForEditForm}
          onSuccess={fetchItems}
          onClose={handleCloseEditNew}
        />
      )}
      {itemForDeleteDialog && (
        <CodebookDeleteForm
          codebookCategory={codebookCategory}
          item={itemForDeleteDialog}
          onSuccess={fetchItems}
          onClose={handleCloseDeleteDialog}
        />
      )}
    </>
  );
};
