import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ArrowDownward, ArrowUpward, FilterAlt, FilterAltTwoTone, MoreVert, Search} from '@mui/icons-material';
import {Button, Grid, IconButton, LinearProgress, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography,} from '@mui/material';
import {useAppTranslation} from '../services/i18n';
import {TextFormField} from './form/TextFormField';
import {Formik, FormikProps} from 'formik';
import {normalizeString} from "utils/utils";
import CircularProgress from "@mui/material/CircularProgress";
import {Color} from "model/form";

export const enum DataGridMode {
    CLIENT = 'client',
    SERVER = 'server',
}

export const enum OrderDirType {
    ASC = 'asc',
    DESC = 'desc',
}

export type DataGridCol<E> = {
    [K in keyof E]: {
        title: string | JSX.Element
        col: K,
        orderCol?: K | null,
        renderValue?: (value: E[K], item: E) => React.ReactNode,
        width?: number | string,
        align?: 'left' | 'right' | 'center',
        filter?: DataGridColFilter<any, any>,
        minScreenSize?: string
    }
}[keyof E]

export function createCol<E, K extends keyof E>(
    title: string | JSX.Element,
    col: K,
    renderValue?: (value: E[K], item: E) => React.ReactNode,
    width?: number | string,
    minScreenSize?: string,
): DataGridCol<E> {
    return {
        title,
        col,
        orderCol: col,
        renderValue,
        width,
        minScreenSize
    } as DataGridCol<E>
}

export function colCenter<E>(col: DataGridCol<E>): DataGridCol<E> {
    col.align = 'center';
    return col;
}

export function colNoSort<E>(col: DataGridCol<E>): DataGridCol<E> {
    col.orderCol = undefined;
    return col;
}

export type FilterContext<F, FT> = {
    defaultFilter: F,
    currentFilter: F,
    onOpen: (colFilter: DataGridColFilter<F, FT>) => void,
    onApply: (values: Partial<F>) => void,
}

export type DataGridColFilter<F, FT> = FilterContext<F, FT> & {
    filterName: FT,
    isApplied: () => boolean,
}

export function colFilterSimple<E, F extends Partial<E>, FT extends keyof F>(
    col: DataGridCol<E>,
    filterContext: FilterContext<F, FT>,
    filterName?: keyof F
): DataGridCol<E> {
    // assume column name === filter name === filter type
    const name: keyof F = filterName || col.col;
    col.filter = {
        filterName: name as string,
        ...filterContext,
        isApplied: () => {
            return filterContext.currentFilter[name] !== filterContext.defaultFilter[name]
                && JSON.stringify(filterContext.currentFilter[name]) !== JSON.stringify(filterContext.defaultFilter[name]);
        }
    };
    return col;
}

export function colFilterAny<E, F, FT>(col: DataGridCol<E>, filter: DataGridColFilter<F, FT>): DataGridCol<E> {
    col.filter = filter;
    return col;
}

export interface DataGridItemAction<E> {
    title: string;
    icon?: JSX.Element;
    color?: Color;
    isApplicable?: (item: E) => boolean;
    callback: (item: E) => unknown;
}

export interface DataGridState<E, F> {
    orderCol: keyof E;
    orderDir: OrderDirType;
    filter: F;
    filterCallback?: (filter: F, item: E) => boolean;
    page?: number;
    pageSize?: number;
}

export interface ItemsState<E> {
    isLoading: boolean;
    items: Array<E>;
}

export type DataGridProps<E, F> = {
    cols: Array<DataGridCol<E>>;
    state: DataGridState<E, F>;
    defaultState?: DataGridState<E, F>;
    setState: (state: DataGridState<E, F>) => void;
    itemsState: ItemsState<E>;
    emptyListMessage: string | JSX.Element;
    emptySearchMessage?: string | JSX.Element;
    createFilter?: (formProps: FormikProps<F>) => JSX.Element;
    actions?: DataGridItemAction<E>[];
    isActionMenu?: boolean;
    mode?: DataGridMode;
    noTopBorder?: boolean;
    absoluteScroll?: boolean;
    rowClass?: (item: E) => string | undefined;
    rowClick?: (item: E) => void;
    legacyPaging?: boolean;
};

export const isSearchWithinSubject = (search: string | undefined, subject: string | undefined): boolean => {
    if (!search) {
        return true;
    }
    if (!subject) {
        return false;
    }
    const normalized = normalizeString(subject);
    return normalized.indexOf(normalizeString(search)) >= 0;
}

export const DataGridItemActions = <E, >(props: {
    actions?: DataGridItemAction<E>[];
    isActionMenu?: boolean;
    item: E;
}) => {
    const t = useAppTranslation();
    const [openMenuItem, setMenuOpenItem] = useState<{ item: E; anchorEl: HTMLElement } | null>(null);

    const {actions, isActionMenu, item} = props;

    const handleMenuOpen = useCallback((e: React.MouseEvent<HTMLElement>, item: E) => {
        e.stopPropagation();
        setMenuOpenItem({item, anchorEl: e.currentTarget});
    }, []);

    const handleMenuClose = useCallback((e: any) => {
        e?.stopPropagation();
        setMenuOpenItem(null);
    }, []);

    if (!actions || !(actions.length > 0)) {
        return null;
    }

    if (isActionMenu) {
        return (
            <TableCell sx={{textAlign: 'right'}}>
                <IconButton onClick={(e) => handleMenuOpen(e, item)} color={'inherit'}>
                    <MoreVert/>
                </IconButton>
                <Menu
                    open={openMenuItem ? openMenuItem.item === item : false}
                    anchorEl={openMenuItem?.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleMenuClose}
                >
                    {actions.map((action, i) => {
                        if (action.isApplicable && !action.isApplicable(item)) {
                            return null;
                        }
                        return (
                            <MenuItem
                                key={i}
                                color={'primary'}
                                onClick={(e) => {
                                    action.callback(item);
                                    setMenuOpenItem(null);
                                    e.stopPropagation();
                                }}
                            >
                                {action.icon}
                                <Typography component={'span'} paragraph={false} color={action.color}>{t(action.title)}</Typography>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </TableCell>
        );
    } else {
        return (
            <TableCell className={'data-grid-actions'}>
                {actions.map((action, i) => {
                    if (action.isApplicable && !action.isApplicable(item)) {
                        return null;
                    }
                    return (
                        <Tooltip key={i} title={t(action.title)}>
                            <IconButton
                                color={action.color || 'secondary'}
                                onClick={() => {
                                    action.callback(item);
                                }}
                            >
                                {action.icon || t(action.title)}
                            </IconButton>
                        </Tooltip>
                    );
                })}
            </TableCell>
        );
    }
};

const DataGridHead = <E, F>(
    {
        col, state, pageSize, setState
    }: {
        col: DataGridCol<E>,
        state: DataGridState<E, F>,
        pageSize: number,
        setState: (s: DataGridState<E, F>) => void
    }
) => {
    const handleSort = useCallback(() => {
        if (!col.orderCol) {
            return;
        }
        if (col.orderCol === state.orderCol) {
            setState({
                ...state,
                page: 1,
                pageSize,
                orderDir:
                    state.orderDir === OrderDirType.ASC
                        ? OrderDirType.DESC
                        : OrderDirType.ASC,
            });
        } else {
            setState({
                ...state,
                page: 1,
                pageSize,
                orderDir: OrderDirType.DESC,
                orderCol: col.orderCol,
            });
        }
    }, [state, col, pageSize, setState]);

    const handleFilter = useCallback(() => {
        if (col.filter) {
            col.filter.onOpen(col.filter);
        }
    }, [col.filter]);

    return (
        <TableCell
            style={{minWidth: col.width}}
            align={col.align}
            className={col.minScreenSize ? 'data-grid-show-' + col.minScreenSize : undefined}
        >
            <div className={'tw-flex tw-items-center'
                + (col.orderCol ? ' data-grid-head-sortable' : '')
                + (col.align === 'center' ? ' tw-place-content-center' : '')}>
                {!!col.filter && (col.filter.isApplied()
                        ? <FilterAlt onClick={handleFilter} sx={{color: 'var(--text-black) !important'}}/>
                        : <FilterAltTwoTone onClick={handleFilter}/>
                )}
                <span onClick={handleSort}>{col.title}</span>
                {state.orderCol === col.orderCol ? (
                    state.orderDir === OrderDirType.ASC ? (
                        <ArrowUpward onClick={handleSort}/>
                    ) : (
                        <ArrowDownward onClick={handleSort}/>
                    )
                ) : null}
            </div>
        </TableCell>
    );
}

export const DataGrid = <E, F>(props: DataGridProps<E, F>) => {
    const {
        cols,
        state,
        defaultState,
        setState,
        createFilter,
        itemsState: {items, isLoading},
        actions,
        noTopBorder,
        absoluteScroll,
        rowClass,
        rowClick,
        legacyPaging
    } = props;

    const t = useAppTranslation();

    const [pendingPageSizeForLoading, setPendingPageSizeForLoading] = useState(0);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const handleFilter = useCallback((filter: F) => {
        setState({...state, filter, page: 1, pageSize: defaultState?.pageSize});
    }, [setState, state, defaultState]);

    const handleFilterReset = useCallback(() => {
        setState({
            ...state,
            filter: JSON.parse(JSON.stringify(defaultState?.filter)),
            page: 1,
            pageSize: defaultState?.pageSize
        });
    }, [setState, state, defaultState]);

    const handleRowClick = useCallback((e: { target?: any }, item: E) => {
        if (rowClick && !e.target?.href) { // ignore <a>
            rowClick(item);
        }
    }, [rowClick])

    const handleLoadMore = useCallback(() => {
        setPendingPageSizeForLoading(items.length);
        if (legacyPaging) {
            setState({...state, pageSize: items.length + (defaultState?.pageSize || 10)});
        } else {
            setState({...state, page: (state.page || 1) + 1});
        }
    }, [state, items.length, defaultState?.pageSize, setState, legacyPaging]);

    useEffect(() => {
        if (!isLoading && isLoadingMore && items.length >= pendingPageSizeForLoading) {
            setPendingPageSizeForLoading(0);
            setIsLoadingMore(false);
        }
    }, [isLoading, isLoadingMore, pendingPageSizeForLoading, items.length, state.pageSize]);

    useEffect(() => {
        if (pendingPageSizeForLoading > 0 && isLoading) {
            setIsLoadingMore(true);
        }
    }, [isLoading, pendingPageSizeForLoading]);

    const isFilterUsed = useMemo(() => defaultState?.filter && JSON.stringify(defaultState.filter) !== JSON.stringify(state.filter)
        , [defaultState?.filter, state.filter]);

    if (!items?.length && isLoading) {
        return (
            <div className={'grid-loading-data'}>
                <LinearProgress/>
            </div>
        );
    }

    let rows;
    if (props.mode !== DataGridMode.SERVER) {
        rows = items
            .slice()
            .filter((item) => {
                if (state.filter && state.filterCallback) {
                    return state.filterCallback(state.filter, item);
                }
                return true;
            })
            .sort((a: E, b: E) => {
                if (a === b) {
                    return 0;
                }
                if (state.orderCol) {
                    const va = a[state.orderCol];
                    const vb = b[state.orderCol];
                    if ((va as any)?.localeCompare) {
                        if ((va as any).localeCompare(vb) > 0) {
                            return state.orderDir === OrderDirType.DESC ? -1 : 1;
                        } else {
                            return state.orderDir === OrderDirType.DESC ? 1 : -1;
                        }
                    }
                    if (va > vb) {
                        return state.orderDir === OrderDirType.DESC ? -1 : 1;
                    } else {
                        return state.orderDir === OrderDirType.DESC ? 1 : -1;
                    }
                }
                return 0;
            });
    } else {
        rows = items;
    }

    return <>
        <Grid container className={'tw-items-center ' + (props.mode !== DataGridMode.SERVER ? 'data-grid-filter' : '')}>
            <Grid item className={'tw-grow'}>
                <Formik initialValues={state.filter} onSubmit={handleFilter} enableReinitialize={true}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            {createFilter ? createFilter(props) : <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextFormField
                                        name="search"
                                        type={'search'}
                                        onBlur={props.submitForm}
                                        label={<>
                                            <Search/> {t('common.search')}
                                        </>}
                                        maxlength={100}
                                    />
                                </Grid>
                            </Grid>}
                        </form>
                    )}
                </Formik>
            </Grid>
            {isFilterUsed && <Grid item>
                <Button variant={'contained'} color={'dark' as any} onClick={handleFilterReset}>{t('common.clearFilters')}</Button>
            </Grid>}
        </Grid>
        <LinearProgress hidden={!isLoading}/>
        <TableContainer className={absoluteScroll ? 'tw-relative tw-flex tw-flex-col tw-grow' : undefined}>
            <Table size={'small'} stickyHeader className={absoluteScroll ? 'tw-absolute' : undefined /*'tw-relative'*/}>
                <TableHead>
                    <TableRow sx={noTopBorder ? {'& > .MuiTableCell-head': {borderTop: 'unset'}} : undefined}>
                        {cols.map((col) => {
                            return <DataGridHead key={String(col.col)} col={col} state={state} pageSize={defaultState?.pageSize || 10} setState={setState}/>
                        })}
                        {actions && actions.length && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                {rows.length > 0 ? (
                    <TableBody sx={{'a:not(:focus):not(:hover)': {color: 'inherit'}}}>
                        {rows.map((item, i) => {
                            return (
                                <TableRow key={i}
                                    className={rowClass ? rowClass(item) : undefined}
                                    onClick={rowClick ? (e) => handleRowClick(e, item) : undefined}
                                    sx={rowClick ? {
                                        '&:hover > td': {
                                            background: '#eee',
                                            cursor: 'pointer'
                                        }
                                    } : undefined}
                                >
                                    {cols.map((col, j) => {
                                        let v;
                                        if (col.renderValue) {
                                            v = col.renderValue(item[col.col], item);
                                        } else {
                                            v = item[col.col];
                                        }
                                        return <TableCell key={j}
                                            className={col.minScreenSize ? 'data-grid-show-' + col.minScreenSize : undefined}
                                            align={col.align}>{v as any}</TableCell>;
                                    })}
                                    <DataGridItemActions {...props} item={item}/>
                                </TableRow>
                            );
                        })}
                        {((!!state.pageSize && rows.length >= state.pageSize && !isLoading) || !!pendingPageSizeForLoading) &&
                            <TableRow>
                                <TableCell colSpan={cols.length}
                                    className={'tw-text-center tw-p-10 tw-pb-20'}>
                                    {(!!pendingPageSizeForLoading || isLoading)
                                        ? <CircularProgress size={24}/>
                                        : <Button color={'primary'} variant={'contained'} disabled={!!pendingPageSizeForLoading || isLoading}
                                            onClick={!!pendingPageSizeForLoading || isLoading ? undefined : handleLoadMore}>
                                            {t('common.loadMore')}
                                        </Button>}
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                ) : <TableBody>
                    <TableRow>
                        <TableCell colSpan={cols.length + (!!actions?.length ? 1 : 0)} className={'tw-p-0'}>
                            <div className={'data-grid-zero-data'}>{isFilterUsed ? (props.emptySearchMessage || props.emptyListMessage) : props.emptyListMessage}</div>
                        </TableCell>
                    </TableRow>
                </TableBody>}
            </Table>
        </TableContainer>
    </>;
};
