import {
    CompanySpacesContentContentIdOffersIdDeleteRequest,
    CompanySpacesContentContentIdOffersIdGetRequest, CompanySpacesContentContentIdOffersIdPutRequest,
    CompanySpacesContentContentIdOffersPostRequest,
    OfferApi
} from "../generated-api";
import {API_CONFIG} from "app/api-config";
import {createAsyncThunk} from "@reduxjs/toolkit";

const offerApi = new OfferApi(API_CONFIG);

export const fetchContentOffers = createAsyncThunk('offer/list', async (args: {contentId: number}) => {
    return await offerApi.companySpacesContentContentIdOffersGet(args);
});

export const fetchContentOffer = createAsyncThunk('offer/get', async (args: CompanySpacesContentContentIdOffersIdGetRequest) => {
    return await offerApi.companySpacesContentContentIdOffersIdGet(args);
});

export const createOffer = createAsyncThunk('offer/create', async (args: CompanySpacesContentContentIdOffersPostRequest) => {
    return await offerApi.companySpacesContentContentIdOffersPost(args);
});

export const updateOffer = createAsyncThunk('offer/update', async (args: CompanySpacesContentContentIdOffersIdPutRequest) => {
    return await offerApi.companySpacesContentContentIdOffersIdPut(args);
});

export const deleteOffer = createAsyncThunk('offer/update', async (args: CompanySpacesContentContentIdOffersIdDeleteRequest) => {
    return await offerApi.companySpacesContentContentIdOffersIdDelete(args);
});
