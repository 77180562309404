import { useAppTranslation } from 'services/i18n';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppDispatch } from 'store';
import { fetchAuthLogout } from 'store/auth';

interface Props {
  onClick: (
    e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => boolean;
}

export const NavMenuLogout = ({ onClick }: Props) => {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const isDisabled = onClick(e);
    if (!isDisabled) {
      await dispatch(fetchAuthLogout());
      window.location.reload();
    }
  };

  return (
    <button
      className="tw-flex tw-h-52 tw-w-full tw-items-center tw-gap-20 tw-px-16 tw-pt-5 hover:tw-bg-black/5"
      onClick={handleClick}
      type="button"
    >
      <LogoutIcon style={{ color: '#757575' }} />
      {t('contentViewer.nav.logout')}
    </button>
  );
};
