import {ThemeProvider} from '@mui/material';
import {EnhancedStore} from '@reduxjs/toolkit';
import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {createAppTheme, cssVar} from '../styles/theme';

interface Props<T extends EnhancedStore> {
  store: T;
  children?: ReactNode | undefined;
}

export function AppProviders<T extends EnhancedStore>({store, children}: Props<T>) {

  const [primaryColor, setPrimaryColor] = useState(cssVar('--color-primary'));

  const effTheme = useMemo(() => {
    if (primaryColor) {
      // this dependency is needed to force theme reload
    }
    return createAppTheme();
  }, [primaryColor]);

  useEffect(() => {
    const styleObserver = new MutationObserver((mutations) => {
      setPrimaryColor(cssVar('--color-primary'));
    });
    styleObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['style'],
    });
    return () => {
      styleObserver?.disconnect();
    }
  }, []);

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={effTheme}>{children}</ThemeProvider>
    </ReduxProvider>
  );
}
