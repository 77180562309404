import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {SubscriptionApi, SubscriptionSubscriptionOverviewShowResponse} from "../generated-api";
import {API_CONFIG} from "../app/api-config";

const subscriptionApi = new SubscriptionApi(API_CONFIG);

export const fetchSubscription = createAsyncThunk('cs/subscription/get', async () => {
    return await subscriptionApi.companySpacesSubscriptionGet();
});

const initialState: BillingState = {
    subscription: undefined,
};

export type BillingState = {
    subscription?: SubscriptionSubscriptionOverviewShowResponse;
}
export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchSubscription.fulfilled, (state, action) => {
            return {
                ...state,
                subscription: action.payload
            };
        });
    }
});

export const billingReducer = billingSlice.reducer;
// eslint-disable-next-line no-empty-pattern
export const {} = billingSlice.actions;