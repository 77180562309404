import { ReactComponent as AwardIcon } from 'icons/award.svg';
import { ReactComponent as PassedIcon } from 'icons/passed.svg';
import { ReactComponent as InfoCircle } from 'icons/info-circle.svg';
import { ReactComponent as FailedIcon } from 'icons/failed.svg';
import { ReactComponent as SadFace } from 'icons/sad-face.svg';
import { ShowAnswersInTestResult, TestResult } from 'utils/types';
import { TestQuestionResults } from './TestQuestionResults';
import clsx from 'clsx';
import { useAppDispatch } from 'store';
import { fetchLesson } from 'store/contentViewer';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectContentViewer } from 'store/selectors';
import { useAppTranslation } from 'services/i18n';
import { Trans } from 'react-i18next';
import { ContentViewerTestDefinitionTestAttemptPayloadUpdateResponse } from 'generated-api';
import { ContentLayoutWithControls } from 'pages/ContentViewer/ContentLayoutWithControls';
import { useMediaQuery } from '@mui/material';
import { Link } from '../clickable/Link';
import { useParams } from 'react-router-dom';

interface Props {
  handleNavigateToNextStep: () => Promise<void>;
  setShowTestResults: (showTestResults: boolean) => void;
  isLastStep?: boolean;
  setShowTestQuestions: (showTestQuestions: boolean) => void;
  contentLoading: boolean;
  showCourseIncompleteModal: boolean;
  isStartTestAllowed: boolean;
  remainingTime?: number | null;
}

export const TestEvaluation = ({
  handleNavigateToNextStep,
  setShowTestResults,
  isLastStep,
  setShowTestQuestions,
  contentLoading,
  showCourseIncompleteModal,
  isStartTestAllowed,
  remainingTime,
}: Props) => {
  const dispatch = useAppDispatch();
  const { courseId } = useParams();
  const contentViewerState = useSelector(selectContentViewer);
  const courseContent = contentViewerState.course;
  const testStepName = contentViewerState.currentContent?.[0].test_definition?.name;
  const testAttempts = contentViewerState.testAttempts;
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const [scrollPosition, setScrollPosition] = useState(0);

  const testResultsRef = useRef<HTMLDivElement | null>(null);
  const getSubmittedTestAttempts = () => {
    if (contentLoading) return undefined;
    return testAttempts.filter((testAttempt) => testAttempt.state === 'turned_in');
  };
  const submittedTestAttempts = getSubmittedTestAttempts();

  const getLatestSubmittedTestAttempt = () => {
    if (submittedTestAttempts && !contentLoading) {
      return submittedTestAttempts.reduce((prevTestAttempt, nextTestAttempt) => {
        return prevTestAttempt.end_date > nextTestAttempt.end_date
          ? prevTestAttempt
          : nextTestAttempt;
      }, {} as ContentViewerTestDefinitionTestAttemptPayloadUpdateResponse);
    }
    return undefined;
  };

  const latestSubmittedTestAttempt = getLatestSubmittedTestAttempt();

  const t = useAppTranslation();

  const contentParticipationId = latestSubmittedTestAttempt?.content_participation_id;
  const showAnswersInTestResult = latestSubmittedTestAttempt?.show_answers_in_test_result;
  const result = latestSubmittedTestAttempt?.result;
  const achievedPoints = latestSubmittedTestAttempt?.achieved_points;
  const maxPoints = latestSubmittedTestAttempt?.max_points;
  const pointsToPass = latestSubmittedTestAttempt?.points_to_pass;
  const studyOfPreviousSteps = latestSubmittedTestAttempt?.enforce_study_of_previous_steps;

  const isOnlyScoreDisplayed = showAnswersInTestResult === ShowAnswersInTestResult.None;
  const isOnlyErrorsDisplayed = showAnswersInTestResult === ShowAnswersInTestResult.OnlyIncorrect;
  const isTestPassed = result === TestResult.Passed;

  const failedDependentSteps = latestSubmittedTestAttempt?.steps_for_repeated_study;
  const dependentStepsToComplete = latestSubmittedTestAttempt?.steps_marked_as_completed;

  const isStepsParticipationChanged =
    !!failedDependentSteps?.length || !!dependentStepsToComplete?.length;

  const scrollTo = (scrollAmount: number) => {
    testResultsRef?.current?.focus();
    window.scrollTo({
      top: scrollPosition - scrollAmount,
      behavior: 'smooth',
    });
    setScrollPosition(scrollPosition - scrollAmount);
  };

  const keyBoardProps = {
    contentLoading: contentLoading,
    leftKeyText: t(`contentViewer.keyboardControls.repeatTest`),
    isLeftKeyAction: !isTestPassed,
    onLeftKeyPress: () => {
      if (isStartTestAllowed) {
        setShowTestResults(false);
        setShowTestQuestions(true);
      } else {
        setShowTestResults(false);
        setShowTestQuestions(false);
      }
    },
    rightKeyText: !isLastStep ? t(`contentViewer.keyboardControls.endStep`) : undefined,
    onRightKeyPress: !isLastStep ? handleNavigateToNextStep : undefined,
    isRightKeyAction: isTestPassed,
    enterKeyText: isLastStep ? t('contentViewer.keyboardControls.certificate') : undefined,
    onEnterKeyPress:
      isLastStep && !showCourseIncompleteModal ? handleNavigateToNextStep : undefined,
    isEnterKeyAction: isLastStep && !showCourseIncompleteModal,
    onUpKeyPress: () => scrollTo(100),
    upDownKeyText:
      !isOnlyScoreDisplayed && !isMobile && t('contentViewer.keyboardControls.testResults'),
    onDownKeyPress: () => scrollTo(-100),
    canRepeatTestOnlyAfterStepsFinished: !isTestPassed && studyOfPreviousSteps,
  };

  useEffect(() => {
    contentParticipationId && dispatch(fetchLesson(contentParticipationId.toString()));
  }, [dispatch, contentParticipationId]);

  return (
    <ContentLayoutWithControls {...keyBoardProps} contentLoading={contentLoading}>
      {latestSubmittedTestAttempt && !contentLoading && (
        <>
          <section
            className={clsx(
              'tw-h-full tw-overflow-auto tw-bg-lightYellow tw-px-16 tw-py-16 lg:tw-py-48 lg:tw-px-0',
              isOnlyScoreDisplayed &&
                (!isStepsParticipationChanged
                  ? 'tw-flex tw-items-center tw-justify-center'
                  : 'tw-flex tw-flex-col tw-gap-24')
            )}
            ref={testResultsRef}
            tabIndex={0}
          >
            <div
              className={clsx(
                'tw-flex tw-flex-col tw-justify-center tw-gap-16 lg:tw-items-center lg:tw-gap-16',
                isOnlyScoreDisplayed && 'tw-flex tw-flex-col'
              )}
            >
              <>
                <div
                  className={clsx(
                    'tw-flex tw-h-48 tw-w-48 tw-items-center tw-justify-center tw-rounded-xl lg:tw-m-auto ',
                    isTestPassed ? 'tw-bg-lightGreen' : 'tw-bg-red'
                  )}
                >
                  {isTestPassed ? <AwardIcon /> : <SadFace />}
                </div>
                <h1
                  className={clsx(
                    'tw-max-w-800 tw-font-roboto tw-text-24 tw-font-semibold lg:tw-text-center',
                    isOnlyScoreDisplayed && 'tw-text-30'
                  )}
                >
                  {pointsToPass === 0
                    ? t(`contentViewer.testViewer.automaticSuccess`)
                    : isTestPassed
                    ? t(`contentViewer.testViewer.success`)
                    : t(`contentViewer.testViewer.failure`)}
                  <span className="tw-mt-8 tw-block tw-text-16 lg:tw-text-24">
                    {testStepName ? testStepName : 'test'}
                  </span>
                </h1>
                <h2 className="tw-flex tw-gap-10 tw-text-center lg:tw-items-center lg:tw-justify-center">
                  {pointsToPass === 0 ? (
                    <InfoCircle />
                  ) : isTestPassed ? (
                    <PassedIcon />
                  ) : (
                    <div className="tw-h-24 tw-w-24 tw-grow-0 tw-rounded-full tw-border-2 tw-border-red tw-p-4">
                      <FailedIcon className="tw-fill-red tw-stroke-red" />
                    </div>
                  )}
                  <span className={clsx(isOnlyScoreDisplayed && 'tw-text-20')}>
                    <Trans i18nKey="contentViewer.testViewer.pointsObtained">
                      Získal/a jste {{ achievedPoints }} / {{ maxPoints }} bodů.
                    </Trans>
                  </span>
                </h2>
                <p
                  className={clsx(
                    'tw-text-12 lg:tw-text-center',
                    isOnlyScoreDisplayed && 'tw-text-16'
                  )}
                >
                  <Trans i18nKey="contentViewer.testViewer.pointsNeeded">
                    Pro splnění testu potřebujete alespoň {{ pointsToPass }} bodů
                  </Trans>
                </p>
                {isOnlyErrorsDisplayed && !isOnlyScoreDisplayed && (
                  <p className={clsx('-tw-mt-8 tw-text-12 lg:tw-text-center')}>
                    <Trans i18nKey="contentViewer.testViewer.onlyErrorsDisplayed">
                      Níže najdete otázky, na které jste odpověděli chybně. Vaše odpovědi jsou
                      zvýrazněné.
                    </Trans>
                  </p>
                )}
              </>
            </div>

            {!!failedDependentSteps?.length && (
              <div className="tw-mx-auto tw-my-24 tw-flex tw-flex-col tw-gap-24 tw-rounded tw-bg-white tw-p-20 lg:tw-w-9/12">
                <span className="tw-font-semibold">
                  {isTestPassed
                    ? t('contentViewer.testViewer.failedSteps.passed')
                    : studyOfPreviousSteps
                    ? t(`contentViewer.testViewer.testRetry`)
                    : t('contentViewer.testViewer.failedSteps.failed')}
                </span>
                <div className="tw-flex tw-gap-8">
                  {failedDependentSteps?.map((dependentStep) => {
                    const { lesson_position, step_position, step_name, id, lesson_id } =
                      dependentStep ?? {};
                    return (
                      <Link
                        kind="bare"
                        to={`/learn/${courseId}/lesson/${lesson_id}/step/${id}`}
                        className="tw-rounded-xl tw-bg-lightRed tw-px-12 tw-py-4 tw-text-12 tw-text-darkerRed"
                        key={id}
                      >
                        {lesson_position}.{step_position} {step_name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}

            {!!dependentStepsToComplete?.length && (
              <div className="tw-mx-auto tw-my-24 tw-flex tw-flex-col tw-gap-24 tw-rounded tw-bg-white tw-p-20 lg:tw-w-9/12">
                <span className="tw-font-semibold">
                  {t('contentViewer.testViewer.completedDependentSteps')}
                </span>
                <div className="tw-flex tw-gap-8">
                  {dependentStepsToComplete?.map((step) => {
                    const { id, lesson_position, step_name, step_position } = step ?? {};
                    return (
                      <span
                        className="tw-rounded-xl tw-bg-lighterGreen tw-px-12 tw-py-4 tw-text-12 tw-text-black"
                        key={id}
                      >
                        {lesson_position}.{step_position} {step_name}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}

            {!isOnlyScoreDisplayed && (
              <TestQuestionResults
                isOnlyErrorsDisplayed={isOnlyErrorsDisplayed}
                results={latestSubmittedTestAttempt}
                courseContent={courseContent}
              />
            )}
          </section>
        </>
      )}
    </ContentLayoutWithControls>
  );
};
