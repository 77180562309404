import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {FormFieldProps, OptionValue} from '../../model/form';
import {useAppTranslation} from "../../services/i18n";
import {Theme} from "@mui/material/styles/createTheme";

const color = (theme: Theme, isSelected: boolean, option: OptionValue) => isSelected
    ? (option.color
        ? theme.palette[option.color].main
        : theme.palette['primary'].main)
    : undefined;

interface Props extends FormFieldProps {
    options: OptionValue[],
    title?: string,
    fullWidth?: boolean
}

export const RadioGroupField = ({name, label, options, onChange, title, fullWidth, disabled}: Props) => {
    const t = useAppTranslation();
    const [field, meta, helpers] = useField(name);
    const {submitCount} = useFormikContext();
    const showError = !!meta.error && (meta.touched || submitCount > 0);

    return <FormControl error={showError} title={title} fullWidth={fullWidth}>
        {label && <FormLabel>{typeof label === 'string' ? t(label) : undefined}</FormLabel>}
        <RadioGroup row name={name}>
            {options.map((option, i) => {
                const isSelected = field.value === option.value;

                return <FormControlLabel key={i}
                    value={option.value}
                    title={option.tooltip}
                    sx={{
                        color: (theme) => color(theme, isSelected, option),
                        '&.Mui-disabled > span': {
                            color: (theme) => color(theme, isSelected, option),
                        },
                        '&.Mui-disabled > .MuiTypography-root': {
                            color: (theme) => color(theme, isSelected, option),
                        },
                        '& > .MuiButtonBase-root svg': {
                            fontSize: '150%'
                        }
                    }}
                    onChange={() => {
                        helpers.setValue(option.value, true);
                        if (onChange) {
                            onChange(option.value);
                        }
                    }}
                    control={<Radio size="small" color={option.color || 'primary'} checked={isSelected}/>}
                    label={option.label}
                    disabled={disabled}/>
            })}
        </RadioGroup>
        {showError && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>;

}
