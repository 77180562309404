import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import {Box, Button, Grid, LinearProgress, Tooltip} from '@mui/material';
import {EditorTestQuestion} from "components/editorTest/EditorTestQuestion";
import {ContentEditorQuestionCataloguesQuestionShowResponse,} from "generated-api";
import {
  CatalogueCategoryType,
  CatalogueQuestionType,
  QuestionErrorType,
  QuestionType,
  uploadFile
} from "../../helpers/editorTest";
import {
  deleteCatalogueQuestionFile,
  fetchCatalogueCategories,
  fetchCatalogueQuestion,
  linkCatalogueQuestionFile,
  publishCatalogueQuestion,
  updateCatalogueQuestion,
  validateCatalogueQuestion
} from "store/catalogues";
import {QuestionCategoriesDialog} from "components/catalogue/QuestionCategoriesDialog";
import {CheckCircle} from "@mui/icons-material";
import {timeToGui} from "../../helpers/date";
import {
  createCol,
  DataGrid,
  DataGridCol,
  DataGridMode,
  DataGridState,
  isSearchWithinSubject,
  ItemsState,
  OrderDirType
} from "components/DataGrid";
import {QuestionPublishDialog} from "components/catalogue/QuestionPublishDialog";

const styles = {
  '.options ': {fontWeight: 'bold'},
  '& span:not(.Mui-checked) > .MuiSvgIcon-root': {
    opacity: .6 // md-icon.md-default-theme, md-icon
  },
  '& button:not(.MuiIconButton-colorPrimary) > .MuiSvgIcon-root': {
    opacity: .6 // md-icon.md-default-theme, md-icon
  },
  '& div > .MuiSvgIcon-root': {
    opacity: .6 // md-icon.md-default-theme, md-icon
  }
}

interface GridFilter {
  search?: string
}

const filterCallback = (filter: GridFilter, item: CatalogueCategoryType): boolean => {
  return isSearchWithinSubject(filter.search, item.title);
};

const categoriesDefaultState: DataGridState<CatalogueCategoryType, GridFilter> = {
  orderCol: 'title',
  orderDir: OrderDirType.ASC,
  filter: {
    search: ''
  },
  filterCallback
};

interface Props {
  catalogueId: number;
  id?: number;
  onActionButtons: (actions?: JSX.Element) => void;
  onCancel: () => void;
  onSuccess: () => void;
}

export const QuestionEditForm = (props: Props) => {
  const {catalogueId, id, onActionButtons, onCancel, onSuccess} = props;

  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const [question, setQuestion] = useState<CatalogueQuestionType | undefined>();
  const [dirtyQuestion, setDirtyQuestion] = useState<CatalogueQuestionType | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const [savedAt, setSavedAt] = useState<Date | undefined>();
  const [categories, setCategories] = useState<CatalogueCategoryType[]>([]);
  const [isCategoriesModal, setIsCategoriesModal] = useState(false);

  const [categoriesDataGridState, setCategoriesDataGridState] = useState(categoriesDefaultState);
  const [categoriesItemState, setCategoriesItemState] = useState<ItemsState<CatalogueCategoryType>>({
    items: [],
    isLoading: false
  });

  const [isPublishModal, setIsPublishModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<QuestionErrorType[]>([]);

  const handleFetchItem = useCallback(async () => {
    if (!id) {
      return;
    }
    const item = (await dispatch(fetchCatalogueQuestion({
      id: '' + id,
      questionCatalogueId: catalogueId
    })))?.payload as ContentEditorQuestionCataloguesQuestionShowResponse;

    setQuestion({
      ...item,
      position: 0,
      question_catalogue_id: catalogueId,
      question_catalogue_category_ids: item.question_catalogue_categories?.map(c => c.id)
    });

    if (item.question_catalogue_categories) {
      setCategoriesItemState((s) => ({
        ...s,
        items: item.question_catalogue_categories || []
      }));
    }
    setCategories((await dispatch(fetchCatalogueCategories({questionCatalogueId: catalogueId})))?.payload as CatalogueCategoryType[]);

  }, [catalogueId, id, dispatch]);

  const broadcastSuccessEvent = useCallback(() => {
    setSavedAt(new Date());
  }, []);

  const handleSaveItem = useCallback(async (dirtyQuestion: CatalogueQuestionType) => {
    setIsSaving(true);
    let id = dirtyQuestion.id;
    await dispatch(updateCatalogueQuestion({
      id: '' + id,
      questionCatalogueId: catalogueId,
      body: dirtyQuestion
    }))
    setIsSaving(false);
    setQuestion((q) => ({
      ...dirtyQuestion,
      // sync if just saved
      id: q?.id || id || dirtyQuestion.id,
      // preserve
      choices: q?.choices,
      file: q?.file
    }));
    broadcastSuccessEvent();

  }, [broadcastSuccessEvent, catalogueId, dispatch]);

  const handleCategoriesModalOpen = useCallback(() => {
    setIsCategoriesModal(true);
  }, []);

  const handleCategoriesModalClose = useCallback(() => {
    setIsCategoriesModal(false);
  }, []);

  const handleCategoriesModalSuccess = useCallback((categoryIds: number[]) => {
    // update only categories
    setDirtyQuestion((q) => (q
      ? {...q, question_catalogue_category_ids: categoryIds}
      : question ? {...question, question_catalogue_category_ids: categoryIds} : undefined));
    setCategoriesItemState((s) => ({...s, items: categories.filter(c => categoryIds.indexOf(c.id) >= 0)}))
    handleCategoriesModalClose();
  }, [question, categories, handleCategoriesModalClose]);

  const onQuestionFormChange = useCallback((values: QuestionType) => {
    // update all BUT categories ...
    setDirtyQuestion((q) => {
      return {
        ...(values as CatalogueQuestionType),
        question_catalogue_category_ids: q?.question_catalogue_category_ids || question?.question_catalogue_category_ids
      };
    });

  }, [question]);

  const handleQuestionFileUpload = useCallback(async (questionId: number, file: File) => {
    // upload file
    const f = await uploadFile(dispatch, file);
    if (!f) {
      return;
    }

    // link to question
    await dispatch(linkCatalogueQuestionFile({
      id: f.id,
      questionCatalogueId: catalogueId,
      questionId: questionId
    }));

    setQuestion((q) => (q && {
      ...q,
      file: f
    }));
    broadcastSuccessEvent();

  }, [broadcastSuccessEvent, catalogueId, dispatch]);

  const handleQuestionFileRemove = useCallback(async (question: QuestionType) => {
    if (!question.file?.id) {
      return;
    }
    await dispatch(deleteCatalogueQuestionFile({
      questionCatalogueId: catalogueId,
      questionId: question.id,
      id: '' + question.file.id
    }));

    setQuestion((q) => (q && {
      ...q,
      file: undefined
    }));
    broadcastSuccessEvent();

  }, [broadcastSuccessEvent, catalogueId, dispatch]);

  const handlePublishModalOpen = useCallback(async () => {
    if (!question?.id) {
      return;
    }
    const errors = (await dispatch(validateCatalogueQuestion({
      questionCatalogueId: catalogueId,
      questionId: question.id,
    }))).payload as QuestionErrorType[];

    setErrors(errors);
    setIsPublishModal(true);
  }, [question?.id, catalogueId, dispatch]);

  const handlePublishModalClose = useCallback(() => {
    setIsPublishModal(false);
  }, []);

  const handlePublish = useCallback(async () => {
    if (!question?.id) {
      return;
    }

    await dispatch(publishCatalogueQuestion({
      questionCatalogueId: catalogueId,
      questionId: question.id,
    }))

    onSuccess();
  }, [question?.id, catalogueId, onSuccess, dispatch]);

  const categoryCols: DataGridCol<CatalogueCategoryType>[] = useMemo(() => [
    createCol(t('catalogues.categories.table.name'), 'title'),
    // createCol(t('catalogues.categories.table.testCount'), 'user_count'),
    // createCol(t('catalogues.categories.table.questionCount'), 'user_count'),
  ], [t]);

  useEffect(() => {
    if (dirtyQuestion) {
      handleSaveItem(dirtyQuestion).then();
    }

  }, [dirtyQuestion, handleSaveItem]);

  useEffect(() => {
    handleFetchItem().then();
  }, [handleFetchItem, t]);

  useEffect(() => {
    onActionButtons(<>
      {!!savedAt && <Tooltip title={t('catalogues.questions.form.savedAutomatically', {savedAt: timeToGui(savedAt)})}>
          <CheckCircle sx={{marginRight: '10px !important', fontSize: '140%', opacity: isSaving ? 0.5 : 1}}/>
      </Tooltip>}
      <Button color={'inherit'} variant={'outlined'} onClick={onCancel}>
        {t('catalogues.questions.form.back')}
      </Button>
      <Button color={'inherit'} variant={'contained'} onClick={handlePublishModalOpen}>
        {t('catalogues.questions.form.publish')}
      </Button>
    </>);
    return () => {
      onActionButtons(undefined);
    }
  }, [savedAt, onActionButtons, handlePublishModalOpen, onCancel, isSaving, t]);

  if (!question?.id) {
    return <LinearProgress/>;
  }

  return <Box sx={styles}>
    <EditorTestQuestion
      testHasSteps={false}
      catalogueId={catalogueId}
      question={question as any as QuestionType}
      onQuestionChange={onQuestionFormChange}
      onQuestionFileRemove={handleQuestionFileRemove}
      onQuestionFileUpload={handleQuestionFileUpload}
      disabled={false}
      onBroadcastAnyChanged={broadcastSuccessEvent}
    />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <span className={'options'}>{t('catalogues.questions.form.categories')}</span>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleCategoriesModalOpen} color={'primary'} variant={'contained'}>
          {!!categoriesItemState.items?.length
            ? t('catalogues.questions.form.editQuestionCategories')
            : t('catalogues.questions.form.addQuestionToCategory')
          }
        </Button>
      </Grid>
      <Grid item xs={12} className={'tw-mb-16'}>
        <DataGrid
          cols={categoryCols}
          state={categoriesDataGridState}
          setState={setCategoriesDataGridState}
          itemsState={categoriesItemState}
          mode={DataGridMode.CLIENT}
          emptyListMessage={t('catalogues.questions.form.noCategories')}
          emptySearchMessage={t('catalogues.categories.emptySearch')}
          // actions={actions}
          noTopBorder
        />
      </Grid>
    </Grid>
    {isCategoriesModal && <QuestionCategoriesDialog
        catalogueId={catalogueId}
        categories={categories}
        currentCategoryIds={categoriesItemState.items?.map(c => c.id)}
        onSuccess={handleCategoriesModalSuccess}
        onClose={handleCategoriesModalClose}/>}
    {isPublishModal && <QuestionPublishDialog
        errors={errors}
        onConfirm={handlePublish}
        onClose={handlePublishModalClose}/>}
  </Box>

};
