import {CompanySpacesUsersGetRequest, CompanySpacesUsersIdGetRequest, UserApi} from "../generated-api";
import {API_CONFIG} from "app/api-config";
import {createAsyncThunk} from "@reduxjs/toolkit";

const userApi = new UserApi(API_CONFIG);

export const fetchUser = createAsyncThunk('user/get', async (args: CompanySpacesUsersIdGetRequest) => {
    return await userApi.companySpacesUsersIdGet(args);
});

export const fetchUsers = createAsyncThunk('user/list', async (args: CompanySpacesUsersGetRequest) => {
    return await userApi.companySpacesUsersGet(args);
});
