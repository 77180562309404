import PersonIcon from '@mui/icons-material/Person';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useAppTranslation } from 'services/i18n';
import { NavMenuLanguage } from './NavMenuLanguage';

interface Props {
  onClick: (
    e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => boolean;
}

export const NavMenuMiddleLinks = ({ onClick }: Props) => {
  const t = useAppTranslation();
  const navigationMiddleMenuLinks = [
    {
      string: t('contentViewer.nav.userProfile'),
      icon: <PersonIcon style={{ color: '#757575' }} />,
      url: '#/userProfile',
      target: '_self',
    },
    {
      string: t('contentViewer.nav.switchCompany'),
      icon: <SwapHorizIcon style={{ color: '#757575' }} />,
      url: '#/spaceSelect',
      target: '_self',
    },
  ];
  return (
    <div className="tw-border-b tw-border-lighterGray">
      {navigationMiddleMenuLinks.map(({ url, target, string, icon }) => (
        <a
          href={url}
          className="tw-flex tw-h-52 tw-items-center tw-gap-20 tw-px-16 tw-pt-5 hover:tw-bg-black/5"
          target={target}
          key={url}
          onClick={onClick}
        >
          {icon}
          <span>{string}</span>
        </a>
      ))}
      <NavMenuLanguage />
    </div>
  );
};
