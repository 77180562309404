import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import { useCallback, useEffect, useRef } from 'react';

import { useAppTranslation } from 'services/i18n';

import { ReactComponent as ArrowRightCircle } from 'icons/arrow-right-circle.svg';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { ReactComponent as RightActionKey } from 'icons/right-key-action.svg';
import { ReactComponent as RightKey } from 'icons/right-key.svg';
import { ReactComponent as LeftKey } from 'icons/left-key.svg';
import { ReactComponent as LeftActionKey } from 'icons/left-key-action.svg';
import { ReactComponent as EnterKey } from 'icons/enter-key.svg';
import { ReactComponent as EnterActionKey } from 'icons/enter-key-action.svg';
import { ReactComponent as EnterKeyDisabled } from 'icons/enter-key-disabled.svg';
import { ReactComponent as SpaceKey } from 'icons/space-key.svg';
import { ReactComponent as SpaceActionKey } from 'icons/space-key-action.svg';
import { ReactComponent as LeftKeyDisabled } from 'icons/left-key-disabled.svg';
import { ReactComponent as RightKeyDisabled } from 'icons/right-key-disabled.svg';
import { ReactComponent as ZoomIn } from 'icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'icons/zoom-out.svg';

import { ReactComponent as UpKey } from 'icons/up-key-action.svg';
import { ReactComponent as UpKeyDisabled } from 'icons/up-key-disabled.svg';
import { ReactComponent as DownKey } from 'icons/down-key-action.svg';
import { ReactComponent as DownKeyDisabled } from 'icons/down-key-disabled.svg';
import { useMediaQuery } from '@mui/material';
import { AnswerKeyType } from 'utils/types';
import { Button } from 'components/clickable/Button';
import { useSelector } from 'react-redux';
import { selectContentViewer } from 'store/selectors';

interface PageInfoProps {
  pageNumber: number;
  totalPages: number;
  className?: string;
}

export const PageInfo = ({ pageNumber, totalPages, className }: PageInfoProps) => {
  return (
    <div
      className={clsx(
        'tw-mr-4 tw-flex tw-h-32 tw-items-center tw-justify-center tw-rounded-lg tw-text-16 tw-text-white',
        className
      )}
    >
      {pageNumber} / {totalPages}
    </div>
  );
};

export interface KeyboardControlsProps {
  leftKeyText?: string;
  leftKeyDisabled?: boolean;
  isLeftKeyAction?: boolean;
  rightKeyText?: string;
  rightKeyDisabled?: boolean;
  isRightKeyAction?: boolean;
  enterKeyText?: string;
  enterKeyDisabled?: boolean;
  isEnterKeyAction?: boolean;
  upKeyDisabled?: boolean;
  downKeyDisabled?: boolean;
  spaceKeyText?: string;
  isSpaceKeyAction?: boolean;
  upDownKeyText?: string;
  pageNumber?: number;
  totalPages?: number;
  onLeftKeyPress?: () => void;
  onRightKeyPress?: () => void;
  onEnterKeyPress?: () => void;
  onSpaceKeyPress?: () => void;
  onUpKeyPress?: () => void;
  onDownKeyPress?: () => void;
  handleZoomIn?: () => void;
  handleZoomOut?: () => void;
  loading?: boolean;
  audioFile?: string;
  manualControl?: boolean;
  answerKeys?: AnswerKeyType[];
  onAnswerKeyPress?: (key: string) => void;
  disableControls?: boolean;
  canRepeatTestOnlyAfterStepsFinished?: boolean;
}

export const KeyboardControls = ({
  leftKeyText,
  leftKeyDisabled,
  rightKeyText,
  rightKeyDisabled,
  isRightKeyAction = true,
  isLeftKeyAction,
  enterKeyText,
  isEnterKeyAction,
  enterKeyDisabled,
  spaceKeyText,
  isSpaceKeyAction,
  upDownKeyText,
  pageNumber,
  totalPages,
  upKeyDisabled,
  downKeyDisabled,
  onLeftKeyPress,
  onRightKeyPress,
  onEnterKeyPress,
  onSpaceKeyPress,
  onUpKeyPress,
  onDownKeyPress,
  loading,
  handleZoomIn,
  handleZoomOut,
  audioFile,
  manualControl,
  answerKeys,
  onAnswerKeyPress,
  disableControls,
  canRepeatTestOnlyAfterStepsFinished,
}: KeyboardControlsProps) => {
  const t = useAppTranslation();
  const audioPlayerRef = useRef<HTMLAudioElement>(null);

  const handlePlayPauseAudio = useCallback(() => {
    return audioPlayerRef.current?.paused
      ? audioPlayerRef.current?.play()
      : audioPlayerRef.current?.pause();
  }, [audioPlayerRef]);

  const contentViewerState = useSelector(selectContentViewer);
  const stepParticipationLoading = contentViewerState.stepParticipationStatus === 'pending';

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;

      if (disableControls) return;
      if (key === 'ArrowRight' && onRightKeyPress && !rightKeyDisabled && !stepParticipationLoading)
        onRightKeyPress();
      if (key === 'ArrowLeft' && onLeftKeyPress && !leftKeyDisabled) onLeftKeyPress();
      if (key === 'ArrowUp' && onUpKeyPress && !upKeyDisabled) onUpKeyPress();
      if (key === 'ArrowDown' && onDownKeyPress && !downKeyDisabled) onDownKeyPress();
      if (key === 'Enter' && onEnterKeyPress && !enterKeyDisabled && !stepParticipationLoading)
        onEnterKeyPress();
      if (key === ' ' && onSpaceKeyPress) onSpaceKeyPress();
      if (key === ' ' && audioFile) handlePlayPauseAudio();
      if (answerKeys && answerKeys.findIndex((a) => a.text === key) >= 0 && onAnswerKeyPress)
        onAnswerKeyPress(key);
    },
    [
      disableControls,
      onRightKeyPress,
      onLeftKeyPress,
      onEnterKeyPress,
      onUpKeyPress,
      onDownKeyPress,
      enterKeyDisabled,
      leftKeyDisabled,
      rightKeyDisabled,
      upKeyDisabled,
      downKeyDisabled,
      onSpaceKeyPress,
      handlePlayPauseAudio,
      audioFile,
      answerKeys,
      onAnswerKeyPress,
      stepParticipationLoading,
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  //   disable native firefox audio keyboard controls (otherwise space is registered double)
  useEffect(() => {
    if (!audioPlayerRef.current) return;

    audioPlayerRef.current.addEventListener(
      'focus',
      function () {
        this.blur();
      },
      false
    );
  }, [audioPlayerRef]);

  const isMobile = useMediaQuery('(max-width: 1023px)');

  return (
    <section className="tw-h-48 tw-shrink-0 tw-bg-darkestGray lg:tw-h-120 lg:tw-px-16 lg:tw-py-8">
      {!loading && (
        <div
          className={clsx(
            'tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between lg:tw-mr-20 lg:tw-justify-start'
          )}
        >
          {/* mobile left button */}
          {isMobile && leftKeyText && (
            <div className="tw-mr-16 tw-flex tw-flex-row tw-items-center tw-gap-4 lg:tw-mr-0 lg:tw-w-128 lg:tw-flex-col">
              <IconButton
                className="tw-mr-8 tw-flex tw-items-center lg:tw-hidden"
                onClick={onLeftKeyPress}
                disabled={leftKeyDisabled}
              >
                <span className="tw-flex tw-h-48 tw-w-48 tw-items-center tw-justify-center tw-bg-white/5">
                  <ArrowLeft className="tw-stroke-white" />
                </span>
                <span
                  className={clsx(
                    'tw-ml-8 tw-text-14',
                    leftKeyDisabled ? 'tw-text-lightGray' : 'tw-text-white'
                  )}
                >
                  {leftKeyText}
                </span>
              </IconButton>
            </div>
          )}
          {pageNumber && totalPages ? (
            <PageInfo
              className="absolute-center-full tw-w-60 tw-shrink-0 "
              pageNumber={pageNumber}
              totalPages={totalPages}
            />
          ) : (
            <div className="tw-hidden tw-w-60 lg:tw-block" />
          )}

          <div className="lg:tw-flex-2 tw-flex tw-flex-grow tw-items-center tw-justify-center tw-space-x-20 lg:tw-justify-around">
            {manualControl && (
              <p className="tw-hidden tw-text-14 tw-text-white md:tw-inline">
                {t('contentViewer.keyboardControls.manualControl')}
              </p>
            )}

            {canRepeatTestOnlyAfterStepsFinished && (
              <p className="tw-hidden tw-text-14 tw-text-white lg:tw-inline">
                {t('contentViewer.keyboardControls.canRepeatTestOnlyAfterStepsFinished')}
              </p>
            )}

            {upDownKeyText && (
              <div className="tw-hidden tw-w-128 tw-flex-col tw-items-center tw-gap-4 lg:tw-flex">
                <div className="tw-flex tw-gap-8">
                  <IconButton
                    ref={(el) => {
                      el?.addEventListener(
                        'focus',
                        function () {
                          this.blur();
                        },
                        false
                      );
                    }}
                    onClick={onUpKeyPress}
                    disabled={upKeyDisabled}
                  >
                    {upKeyDisabled ? <UpKeyDisabled /> : <UpKey />}
                  </IconButton>
                  <IconButton
                    ref={(el) => {
                      el?.addEventListener(
                        'focus',
                        function () {
                          this.blur();
                        },
                        false
                      );
                    }}
                    onClick={onDownKeyPress}
                    disabled={downKeyDisabled}
                  >
                    {downKeyDisabled ? <DownKeyDisabled /> : <DownKey />}
                  </IconButton>
                </div>

                <span className={clsx('tw-text-14 tw-text-white')}>{upDownKeyText}</span>
              </div>
            )}

            {spaceKeyText && (
              <div className="tw-hidden tw-flex-col tw-items-center tw-gap-4 lg:tw-flex">
                <IconButton
                  onClick={onSpaceKeyPress}
                  disableFocusRipple={true}
                  // remove focus so space press isn't registered double
                  ref={(el) => {
                    el?.addEventListener(
                      'focus',
                      function () {
                        this.blur();
                      },
                      false
                    );
                  }}
                >
                  {isSpaceKeyAction ? <SpaceActionKey /> : <SpaceKey />}
                </IconButton>
                <span className="tw-whitespace-nowrap tw-text-14 tw-text-white">
                  {spaceKeyText}
                </span>
              </div>
            )}

            {audioFile && (
              <div className="absolute-center tw-absolute tw-bottom-64 tw-flex tw-items-center sm:tw-static">
                <audio
                  ref={audioPlayerRef}
                  className="tw-h-32 tw-w-360 tw-rounded-full tw-bg-gray lg:tw-w-430"
                  controls
                  src={audioFile}
                ></audio>
              </div>
            )}
          </div>

          <div
            className={clsx('tw-flex tw-items-center tw-justify-end lg:tw-flex-1 lg:tw-flex-grow')}
          >
            {!isMobile && leftKeyText && (
              <div className="tw-mr-auto tw-flex tw-flex-row tw-items-center tw-gap-4 lg:tw-mr-0 lg:tw-w-128 lg:tw-flex-col">
                <Button
                  kind="icon"
                  className="tw-hidden lg:tw-inline"
                  onClick={onLeftKeyPress}
                  disabled={leftKeyDisabled}
                >
                  {leftKeyDisabled ? (
                    <LeftKeyDisabled />
                  ) : isLeftKeyAction ? (
                    <LeftActionKey />
                  ) : (
                    <LeftKey />
                  )}
                </Button>
                <span
                  className={clsx(
                    'tw-hidden tw-text-14 lg:tw-inline',
                    leftKeyDisabled ? 'tw-text-lightGray' : 'tw-text-white'
                  )}
                >
                  {leftKeyText}
                </span>
              </div>
            )}

            {rightKeyText && onRightKeyPress && (
              <div
                className={clsx(
                  'tw-ml-auto tw-flex tw-flex-row tw-items-center tw-gap-4 lg:tw-ml-0 lg:tw-w-128 lg:tw-flex-col'
                )}
              >
                <Button
                  className="tw-hidden lg:tw-inline"
                  kind="icon"
                  onClick={stepParticipationLoading ? undefined : onRightKeyPress}
                  disabled={rightKeyDisabled || stepParticipationLoading}
                >
                  {rightKeyDisabled || stepParticipationLoading ? (
                    <RightKeyDisabled />
                  ) : isRightKeyAction ? (
                    <RightActionKey />
                  ) : (
                    <RightKey />
                  )}
                </Button>
                <span
                  className={clsx(
                    'tw-hidden tw-text-14 lg:tw-inline',
                    rightKeyDisabled ? 'tw-text-lightGray' : 'tw-text-white'
                  )}
                >
                  {rightKeyText}
                </span>

                {/* TODO refactor mobile and Button kind icon with label */}
                {/* mobile right button */}
                <Button
                  kind="icon"
                  className="tw-ml-8 tw-flex tw-items-center lg:tw-hidden"
                  onClick={stepParticipationLoading ? undefined : onRightKeyPress}
                  disabled={rightKeyDisabled || stepParticipationLoading}
                >
                  <span
                    className={clsx(
                      'tw-mr-8 tw-text-14',
                      rightKeyDisabled || stepParticipationLoading
                        ? 'tw-text-lightGray'
                        : 'tw-text-white'
                    )}
                  >
                    {rightKeyText}
                  </span>
                  <span className="tw-flex tw-h-48 tw-w-48 tw-items-center tw-justify-center tw-bg-white/5">
                    <ArrowRightCircle
                      className={clsx(
                        rightKeyDisabled || stepParticipationLoading
                          ? 'tw-fill-gray'
                          : isRightKeyAction
                          ? 'tw-fill-lightGreen'
                          : 'tw-fill-none'
                      )}
                    />
                  </span>
                </Button>
              </div>
            )}

            {((isMobile && enterKeyText && (!rightKeyText || rightKeyDisabled)) || !isMobile) && (
              <div className="tw-ml-auto tw-flex tw-justify-end tw-justify-self-end lg:tw-ml-0 xl:tw-w-320">
                {enterKeyText && onEnterKeyPress && (
                  <div className="tw-flex tw-items-center tw-justify-end tw-gap-4 lg:tw-w-100 lg:tw-flex-col">
                    <Button
                      className="tw-hidden lg:tw-inline"
                      kind="icon"
                      onClick={stepParticipationLoading ? undefined : onEnterKeyPress}
                      disabled={enterKeyDisabled || stepParticipationLoading}
                      disableFocus
                    >
                      {enterKeyDisabled || stepParticipationLoading ? (
                        <EnterKeyDisabled />
                      ) : isEnterKeyAction ? (
                        <EnterActionKey />
                      ) : (
                        <EnterKey />
                      )}
                    </Button>
                    <span className="tw-hidden tw-text-14 tw-text-white lg:tw-inline">
                      {enterKeyText}
                    </span>
                    <Button
                      kind="icon"
                      className="tw-flex tw-items-center tw-justify-center lg:tw-hidden"
                      onClick={stepParticipationLoading ? undefined : onEnterKeyPress}
                      disabled={enterKeyDisabled}
                      disableFocus
                    >
                      <span
                        className={clsx(
                          'tw-mr-8 tw-text-14 tw-text-white',
                          enterKeyDisabled ? 'tw-text-lightGray' : 'tw-text-white'
                        )}
                      >
                        {enterKeyText}
                      </span>
                      <span className="tw-flex tw-h-48 tw-w-48 tw-items-center tw-justify-center tw-bg-white/5">
                        <ArrowRightCircle
                          className={clsx(
                            'tw-inline lg:tw-hidden',
                            rightKeyDisabled || stepParticipationLoading
                              ? 'tw-fill-gray'
                              : 'tw-fill-lightGreen'
                          )}
                        />
                      </span>
                    </Button>
                  </div>
                )}

                <div className="tw-ml-16 tw-hidden tw-w-72 tw-gap-8 tw-self-center lg:tw-flex">
                  {handleZoomIn && handleZoomOut && (
                    <>
                      <IconButton
                        onClick={handleZoomIn}
                        className="tw-rounded-lg tw-bg-white/5 tw-p-8"
                      >
                        <ZoomIn />
                      </IconButton>

                      <IconButton
                        onClick={handleZoomOut}
                        className="tw-rounded-lg tw-bg-white/5 tw-p-8"
                      >
                        <ZoomOut />
                      </IconButton>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};
