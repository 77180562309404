import {Alert, Snackbar} from '@mui/material';
import {useCallback, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'store';

import {FlashMessageWithId, removeAllMessages, removeMessage, selectAppMessages} from 'store/localApp';

export const ToastContainer = () => {
  const appMessages = useSelector(selectAppMessages);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(removeAllMessages());
    }
  }, [dispatch]);

  const removeAppMessage = useCallback(
    (m: FlashMessageWithId) => {
      dispatch(removeMessage(m));
    },
    [dispatch]
  );
  return (
    <>
      {appMessages && appMessages.length > 0 && (
        createPortal(<div className={'snackbar-container'}>
          {appMessages?.slice(Math.max(0, appMessages.length - 5), appMessages.length).map((m) => {
            return (
              <Snackbar
                key={m.id}
                open={true}
                autoHideDuration={6000}
                onClose={(e, reason) => {
                  if (reason === 'clickaway') {
                    return false;
                  }
                  removeAppMessage(m);
                }}
              >
                <Alert severity={m.severity} variant={'standard'} className={'toast-' + m.severity} icon={false}>
                  {m.title}
                </Alert>
              </Snackbar>
            );
          })}
        </div>, document.body)
      )}
    </>
  );
};
