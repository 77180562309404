import {Formik, FormikErrors} from 'formik';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import {createCatalogue} from '../../store/catalogues';
import {Button, LinearProgress} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {isApiResultError} from '../../helpers/api';
import {QuestionCatalogueCreate} from "generated-api";
import {CatalogueFormFields} from "components/catalogue/CatalogueEditForm";

type Props = {
    onSuccess: (result: any) => unknown;
    onClose: () => unknown;
};

export const CatalogueAddForm = (props: Props) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);

    const {onSuccess, onClose,} = props;

    const handleSaveItem = useCallback(
        async (values: QuestionCatalogueCreate) => {
            setIsSaving(true);
            const result = await dispatch(createCatalogue({
                body: values
            }));
            setIsSaving(false);

            if (!isApiResultError(result)) {
                onSuccess(result.payload);
                onClose();
            }
        },
        [dispatch, onSuccess, onClose]
    );

    const handleValidate = useCallback((values: QuestionCatalogueCreate) => {
        let errors = {} as FormikErrors<QuestionCatalogueCreate>;
        if (!values.title) {
            errors.title = t('catalogues.form.error.titleRequired');
        }
        return errors;
    }, [t]);

    return (
        <Dialog open={true} onClose={props.onClose} PaperComponent={Paper}>
            <Formik
                initialValues={{title: '', author_ids: []} as QuestionCatalogueCreate}
                onSubmit={handleSaveItem}
                validate={handleValidate}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogTitle>
                            {t('catalogues.add')}
                        </DialogTitle>
                        <LinearProgress hidden={!isSaving}/>
                        <DialogContent>
                            <CatalogueFormFields/>
                        </DialogContent>
                        <DialogActions>
                            <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                                {t('form.cancel')}
                            </Button>
                            <Button color={'primary'} type={'submit'} disabled={isSaving}>
                                {t('form.add')}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};
