import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {deleteLearningEventParticipant, fetchLearningEvent} from "store/userGroup";
import {Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import {TextFormFieldPlain} from "components/form/TextFormField";
import {CheckboxPlain} from "components/form/CheckboxFormField";
import Button from "@mui/material/Button";
import {AddOutlined, CopyAllOutlined, Help} from "@mui/icons-material";
import {LearningEvent, UserGroup} from "pages/UserGroupsPage";
import {BulkSendInvitationResponseInvitedUsersInner, ContentLearningEventShowResponseOrdersInner} from "generated-api";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";
import {DataGridItemActions, isSearchWithinSubject} from "components/DataGrid";
import {addMessage} from "store/localApp";
import {isApiResultError} from "../../helpers/api";
import {getNgService, sortByFullname} from "utils/utils";
import {useModal} from "services/modal";

interface Order extends ContentLearningEventShowResponseOrdersInner {

}

interface Participant extends BulkSendInvitationResponseInvitedUsersInner {

}

const orderStyle: { [key in 'internal' | 'confirmed' | 'unconfirmed']: { [key in 'header']: SxProps<Theme> } } = {
    internal: {
        header: {background: '#EEEEEE', 'th': {color: 'var(--color-black-text)'}}
    },
    unconfirmed: {
        header: {background: 'var(--color-warning-back)', 'th': {color: 'var(--color-black-text)'}}
    },
    confirmed: {
        header: {background: 'var(--color-primary-default15)', 'th': {color: 'var(--color-black-text)'}}
    }
}

const UserGroupOrder = ({order, search, onAddParticipant, onRemoveParticipant, onShowParticipant, hasMassActions}: {
    order: Order,
    search?: string,
    onAddParticipant: (event?: any) => void,
    onRemoveParticipant: (participant: Participant) => void,
    onShowParticipant: (participant: Participant) => void,
    hasMassActions: boolean,
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const isInternal = order.internal;

    return <TableBody>
        <TableRow sx={orderStyle[isInternal ? 'internal' : 'confirmed' /* unconfirmed */].header}>
            <TableCell>
                {hasMassActions && <CheckboxPlain name={'toggleRows'} disabled={true}/>}
            </TableCell>
            <TableCell>{isInternal ? t('userGroup.registration.internalUsers') : order.company_space?.name}</TableCell>
            <TableCell></TableCell>
            <TableCell>
                <Tooltip title={<div>
                    <strong>{t('userGroup.registration.link.label')}</strong>
                    <div dangerouslySetInnerHTML={{__html: t('userGroup.registration.link.hint')}}/>
                </div>}>
                    <Button color={'inherit'} variant={'text'}
                        onClick={() => {
                            navigator.clipboard.writeText(order.invitation_link);
                            dispatch(addMessage({
                                severity: 'success',
                                title: t('userGroup.registration.link.copied')
                            }));
                        }}
                    >
                        <CopyAllOutlined/>
                    </Button>
                </Tooltip>
            </TableCell>
            <TableCell colSpan={6}></TableCell>
            <DataGridItemActions item={order} isActionMenu actions={[
                {title: 'userGroup.registration.addUser', callback: onAddParticipant},
            ]}/>
        </TableRow>
        {order.participants
            ?.filter(p => isSearchWithinSubject(search, p.full_name))
            ?.sort(sortByFullname)
            ?.map((participant, i) => <TableRow key={i}>
                <TableCell>
                    {hasMassActions && <CheckboxPlain name={'toggleRow' + i}/>}
                    <img alt={participant.full_name} style={{width: '32px', borderRadius: '50%', verticalAlign: 'middle', marginLeft: '-8px'}}
                        src={participant.small_picture_url || participant.picture_url}/>
                </TableCell>
                <TableCell>{participant.full_name}</TableCell>
                <TableCell>{participant.email}</TableCell>
                <TableCell colSpan={7}></TableCell>
                <DataGridItemActions item={participant} isActionMenu actions={[
                    {title: 'userGroup.registration.removeUser.action', callback: onRemoveParticipant},
                    {title: 'userGroup.registration.showUser', callback: onShowParticipant}
                ]}/>
            </TableRow>)}
        <TableRow>
            <TableCell colSpan={11}>
                <Tooltip placement={'right-end'} title={<div>
                    <strong>{'Přidání uživatele do plánu'}</strong>
                    <div dangerouslySetInnerHTML={{__html: 'Přidejte interní uživatele z organizace. Stačí zadat e-mail nebo jméno.'}}/>
                </div>}>
                    <Button color={'dark' as any} variant={'text'}
                        onClick={onAddParticipant}
                        className={'tw-w-auto tw-normal-case'}>
                        <AddOutlined/>
                        &nbsp;&nbsp;&nbsp;&nbsp;{t('userGroup.registration.addUser')}
                    </Button>
                </Tooltip>
            </TableCell>
        </TableRow>
    </TableBody>
}

export const UserGroupRegisteredTab = ({userGroup}: { userGroup: UserGroup }) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const [search, setSearch] = useState('');
    const [learningEvent, setLearningEvent] = useState<LearningEvent>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchEvent = useCallback(async () => {
        if (!userGroup.learning_event?.id) {
            return;
        }
        setIsLoading(true);
        try {
            const res = await dispatch(fetchLearningEvent({id: String(userGroup.learning_event.id)}));
            if (!isApiResultError(res)) {
                setLearningEvent(res.payload as LearningEvent);
            }
        } finally {
            setIsLoading(false);
        }
    }, [userGroup, dispatch]);

    const handleAddParticipant = useCallback(async (event?: any) => {
        try {
            // addUserToGroupDialog.showDialog(userGroup, event, isAdminDashboard)
            await getNgService('addUserToGroupDialog').showDialog(userGroup, event, false);
            await fetchEvent();
        } catch (_) {
        }
    }, [userGroup, fetchEvent]);

    const handleShowParticipant = useCallback(async (participant: Participant) => {
        try {
            // userDetailDialog.showUserDetail(user, event, ctrl.users.removeItem.bind(ctrl.users, index));
            await getNgService('userDetailDialog').showUserDetail(participant);
            await fetchEvent();
        } catch (_) {
        }
    }, [fetchEvent]);

    const handleRemoveParticipant = useCallback(async (participant: Participant) => {
        if (!learningEvent?.id) {
            return;
        }
        const result = await modal.confirm({
            title: t('userGroup.registration.removeUser.title'),
            message: t('userGroup.registration.removeUser.body', participant),
            confirmText: t('userGroup.registration.removeUser.button')
        });
        if (result !== 'CONFIRM') {
            return;
        }
        await dispatch(deleteLearningEventParticipant({learningEventId: learningEvent.id, id: '' + participant.user_in_company_space_id}));
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.registration.removeUser.success')
        }));
        await fetchEvent();
    }, [learningEvent?.id, fetchEvent, t, modal, dispatch]);

    const filteredOrders = useMemo(() => {
        if (!search || !learningEvent?.orders) {
            return learningEvent?.orders;
        }
        // const matchedOrders = learningEvent.orders
        //     .filter(o => isSearchWithinSubject(search, o.internal ? t('userGroup.registration.internalUsers') : o.company_space?.name));

        return learningEvent.orders.filter(o => o.participants?.find(p => isSearchWithinSubject(search, p.full_name)));
    }, [search, learningEvent?.orders]);

    useEffect(() => {
        fetchEvent().then();
    }, [fetchEvent]);

    if (learningEvent === undefined) {
        return <LinearProgress/>;
    }

    const hasMassActions = false;

    const tableStyle: SxProps = {
        marginTop: '32px',
        'th.MuiTableCell-head, th, td': {
            padding: '4px 4px',
            border: 'none', '.MuiFormControlLabel-root': {
                margin: 0
            }
        },
        '.MuiTableHead-root': {
            'th': {
                color: '#8D8D8D',
                fontWeight: 'normal',
                border: 'none !important',
                borderBottom: '1px solid #8D8D8D !important'
            }
        },
        '.MuiTableCell-root:first-of-type:not(:last-of-type)': {
            width: '45px',
            maxWidth: '45px',
            padding: '4px 16px'
        }
    }

    return <Grid container sx={{padding: '16px 24px', background: '#fff'}}>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <TextFormFieldPlain
                        name={'search'}
                        currentValue={search}
                        onChange={setSearch}
                        label={t('userGroup.filter.search.label')}
                        autoComplete={'off'}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={8}>

                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} className={'tw-relative'}>
            {isLoading && <LinearProgress className={'tw-absolute tw-left-0 tw-right-0'}/>}
            <TableContainer sx={tableStyle}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {hasMassActions && <CheckboxPlain name={'toggleAll'}/>}
                            </TableCell>
                            <TableCell>{t('userGroup.registration.participant')}</TableCell>
                            <TableCell>{t('userGroup.registration.email')}</TableCell>
                            <TableCell>{t('userGroup.registration.invitation')}</TableCell>
                            <TableCell>{t('userGroup.registration.note')}</TableCell>
                            <TableCell>{t('userGroup.registration.order')}</TableCell>
                            <TableCell>{t('userGroup.registration.ordered')}</TableCell>
                            <TableCell>{t('userGroup.registration.confirmed')}</TableCell>
                            <TableCell>{t('userGroup.registration.userCount')}</TableCell>
                            <TableCell>{t('userGroup.registration.available')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    {filteredOrders?.map((order, i) => <UserGroupOrder key={i}
                        order={order}
                        search={search}
                        onAddParticipant={handleAddParticipant}
                        onRemoveParticipant={handleRemoveParticipant}
                        onShowParticipant={handleShowParticipant}
                        hasMassActions={hasMassActions}
                    />)}
                    {!filteredOrders?.length && <TableRow>
                        <TableCell></TableCell>
                        <TableCell colSpan={10} className={'tw-p-0'}>
                            <div className={'data-grid-zero-data'}>{t('common.emptySearch')}</div>
                        </TableCell>
                    </TableRow>}
                </Table>
            </TableContainer>
        </Grid>
    </Grid>;
}
