import clsx from 'clsx';

interface Props {
  textValue: string;
  label: string;
  isChecked?: boolean;
  isCorrect?: boolean;
  isMissed?: boolean;
  className?: string;
}

export const InputLabel = ({
  textValue,
  label,
  isChecked,
  className,
  isCorrect,
  isMissed,
}: Props) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-10">
      <div
        className={clsx(
          'input-label tw-flex tw-h-40 tw-w-40 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-md',
          isChecked || isCorrect
            ? 'tw-bg-lightGreen tw-font-semibold tw-text-white'
            : isCorrect !== undefined && !isCorrect
            ? 'tw-bg-red tw-font-semibold tw-text-white'
            : 'tw-bg-black/5 tw-text-darkestGray',
          isMissed && 'tw-border-2 tw-border-red tw-bg-white tw-font-semibold tw-text-red',
          (isMissed !== undefined || isCorrect !== undefined) && 'tw-font-semibold',
          className
        )}
      >
        {label}
      </div>
      <span className={clsx(isChecked && 'tw-font-semibold', 'tw-leading-22 tw-text-darkestGray')}>
        {textValue}
      </span>
    </div>
  );
};
