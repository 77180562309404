import clsx from 'clsx';
import { Link } from 'react-router-dom';

export interface ClickableProps {
  href?: string;
  to?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
  ariaLabel?: string;
  openInNewWindow?: boolean;
  disableFocus?: boolean;
  scrollIntoView?: boolean;
}

export const Clickable: React.FC<ClickableProps> = ({
  disabled,
  href,
  className,
  children,
  onClick,
  ariaLabel,
  to,
  openInNewWindow,
  disableFocus,
  scrollIntoView,
}) => {
  const classes = clsx(className, disabled && 'cursor-not-allowed');
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const { key } = event;
    if (key === ' ') {
      event.preventDefault();
    }
  };
  if (href) {
    return (
      <a
        className={classes}
        href={href}
        aria-label={ariaLabel}
        target={openInNewWindow ? '_blank' : '_self'}
        rel="noreferrer"
        ref={(el) => {
          disableFocus &&
            el?.addEventListener(
              'focus',
              function () {
                this.blur();
              },
              false
            );
          if (scrollIntoView) el?.scrollIntoView(false);
        }}
      >
        {children}
      </a>
    );
  } else if (to) {
    return (
      <Link
        className={classes}
        to={to}
        aria-label={ariaLabel}
        target={openInNewWindow ? '_blank' : '_self'}
        ref={(el) => {
          disableFocus &&
            el?.addEventListener(
              'focus',
              function () {
                this.blur();
              },
              false
            );
          if (scrollIntoView) el?.scrollIntoView(false);
        }}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button
        className={classes}
        disabled={disabled}
        onClick={onClick}
        aria-label={ariaLabel}
        onKeyDown={handleKeyDown}
        ref={(el) => {
          disableFocus &&
            el?.addEventListener(
              'focus',
              function () {
                this.blur();
              },
              false
            );
          if (scrollIntoView) el?.scrollIntoView(false);
        }}
      >
        {children}
      </button>
    );
  }
};
