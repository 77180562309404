import {
    CompanySpacesUsersUserIdMarketplaceProfileGetRequest,
    MarketplaceProfileApi
} from "../generated-api";
import {API_CONFIG} from "app/api-config";
import {createAsyncThunk} from "@reduxjs/toolkit";

const marketplaceProfileApi = new MarketplaceProfileApi(API_CONFIG);

export const fetchMarketplaceProfile = createAsyncThunk('marketplaceProfile/get', async (args: CompanySpacesUsersUserIdMarketplaceProfileGetRequest) => {
    return await marketplaceProfileApi.companySpacesUsersUserIdMarketplaceProfileGet(args);
});
