import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from '../services/i18n';
import {
  CompanySpacesOrganizationUnitsIdDeleteRequest,
  CompanySpacesOrganizationUnitsIdPutRequest,
  OrganizationUnitApi,
  OrganizationUnitBulkCreate,
} from '../generated-api';
import { API_CONFIG } from '../app/api-config';

const organizationUnitApi = new OrganizationUnitApi(API_CONFIG);

export interface LocalCodebookItem<T = number | string, TP = any> {
  label: string;
  value: T;
  param?: TP;
}

export const initStaticCodebooks = createAction('codebook/init-static', () => {
  const enums = t('enums', { returnObjects: true });
  const payload = {} as { [name: string]: LocalCodebookItem[] };
  Object.keys(enums).forEach((item: string) => {
    payload[item] = [];
    Object.keys(enums[item as any]).forEach((value: string) => {
      payload[item].push({ value, label: enums[item as any][value as any] });
    });
  });
  return { payload };
});

export interface BaseCodebook /*extends OrganizationUnitUpdate*/ {
  id: number;
  name: string;
  category: string;
  created_at: Date;
  updated_at: Date;
  user_count?: number;
}

export interface BaseCodebookBulkRequest extends OrganizationUnitBulkCreate {}

export const fetchOrgUnits = createAsyncThunk('orgUnits/all', async () => {
  return await organizationUnitApi.companySpacesOrganizationUnitsGet();
});

export const createOrgUnits = createAsyncThunk(
  'orgUnits/create',
  async (body: OrganizationUnitBulkCreate) => {
    return await organizationUnitApi.companySpacesOrganizationUnitsBulkCreatePost({ body });
  }
);

export const updateOrgUnit = createAsyncThunk(
  'orgUnits/update',
  async (request: CompanySpacesOrganizationUnitsIdPutRequest) => {
    return organizationUnitApi.companySpacesOrganizationUnitsIdPut(request);
  }
);

export const deleteOrgUnit = createAsyncThunk(
  'orgUnits/delete',
  async (request: CompanySpacesOrganizationUnitsIdDeleteRequest) => {
    return organizationUnitApi.companySpacesOrganizationUnitsIdDelete(request);
  }
);

const initialState = {
  department: [] as BaseCodebook[],
  job_title: [] as BaseCodebook[],
};

export type CodebookState = {
  [name: string]: LocalCodebookItem[];
};
export type CodebookCodesType = keyof CodebookState;

export const codebooksSlice = createSlice({
  name: 'codebooks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initStaticCodebooks, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(fetchOrgUnits.fulfilled, (state, action) => {
      return {
        ...state,
        department: action.payload.filter((i) => i.category === 'department'),
        job_title: action.payload.filter((i) => i.category === 'job_title'),
      };
    });
  },
});

export const codebooksReducer = codebooksSlice.reducer;
// eslint-disable-next-line no-empty-pattern
export const {} = codebooksSlice.actions;
